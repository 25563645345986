import React from 'react';
import { Tooltip } from '@material-ui/core';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { useStyles } from './Button.css.js';

export const ButtonWithTooltip = ({ text, disabled, type, callback, color = 'defaultColor', smallButton = false, showMoreButton = false, onClick, tooltipTitle = '', tooltipPosition = 'top' }) => {

  const classes = useStyles();
  const [t] = useTranslation('common');

  const smallButtonStyle = smallButton ? classes.smallContainer : '';
  const showMoreButtonStyle = showMoreButton ? classes.showMoreButtonContainer : '';

  return (
    <Tooltip title={tooltipTitle} placement={tooltipPosition}>
      <div className={classes.container}>
        <button
          type={type}
          disabled={disabled}
          className={`${classes.container} ${classes[color]} ${disabled && classes.disabled} ${smallButtonStyle} ${showMoreButtonStyle}`}
          onClick={() => {
            if (callback) {
              callback();
            }
            if (onClick) {
              onClick();
            }
          }}>
          {t(text)}
        </button>
      </div>
    </Tooltip>
  );
};

ButtonWithTooltip.propTypes = {
  text: PropTypes.string,
  type: PropTypes.string,
  disabled: PropTypes.bool,
  callback: PropTypes.func,
  color: PropTypes.string,
  smallButton: PropTypes.bool,
  showMoreButton: PropTypes.bool,
  onClick: PropTypes.func,
  tooltipTitle: PropTypes.string,
  tooltipPosition: PropTypes.string
};