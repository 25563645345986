import React from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@material-ui/core';
import { arrayOfFonts, arrayOfFontSizes } from 'helpers';
import { FormSelectFilter } from 'components';
import { TwitterPicker } from 'react-color';

import { useStyles } from './SendOfferPage.css.js';

export const initFontStyle = {
  isBold: false,
  isItalic: false,
  isUnderline: false,
  family: 'Arial',
  size: 30,
  color: '#FFFFFF'
};

export const colors = [
  '#FFFFFF', '#FCF404', '#FF6404', '#DC0808', '#F00884', '#4800A4', '#0000D4', '#00ACE8',
  '#20B814', '#006410', '#582C04', '#907038', '#C0C0C0', '#808080', '#404040', '#000000'
];

export const FontStyleComponent = ({ fontStyle, onChange }) => {

  const { family, size, color, isBold, isItalic, isUnderline } = fontStyle ?? initFontStyle;
  const classes = useStyles();

  const getFontStyle = (value) => {
    const font = arrayOfFonts.find(x => x.value === value);
    if (font) {
      return font.style;
    }
    return {};
  };

  return (
    <Grid container spacing={0}>
      <Grid item xs={12} md={12} className={classes.maxWidth}>
        <span className={classes.color_pallete_label}>Font Color<span style={{ backgroundColor: color }}></span></span>
        <TwitterPicker className={classes.color_pallete} colors={colors} triangle='hide' width={size > 1200 ? '340px' : '%100'}
          onChange={(evt) => {
            onChange({ color: evt.hex });
          }} />
      </Grid>

      <Grid item xs={12} md={12}>
        <FormSelectFilter
          label='font'
          selectedValueStyle={getFontStyle(family)}
          className={classes.selectInputField}
          onChange={(e) => {
            onChange({ family: e.target.value });
          }}
          value={family}
          menuItem={arrayOfFonts} />
      </Grid>

      <Grid container spacing={0}>
        <Grid item xs={3} md={3}>
          <FormSelectFilter
            label='fontSize'
            className={classes.selectInputField}
            onChange={(e) => {
              onChange({ size: Number(e.target.value) });
            }}
            value={size}
            menuItem={arrayOfFontSizes} />
        </Grid>
        <Grid item xs={9} md={9}>
          <button type='button' className={`${classes.fontStyleButton} ${isBold ? classes.activeButton : classes.inactiveButton}`}
            onClick={() => {
              onChange({ isBold: !isBold });
            }}>
            <b>B</b>
          </button>
          <button type='button' className={`${classes.fontStyleButton} ${isItalic ? classes.activeButton : classes.inactiveButton}`}
            onClick={() => {
              onChange({ isItalic: !isItalic });
            }}>
            <i>I</i>
          </button>
          <button type='button' className={`${classes.fontStyleButton} ${isUnderline ? classes.activeButton : classes.inactiveButton}`}
            onClick={() => {
              onChange({ isUnderline: !isUnderline });
            }}>
            <u>U</u>
          </button>
        </Grid>
      </Grid>
    </Grid>
  );
};

FontStyleComponent.propTypes = {
  fontStyle: PropTypes.object,
  onChange: PropTypes.func,
};
