import { makeStyles } from '@material-ui/core/styles';
import { displayBorder, displayBorderExtraLarge } from 'helpers';

export const useStyles = makeStyles((theme) => ({
  container: {
    width: '100%',
    margin: 'auto',
    [theme.breakpoints.up(displayBorder)]: {
      width: '50%'
    }
  },
  closeImg: {
    cursor: 'pointer',
    float: 'right',
    marginTop: '5px',
    width: '100%',
    padding: '0px 6px 0px 0px',
    color: theme.primaryButtonColor,
    fontSize: '1.5rem',
    '&:hover': {
      backgroundColor: 'transparent'
    }
  },
  dialogTitle: {
    width: 'auto',
    height: 'auto',
    marginTop: '5%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
    position: 'relatiev',
    fontSize: '1.3rem',
    '& .MuiTypography-h6': {
      fontFamily: theme.fontLato,
      fontWeight: 'bold'
    },
  },
  positionEnd: {
    justifyContent: 'flex-end',
    alignItems: 'flex-end',
  },
  dialogPaper: {
    minHeight: '70vh',
    maxHeight: '70vh',
    [theme.breakpoints.up(displayBorderExtraLarge)]: {
      minHeight: '55vh',
      maxHeight: '55vh',
    }
  },
  dialogPaperSafari: {
    minHeight: '55vh',
    maxHeight: '55vh'
  },
  feedbackMessage: {
    margin: '100px auto',
  },
  directionRow: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%'
  }
}));