import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(theme => ({
  container: {
    display: 'flex',
    width: '100%',
    minHeight: '100vh',
    backgroundColor: theme.quinaryBackgroundColor,
    alignItems: 'center',
    justifyContent: 'center'
  },
  formContainer: {
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    width: '32.5rem',
    height: '46.875rem',
    margin: 'auto'
  },
  inputFieldContainer: {
    backgroundColor: theme.quaternaryBackgroundColor,
    paddingRight: '4rem',
    paddingLeft: '4rem',
    justifyContent: 'center'
  },
  logo: {
    display: 'block',
    textAlign: 'center',
    paddingTop: '1.5rem',
    margin: 'auto',
  }
  , '@media screen and (max-width:600px)': {
    container: {
      backgroundColor: 'white'
    },
    formContainer: {
      height: 'auto',
    }
  }
}));
