import React, { useState } from 'react';
import clsx from 'clsx';
import { format } from 'date-fns';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { Card, Grid } from '@material-ui/core';
import { DeleteSharp, Edit, Filter } from '@material-ui/icons';

import { DeleteDialog, toastNotification } from 'components';
import { dateFormat } from 'helpers';
import { gifTemplateService } from 'services/gifTemplates.service.js';

import { useStyles } from './EnhancedCardTableItem.css.js';
import './EnhancedCardTableItem.css';

const FIRST_PAGE = 1;

export const EnhancedCardTableItem = ({ item, browseMode, deletedGif, setdeletedGif, selectedCardItem, setselectedCardItem, onDoubleClick, setPage }) => {

  const classes = useStyles();
  const { t } = useTranslation(['common', 'error']);
  const navHistory = useHistory();
  const [isOpen, setDialogOpen] = useState(false);

  const createAt = new Date(item.createdAt);
  const modifiedAt = new Date(item.modifiedAt);
  const canModify = item.canModify ?? false;

  const handleDelete = async () => {
    try {
      await gifTemplateService.deleteGifTemplate(item.id);
      toastNotification('info', t('gifTemplateIsDeleted'));
      setdeletedGif(!deletedGif);
      setPage(FIRST_PAGE);
    } catch (error) {
      toastNotification('error', t(`error:${Object.values(error.response.data.errors)[0]}`));
    }
  };

  const handleClick = () => {
    setselectedCardItem(item);
  };

  const handleDoubleClick = () => {
    setselectedCardItem(item);
    onDoubleClick();
  };

  const browseStyle = browseMode ? classes.browseBorder : '';

  let selectedCardStyle = '';
  if (browseMode) {
    selectedCardStyle = item.id === selectedCardItem?.id ? classes.browseSelectedBorder : classes.browseBorder;
  }

  return (
    <Card
      key={item.id}
      id={item.id}
      name='gifTemplateCard'
      className={clsx(classes.cardContainer, browseStyle, selectedCardStyle)}
      onClick={browseMode ? () => handleClick() : null}
      onDoubleClick={browseMode ? () => handleDoubleClick() : null}>
      <DeleteDialog isOpen={isOpen} setDialogOpen={setDialogOpen} callback={handleDelete} />
      <Grid className={classes.titleContainer}><b className={classes.noSpacing}>{item.title}</b></Grid>
      <div className='container'>
        <img src={item.url} className='image' />
        {!browseMode &&
          <div className='overlay'>
            <Grid container className={classes.spaceBetween}>
              {canModify &&
                <>
                  <Grid container className={classes.iconGrid}>
                    <Grid item onClick={() => navHistory.push(`/gifTemplates/edit/${item.id}`)}>
                      <span className={classes.wrapperIcon}>
                        <Edit className={classes.icon}></Edit>
                      </span>
                    </Grid>
                  </Grid>
                  <Grid container className={classes.iconGrid}>
                    <Grid item onClick={() => setDialogOpen(true)}>
                      <span className={classes.wrapperIcon}>
                        <DeleteSharp className={classes.icon}></DeleteSharp>
                      </span>
                    </Grid>
                  </Grid>
                </>}
              <Grid container className={classes.iconGrid}>
                <Grid item onClick={() => navHistory.push(`/gifTemplates/create?from=${item.id}`)}>
                  <span className={classes.wrapperIcon}>
                    <Filter className={classes.icon}></Filter>
                  </span>
                </Grid>
              </Grid>
            </Grid>
          </div>
        }
      </div>
      <div className={classes.bottomText}>
        <div className={classes.noSpacing}>{item.description}</div>
        <div className={classes.datePasus}>
          <p className={classes.lightText}>Created: {format(createAt, dateFormat)}</p>
          <p className={classes.lightText}>Last Modified: {format(modifiedAt, dateFormat)}</p>
        </div>
      </div>
    </Card>
  );
};

EnhancedCardTableItem.propTypes = {
  item: PropTypes.object,
  browseMode: PropTypes.bool,
  deletedGif: PropTypes.bool,
  setdeletedGif: PropTypes.func,
  selectedCardItem: PropTypes.object,
  setselectedCardItem: PropTypes.func,
  onDoubleClick: PropTypes.func,
  setPage: PropTypes.func,
};