import { makeStyles } from '@material-ui/core/styles';
import { displayBorder } from 'helpers';

export const useStyles = makeStyles((theme) => ({
  container: {
    width: '100%',
    paddingLeft: '0.5rem',
    paddingBottom: '0.5rem',
    position: 'relative'
  },
  form: {
    width: '100%'
  },
  header: {
    fontSize: '24px',
    fontFamily: theme.fontLato,
    color: theme.quaternaryTextColor,
    letterSpacing: 0,
    [theme.breakpoints.down(displayBorder)]: {
      marginBottom: 0
    },
    width: '100%',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    lineClamp: 2,
  },
  userActions: {
    marginTop: '5%',
    placeContent: 'end',
    [theme.breakpoints.down(displayBorder)]: {
      placeContent: 'space-between',
      flexWrap: 'nowrap'
    },
  },
  sectionTitle: {
    marginBottom: '2%'
  },
  modal: {
    height: 'fit-content',
    minHeight: 'calc(100vh - 150px)',
    padding: '40px',
    backgroundClip: 'content-box',
    boxShadow: 'inset 0 0 0 40px' + theme.quinaryBackgroundColor,
    margin: '0 auto',
    maxWidth: '100%'
  },
  modalPhone: {
    padding: '1rem'
  },
  selectRoleContainer: {
    alignItems: 'center',
    marginBottom: '0.8rem'
  },
  selectRole: {
    color: theme.primaryTextColor
  },
  iconStyle: {
    height: '1.5rem',
    width: '1.5rem',
    color: theme.primaryBackgroundColor,
    paddingLeft: '0,'
  },
  uncheckedIconStyle: {
    height: '1.5rem',
    width: '1.5rem',
    color: theme.primaryBorderColor,
    paddingLeft: '0,'
  },
  isRequired: {
    marginRight: '1rem',
    color: theme.primaryBackgroundColor
  },
  role: {
    marginTop: 0,
    color: theme.primaryTextColor
  },
}));
