import clsx from 'clsx';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Grid } from '@material-ui/core';

import { useStyles } from './SwitchButton.css.js';

export const SwitchItemButton = ({ label, icon, checked, setChecked, md = 6 }) => {
  const classes = useStyles();
  const [t] = useTranslation('common');
  return (
    <Grid item md={md} className={clsx(classes.button, { [classes.checked]: checked, [classes.notChecked]: !checked })} onClick={() => !checked && setChecked()}>
      {!!icon && <img className={classes[label]} src={icon} alt={''} />}
      <Grid item className={classes.label}>
        {t(label)}
      </Grid>
    </Grid>
  );
};

SwitchItemButton.propTypes = {
  icon: PropTypes.any,
  label: PropTypes.string,
  checked: PropTypes.bool,
  setChecked: PropTypes.func,
  md: PropTypes.number
};