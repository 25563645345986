import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  stickyHeader: {
    height: '4.375rem',
    position: 'sticky',
    top: '0',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    backgroundColor: theme.tertiaryBackgroundColor,
    zIndex: '3',
    paddingLeft: '2rem',
    paddingRight: '2rem',
  },
  flex: {
    display: 'flex',
    alignContent: 'center'
  },
  logo: {
    width: '13.125rem',
    height: '2.413rem'
  },
  gutters: {
    paddingLeft: 0,
    paddingRight: 0
  },
  menu: {
    padding: '0.8rem',
    cursor: 'pointer'
  },
  downIcon: {
    paddingBottom: '0.25rem'
  },
  settingsIcon: {
    paddingRight: '0.5rem',
    paddingTop: '0.2rem'
  },
  welcomeMessage: {
    paddingRight: '0.5rem',
    color: theme.quaternaryBackgroundColor
  },
  settingsContainer: {
    paddingRight: '1rem'
  },
  cursor: {
    cursor: 'pointer'
  },
  logout: {
    borderTop: '1px solid' + theme.primaryBorderColor,
    color: theme.primaryBorderColor
  },
  listItemIcon: {
    minWidth: '40px'
  },
  listItemImg: {
    width: '16px',
    height: '16px',
  },
  '@media screen and (max-width: 615px)': {
    welcomeMessage: {
      display: 'none'
    },
    stickyHeader: {
      height: '3rem',
    },
    logo: {
      width: '10rem',
      height: '3rem'
    },
    menuListIcon: {
      width: '1rem',
      marginLeft: '0.3rem',
    },
    notificationListIcon: {
      marginTop: '0.3rem',
      marginLeft: '0.3rem'
    },
    menu: {
      paddingLeft: '0.1rem'
    },
    settingsContainer: {
      paddingRight: '0.5rem'
    },
  },
  '@media screen and (max-width: 380px)': {
    settingsContainer: {
      paddingRight: '0.2rem'
    },
    stickyHeader: {
      paddingRight: '1.1rem',
      paddingLeft: '1.1rem'
    },
    notificationListIcon: {
      marginTop: '0.3rem',
      marginLeft: '0.3rem'
    },
    menu: {
      paddingLeft: '0.1rem',
      paddingRight: '0'
    }
  },
  notificationsContainer: {
    marginRight: '0.5rem',
    cursor: 'pointer'
  },
  alignItemsCenter: {
    alignItems: 'center'
  },
  marginRight: {
    marginRight: '0.2rem'
  },
}));