import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  container: {
    border: '1px solid; border-radius: 7px; height: 3.5rem;',
    borderColor: theme.primaryBorderColor,
    width: '100%',
    marginBottom: '1.5rem'
  },
  containerDragAndDrop: {
    border: '1px dashed; border-radius: 7px; height: 4.5rem;',
    borderColor: theme.primaryBorderColor,
    backgroundColor: theme.denaryBackgroundColor,
    width: '100%',
    marginBottom: '1.5rem'
  },
  inputContainer: {
    width: '98%'
  },
  passwordInputContainer: {
    width: '80%',
  },
  maxWidth: {
    width: '100%'
  },
  input: {
    marginLeft: '0.2rem',
    paddingLeft: '0.5rem',
    borderLeft: '1px solid ' + theme.borderColor,
    border: '0',
    outline: 'none',
    top: '0.6rem',
    height: '2rem',
    position: 'relative',
    backgroundColor: 'transparent',
    '&:-webkit-autofill': {
      WebkitBoxShadow: '0 0 0 1000px white inset',
      fontSize: '1rem'
    },
    fontSize: '1rem',
    width: '97%'
  },
  inputDragAndDrop: {
    marginLeft: '0.2rem',
    paddingLeft: '0.5rem',
    borderLeft: '1px solid ' + theme.borderColor,
    border: '0',
    outline: 'none',
    height: '4.5rem',
    position: 'relative',
    backgroundColor: 'transparent',
    '&:-webkit-autofill': {
      WebkitBoxShadow: '0 0 0 1000px white inset',
      fontSize: '1rem'
    },
    fontSize: '1rem',
    width: '100%',
    textAlign: 'center',
    color: theme.primaryTextColor
  },
  spaceBetween: {
    direction: 'row',
    justifyContent: 'center',
    alignSelf: 'center',
    paddingTop: '1.5rem'
  },
  iconDorp: {
    fontSize: '1.2rem',
    paddingTop: '0.1rem',
    paddingRight: '0.5rem'
  },
  hiddenInputDragAndDrop: {
    opacity: '0',
    top: '-4.4rem'
  },
  validation: {
    color: theme.validationTextColor,
    top: '1.4rem',
    position: 'relative',
    textAlign: 'center',
    fontSize: '1rem',
    fontWeight: 'normal',
    backgroundColor: theme.validationBackgroundColor,
    width: '102%',
    zIndex: '1000'
  },
  validationNegativeTop: {
    top: '-4.4rem'
  },
  validationNegativeTopBrowse: {
    top: '-3.5rem'
  },
  passwordValidation: {
    color: theme.validationTextColor,
    top: '1.4rem',
    position: 'relative',
    textAlign: 'center',
    fontSize: '1rem',
    backgroundColor: theme.validationBackgroundColor,
    width: '125%'
  },
  conversationValidation: {
    color: theme.validationTextColor,
    top: '1.4rem',
    position: 'relative',
    textAlign: 'center',
    fontSize: '1rem',
    fontWeight: 'normal',
    backgroundColor: theme.validationBackgroundColor,
    width: '96%'
  },
  labelContainer: {
    flexDirection: 'row'
  },
  label: {
    outline: 'none',
    bottom: '0.5rem',
    position: 'relative',
    fontSize: '1rem',
    whiteSpace: 'nowrap',
    color: theme.primaryTextColor,
    paddingTop: '1rem'
  },
  isRequired: {
    bottom: '0.5rem',
    position: 'relative',
    fontSize: '1rem',
    color: theme.primaryBackgroundColor,
    paddingTop: '1rem'
  },
  readOnly: {
    color: theme.primaryBorderColor
  },
  passwordIcon: {
    cursor: 'pointer',
    direction: 'rtl',
    position: 'relative',
    marginLeft: '1rem',
    marginRight: '0.5rem',
    marginTop: '1rem'
  },
  passwordIconProfileForm: {
    cursor: 'pointer',
    direction: 'rtl',
    position: 'relative',
    marginLeft: '0.4rem',
    marginRight: '0.4rem',
    marginTop: '1rem'
  },
  noteContainer: {
    border: '1px solid; border-radius: 7px; height: 13.5rem;',
    borderColor: theme.primaryBorderColor,
    width: '100%',
    marginBottom: '1.5rem'
  },
  noteSmallContainer: {
    border: '1px solid; border-radius: 7px; height: 5.8rem;',
    borderColor: theme.primaryBorderColor,
    width: '100%',
    marginBottom: '1.5rem'
  },
  focusNoteSmallContainer: {
    border: '1px solid; border-radius: 7px; height: 5.8rem;',
    borderColor: theme.primaryBackgroundColor,
    width: '100%',
    marginBottom: '1.5rem'
  },
  noteInput: {
    border: '0',
    outline: 'none',
    top: '0.6rem',
    height: '12rem',
    position: 'relative',
    backgroundColor: 'transparent',
    '&:-webkit-autofill': {
      WebkitBoxShadow: '0 0 0 1000px white inset',
      fontSize: '1rem'
    },
    fontSize: '1rem',
    width: '99%',
    resize: 'none'
  },
  noteSmallInput: {
    border: '0',
    outline: 'none',
    top: '0.6rem',
    height: '4.3rem',
    position: 'relative',
    backgroundColor: 'transparent',
    '&:-webkit-autofill': {
      WebkitBoxShadow: '0 0 0 1000px white inset',
      fontSize: '1rem'
    },
    fontSize: '1rem',
    width: '99%',
    resize: 'none'
  },
  noteValidation: {
    color: theme.validationTextColor,
    top: '0.5rem',
    position: 'relative',
    textAlign: 'center',
    fontSize: '0.9rem',
    backgroundColor: theme.validationBackgroundColor,
    width: '100%',
  },
  noteInputContainer: {
    width: '100%'
  },
  focusContainer: {
    border: '1px solid; border-radius: 7px; height: 3.5rem;',
    borderColor: theme.primaryBackgroundColor,
    width: '100%',
    marginBottom: '1.5rem'
  },
  focusNoteContainer: {
    border: '1px solid; border-radius: 7px; height: 13.5rem;',
    borderColor: theme.primaryBackgroundColor,
    width: '100%',
    marginBottom: '1.5rem'
  },
  conversationInput: {
    marginLeft: '0.2rem',
    paddingRight: '0.2rem',
    border: '0',
    outline: 'none',
    height: '100%',
    position: 'relative',
    backgroundColor: 'transparent',
    fontSize: '1rem',
    wordBreak: 'break-word',
    width: '-webkit-fill-available',
    resize: 'none',
    '&::-webkit-input-placeholder': {
      color: theme.primaryTextColor,
      fontFamily: '-apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif'
    }
  },
  browseContainer: {
    background: theme.primaryBackgroundColor,
    display: 'none'
  },
  containerDragAndDropBrowse: {
    display: 'none'
  },
  inputDragAndDropBrowse: {
    display: 'none'
  },
  hiddenInputDragAndDropBrowse: {
    display: 'none'
  },
  checkboxInputLabel: {
    color: theme.primaryTextColor,
  },
  checkboxInputControl: {
    marginTop: '1.5rem',
    '& > label': {
      width: 'max-content'
    }
  },
  '@media screen and (max-width: 520px)': {
    checkboxInputControl: {
      margin: 0,
    },
    passwordInputContainer: {
      width: '70%'
    },
    passwordValidation: {
      color: theme.validationTextColor,
      top: '1.4rem',
      position: 'relative',
      textAlign: 'center',
      fontSize: '1rem',
      backgroundColor: theme.validationBackgroundColor,
      width: '145%'
    },
  },
  '@media screen and (max-width: 1024px)': {
    containerDragAndDrop: {
      display: 'none'
    },
    inputDragAndDrop: {
      display: 'none'
    },
    hiddenInputDragAndDrop: {
      display: 'none'
    },
    containerDragAndDropBrowse: {
      display: 'flex',
      height: '3.5rem',
      backgroundColor: theme.senaryBackgroundColor,
      width: '100%',
      minWidth: '6rem'
    },
    inputDragAndDropBrowse: {
      display: 'flex',
      outline: 'none',
      height: '3.5rem',
      position: 'relative',
      backgroundColor: 'transparent',
      '&:-webkit-autofill': {
        WebkitBoxShadow: '0 0 0 1000px white inset',
        fontSize: '1rem'
      },
      fontSize: '1rem',
      width: '100%',
      textAlign: 'center',
      color: theme.primaryBackgroundColor,
      fontWeight: 'bold',
      minWidth: '5rem'
    },
    hiddenInputDragAndDropBrowse: {
      display: 'flex',
      opacity: '0',
      top: '-3.6rem',
      minWidth: '5rem',
      width: '6rem',
      height: '3.5rem',
    },
    centerContent: {
      width: '100%',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
    spaceBetween: {
      justifyContent: 'center',
      alignSelf: 'center'
    },
    browseContainer: {
      background: theme.primaryBackgroundColor,
      display: 'flex'
    },
  },
  '@media screen and (min-width: 1010px) and (max-width: 1233px)': {
    passwordIcon: {
      marginRight: '0.3rem'
    },
    passwordIconProfileForm: {
      marginRight: '0.3rem'
    }
  },
  '@media screen and (min-width: 960px) and (max-width: 1009px)': {
    passwordIcon: {
      marginRight: '0.1rem'
    },
    passwordIconProfileForm: {
      marginRight: '0.1rem'
    }
  }
}));