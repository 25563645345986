import React, { useState, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { useQuery } from 'react-query';
import { Container, Chip, Grid, Tooltip, useMediaQuery, useTheme } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import PropTypes from 'prop-types';
import withWidth, { isWidthUp } from '@material-ui/core/withWidth';

import { Button, CustomTable as Table, FiltersDrawer, Header, Loader, SadStates, SearchFilter, SelectMenu, SwitchButton, Wrapper } from 'components';
import { displayBorder, formatDateForTable, paymentTypes, leadStatus, isAdmin, isSalesMng, allOrMine } from 'helpers';
import { UserContext } from 'providers';
import { filterIcon } from 'resources';
import { leadService } from 'services';

import { useStyles } from './LeadsPage.css.js';

const MODEL = 'model';
const STATUS = 'status';
const PAYMENT_TYPE = 'paymentType';
const SALE_REPRESENTATIVE = 'userId';

const LeadsPageComponent = ({ width }) => {
  const classes = useStyles();
  const [t] = useTranslation('common');
  const navHistory = useHistory();
  const { user } = useContext(UserContext);
  const [search, setSearch] = useState('');
  const [myLeads, setMyLeads] = useState(true);
  const [total, setTotal] = useState();
  const [array, setArray] = useState([]);
  const userId = myLeads ? user.id : '';
  const [allOrMineValue, setAllOrMineValue] = useState(allOrMine.find(item => 'mine' === item.label).value);
  const { isLoading, data = [] } = useQuery(
    ['filterParams', { allOrMineValue }],
    () => leadService.getFilterParameters(allOrMineValue),
    { cacheTime: 0 }
  );
  const [modelMenuItem, setModelMenuItem] = useState(false);
  const [saleRepMenuItem, setSaleRepMenuItem] = useState(false);
  const theme = useTheme();
  const isSmaller = useMediaQuery(theme.breakpoints.down('sm'));

  const callback = (query) => setSearch(query);

  const handlePaymentType = paymentType => {
    const chosenPaymentType = paymentTypes.find(type => paymentType === type.value);
    return t(chosenPaymentType.label);
  };

  const handleStatus = s => {
    let currentStatus = leadStatus.find(x => x.value === s);
    if (!currentStatus) {
      currentStatus = leadStatus[0];
    }
    return (
      currentStatus &&
      <Tooltip
        classes={{
          tooltip: classes.customTooltip
        }}
        title={t(currentStatus.label)}
        placement='bottom'>
        <Grid className={`${classes.imgContainer} ${classes[currentStatus.label]}`} style={{ backgroundColor: currentStatus.color }}>
          <img className={classes.img} src={currentStatus.icon} alt={''} />
        </Grid>
      </Tooltip>
    );
  };

  const handleLeadName = lead => {
    return (
      <p className={classes.leadName} onClick={() => navHistory.push(`/leads/view/${lead.id}`)}>
        {`${lead.name} ${lead.surname}`}
      </p>
    );
  };

  const columnConfiguration = [];

  const isVisible = isWidthUp(displayBorder, width);

  if (isVisible) {
    columnConfiguration.push(
      { id: 'id', label: t('id'), disableSorting: true },
      { id: 'name', label: t('leadName'), format: lead => handleLeadName(lead) },
      !myLeads && { id: 'saleRep', label: t('saleRep'), format: lead => lead.userFullName },
      { id: 'year', label: t('year'), disableSorting: true },
      { id: 'make', label: t('make'), disableSorting: true },
      { id: 'model', label: t('model'), disableSorting: true },
      { id: 'trim', label: t('trim'), disableSorting: true },
      { id: 'paymentType', label: t('paymentType'), disableSorting: true, format: lead => handlePaymentType(lead.paymentType) },
      {
        id: 'dateCreated', label: t('dateCreated'), format: (lead) => {
          return formatDateForTable(lead.createdAt, classes.tableCell);
        }
      },
      {
        id: 'lastModified', label: t('lastModified'), format: (lead) => {
          return formatDateForTable(lead.modifiedAt, classes.tableCell);
        }
      },
      { id: 'status', label: t('status'), disableSorting: true, format: lead => handleStatus(lead.status) },
    );
  } else {
    columnConfiguration.push(
      { id: 'name', label: t('leadName'), format: lead => handleLeadName(lead) },
      { id: 'status', label: t('status'), disableSorting: true, format: lead => handleStatus(lead.status) },
    );
  }

  const handleChange = (value, filterName, filterLabel) => {
    if (array.filter(arr => arr.key === `${filterName}_${value}`).length === 0) {
      setArray([...array, { key: `${filterName}_${value}`, id: filterName, value: value, label: filterLabel }]);
    }
  };

  const handleDelete = el => {
    setArray(array.filter(a => el !== a));
  };

  const filters = { array, search, userId };

  return (
    <Wrapper>
      <div className={classes.container}>
        <Grid container className={classes.spaceBetween} spacing={2}>
          <Grid item className={classes.paddingTop}>
            <Header title={t('leads')} />
          </Grid>
          <Grid item className={classes.paddingTop}>
            <Button text='createNewLead' callback={() => navHistory.push('/leads/create')} />
          </Grid>
        </Grid>
        {isSmaller ?
          <div>
            <FiltersDrawer>
              <Container maxWidth={false} disableGutters={true}>
                <Grid className={classes.drawerfilterContainer} container direction='column' justifyContent='space-between' alignItems='center' spacing={3}>
                  <Grid className={classes.selectFilter} item>
                    <SelectMenu
                      label={t('byCarModel')}
                      onChange={value => {
                        setModelMenuItem(true);
                        setSaleRepMenuItem(false);
                        handleChange(value, MODEL, data.models.find(item => item === value));
                      }}
                      menuItem={data.models}
                      modelMenuItem={true}
                      saleRepMenuItem={false} />
                  </Grid>
                  <Grid className={classes.selectFilter} item>
                    <SelectMenu
                      label={t('byPaymentType')}
                      onChange={value => {
                        setModelMenuItem(false);
                        setSaleRepMenuItem(false);
                        handleChange(value, PAYMENT_TYPE, t(paymentTypes[value - 1].label));
                      }}
                      menuItem={paymentTypes}
                      modelMenuItem={false}
                      saleRepMenuItem={false} />
                  </Grid>
                  <Grid className={classes.selectFilter} item>
                    <SelectMenu
                      label={t('byStatus')}
                      onChange={value => {
                        setModelMenuItem(false);
                        setSaleRepMenuItem(false);
                        handleChange(value, STATUS, t(leadStatus.find(x => x.value === value).label));
                      }}
                      menuItem={leadStatus}
                      modelMenuItem={false}
                      saleRepMenuItem={false} />
                  </Grid>
                  {allOrMineValue === allOrMine.find(item => 'all' === item.label).value &&
                    <Grid className={classes.selectFilter} item >
                      <SelectMenu
                        label={t('bySaleRep')}
                        onChange={value => {
                          setModelMenuItem(false);
                          setSaleRepMenuItem(true);
                          handleChange(value, SALE_REPRESENTATIVE, data.saleRepresentatives.find(item => item.id === value).nameSurname);
                        }}
                        menuItem={data.saleRepresentatives}
                        modelMenuItem={false}
                        saleRepMenuItem={true} />
                    </Grid>
                  }
                </Grid>
              </Container>
            </FiltersDrawer>
            <Grid className={classes.smallSearchContainer} item md={3}>
              <SearchFilter className={classes.smallSearch} placeholder={t('typeHereToSearch')} callback={callback} />
            </Grid>
          </div> :
          <Grid container className={classes.alignItems}>
            <Grid className={classes.paddingRight} item md={1}>
              <img className={classes.filterIcon} src={filterIcon} />
              <p className={classes.filterText}>{t('filters')}</p>
            </Grid>
            <SadStates
              states={[
                {
                  when: isLoading,
                  render: <Loader />
                }
              ]}>
              <Grid className={classes.selectFilter} item>
                <SelectMenu
                  label={t('byCarModel')}
                  onChange={value => {
                    setModelMenuItem(true);
                    setSaleRepMenuItem(false);
                    handleChange(value, MODEL, data.models.find(item => item === value));
                  }}
                  menuItem={data.models}
                  modelMenuItem={true}
                  saleRepMenuItem={false} />
              </Grid>
              <Grid className={classes.selectFilter} item>
                <SelectMenu
                  label={t('byPaymentType')}
                  onChange={value => {
                    setModelMenuItem(false);
                    setSaleRepMenuItem(false);
                    handleChange(value, PAYMENT_TYPE, t(paymentTypes[value - 1].label));
                  }}
                  menuItem={paymentTypes}
                  modelMenuItem={false}
                  saleRepMenuItem={false} />
              </Grid>
              <Grid className={classes.selectFilter} item>
                <SelectMenu
                  label={t('byStatus')}
                  onChange={value => {
                    setModelMenuItem(false);
                    setSaleRepMenuItem(false);
                    handleChange(value, STATUS, t(leadStatus.find(x => x.value === value).label));
                  }}
                  menuItem={leadStatus}
                  modelMenuItem={false}
                  saleRepMenuItem={false} />
              </Grid>
              {allOrMineValue === allOrMine.find(item => 'all' === item.label).value &&
                <Grid className={classes.selectFilter} item >
                  <SelectMenu
                    label={t('bySaleRep')}
                    onChange={value => {
                      setModelMenuItem(false);
                      setSaleRepMenuItem(true);
                      handleChange(value, SALE_REPRESENTATIVE, data.saleRepresentatives.find(item => item.id === value).nameSurname);
                    }}
                    menuItem={data.saleRepresentatives}
                    modelMenuItem={false}
                    saleRepMenuItem={true} />
                </Grid>
              }
              <Grid className={classes.paddingRight} item md={3}>
                <SearchFilter placeholder={t('typeHereToSearch')} callback={callback} />
              </Grid>
            </SadStates>
          </Grid>}
        <Grid container className={classes.switchButton}>
          <Grid item className={classes.total}>
            {total !== 0 &&
              <Grid>
                <Grid item className={classes.total}>
                  <p className={classes.marginRight}>{t('total')}:</p>
                  <p>{total}</p>
                </Grid>
              </Grid>}
            {array.map((el, index) => {
              return (
                <Grid className={classes.chipContainer} item key={index}>
                  <Chip classes={{ root: classes.root, deleteIcon: classes.deleteIcon }} label={modelMenuItem || saleRepMenuItem ? el.label : t(el.label)} onDelete={() => handleDelete(el)} deleteIcon={<CloseIcon />} />
                </Grid>
              );
            })}
          </Grid>
        </Grid>
        {(isAdmin(user && user.role) || isSalesMng(user && user.role)) && <SwitchButton myLeads={myLeads} setMyLeads={setMyLeads} setAllOrMineValue={setAllOrMineValue} />}
        <Table tableId='leadsPage' columnConfiguration={columnConfiguration} source={leadService.getLeads} setTotal={value => setTotal(value)} filter={filters} />
      </div>
    </Wrapper>
  );
};
LeadsPageComponent.propTypes = {
  width: PropTypes.string
};

export const LeadsPage = withWidth()(LeadsPageComponent);
