import React from 'react';
import { format } from 'date-fns';
import PropTypes from 'prop-types';

import {dateFormat} from 'helpers';

import { useStyles } from './EnhancedTableDateTimeColumn.css';

export const EnhancedTableDateTimeColumn = ({date}) => {
  const classes = useStyles();
  return (
    <div className={classes.container}>
      <div>{format(date, dateFormat)}</div>
    </div>);
};

EnhancedTableDateTimeColumn.propTypes = {
  date: PropTypes.object.isRequired
};