import PropTypes from 'prop-types';
import clsx from 'clsx';
import { format } from 'date-fns';
import { Grid } from '@material-ui/core';

import { dateFormatHours } from 'helpers';
import { completedIconWhite } from 'resources';
import { notificationService } from 'services';

import { useStyles } from './NotificationMessage.css.js';
import { useState } from 'react';

export const NotificationMessage = ({ id, isRead, title, description, createdAt, url, refetchData, setRefetchData }) => {
  const classes = useStyles();
  const created = new Date(createdAt);
  const [seeAll, setSeeAll] = useState(false);

  const handleUpdate = () => {
    if (!isRead) {
      notificationService.updateNotification(id);
      setRefetchData(!refetchData);
    }
  };

  const handleNotification = () => {
    window.location.href = url;
    if (!isRead) {
      notificationService.updateNotification(id);
      setRefetchData(!refetchData);
    }
  };

  return (
    <Grid container className={clsx(classes.container, { [classes.isRead]: isRead, [classes.notRead]: !isRead })}>
      <Grid item xs={10} sm={10} md={10} lg={10}>
        <Grid container onClick={handleNotification} className={classes.textContainer}>
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <div className={classes.date}>{format(created, dateFormatHours)}</div>
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <p className={classes.title}>{title}</p>
          </Grid>
          <Grid item onClick={() => setSeeAll(!seeAll)} xs={12} sm={12} md={12} lg={12}>
            <p className={clsx(classes.description, { [classes.seeAll]: !seeAll })}>{description}</p>
          </Grid>
        </Grid>
      </Grid>
      <Grid onClick={handleUpdate} item className={clsx(classes.imgContainer, { [classes.opacity]: isRead })}>
        <img className={classes.img} src={completedIconWhite} alt={''} />
      </Grid>
    </Grid>
  );
};

NotificationMessage.propTypes = {
  id: PropTypes.number,
  isRead: PropTypes.bool,
  title: PropTypes.string,
  description: PropTypes.string,
  createdAt: PropTypes.string,
  url: PropTypes.string,
  refetchData: PropTypes.bool,
  setRefetchData: PropTypes.func
};
