import React from 'react';
import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/database';
import { Formik, Form } from 'formik';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import * as Yup from 'yup';
import { Box, Grid } from '@material-ui/core';

import { PasswordInputField, Button, toastNotification } from 'components';
import { handleFirebaseErrors, getParameterByName } from 'helpers';
import { forgotPasswordIcon, logoWithText } from 'resources';

import { useStyles } from './ForgotPasswordPage.css.js';

export const ResetPasswordPage = () => {
  const classes = useStyles();
  const [t] = useTranslation('common');
  const navHistory = useHistory();

  const handleSubmit = async (values, { setSubmitting }) => {
    const actionCode = getParameterByName('oobCode');
    try {
      await firebase.auth().verifyPasswordResetCode(actionCode);
      try {
        await firebase.auth().confirmPasswordReset(actionCode, values.password);
        toastNotification('info', t('passwordResetSuccess'));
        navHistory.replace('/signin');
      } catch (error) {
        const message = handleFirebaseErrors(error);
        toastNotification('warning', t(message));
      }
    } catch (error) {
      const message = handleFirebaseErrors(error);
      toastNotification('warning', t(message));
    }
    setSubmitting(false);
  };

  const resetSchema = Yup.object().shape({
    password: Yup.string()
      .min(8, t('tooShort'))
      .max(50, t('tooLong'))
      .required(t('required')),
    confirmedPassword: Yup.string()
      .oneOf([ Yup.ref('password'), null ], t('doesNotMatch'))
      .min(8, t('tooShort'))
      .max(50, t('tooLong'))
      .required(t('required'))
  });

  return (
    <Box className={classes.container}>
      <Grid container className={classes.formContainer}>
        <Grid item md={10} xl={10} className={classes.inputFieldContainer}>
          <Grid item>
            <img className={classes.logoReset} src={logoWithText} alt={''} />
          </Grid >
          <Grid item>
            <img className={classes.logoPassword} src={forgotPasswordIcon} alt={''} />
          </Grid >
          <Grid>
            <Formik
              initialValues={{ password: '', confirmedPassword: '' }}
              validationSchema={resetSchema}
              onSubmit={handleSubmit}>
              {({ isSubmitting, isValid, dirty }) => (
                <Form className={classes.form}>
                  <Grid className={classes.marginTop20}>
                    <PasswordInputField label='password' inputName='password' isRequired/>
                  </Grid>
                  <Grid className={classes.marginTop20}>
                    <PasswordInputField label='confirmPassword' inputName='confirmedPassword' isRequired/>
                  </Grid>
                  <br/>
                  <Button text='resetPassword' disabled={isSubmitting || !(isValid && dirty)} type='submit' color='primary'/>
                  <br/>
                </Form>
              )}
            </Formik>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};
