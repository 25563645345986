import React, { useState, useContext } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { Grid, MenuItem, Menu, ListItemIcon, ListItemText } from '@material-ui/core';

import { auth, isAdmin, isSalesMng, isDesigner } from 'helpers';
import { NotificationsPage, NotificationIcon } from 'pages';
import { UserContext } from 'providers';
import { gifTemplatesIcon, logoBlackBackground, menuIcon, leadsIcon, usersIcon, settingsIcon, downIcon, logoutIcon, notificationsIcon, userGroupIcon, reports } from 'resources';

import { useStyles } from './PageHeader.css';

export const PageHeader = ({ hideControls = false }) => {
  const classes = useStyles();
  const navHistory = useHistory();
  const [t] = useTranslation('common');
  const { user } = useContext(UserContext);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isAccountMenuOpen, setIsAccountMenuOpen] = useState(false);
  const [notificationsActive, setNotificationsActive] = useState(false);
  const isSalesMngRole = isSalesMng(user && user.role);
  const isAdminRole = isAdmin(user && user.role);
  const isDesignerRole = isDesigner(user && user.role);

  const handleNavigate = (route) => {
    if (route === '/logout') {
      auth.signOut();
    } else {
      navHistory.push({
        pathname: route
      });
      handleClose();
    }
  };

  const handleClose = () => {
    setIsMenuOpen(false);
    setIsAccountMenuOpen(false);
  };

  const handleMenuItem = (icon, text) => {
    const link = text === 'myCompany' ? `/companies/${user.company.id}` : `/${text}`;
    return (
      <MenuItem className={clsx({ [classes.logout]: text === 'logout' })} key={text} button onClick={() => handleNavigate(link)}>
        {icon &&
          <ListItemIcon className={classes.listItemIcon}>
            <img className={classes.listItemImg} src={icon} alt={''} />
          </ListItemIcon>}
        <ListItemText primary={t(text)} />
      </MenuItem>);
  };

  return (
    <div className={classes.stickyHeader}>
      <NotificationsPage notificationsActive={notificationsActive} setNotificationsActive={setNotificationsActive} />
      <Grid container justifyContent='space-between' alignItems='center'>
        <Grid item>
          <Grid container>
            <Grid item>
              <img className={classes.logo} src={logoBlackBackground} alt={''} />
            </Grid>
          </Grid>
        </Grid>
        {!hideControls &&
          <Grid item>
            <Grid container alignItems='center'>
              <Grid item className={classes.settingsContainer}>
                <Grid container>
                  <Grid item className={classes.welcomeMessage}>
                    {t('welcomeToYourAccount', { name: user.name })}
                  </Grid>
                  <Grid item>
                    <Grid className={classes.cursor} container onClick={e => setIsAccountMenuOpen(e.currentTarget)}>
                      <Grid item>
                        <img className={classes.settingsIcon} src={settingsIcon} alt={''} />
                      </Grid>
                      <Grid item>
                        <img className={classes.downIcon} src={downIcon} alt={''} />
                      </Grid>
                    </Grid>
                  </Grid>
                  <Menu
                    id='menu-appbar2'
                    anchorEl={isAccountMenuOpen}
                    anchorOrigin={{
                      vertical: 'top',
                      horizontal: 'left',
                    }}
                    PaperProps={{
                      style: {
                        left: '0%',
                        transform: 'translateX(-40%) translateY(40%)',
                      }
                    }}
                    MenuListProps={{
                      style: {
                        padding: 0,
                      },
                    }}
                    open={Boolean(isAccountMenuOpen)}
                    onClose={handleClose}>
                    {handleMenuItem(null, 'accountSettings')}
                    {isSalesMngRole && handleMenuItem(null, 'myCompany')}
                    {handleMenuItem(logoutIcon, 'logout')}
                  </Menu>
                </Grid>
              </Grid>
              <Grid item onClick={() => setNotificationsActive(true)} className={classes.notificationsContainer}>
                <Grid container className={classes.alignItemsCenter}>
                  <Grid item className={classes.marginRight}>
                    <img className={classes.notificationListIcon} src={notificationsIcon} alt={''} />
                  </Grid>
                  <Grid item>
                    <NotificationIcon />
                  </Grid>
                </Grid>
              </Grid>
              {!isDesignerRole &&
                <Grid item className={classes.menu} onClick={e => setIsMenuOpen(e.currentTarget)}>
                  <img className={classes.menuListIcon} src={menuIcon} alt={''} />
                </Grid>}
              <Menu
                id='menu-appbar'
                anchorEl={isMenuOpen}
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                PaperProps={{
                  style: {
                    left: '0%',
                    transform: 'translateX(0%) translateY(30%)',
                  }
                }}
                MenuListProps={{
                  style: {
                    padding: 0,
                  },
                }}
                open={Boolean(isMenuOpen)}
                onClose={handleClose}>
                {handleMenuItem(leadsIcon, 'leads')}
                {(isSalesMngRole || isAdminRole) && handleMenuItem(usersIcon, 'users')}
                {isAdminRole && handleMenuItem(userGroupIcon, 'companies')}
                {handleMenuItem(gifTemplatesIcon, 'gifTemplates')}
                {isAdminRole && handleMenuItem(reports, 'reports')}
              </Menu>
            </Grid>
          </Grid>}
      </Grid>
    </div>
  );
};

PageHeader.propTypes = {
  hideControls: PropTypes.bool,
};