import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import { Grid, Tooltip } from '@material-ui/core';
import { NumericFormat } from 'react-number-format';

import {
  viewLeadInfo, paymentTypes, lease, finance, cash,
  notes, tradeIn, communicationChannels, optInSmsType
} from 'helpers';

import { useStyles } from './ViewLead.css';

export const ViewLead = ({ data, showNElement, onOptInClick, isOptInSent }) => {
  const [t] = useTranslation('common');
  const [viewLead, setViewLead] = useState([]);
  const classes = useStyles();

  const handleLabel = (label) => {
    if (label === 'term' && data.paymentType === paymentTypes[1].value) {
      return <span>{t('termFinanceView')}</span>;
    }
    return <span>{t(label)}</span>;
  };

  const handleText = label => {
    if (label === 'paymentType') {
      return <span>{t(paymentTypes[data.paymentType - 1].label)}</span>;
    }

    if (label === 'defaultChannel') {
      return <span>{t(communicationChannels[data.defaultChannel].label)}</span>;
    }

    if (label === 'optInSms') {
      const optInValue = Object.keys(optInSmsType)[data[label]];
      if (data[label] === optInSmsType.Agreed) {
        return <span>{optInValue}</span>;
      } else {
        if (data['optInSmsCanResend']) {
          return (
            <span>
              <span>{optInValue}</span>
              <button disabled={isOptInSent} className={classes.optInBtn} onClick={onOptInClick}>{t('optInSmsSend')}</button>
            </span>
          );
        } else {
          return (
            <span>
              <span>{optInValue}</span>
              <Tooltip title={t('optInSmsTooltip')} placement='bottom'>
                <button disabled className={clsx(classes.optInBtn, classes.optInBtnDisabled)}>{t('optInSmsSend')}</button>
              </Tooltip>
            </span>
          );
        }
      }
    }

    if (viewLead.some(x => x.label === label && x.type === 'money')) {
      return <NumericFormat value={data[label]} displayType={'text'} thousandSeparator={true} prefix={'$'} />;
    } else {
      if (viewLead.some(x => x.label === label && x.type === 'number')) {
        if (label === 'rate') {
          return <span>{data[label]}%</span>;
        }
        return <NumericFormat value={data[label]} displayType={'text'} thousandSeparator={true} />;
      } else {
        return <span>{data[label]}</span>;
      }
    }
  };

  useEffect(() => {
    let result = viewLeadInfo;
    switch (data.paymentType) {
      case (paymentTypes[0].value):
        result = result.concat(lease);
        break;
      case (paymentTypes[1].value):
        result = result.concat(finance);
        break;
      case (paymentTypes[2].value):
        result = result.concat(cash);
    }
    if (typeof data.tradeInValue === 'number') {
      result = result.concat(tradeIn);
    }
    setViewLead(result.concat(notes));
  }, [data]);

  return (
    viewLead.slice(0, showNElement).map(lead => {
      return (
        <Grid container className={classes.container} key={lead.label}>
          <Grid item lg={4} xs={4} md={4}>
            <Grid container spacing={2}>
              <Grid item lg={2} xs={2} md={2}>
                <img className={classes.icon} src={lead.icon} />
              </Grid>
              <Grid item lg={10} xs={10} md={10}>
                <p className={classes.label}>{handleLabel(lead.label)}</p>
              </Grid>
            </Grid>
          </Grid>
          <Grid item lg={8} xs={8} md={8}>
            <p className={classes.text}>{handleText(lead.label)}</p>
          </Grid>
        </Grid>
      );
    })
  );
};

ViewLead.propTypes = {
  data: PropTypes.object,
  showNElement: PropTypes.number,
  onOptInClick: PropTypes.func
};