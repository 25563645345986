/* eslint-disable no-unused-vars */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { FormControl, Select, MenuItem, Grid } from '@material-ui/core';
import { ErrorMessage, Field } from 'formik';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';

import { useStyles } from './SelectFilter.css.js';
import { lockIcon } from 'resources/index.js';
import { LockIcon } from 'components';

export const FormSelectFilter = ({ label, value, menuItem, onChange, inputName, isRequired, disabled, hidden, className, selectedValueStyle = null, lockProps = null }) => {

  const classes = useStyles();
  const [t] = useTranslation('common');
  const [filterValue, setFilterValue] = useState(value);

  if (hidden) {
    return <Field type='hidden' name={inputName} value={value}></Field>;
  }

  return (
    <div className={className}>
      {lockProps && <LockIcon {...lockProps} />}
      <FormControl className={lockProps ? classes.formControlWithIcon : classes.formControl}>
        <Grid container>
          <Grid item className={classes.labelContainer}>
            <span className={classes.label}>{t(label)}</span>
            {isRequired && <span className={classes.isRequired}>*</span>}
          </Grid>
          <Grid item className={classes.formContainer}>
            <Select
              disabled={disabled}
              IconComponent={ArrowDropDownIcon}
              disableUnderline
              className={classes.formInput}
              style={selectedValueStyle}
              value={filterValue}
              onChange={(e) => {
                setFilterValue(e.target.value);
                onChange(e);
              }}>
              {menuItem.map(c => (
                <MenuItem className={classes.formSelectItem} style={c.style}
                  key={c.value} value={c.value}>{t(c.label)}
                </MenuItem>
              ))}
            </Select>
            {inputName && <ErrorMessage className={classes.validation} name={inputName} component='div' />}
          </Grid>
        </Grid>
      </FormControl>
    </div>

  );
};

FormSelectFilter.propTypes = {
  className: PropTypes.string,
  inputName: PropTypes.string,
  label: PropTypes.string,
  value: PropTypes.any,
  menuItem: PropTypes.array,
  onChange: PropTypes.func,
  isRequired: PropTypes.bool,
  disabled: PropTypes.bool,
  hidden: PropTypes.bool,
  selectedValueStyle: PropTypes.object,
  lockProps: PropTypes.object
};
