import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { Grid } from '@material-ui/core';
import PropTypes from 'prop-types';
import withWidth, { isWidthUp } from '@material-ui/core/withWidth';

import { Button, CustomTable as Table, Header, SearchFilter, SelectFilter, Wrapper } from 'components';
import { displayBorder, formatDateForTable, roles } from 'helpers';
import { filterIcon } from 'resources';
import { userService } from 'services';

import { useStyles } from './UsersPage.css.js';

const UsersPageComponent = ({ width }) => {
  const classes = useStyles();
  const [t] = useTranslation('common');
  const navHistory = useHistory();
  const [role, setRole] = useState(-1);
  const [search, setSearch] = useState('');
  const [total, setTotal] = useState();

  const callback = (query) => setSearch(query);

  const handleUserName = user => {
    return (
      <p className={classes.userName} onClick={() => navHistory.push(`/users/edit/${user.id}`)}>
        {`${user.name} ${user.surname}`}
      </p>
    );
  };

  const handleCompanyName = user => {
    if (!user.company || user.role === 1) {
      return (
        <p className={classes.companyName}></p>
      );
    }
    return (
      <p className={classes.companyName} onClick={() => navHistory.push(`/companies/${user.company.id}`)}>
        {user.company.name}
      </p>
    );
  };

  const handleRoles = user => {
    const userRole = roles.find(r => user.role === r.value);
    return <p className={classes.role}>{t(userRole.label)}</p>;
  };

  const handleStatus = user => {
    if (user.role === 1) {
      return <p className={classes.role}></p>;
    }
    const userStatus = user.company?.status ?? 0;
    return <p className={classes.role}>{userStatus === 0 ? 'Inactive' : 'Active'}</p>;
  };

  const columnConfiguration = [];

  const isVisible = isWidthUp(displayBorder, width);

  if (isVisible) {
    columnConfiguration.push(
      { id: 'id', label: t('id'), disableSorting: false, format: user => user.id },
      { id: 'userName', label: t('userName'), disableSorting: false, format: user => handleUserName(user) },
      { id: 'userRole', label: t('userRole'), disableSorting: false, format: user => handleRoles(user) },
      { id: 'userStatus', label: t('userStatus'), disableSorting: false, format: user => handleStatus(user) },
      { id: 'companyName', label: t('companyName'), disableSorting: false, format: user => handleCompanyName(user) },
      {
        id: 'dateCreated', label: t('dateCreated'), format: (user) => {
          return formatDateForTable(user.createdAt, classes.tableCell);
        }
      },
      {
        id: 'lastModified', label: t('lastModified'), format: (user) => {
          return formatDateForTable(user.modifiedAt, classes.tableCell);
        }
      }
    );
  } else {
    columnConfiguration.push(
      { id: 'userName', label: t('userName'), disableSorting: true, format: user => handleUserName(user) },
      { id: 'userRole', label: t('userRole'), disableSorting: true, format: user => handleRoles(user) }
    );
  }

  const handleChangeRole = (e) => {
    setRole(e.target.value > 0 ? e.target.value + 1 : -1);
  };

  const filters = { role, search };

  return (
    <Wrapper>
      <div className={classes.container}>
        <Grid container className={classes.spaceBetween} spacing={2}>
          <Grid item className={classes.paddingTop}>
            <Header title={t('users')} />
          </Grid>
          <Grid item className={classes.paddingTop}>
            <Button text='addNewUser' callback={() => navHistory.push('/users/create')} />
          </Grid>
        </Grid>
        <Grid container className={classes.alignItems}>
          <Grid className={classes.marginRight} item md={1}>
            <img className={classes.filterIcon} src={filterIcon} />
            <p className={classes.filterText}>{t('filters')}</p>
          </Grid>
          <Grid className={classes.paddingRight} item md={2} sm={3} xs={10}>
            <SelectFilter label={t('allRoles')} onChange={handleChangeRole} value={role} menuItem={roles} />
          </Grid>
          <Grid className={classes.paddingRight} item md={3} sm={5} xs={10}>
            <SearchFilter placeholder={t('typeHereToSearch')} callback={callback} />
          </Grid>
        </Grid>
        {total !== 0 &&
          <Grid container>
            <Grid item className={classes.total}>
              <p className={classes.marginRight}>{t('total')}:</p>
              <p>{total}</p>
            </Grid>
          </Grid>}
        <Table tableId='usersPage' columnConfiguration={columnConfiguration} source={userService.getUsers} setTotal={value => setTotal(value)} filter={filters} />
      </div>
    </Wrapper>
  );
};

UsersPageComponent.propTypes = {
  width: PropTypes.string
};

export const UsersPage = withWidth()(UsersPageComponent);