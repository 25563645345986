import React, { useEffect, useState, createContext } from 'react';
import PropTypes from 'prop-types';
import { loginService } from 'services';
import { auth } from '../helpers/config';

export const UserContext = createContext({ user: null, initialized: false });

export const UserProvider = ({ children }) => {
  const [ user, setUser ] = useState(null);
  const [ firebaseUser, setFirebaseUser ] = useState(null);
  const [ claims, setClaims ] = useState(null);
  const [ isSetupAccount, setIsSetupAccount ] = useState(false);
  const [ initialized, setInitialized ] = useState(false);

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged(userAuth => {
      if (firebaseUser !== userAuth || !userAuth) {
        setFirebaseUser(userAuth);

        if (userAuth && !isSetupAccount) {
          getUserData();
        } else {
          setUser(null);
          setClaims(null);
          setInitialized(true);
        }
      }
    });

    return unsubscribe;
  });

  const getUserData = () => {
    loginService.login().then(res => {
      if (res) {
        auth.currentUser.getIdTokenResult().then(getIdTokenResult => {
          const firebaseClaims = getIdTokenResult.claims;
          setClaims(firebaseClaims);
          setUser(res.data);
          setInitialized(true);
        });
      } else {
        auth.signOut();
      }
    });
  };

  return (
    <UserContext.Provider value={{ user, setUser, claims, setIsSetupAccount, initialized }}>
      {children}
    </UserContext.Provider>
  );
};

UserProvider.propTypes = {
  children: PropTypes.any
};
