import React from 'react';
import qs from 'qs';

import { EnhancedTableDateTimeColumn } from 'components';
import { roleType, OPERA, OPR, CHROME, SAFARI, FIREFOX, MSIE, UNKNOWN } from './';
import { paidFeaturesType } from './enums';

export const handleFirebaseErrors = (error) => {
  if (!error) {
    return null;
  }
  switch (error.code) {
    case 'auth/invalid-email':
    case 'auth/wrong-password':
      return 'invalidCredential';
    case 'auth/user-disabled':
      return 'userDisabled';
    case 'auth/user-not-found':
      return 'userNotFound';
    case 'auth/account-exists-with-different-credential':
      return 'accountExistsWithDifferentCredential';
    case 'auth/invalid-credential':
      return 'invalidCredential';
    case 'auth/operation-not-allowed':
      return 'operationNotAllowed';
    case 'auth/email-already-in-use':
      return 'emailAlreadyInUse';
    case 'auth/weak-password':
      return 'weakPassword';
    case 'auth/user-mismatch':
      return 'invalidCredential';
    default:
      return error.message;
  }
};

export const getParameterByName = (value) => {
  const url = window.location.search.slice(1);
  const parsed = qs.parse(url);
  return parsed[value];
};

export const formatDateForTable = (date) => {
  const d = new Date(date);
  return <EnhancedTableDateTimeColumn date={d} />;
};

export const formatDate = (date) => {
  const d = new Date(date);
  const day = d.getDate() > 9 ? d.getDate() : `0${d.getDate()}`;
  const month = d.getMonth() > 8 ? d.getMonth() + 1 : `0${d.getMonth() + 1}`;
  return `${month}/${day}/${d.getFullYear()}`;
};

export const formatNumber = (num) => {
  return Number(num.replaceAll(',', '').replaceAll('$', ''));
};

export const formatNumberWithSeparator = (number, separator = ',') => {
  if (number === null || number === undefined) {
    return '';
  }
  const numStr = number.toString();
  if (number < 1000) {
    return numStr;
  }
  const parts = numStr.split('.');
  let integerPart = parts[0];
  const decimalPart = parts[1] || '';

  integerPart = integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, separator);

  if (decimalPart === '') {
    return integerPart;
  } else {
    return `${integerPart}.${decimalPart}`;
  }
};

export const phoneRegExp = /^[\\+]?((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

export const setAmericanPhoneCode = phone => {
  if (!phone.startsWith('+') && phone.startsWith('1')) {
    phone = '+' + phone;
  } else if (!phone.startsWith('+') && !phone.startsWith('1')) {
    phone = '+1' + phone;
  }

  return phone;
};

export const checkFileFormat = (fileName, formats = []) => {
  const splittedFileName = fileName?.split('.');

  if (splittedFileName && splittedFileName?.length > 1 && formats.find(f => f === splittedFileName[splittedFileName.length - 1]?.toLowerCase())) {
    return true;
  }

  return false;
};

export const checkRunningBrowser = () => {
  if ((navigator.userAgent.indexOf(OPERA) || navigator.userAgent.indexOf(OPR)) !== -1) {
    return OPERA;
  } else if (navigator.userAgent.indexOf(CHROME) !== -1) {
    return CHROME;
  } else if (navigator.userAgent.indexOf(SAFARI) !== -1) {
    return SAFARI;
  } else if (navigator.userAgent.indexOf(FIREFOX) !== -1) {
    return FIREFOX;
  } else if ((navigator.userAgent.indexOf(MSIE) !== -1) || (!!document.documentMode === true)) {
    return MSIE;
  } else {
    return UNKNOWN;
  }
};

export const isAdmin = role => {
  return role === roleType.ADMIN;
};

export const isSalesMng = role => {
  return role === roleType.SALE_MNG;
};

export const isSalesRep = role => {
  return role === roleType.SALE_REP;
};

export const isDesigner = role => {
  return role === roleType.DESIGNER;
};

export const getPaidFeaturesError = (company, paidFeature) => {
  const noPaidFeaturesError = 'This feature is not available. Your company hasn’t paid for SMS and Email messaging options.';
  const paidSmsError = 'This feature is not available. Your company hasn’t paid for SMS messaging option.';
  const paidEmailError = 'This feature is not available. Your company hasn’t paid for Email messaging option.';
  if (company) {
    if (!company.paidSms && !company.paidEmail) {
      return noPaidFeaturesError;
    } else {
      switch (paidFeature) {
        case paidFeaturesType.sendSms:
          if (company.paidSms) {
            break;
          }
          return paidSmsError;
        case paidFeaturesType.sendEmail:
          if (company.paidEmail) {
            break;
          }
          return paidEmailError;
        default: break;
      }
    }
  }
  return '';
};

export const getChangeComunicationChannelError = (company, paidFeature, isOptInAgreed = false) => {
  const noPaidFeaturesError = 'This feature is not available. Your company hasn’t paid for SMS and Email messaging options.';
  const paidSmsError = 'You cannot switch between options. Your company has paid only for Email option.';
  const paidEmailError = 'You cannot switch between options. Your company has paid only for SMS/MMS option.';
  const optInDisagreedError = 'You cannot switch between options. The lead didn’t consent to receive SMS messages and offers.';
  if (company) {
    if (!company.paidSms && !company.paidEmail) {
      return noPaidFeaturesError;
    } else if (paidFeature > -1) {
      switch (paidFeature) {
        case paidFeaturesType.sendSms:
          if (company.paidSms) {
            if (!isOptInAgreed && !company.optInDisabled) {
              return optInDisagreedError;
            }
            break;
          }
          return paidSmsError;
        case paidFeaturesType.sendEmail:
          if (company.paidEmail) {
            break;
          }
          return paidEmailError;
        default: break;
      }
    } else {
      if (!company.paidSms) {
        return paidSmsError;
      }
      if (!company.paidEmail) {
        return paidEmailError;
      }
      if (!isOptInAgreed && !company.optInDisabled) {
        return optInDisagreedError;
      }
    }
  }
  return '';
};
