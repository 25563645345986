import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { FormControl, Select, MenuItem, Grid } from '@material-ui/core';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';

import { useStyles } from './SelectFilter.css.js';

export const SelectFilter = ({label, value, menuItem, onChange, width}) => {

  const classes = useStyles();
  const [t] = useTranslation('common');
  const [ filterValue, setFilterValue ] = useState(value);

  const selectValue = filterValue === -1 ? value === -1 ? -1 : value : filterValue;

  return (
    <FormControl className={classes.container}>
      <Grid container className={classes.childContainer}>
        <Grid item md={width} className={classes.inputContainer}>
          <Select
            IconComponent = {ArrowDropDownIcon}
            classes={{ icon: classes.selectIcon }}
            disableUnderline
            MenuProps={{
              anchorOrigin: {
                vertical: 'bottom',
                horizontal: 'left',
              },
              transformOrigin: {
                vertical: 'top',
                horizontal: 'left',
              },
              getContentAnchorEl: null,
              menuStyle: {
                marginLeft: '6.25rem',
              },
            }}
            className={classes.input}
            value={selectValue}
            onChange={(e) => {
              setFilterValue(e.target.value);
              onChange(e);
            }}>
            {label &&
            <MenuItem
              classes={{root: classes.selectMenu}}
              value={-1}>{label}
            </MenuItem>}
            {menuItem.map(c => (
              <MenuItem
                classes={{root: classes.selectMenu}}
                key={c.value} value={c.value}>{t(c.label)}
              </MenuItem>
            ))}
          </Select>
        </Grid>
      </Grid>
    </FormControl>
  );
};

SelectFilter.propTypes = {
  label: PropTypes.string,
  value: PropTypes.number,
  menuItem: PropTypes.array,
  onChange: PropTypes.func,
  width: PropTypes.number
};
