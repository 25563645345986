import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import withWidth from '@material-ui/core/withWidth';
import { Grid } from '@material-ui/core';

import { EnhancedCardTableItems } from './EnhancedCardTableItems';
import { Loader, Pagination, SadStates } from 'components';

import { useStyles } from './EnhancedCardTable.css.js';

const FIRST_PAGE = 1;

const EnhancedCardTable = ({tableId, source, height, defaultCardsPerPage = 10, filter, setTotal, browseMode, selectedCardItem, setselectedCardItem, onDoubleClick }) => {

  const [ cardsPerPage, setCardsPerPage ] = useState(defaultCardsPerPage);
  const classes = useStyles();
  const [t] = useTranslation('common');
  const [ page, setPage ] = useState(1);
  const [ deletedGif, setdeletedGif ] = useState(false);
  const [ prevFilter, setPrevFilter ] = useState(filter);

  const paginationRef = useRef();

  const { isLoading, data, refetch } = useQuery([ 'repoCardData' + tableId, {page, deletedGif, cardsPerPage, filter}]
    , () => source((page - 1), cardsPerPage, filter));

  useEffect(() => {
    refetch();
  }, [refetch]);

  useEffect(() => {
    for (const property in filter) {
      if (filter[property] !== prevFilter[property]) {
        setPrevFilter(filter);
        setPage(FIRST_PAGE);
        return;
      }
    }
  }, [ filter, page ]);

  const tableData = data ? data : { items: [], total: 0};

  useEffect(() => {
    setTotal(tableData.total);
  }, [tableData]);

  return (
    <div>
      {!isLoading && tableData?.total !== 0 &&
      <div>
        <SadStates
          states={[
            {
              when: isLoading,
              render: <Loader/>
            }
          ]} >
          <div className={classes.container}>
            <EnhancedCardTableItems
              items={tableData.items}
              browseMode={browseMode}
              deletedGif={deletedGif}
              setdeletedGif={setdeletedGif}
              selectedCardItem={selectedCardItem}
              setselectedCardItem={setselectedCardItem}
              onDoubleClick={onDoubleClick}
              setPage={setPage}
              setCardsPerPage={(trHeight) => {
                if (!height) {
                  return;
                }
                const pageinationHeight = paginationRef.current ? paginationRef.current.clientHeight : 0;
                const cardsCount = Math.round((height - pageinationHeight) / trHeight);
                setCardsPerPage(cardsCount > 0 ? cardsCount - 1 : 0);
              }}/>
          </div>
        </SadStates>
        <Pagination
          paginationRef={paginationRef}
          count={Math.ceil(tableData.total / cardsPerPage)}
          page={page}
          setPage={setPage}
          rowsPerPage={cardsPerPage}
          setRowsPerPage={setCardsPerPage}/>
      </div>}
      {!isLoading && tableData?.total === 0 &&
      <Grid container>
        <Grid item className={classes.total}>
          <p className={classes.marginRight}>{t('noResultsFound')}</p>
        </Grid>
      </Grid>}
    </div>
  );
};

EnhancedCardTable.propTypes = {
  source: PropTypes.func.isRequired,
  height: PropTypes.number,
  tableId: PropTypes.string,
  defaultCardsPerPage: PropTypes.number,
  filter: PropTypes.object,
  setTotal: PropTypes.func,
  browseMode: PropTypes.bool,
  selectedCardItem: PropTypes.object,
  setselectedCardItem: PropTypes.func,
  onDoubleClick: PropTypes.func
};

export const CustomCardTable = withWidth()(EnhancedCardTable);