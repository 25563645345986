import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { TableBody, TableRow } from '@material-ui/core';

import { useStyles, BodyCell } from './EnhancedTableBody.css.js';

export const EnhancedTableBody = ({ columnConfiguration, rows }) => {

  const [selected, setSelected] = useState([]);
  const classes = useStyles();

  const handleClick = (e, columnName) => {
    const selectedIndex = selected.indexOf(columnName);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, columnName);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1),
      );
    }

    setSelected(newSelected);
  };

  const getCells = row => {
    return columnConfiguration.map((h, index) => {
      if (h.format) {
        return <BodyCell className={index ? classes.tableCell : classes.tableFirstColumn} key={index} align='left'>{h.format(row)}</BodyCell>;
      } else {
        return <BodyCell className={index ? classes.tableCell : classes.tableFirstColumn} key={index} align='left'>{row[h.id]}</BodyCell>;
      }
    });
  };

  return (
    <TableBody>
      {rows.map((row, index) => {
        return (
          <TableRow
            key={`${row.columnName}-${index}`}
            hover
            onClick={e => handleClick(e, index)}
            tabIndex={-1}
            className={classes.tableRow}>
            {getCells(row)}
          </TableRow>
        );
      })}
    </TableBody>
  );
};

EnhancedTableBody.propTypes = {
  columnConfiguration: PropTypes.array.isRequired,
  rows: PropTypes.array
};
