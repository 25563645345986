import { makeStyles } from '@material-ui/core/styles';
import { displayBorder } from 'helpers';

export const useStyles = makeStyles((theme) => ({
  container: {
    width: '100%',
    paddingLeft: '0.5rem',
    paddingBottom: '0.5rem',
    position: 'relative'
  },
  form: {
    width: '100%'
  },
  header: {
    fontSize: '24px',
    fontFamily: theme.fontLato,
    color: theme.quaternaryTextColor,
    letterSpacing: 0,
    [theme.breakpoints.down(displayBorder)]: {
      marginBottom: 0
    },
  },
  subheading: {
    fontSize: '18px',
    fontFamily: theme.fontLato,
    color: theme.quaternaryTextColor,
    letterSpacing: 0,
    marginBottom: '1.5rem'
  },
  userActions: {
    marginTop: '5%',
    justifyContent: 'space-between'
  },
  sectionTitle: {
    marginBottom: '2%'
  },
  modal: {
    height: 'fit-content',
    minHeight: 'calc(100vh - 150px)',
    padding: '40px',
    backgroundClip: 'content-box',
    boxShadow: 'inset 0 0 0 40px' + theme.quinaryBackgroundColor,
    margin: '0 auto',
    maxWidth: '100%'
  },
  selectRoleContainer: {
    alignItems: 'center',
    marginBottom: '0.8rem'
  },
  role: {
    marginTop: 0,
    color: theme.primaryTextColor
  },
  '@media screen and (max-width: 768px)': {
    modal: {
      boxShadow: 'inset 0 0 0 0',
      padding: '1rem'
    }
  }
}));
