import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(theme => ({
  container: {
    display: 'flex',
    justifyContent: 'flex-start',
    flexDirection: 'column',
    alignItems: 'flex-start',
    wordBreak: 'break-word'
  },
  buyerName: {
    fontWeight: 'bold',
    fontSize: '1rem',
    color: theme.primaryBackgroundColor,
  },
  buyerContainer: {
    backgroundColor: theme.senaryBackgroundColor,
    padding: '0.1rem 2rem',
    maxWidth: '80%'
  },
  date: {
    fontSize: '0.7rem',
    color: theme.primaryTextColor,
    display: 'block',
  },
  salesRepName: {
    fontWeight: 'bold',
    fontSize: '1rem',
    color: theme.tertiaryBackgroundColor,
    display: 'block'
  },
  salesRepContainer: {
    backgroundColor: theme.quinaryBackgroundColor,
    padding: '0.1rem 2rem',
    maxWidth: '80%',
    display: 'block'
  },
  containerSalesRep: {
    wordBreak: 'break-word',
    flexWrap: 'wrap',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end'
  },
  dateSalesRep: {
    fontSize: '0.7rem',
    color: theme.primaryTextColor,
    display: 'block'
  },
  wrapper: {
    marginLeft: '10%',
    marginRight: '10%',
  },
  gifContainer: {
    maxWidth: '80%',
    display: 'block',
    float: 'right'
  },
  gifContainerBuyer: {
    maxWidth: '80%',
    display: 'block',
  },
  baseButton: {
    height: '3rem',
    width: '180px',
    fontWeight: '500',
    fontSize: '1rem',
    textAlign: 'center',
    cursor: 'pointer',
    outline: 'none',
    borderRadius: '0.5rem',
  },
  acceptButton: {
    width: '180px',
    backgroundColor: theme.primaryBackgroundColor,
    color: theme.quaternaryBackgroundColor,
    border: 'none',
    marginRight: '5px'
  },
  editButton: {
    width: '120px',
    backgroundColor: theme.quaternaryBackgroundColor,
    color: theme.tertiaryBackgroundColor,
    border: `1px solid ${theme.primaryBackgroundColor}`
  },
  disabledButton: {
    backgroundColor: theme.quinaryBackgroundColor,
    color: theme.primaryTextColor,
    border: 'none'
  },
  buttonWrepper: {
    display: 'inline-flex',
    margin: '10px 0 10px 0'
  }
}));