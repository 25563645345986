import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import { Container, Grid, Typography, Card, CardContent, CardActions, Button } from '@material-ui/core';
import { useState, useEffect } from 'react';
import { AppBackdrop, CheckboxInputField } from 'components';
import { leadService } from 'services';
import { optInSmsType } from 'helpers';
import { Check, Clear } from '@material-ui/icons';
import { useStyles } from './OptInSmsPage.css';

const initPageState = {
  isFetching: true,
  error: null,
  lead: null,
  //lead: {
  //  companyName: 'Company Name',
  //  salesFullName: John Smit,
  //  userFullName: 'Nick Beem',
  //  userPhone: '380995551122',
  //  optInSms: 0,
  //},
};

export const OptInSmsPage = () => {
  const classes = useStyles();
  const [t] = useTranslation(['common', 'error']);
  const [state, setState] = useState(initPageState);
  const [cbValue, setCbValue] = useState(false);
  const [complete, setComplete] = useState(false);
  const [userSelection, setUserSelection] = useState(false);
  const { search } = useLocation();
  const tempCode = new URLSearchParams(search).get('c');
  const isSelected = new URLSearchParams(search).get('s') === '1';

  const setPageDataState = (res) => {
    setState({
      ...state,
      isFetching: false,
      lead: {
        ...res.data
      },
    });
  };

  const setPageStateError = (error) => {
    let errorMessage = 'Sorry, this link has expired.';
    if (error?.response?.data?.errors) {
      const errorCode = Object.values(error.response.data.errors)[0];
      if (errorCode === 'ForbiddenError') {
        errorMessage = t(`error:${errorCode}`);
      }
    }
    setState({
      ...state,
      isFetching: false,
      error: errorMessage,
    });
  };

  useEffect(() => {
    if (tempCode) {
      leadService.getLeadInfo(tempCode).then((res) => {
        setPageDataState(res);
      }).catch((error) => {
        setPageStateError(error);
      });
    }
  }, [tempCode]);

  const onSelection = (selection) => {
    setState({
      ...state,
      isFetching: true,
    });
    setUserSelection(selection);
    leadService.sendOptInSelection(tempCode, selection).then(() => {
      setComplete(true);
      setState({
        ...state,
        isFetching: false,
      });
    }).catch((error) => {
      setPageStateError(error);
    });
  };

  if (state.error) {
    return (
      <Container fixed maxWidth='md'>
        <Card className={classes.root}>
          <Grid container
            direction='row'
            justifyContent='center'
            alignItems='center'>
            <Typography align='center' className={classes.text} gutterBottom variant='h6'>
              {state.error ?? 'Sorry, this link has expired.'}
            </Typography>
          </Grid>
          <CardContent>
            <Typography variant='h6' align='right' className={`${classes.text} ${classes.textRegards}`}>
              Best regards,<br />Nice Price team
            </Typography>
          </CardContent>
        </Card>
      </Container>
    );
  }

  if (isSelected) {
    return (
      <Container fixed maxWidth='md'>
        {!state.isFetching && state.lead &&
          <Card className={classes.root}>
            <Grid container
              direction='row'
              justifyContent='center'
              alignItems='center'>
              <Typography align='center' className={classes.headerText} gutterBottom variant='h6'>
                {`${state.lead.companyName} car sales offers`}
              </Typography>
            </Grid>
            {complete &&
              <CardContent>
                <Typography variant='h6' align='center' className={`${classes.text}`}>
                  <div>Thanks for your answer!</div>
                  <div>{userSelection === optInSmsType.Disagreed ?
                    `You disagreed to receive SMS notifications from ${state.lead.companyName}.` :
                    `You will continue receive SMS messages from ${state.lead.companyName} with car sale offer notifications.`}
                  </div>
                </Typography>
              </CardContent>
            }
            {!complete &&
              <CardContent>
                <Typography variant='h6' align='center' className={`${classes.text}`}>
                  <div>{`${state.lead.companyName} sends you car sale offers over Nice Price`}</div>
                  <div>when there is one matching your requirements.</div>
                  <div>Would you like to unsubscribe?</div>
                </Typography>
                <CardActions>
                  <Grid container
                    className={classes.btnContainer}
                    direction='row'
                    justifyContent='center'
                    alignItems='center'>
                    <Button
                      variant='outlined'
                      size='large'
                      color='default'
                      onClick={() => onSelection(2)}
                      className={clsx(classes.buttonDecline, classes.buttonStyle)}>
                      Yes
                    </Button>
                    <Button
                      variant='outlined'
                      size='large'
                      color='default'
                      onClick={() => onSelection(1)}
                      className={clsx(classes.buttonAccept, classes.buttonStyle)}>
                      No, keep subscription
                    </Button>
                  </Grid>
                </CardActions>
              </CardContent>
            }
          </Card>
        }
        <AppBackdrop show={state.isFetching} />
      </Container>
    );
  }

  return (
    <Container fixed maxWidth='md'>
      {!state.isFetching && state.lead &&
        <Card className={classes.root}>
          <Grid container
            direction='row'
            justifyContent='center'
            alignItems='center'>
            <Typography align='center' className={classes.headerText} gutterBottom variant='h6'>
              {`${state.lead.companyName} car sales offers`}
            </Typography>
          </Grid>
          {complete &&
            <CardContent>
              <Typography variant='h6' align='center' className={`${classes.text}`}>
                <div>Thanks for your answer!</div>
                <div>{userSelection === optInSmsType.Disagreed ?
                  `You disagreed to receive SMS notifications from ${state.lead.companyName}.` :
                  `You have successfully opted in for SMS messages from ${state.lead.companyName} for car sale offer notifications.`}
                </div>
              </Typography>
            </CardContent>
          }
          {!complete &&
            <CardContent>
              <Typography variant='h6' align='center' className={`${classes.text}`}>
                <div>{`${state.lead.companyName} will send you car sale offers over`}</div>
                <div>Nice Price when there is one matching your requirements.</div>
              </Typography>
              <Typography variant='h6' align='left' className={`${classes.cbText}`}>
                <CheckboxInputField className={classes.optInCheckbox}
                  label={t('optInSmsCheckboxMessage').replace('{name}', state.lead.companyName)}
                  checked={cbValue}
                  onChange={(val) => {
                    setCbValue(val);
                  }} />
                <a href={t('privacyPolicyLink')} target='_blank' rel='noreferrer'>Privacy Policy</a>
              </Typography>
              <CardActions>
                <Grid container
                  className={classes.btnContainer}
                  direction='row'
                  justifyContent='center'
                  alignItems='center'>
                  <Button
                    variant='outlined'
                    size='large'
                    color='default'
                    onClick={() => onSelection(2)}
                    className={clsx(classes.buttonDecline, classes.buttonStyle)}
                    startIcon={<Clear />}>
                    No, thanks
                  </Button>
                  <Button
                    variant='outlined'
                    size='large'
                    color='default'
                    disabled={!cbValue}
                    onClick={() => onSelection(1)}
                    className={clsx(classes.buttonAccept, classes.buttonStyle)}
                    startIcon={<Check />}>
                    Accept
                  </Button>
                </Grid>
              </CardActions>
            </CardContent>
          }
        </Card>
      }
      <AppBackdrop show={state.isFetching} />
    </Container>
  );
};