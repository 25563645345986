const CallbackIcon = () => {
  return (
    <svg className='MuiSvgIcon-root MuiSvgIcon-internal' style={{ 'margin-top': '4px' }} height='16px' width='16px'>
      <g>
        <path d='M8.20312 0.000660577C11.9561 0.000660577 15 3.04461 15 6.79754C15 7.18718 14.6865 7.50066 14.2969 7.50066C13.9072 7.50066 13.5938 7.18718 13.5938 6.79754C13.5938 3.82097 11.1797 1.40691 8.20312 1.40691C7.81348 1.40691 7.5 1.09343 7.5 0.703786C7.5 0.314137 7.81348 0.000660577 8.20312 0.000660577ZM8.4375 5.62566C8.68614 5.62566 8.9246 5.72443 9.10041 5.90025C9.27623 6.07606 9.375 6.31452 9.375 6.56316C9.375 6.8118 9.27623 7.05026 9.10041 7.22607C8.9246 7.40189 8.68614 7.50066 8.4375 7.50066C8.18886 7.50066 7.9504 7.40189 7.77459 7.22607C7.59877 7.05026 7.5 6.8118 7.5 6.56316C7.5 6.31452 7.59877 6.07606 7.77459 5.90025C7.9504 5.72443 8.18886 5.62566 8.4375 5.62566ZM7.5 3.51629C7.5 3.12664 7.81348 2.81316 8.20312 2.81316C10.4033 2.81316 12.1875 4.59734 12.1875 6.79754C12.1875 7.18718 11.874 7.50066 11.4844 7.50066C11.0947 7.50066 10.7812 7.18718 10.7812 6.79754C10.7812 5.37371 9.62695 4.21941 8.20312 4.21941C7.81348 4.21941 7.5 3.90593 7.5 3.51629ZM3.44238 0.0416762C4.01074 -0.113597 4.60547 0.176442 4.83105 0.721364L6.00293 3.53386C6.20215 4.0114 6.06445 4.56511 5.66309 4.89031L4.21875 6.0739C5.19434 8.1364 6.86426 9.80632 8.92676 10.7819L10.1074 9.33757C10.4355 8.93621 10.9863 8.79851 11.4639 8.99773L14.2764 10.1696C14.8213 10.3952 15.1113 10.9899 14.9561 11.5583L14.2529 14.1364C14.1152 14.6462 13.6523 15.0007 13.125 15.0007C5.87695 15.0007 0 9.12371 0 1.87566C0 1.34832 0.354492 0.885426 0.864258 0.744801L3.44238 0.0416762Z' />
      </g>
    </svg>
  );
};

export default CallbackIcon;