import React, { useState } from 'react';
import clsx from 'clsx';
import { Formik, Form } from 'formik';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import * as Yup from 'yup';
import { Box, Grid } from '@material-ui/core';

import { InputField, Button, toastNotification } from 'components';
import { logoWithText, forgotPasswordIcon, emailSentIcon } from 'resources';
import { loginService } from 'services';

import { useStyles } from './ForgotPasswordPage.css.js';

export const ForgotPasswordPage = () => {
  const classes = useStyles();
  const [t] = useTranslation('common');
  const navHistory = useHistory();
  const [isEmailSent, setIsEmailSent] = useState(false);

  const handleSubmit = async (values, { setSubmitting }) => {
    try {
      await loginService.resetPassword(values.email);
      toastNotification('info', t('resetEmailHasBeenSent'));
      setIsEmailSent(true);
    } catch (error) {
      const message = error.code ? error.code : 'userNotRegistered';
      toastNotification('warning', t(message));
    }
    setSubmitting(false);
  };

  const forgotPasswordSchema = Yup.object().shape({
    email: Yup.string()
      .max(300, t('emailValidation'))
      .email(t('invalidEmail'))
      .required(t('required'))
  });

  return (
    <Box className={classes.container}>
      <Grid container className={classes.formContainer}>
        <Grid item xs={9} sm={10} md={10} xl={10} className={classes.inputFieldContainer}>
          <Grid item>
            <img className={classes.logo} src={logoWithText} alt={''} />
          </Grid>
          <Grid item>
            <img className={clsx(classes.logoPassword, { [classes.isEmailSent]: isEmailSent })} src={isEmailSent ? emailSentIcon : forgotPasswordIcon} alt={''} />
          </Grid>
          <Grid>
            <p className={classes.emailText}>
              {t(isEmailSent ? 'emailIsSent' : 'enterTheEmail')}
            </p>
          </Grid>
          {!isEmailSent &&
            <Grid>
              <Formik
                initialValues={{ email: '' }}
                validationSchema={forgotPasswordSchema}
                onSubmit={handleSubmit}>
                {({ isSubmitting, isValid, dirty }) => (
                  <Form className={classes.form}>
                    <InputField label='email' inputName='email' type='email' placeholder='' isRequired />
                    <br />
                    <Button text='sendInstructions' disabled={isSubmitting || !(isValid && dirty)} type='submit' color='primary' />
                  </Form>
                )}
              </Formik>
            </Grid>}
          {isEmailSent &&
            <Grid item>
              <Grid className={classes.button}>
                <Button text='goBackToSignInPage' callback={() => navHistory.push('/signin')} type='button' color='primary' />
              </Grid>
              <Grid>
                <p className={classes.footer}>
                  {t('didNotReceiveTheEmail')}
                </p>
              </Grid>
            </Grid>
          }
        </Grid>
      </Grid>
    </Box>
  );
};