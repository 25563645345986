import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  link: {
    display: 'block',
    fontSize: '1rem',
    margin: '1rem',
    textAlign: 'center',
    color: 'black',
    fontWeight: '600'
  },
  container: {
    display: 'flex',
    width: '100%',
    minHeight: '100vh',
    backgroundColor: theme.quinaryBackgroundColor,
    alignItems: 'center',
    justifyContent: 'center'
  },
  formContainer: {
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    width: '32.5rem',
    height: '46.875rem',
    margin: 'auto'
  },
  inputFieldContainer: {
    backgroundColor: theme.quaternaryBackgroundColor,
    paddingRight: '4rem',
    paddingLeft: '4rem',
    justifyContent: 'center'
  },
  logo: {
    width: '-webkit-fill-available',
    display: 'block',
    textAlign: 'center',
    margin: 'auto',
    paddingTop: '1.5rem'
  },
  logoUser: {
    display: 'block',
    textAlign: 'center',
    margin: 'auto',
    paddingTop: '1.5rem'
  },
  '@media screen and (max-width: 769px)': {
    container: {
      backgroundColor: theme.quaternaryBackgroundColor,
    },
    formContainer: {
      height: 'auto',
      marginTop: '0',
      marginBottom: '0'
    },
    inputFieldContainer: {
      paddingRight: '1rem',
      paddingLeft: '1rem',
    },
  }
}));
