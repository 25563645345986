import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Tooltip } from '@material-ui/core';
import { useStyles } from './LockIcon.css';
import { lockIcon, unlockIcon } from 'resources';

export const LockIcon = ({ value = false, handleLock = null }) => {
  const classes = useStyles();
  const [isLock, setIsLock] = useState(value);

  const onClick = () => {
    const val = !isLock;
    setIsLock(val);
    if (handleLock) {
      handleLock(val);
    }
  };

  return (
    <Tooltip title={isLock ? 'Locked fields are disabled when sending a counter offer' : 'Unlocked fields are editable when sending a counter offer'} placement='top'>
      <img className={classes.lockIcon} onClick={onClick} src={isLock ? lockIcon : unlockIcon} />
    </Tooltip>
  );
};

LockIcon.propTypes = {
  value: PropTypes.bool,
  handleLock: PropTypes.func
};