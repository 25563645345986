import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Grid } from '@material-ui/core';

import { backButtonIcon } from 'resources';

import { useStyles } from './Button.css.js';

export const BackButton = ({callback}) => {
  const classes = useStyles();
  const [t] = useTranslation('common');

  return (
    <Grid className={classes.backButtonContainer} onClick={callback}>
      <Grid item>
        <img src={backButtonIcon} alt={''} />
      </Grid>
      <Grid item>
        <p className={classes.backButtonText}>{t('back')}</p>
      </Grid>
    </Grid>
  );
};

BackButton.propTypes = {
  callback: PropTypes.func
};