import React from 'react';
import clsx from 'clsx';
import { ErrorMessage } from 'formik';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import Grid from '@material-ui/core/Grid';
import VerticalAlignTopIcon from '@material-ui/icons/VerticalAlignTop';

import { useStyles } from './InputField.css.js';

export const DragAndDropInputField = ({inputName, type, accept = '', placeholder, label, isRequired, readOnly, disabled, onChange, toUploadOnPhone}) => {

  const classes = useStyles();
  const [t] = useTranslation('common');

  const containerDragAndDropStyle = toUploadOnPhone ? classes.containerDragAndDropBrowse : classes.containerDragAndDrop;
  const inputDragAndDropStyle = toUploadOnPhone ? classes.inputDragAndDropBrowse : classes.inputDragAndDrop;
  const hiddenInputDragAndDropStyle = toUploadOnPhone ? classes.hiddenInputDragAndDropBrowse : classes.hiddenInputDragAndDrop;
  const spaceBetweenStyle = toUploadOnPhone ? '' : classes.spaceBetween;
  const validationNegativeTopStyle = toUploadOnPhone ? classes.validationNegativeTopBrowse : classes.validationNegativeTop;

  return (
    <div>
      <Grid item className={classes.labelContainer}>
        <span className={classes.label}>{t(label)}</span>
        {isRequired && <span className={classes.isRequired}>*</span>}
      </Grid>
      <div className={containerDragAndDropStyle}>
        <Grid container>
          <Grid item className={classes.inputContainer}>
            <Grid
              className={clsx(inputDragAndDropStyle, {[classes.readOnlyInput]: readOnly})}
              type={type}
              name={inputName}
              placeholder={t(placeholder)}
              readOnly={readOnly}
              onChange={onChange}>
              <Grid container className={spaceBetweenStyle}>
                {!toUploadOnPhone &&
                  <Grid>
                    <VerticalAlignTopIcon className={classes.iconDorp} />
                  </Grid>
                }
                <Grid className={classes.centerContent}>
                  {t(placeholder)}
                </Grid>
              </Grid>
            </Grid>
            <input
              className={clsx(classes.inputDragAndDrop, hiddenInputDragAndDropStyle, classes.negativeTop, {[classes.readOnlyInput]: readOnly})}
              type={type}
              name={inputName}
              accept={accept}
              readOnly={readOnly}
              disabled={disabled}
              onChange={onChange}/>
            <ErrorMessage className={clsx(classes.validation, validationNegativeTopStyle)} name={inputName} component='div' />
          </Grid>
        </Grid>
      </div>
    </div>
  );
};

DragAndDropInputField.propTypes = {
  inputName: PropTypes.string,
  type: PropTypes.string,
  accept: PropTypes.string,
  placeholder: PropTypes.string,
  label: PropTypes.string,
  isRequired: PropTypes.bool,
  readOnly: PropTypes.bool,
  disabled: PropTypes.bool,
  onChange: PropTypes.func,
  toUploadOnPhone: PropTypes.bool,
};
