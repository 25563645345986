import { useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Grid } from '@material-ui/core';

import { PaginationSelectFilter } from 'components';
import { rolesPerPage } from 'helpers';
import { arrowRightIcon } from 'resources';

import { useStyles, StyledPagination } from './Pagination.css';

const FIRST_PAGE = 1;
const ROWS_PER_PAGE = 10;

export const Pagination = ({page, setPage, count, rowsPerPage, setRowsPerPage, paginationRef}) => {
  const classes = useStyles();
  const [t] = useTranslation('common');
  const [ goToPage, setGoToPage ] = useState();

  const handleChangePage = (e, newPage) => {
    setPage(newPage);
  };

  return (
    <div className={classes.container}>
      <StyledPagination
        ref={paginationRef}
        onChange={handleChangePage}
        page={page}
        defaultPage={FIRST_PAGE}
        siblingCount={1}
        boundaryCount={1}
        count={count} />
      <Grid item className={classes.navigationContainer}>
        <p className={classes.goToPageText}>{t('goToPage')}</p>
        <div className={classes.inputContainer}>
          <input
            className={classes.input}
            onChange={e => {
              if (e.target.value) {
                setGoToPage(e.target.value > count ? count : e.target.value);
              } else {
                setGoToPage(FIRST_PAGE);
              }
            }}
            maxLength='4'/>
          <div className={classes.imgContainer}>
            <img className={classes.img} src={arrowRightIcon} alt={''} onClick={() => setPage(Number(goToPage))}/>
          </div>
        </div>
      </Grid>
      <Grid item md={3} className={classes.displayFlex}>
        <p className={classes.showText}>{t('show')}</p>
        <PaginationSelectFilter
          width={1}
          className={classes.paginationSelectFilter}
          onChange={e => {
            setRowsPerPage(e.target.value ? e.target.value : ROWS_PER_PAGE);
            setPage(FIRST_PAGE);
          }}
          value={rowsPerPage}
          menuItem={rolesPerPage}/>
        <p className={classes.showText}>{t('resultsPerPage')}</p>
      </Grid>
    </div>
  );
};

Pagination.propTypes = {
  page: PropTypes.number,
  setPage: PropTypes.func,
  count: PropTypes.number,
  rowsPerPage: PropTypes.number,
  setRowsPerPage: PropTypes.func,
  paginationRef: PropTypes.any
};