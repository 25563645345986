import React from 'react';
import clsx from 'clsx';
import { Field, ErrorMessage } from 'formik';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { useStyles } from './InputField.css.js';

export const ConversationInputField = ({ inputName, type, placeholder, readOnly }) => {

  const classes = useStyles();
  const [t] = useTranslation('common');

  return (
    <div className={classes.maxWidth}>
      <Field xs={8}
        className={clsx(classes.conversationInput, { [classes.readOnly]: readOnly })}
        type={type}
        name={inputName}
        placeholder={t(placeholder)}
        component='textarea'
        readOnly={readOnly} />
      <ErrorMessage className={classes.conversationValidation} name={inputName} component='div' />
    </div>
  );
};

ConversationInputField.propTypes = {
  inputName: PropTypes.string,
  type: PropTypes.string,
  placeholder: PropTypes.string,
  readOnly: PropTypes.bool
};
