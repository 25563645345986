import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  container: {
    width: '100%',
    padding: '0 0 40px 0',
  },
  form: {
    width: '100%'
  },
  header: {
    fontSize: '24px',
    fontFamily: theme.fontLato,
    color: theme.quaternaryTextColor,
    letterSpacing: 0,
    width: '100%',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    lineClamp: 2,
    padding: '1rem 0'
  },
  formActions: {
    marginTop: '5%',
    placeContent: 'end',
  },
  modal: {
    height: 'fit-content',
    minHeight: 'calc(100vh - 150px)',
    padding: '40px',
    backgroundClip: 'content-box',
    boxShadow: 'inset 0 0 0 40px' + theme.quinaryBackgroundColor,
    margin: '0 auto',
    maxWidth: '100%'
  },
  modalPhone: {
    padding: '1rem'
  },
  iconStyle: {
    height: '1.5rem',
    width: '1.5rem',
    color: theme.primaryBackgroundColor,
    paddingLeft: '0,'
  },
  uncheckedIconStyle: {
    height: '1.5rem',
    width: '1.5rem',
    color: theme.primaryBorderColor,
    paddingLeft: '0,'
  },
  isRequired: {
    marginRight: '1rem',
    color: theme.primaryBackgroundColor
  },
  radioGroup: {
    padding: '1rem',
  },
  radio: {
    marginRight: '3rem',
  },
  radioLabel: {
    fontWeight: 500,
  },
  radioBlock: {
    '& > fieldset': {
      padding: '0',
      margin: '0 0 0 15px',
    }
  },
  checkboxBlock: {
    margin: '12px 0 0 0',
    '& > span': {
      display: 'inline-flex',
      marginBottom: '12px',
      color: theme.primaryTextColor,
    },
    '& > div > div': {
      margin: '0 0 0 15px',
      display: 'flex',
    },
    '& > div > div > label > span.MuiTypography-root > span': {
      color: theme.tertiaryBackgroundColor
    }
  },
}));
