import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@material-ui/core';

import { EnhancedCardTableItem } from './EnhancedCardTableItem';

import { useStyles } from './EnhancedCardTableItems.css.js';

export const EnhancedCardTableItems = ({ items, browseMode, setPage, setCardsPerPage, deletedGif, setdeletedGif, selectedCardItem, setselectedCardItem, onDoubleClick }) => {

  const [ selected, setSelected ] = useState([]);
  const classes = useStyles();

  const cardRef = useRef();
  let timer;
  const updateSize = () => {
    clearTimeout(timer);
    timer = setTimeout(() => {
      if (cardRef.current) {
        setCardsPerPage(cardRef.current.clientHeight);
      }
    }, 250);
  };

  window.addEventListener('resize', updateSize);

  useEffect(() => {
    if (cardRef.current) {
      setCardsPerPage(cardRef.current.clientHeight);
    }
  }, [ cardRef, setCardsPerPage ]);

  const handleClick = (e, columnName) => {
    const selectedIndex = selected.indexOf(columnName);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, columnName);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1),
      );
    }

    setSelected(newSelected);
  };

  return (
    <div className={classes.cardContainer} >
      <Grid container className={classes.spaceBetween}>
        {items.map((item, index) => {
          return (
            <Grid container key={item.id} className={classes.cardGrid}>
              <EnhancedCardTableItem
                item={item}
                browseMode={browseMode}
                deletedGif={deletedGif}
                setdeletedGif={setdeletedGif}
                selectedCardItem={selectedCardItem}
                setselectedCardItem={setselectedCardItem}
                onDoubleClick={onDoubleClick}
                setPage={setPage}
                key={item.id}
                hover
                onClick={e => handleClick(e, index)}
                tabIndex={-1}>
              </EnhancedCardTableItem>
            </Grid>
          );
        })}
      </Grid>
    </div>
  );
};

EnhancedCardTableItems.propTypes = {
  items: PropTypes.array,
  browseMode: PropTypes.bool,
  setCardsPerPage: PropTypes.func.isRequired,
  deletedGif: PropTypes.bool,
  setdeletedGif: PropTypes.func,
  setPage: PropTypes.func,
  selectedCardItem: PropTypes.object,
  setselectedCardItem: PropTypes.func,
  onDoubleClick: PropTypes.func
};