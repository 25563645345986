import React, { useEffect, useState } from 'react';
import * as FileSaver from 'file-saver';
import XLSX from 'sheetjs-style';
import PropTypes from 'prop-types';
import { IconButton } from '@material-ui/core';
import { excel } from 'resources';

import { useStyles } from './style.css.js';

export const AppExcelExport = ({ fileName, fetchData, disabled }) => {
  const classes = useStyles();
  const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
  const fileExtension = '.xlsx';
  const [disabledExport, setDisabledExport] = useState(disabled);

  useEffect(() => {
    setDisabledExport(disabled);
  }, [disabled]);

  const exportToExcel = async () => {
    setDisabledExport(true);
    fetchData().then((response) => {
      const ws = XLSX.utils.json_to_sheet(response);
      const wb = { Sheets: { data: ws }, SheetNames: ['data'] };
      const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
      const data = new Blob([excelBuffer], { type: fileType });
      FileSaver.saveAs(data, fileName + fileExtension);
      setDisabledExport(false);
    });
  };

  return (
    <IconButton aria-label='download' title='Download report' className={classes.button}
      onClick={exportToExcel}
      disabled={disabledExport}>
      <img className={classes.image} src={excel} alt={''} />
    </IconButton>
  );
};

AppExcelExport.propTypes = {
  fetchData: PropTypes.func,
  fileName: PropTypes.string,
  disabled: PropTypes.bool,
};