import { makeStyles } from '@material-ui/core/styles';
import { displayBorder, displayBorderExtraLarge } from 'helpers';

export const useStyles = makeStyles((theme) => ({
  container: {
    color: theme.quaternaryTextColor,
    height: '100%',
    position: 'relative',
    paddingLeft: '1rem',
    paddingRight: '1rem',
    backgroundColor: theme.drawersBackgroundColor,
    paddingBottom: '3.5rem',
    overflowY: 'auto',
    [theme.breakpoints.up(displayBorder)]: {
      paddingLeft: '6rem',
      paddingRight: '6rem'
    }
  },
  nullPaddingBottom: {
    paddingBottom: '0'
  },
  whiteBackground: {
    backgroundColor: 'white !important',
  },
  paddingRight: {
    paddingRight: '20px',
    display: 'flex'
  },
  spaceBetween: {
    direction: 'row',
    justifyContent: 'space-between',
    marginBottom: '1rem'
  },
  paddingTop: {
    paddingTop: '1.5rem !important'
  },
  total: {
    color: theme.primaryTextColor,
    display: 'flex',
    alignItems: 'end',
    flexWrap: 'wrap'
  },
  marginRight: {
    marginRight: '0.3rem',
    display: 'flex'
  },
  filterIcon: {
    width: '1.3rem',
    height: '1.3rem',
    marginRight: '0.5rem'
  },
  filterText: {
    margin: 0
  },
  sortByText: {
    margin: 0,
    paddingLeft: '2rem',
    [theme.breakpoints.up(displayBorderExtraLarge)]: {
      paddingLeft: '2.5rem'
    }
  },
  filterContainer: {
    alignItems: 'center'
  },
  arrows: {
    cursor: 'pointer',
    paddingLeft: '0.5rem'
  },
  selectContainer: {
    paddingRight: '0.7rem',
    paddingLeft: '0.7rem'
  },
  sortContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  selectFilter: {
    padding: '0 0.5rem',
    width: '14rem'
  },
  smallSearchContainer: {
    width: '80%',
    margin: '5% auto',
    marginRight: '11%'
  },
  switchButton: {
    flexDirection: 'column',
  },
  alignItems: {
    alignItems: 'center'
  },
  root: {
    backgroundColor: theme.quinaryBackgroundColor,
    border: '2px solid' + theme.primaryBackgroundColor,
    alignItems: 'center',
    '&:hover': {
      backgroundColor: theme.quinaryBackgroundColor
    },
    marginBottom: '0.5rem',
    maxWidth: '20rem',
    width: 'auto',
  },
  deleteIcon: {
    color: theme.primaryTextColor,
    width: '1rem',
    height: '1rem'
  },
  chipContainer: {
    marginLeft: '1rem',
    marginTop: '1rem',
    alignItems: 'center',
    display: 'block',
    width: 'auto',
    whiteSpace: 'nowrap',
    overflow: 'hidden !important',
    textOverflow: 'ellipsis'
  },
  '@media screen and (max-width:615px)': {
    filterContainer: {
      display: 'flex',
      alignItems: 'flex-start',
      alignContent: 'stretch'
    },
    searchContainer: {
      margin: 'auto',
      display: 'flex',
      marginBottom: '1rem'
    },
    selectContainer: {
      margin: '1rem auto',
      marginRight: '1.2rem',
      paddingRight: '0rem'
    },
    arrows: {
      paddingRight: '0.7rem',
      paddingLeft: '0',
      paddingTop: '2rem'
    },
    paddingRight: {
      paddingLeft: '1rem',
      paddingBottom: '1rem'
    },
    sortByText: {
      width: '100%',
      paddingLeft: '1rem',
    },
  },
  '@media screen and (max-width:960px)': {
    marginRight: {
      marginLeft: '1rem',
    },
    chipContainer: {
      marginTop: '0',
      marginBottom: '0.5rem',
      marginLeft: '0.5rem',
    }
  },
}));