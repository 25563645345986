import { makeStyles } from '@material-ui/core/styles';
import { displayBorder, displayBorderExtraLarge } from 'helpers';

export const useStyles = makeStyles((theme) => ({
  container: {
    width: '100%',
    height: '100%',
    [theme.breakpoints.up(displayBorder)]: {
      width: '100%',
      height: '100%',
    }
  },
  contentContainer: {
    margin: '0.5rem'
  },
  closeImg: {
    cursor: 'pointer',
    float: 'right',
    marginTop: '5px',
    width: '40px',
    padding: '0px 6px 0px 0px',
    color: theme.primaryButtonColor,
    fontSize: '1.5rem',
    '&:hover': {
      backgroundColor: 'transparent'
    }
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'center'
  },
  dialogPaper: {
    marginTop: '8%',
    minHeight: '80%',
    maxHeight: '80%',
    minWidth: '85%',
    maxWidth: '85%',
    [theme.breakpoints.up(displayBorderExtraLarge)]: {
      marginTop: '5%',
      minHeight: '85%',
      maxHeight: '85%',
      minWidth: '90%',
      maxWidth: '90%',
    }
  },
  deleteMessageIcon: {
    alignSelf: 'center'
  }
}));