import axios from 'axios';
import qs from 'qs';
import { getApiCoreUrl, notificationsLifetimeInDays, rows } from 'helpers';
import { baseApiErrorHandler } from 'services';

const baseUrl = `${getApiCoreUrl()}/api/v1/Notification`;

const getNotifications = (userId, rowsPerPage = rows, page = 0, lifetime = -notificationsLifetimeInDays) => {
  if (!userId) {
    return null;
  }
  const url = `${baseUrl}?${qs.stringify({
    lifetime: -lifetime,
    offset: page * rowsPerPage,
    limit: rowsPerPage,
    userId
  })}`;
  return axios.get(url).then(response => {
    return response.data;
  }).catch(baseApiErrorHandler);
};

const updateNotification = (id) => {
  const url = `${baseUrl}/read/${id}`;
  return axios.put(url).catch(baseApiErrorHandler);
};

const deleteAllNotifications = () => {
  return axios.delete(baseUrl).catch(baseApiErrorHandler);
};

export const notificationService = {
  getNotifications,
  updateNotification,
  deleteAllNotifications
};