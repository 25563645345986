import React, { useEffect, useState } from 'react';

import { Grid, Card, CardActions, CardContent, CardMedia, Button, Typography } from '@material-ui/core';
import { Check, Clear } from '@material-ui/icons';
import Countdown from 'react-countdown';
import PropTypes from 'prop-types';
import { LeaseForm, FinanceForm, FinanceTradeInForm, CashForm, CashTradeInForm } from './CounterOfferForms';
import { ShareIcon, CallbackIcon } from '../../resources';
import { useStyles } from './style';

const OfferCard = ({ offer, onAccept, onDecline, onCallback, onCounterOffer, isCounterOfferSelected }) => {
  const classes = useStyles();
  const [isExpired, setIsExpired] = useState(false);
  const [showCounterofferForm, setShowCounterofferForm] = useState(isCounterOfferSelected ?? false);
  const [clientSelectionState, setClientSelectionState] = useState(0);

  useEffect(() => {
    setClientSelectionState(offer?.clientSelection ?? 0);
    setShowCounterofferForm(isCounterOfferSelected ?? false);
  }, [offer, isCounterOfferSelected]);

  const countdownRenderer = ({ hours, minutes, seconds, completed }) => {
    if (hours > 0) {
      minutes = minutes + hours * 60;
    }
    if (completed) {
      setIsExpired(true);
      return <></>;
    } else {
      return (
        <Typography className={classes.countdown} align='center' component='div'>
          <Typography align='center' className={classes.text} gutterBottom variant='h6'>
            Your offer expires in:
          </Typography>
          <Typography className={classes.countdownNumber} align='center' variant='h2'>
            <span>{minutes < 10 ? `0${minutes}` : minutes}</span>
            <span className={classes.divider}>:</span>
            <span>{seconds < 10 ? `0${seconds}` : seconds}</span>
          </Typography>
          <Typography className={classes.countdownText} align='center' variant='h2'>
            <span>minutes</span>
            <span className={classes.divider2}></span>
            <span>seconds</span>
          </Typography>
        </Typography>
      );
    }
  };

  const getCardButtons = () => {
    if (!isExpired && clientSelectionState === 0 && !showCounterofferForm) {
      return (
        <CardActions>
          <Grid container
            direction='row'
            justifyContent='center'
            alignItems='center'
            spacing={0}>
            <Button
              variant='outlined'
              size='large'
              color='default'
              disabled={isExpired}
              onClick={onAccept}
              className={[classes.buttonAccept, classes.buttonStyle].join(' ')}
              startIcon={<Check />}>
              Accept
            </Button>
            <Button
              variant='outlined'
              size='large'
              color='default'
              disabled={isExpired}
              onClick={onDecline}
              className={[classes.buttonDecline, classes.buttonStyle].join(' ')}
              startIcon={<Clear />}>
              Reject
            </Button>
          </Grid>
        </CardActions>
      );
    }

    return null;
  };

  const getCounterOfferForm = () => {
    if (isExpired || clientSelectionState !== 0) {
      return null;
    }

    if (showCounterofferForm) {
      switch (offer.paymentType) {
        case 1: return <LeaseForm offer={offer} onCounterOffer={onCounterOffer} onCancel={onCancel} />;
        case 2: return offer.isTradeIn ? <FinanceTradeInForm offer={offer} onCounterOffer={onCounterOffer} onCancel={onCancel} /> :
          <FinanceForm offer={offer} onCounterOffer={onCounterOffer} onCancel={onCancel} />;
        case 3: return offer.isTradeIn ? <CashTradeInForm offer={offer} onCounterOffer={onCounterOffer} onCancel={onCancel} /> :
          <CashForm offer={offer} onCounterOffer={onCounterOffer} onCancel={onCancel} />;
        default:
          return null;
      }

    } else {
      if (offer.hiddenCounterOffer) {
        return null;
      } else {
        return (
          <CardActions>
            <Grid container
              direction='row'
              justifyContent='center'
              alignItems='center'
              spacing={0}>
              <Button
                type='submit'
                variant='outlined'
                size='large'
                color='default'
                className={[classes.buttonCounterOffer, classes.buttonStyle, classes.buttonCounterOfferLarge].join(' ')}
                onClick={() => setShowCounterofferForm(true)}
                startIcon={<ShareIcon />}>
                Counter Offer
              </Button>
            </Grid>

          </CardActions>
        );
      }
    }
  };

  const onRejectAndCounterOffer = () => {
    setClientSelectionState(0);
    setShowCounterofferForm(true);
  };

  const onCancel = () => {
    setClientSelectionState(offer.clientSelection);
    setShowCounterofferForm(false);
  };

  const getHeaderMessage = () => {
    if (isExpired || clientSelectionState === 4) {
      return (
        <Typography align='center' className={classes.text} gutterBottom variant='h6'>
          Your offer has expired.
        </Typography>
      );
    }

    switch (clientSelectionState) {
      case 1:
        return (
          <Typography align='center' className={classes.text} gutterBottom variant='h6'>
            You`ve accepted the offer.<br />
            Our team will contact you soon.
          </Typography>
        );
      case 2:
        return (
          <CardActions>
            <Grid container
              direction='row'
              justifyContent='center'
              alignItems='center'
              spacing={0}>
              <Typography align='center' className={classes.text} gutterBottom variant='h6'>
                You`ve rejected the offer.<br />
                <b>So you say No Deal. Would you like to Counteroffer or Speak to a Manager? Let us know!</b>
              </Typography>
              {!offer.hiddenCounterOffer &&
                <Button
                  type='submit'
                  variant='outlined'
                  size='medium'
                  color='default'
                  disabled={isExpired}
                  className={[classes.buttonCounterOffer, classes.buttonStyle].join(' ')}
                  onClick={onRejectAndCounterOffer}
                  startIcon={<ShareIcon />}>
                  Counter Offer
                </Button>
              }
              <Button
                variant='outlined'
                size='medium'
                color='default'
                onClick={onCallback}
                className={[classes.buttonCallback, classes.buttonStyle].join(' ')}
                startIcon={<CallbackIcon />}>
                Callback
              </Button>
            </Grid>
          </CardActions>
        );
      case 3:
        return (
          <Typography align='center' className={classes.text} gutterBottom variant='h6'>
            Your counter offer has been received.<br />
            You will be contacted shortly.
          </Typography>
        );
      case 5:
        return (
          <Typography align='center' className={classes.text} gutterBottom variant='h6'>
            Your counter offer has been accepted.
          </Typography>
        );
      case 6:
        return (
          <Typography align='center' className={classes.text} gutterBottom variant='h6'>
            Thanks for your request!<br />
            You will be contacted shortly.
          </Typography>
        );
      default:
        return (
          <div>
            <Typography className={classes.text} gutterBottom variant='h6' >
              <i>Dear {offer.clientName},</i>
            </Typography>
            <Typography style={{ margin: '20px 0 20px 0' }} className={classes.text} gutterBottom variant='h6' >
              <span style={{ fontWeight: 'bold', fontSize: '24px' }}>Here is your offer:</span>
            </Typography>
          </div>
        );
    }
  };

  return offer ? (
    <Card className={classes.root}>
      <Grid container
        direction='row'
        justifyContent='center'
        alignItems='center'>
        <Grid item xs={12}>
          <Typography align='center' gutterBottom variant='h5'>
            <span className={classes.headCompName}>{offer.companyName}</span>
          </Typography>
        </Grid>
        <Grid item xs={12} mb={12}>
          {getHeaderMessage()}
        </Grid>
        <CardMedia
          component='img'
          className={classes.media}
          image={clientSelectionState === 3 ? offer.gifCounteredUrl : offer.gifUrl}
          title='Offer' />
      </Grid>
      {offer.disclaimer &&
        <Typography className={classes.text} variant='h6'>
          Disclaimer: <br />
          {offer.disclaimer}
        </Typography>
      }
      <CardContent>
        {(offer.expireAt && !clientSelectionState) && <Countdown date={offer.expireAt} renderer={countdownRenderer} />}
        {getCardButtons()}
        {getCounterOfferForm()}
        {(!showCounterofferForm || clientSelectionState === 3 || clientSelectionState === 5) &&
          <Typography variant='h6' align='right' className={`${classes.text} ${classes.textRegards}`}>
            Best regards,
            <br />{offer.salesName},
            <br />{offer.companyName}
          </Typography>}
      </CardContent>
    </Card>
  ) : null;
};

OfferCard.propTypes = {
  offer: PropTypes.object,
  onAccept: PropTypes.func,
  onDecline: PropTypes.func,
  onCallback: PropTypes.func,
  onCounterOffer: PropTypes.func,
  isCounterOfferSelected: PropTypes.bool,
};

export default OfferCard;
