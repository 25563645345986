import { useLocation } from 'react-router-dom';
import { Container, Grid, Typography, Card, CardContent } from '@material-ui/core';
import { useState, useEffect } from 'react';
import { AppBackdrop } from 'components';
import { offerService } from 'services';
import OfferCard from './OfferCard';
import { useStyles } from './style';

const initPageState = {
  isFetching: true,
  error: null,
  offer: null,
  //offer: {
  //  gifUrl: 'https://stnicepriceprod.blob.core.windows.net/gifs/Congratulations.gif',
  //  expireAt: 1708344004236,
  //  clientName: 'Nick',
  //  salesName: 'John Smit',
  //  clientSelection: 0,
  //},
};

export const ConfirmOfferPage = () => {
  const classes = useStyles();
  const [state, setState] = useState(initPageState);
  const { search } = useLocation();
  const tempCode = new URLSearchParams(search).get('c');
  const userSelection = Number.parseInt(new URLSearchParams(search).get('s'));
  const isUserSelectionValid = userSelection && (userSelection === 1 || userSelection === 2);
  const isCounterOfferSelected = userSelection === 3;

  const setStateOffer = (res) => {
    setState({
      ...state,
      offer: {
        ...res.data,
        salesName: `${res.data.salesName} ${res.data.salesSurname}`
      },
      isFetching: false,
    });
  };

  const setStateError = (error) => {
    setState({
      ...state,
      isFetching: false,
      error: {
        data: error?.response?.data,
        status: error?.response?.status
      },
    });
  };

  const onSelection = (selection) => {
    if (selection === 1 || selection === 2 || selection === 6) {
      setState({
        ...state,
        isFetching: true,
      });

      const request = {
        tempCode,
        userSelection: selection
      };

      offerService.confirmOffer(request).then((res) => {
        setStateOffer(res);
      }).catch((error) => {
        setStateError(error);
      });
    }
  };

  const onCounterOffer = (formData) => {
    setState({
      ...state,
      isFetching: true,
    });

    const request = {
      tempCode,
      userSelection: 3,
      ...formData
    };

    offerService.confirmOffer(request).then((res) => {
      setStateOffer(res);
    }).catch((error) => {
      setStateError(error);
    });
  };

  useEffect(() => {
    if (tempCode) {
      offerService.getOfferInfo(tempCode).then((res) => {
        if (res.data.clientSelection === 0 && isUserSelectionValid) {
          onSelection(userSelection);
        } else {
          setStateOffer(res);
        }
      }).catch((error) => {
        setStateError(error);
      });
    }
  }, [tempCode, isUserSelectionValid]);

  if (state.error && state.error.status) {
    return (
      <Container fixed maxWidth='md'>
        <Card className={classes.root}>
          <Grid container
            direction='row'
            justifyContent='center'
            alignItems='center'>
            <Typography align='center' className={classes.text} gutterBottom variant='h6'>
              Sorry, your offer does not exist anymore.
            </Typography>
          </Grid>
          <CardContent>
            <Typography variant='h6' align='right' className={`${classes.text} ${classes.textRegards}`}>
              Best regards,<br />Nice Price team
            </Typography>
          </CardContent>
        </Card>
      </Container>
    );
  }

  return (
    <Container fixed maxWidth='sm'>
      {state.offer &&
        <OfferCard
          offer={state.offer}
          onAccept={() => onSelection(1)}
          onDecline={() => onSelection(2)}
          onCallback={() => onSelection(6)}
          onCounterOffer={onCounterOffer}
          isCounterOfferSelected={isCounterOfferSelected} />}
      <AppBackdrop show={state.isFetching} />
    </Container>
  );
};