import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  root: {
    overflow: 'scroll',
    width: '70%'
  },
  fullList: {
    width: 'auto',
  },
  paper: {
    backgroundColor: theme.quinaryBackgroundColor,
    width: '70%',
    overflowY: 'auto',
    overflowX: 'hidden',
  },
  flexEnd: {
    justifyContent: 'flex-end'
  },
  closeImg: {
    cursor: 'pointer',
    marginTop: '1rem',
    marginBottom: '0.5rem',
    width: '40px',
    padding: '0px 6px 0px 0px',
    color: theme.primaryTextColor,
    '&:hover': {
      backgroundColor: 'transparent'
    },
    fontSize: '1.5rem'
  },
  buttonPadding: {
    padding: '2rem',
  },
  h: {
    color: theme.primaryTextColor,
    fontSize: '1.5rem',
    paddingBottom: '.25rem',
    marginLeft: '1.5rem',
    marginTop: '0rem',
    display: 'inline-block'
  },
  buttonMargin: {
    marginBottom: '1rem'
  },
  buttonContainer: {
    width: '80%',
    margin: '1rem auto'
  },
  filterIcon: {
    width: '1.54rem',
    display: 'inline',
    marginRight: '1rem'
  },
  '@media screen and (min-width: 600px) and (max-width: 960px)': {
    paper: {
      width: '40%',
    },
  }
}));