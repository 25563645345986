import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';
import axios from 'axios';

const firebaseConfig = {
  apiKey: window.REACT_APP_FIREBASE_API_KEY,
  authDomain: window.REACT_APP_FIREBASE_AUTH_DOMAIN,
  projectId: window.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: window.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: window.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: window.REACT_APP_APP_ID,
  measurementId: window.REACT_APP_MEASUREMENT_ID
};

// Initialize Firebase
firebase.initializeApp(firebaseConfig);

export const auth = firebase.auth();
export const firestore = firebase.firestore();

//axios interceptors
axios.interceptors.request.use(async (config) => {
  if (config.headers['x-ms-blob-type'] || config.url.includes('/reset_password')) {
    return config;
  }

  let accessToken = null;
  try {
    accessToken = await firebase.auth().currentUser.getIdToken();
  } catch {
    accessToken = null;
  }

  if (accessToken) {
    config.headers.Authorization = 'Bearer ' + accessToken;
  }

  return config;
}, err => {
  return Promise.reject(err);
});

export const getApiCoreUrl = () => {
  const r = new RegExp('^(?:[a-z]+:)?//', 'i');

  if (r.test(window.REACT_APP_CORE_API_URL)) {
    return window.REACT_APP_CORE_API_URL;
  } else {
    return window.location.origin + window.REACT_APP_CORE_API_URL;
  }
};

export const getTenantId = () => {
  const tenantsConfig = window.REACT_APP_FIREBASE_TENANTS_CONFIG;
  const tenant = tenantsConfig.find(c => c.domain === window.location.host);
  return tenant ? tenant.tenantId : null;
};

export const getApiKey = () => {
  return window.REACT_APP_CORE_API_KEY;
};

export const getWebsiteBaseUrl = () => {
  return window.REACT_APP_WEBSITE_URL;
};