import React, { useContext } from 'react';
import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/database';
import { Formik, Form } from 'formik';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import * as Yup from 'yup';
import { Box, Grid } from '@material-ui/core';

import { PasswordInputField, Button, toastNotification } from 'components';
import { handleFirebaseErrors, getParameterByName } from 'helpers';
import { UserContext } from 'providers';
import { forgotPasswordIcon, logoWithText } from 'resources';
import { loginService } from 'services';

import { useStyles } from './Common.css.js';

export const SetupAccount = () => {
  const classes = useStyles();
  const { t } = useTranslation([ 'common', 'error' ]);
  const navHistory = useHistory();
  const { setIsSetupAccount } = useContext(UserContext);
  const [ errorMessage, setErrorMessage ] = React.useState(null);

  const handleSubmit = async(values, { setSubmitting }) => {
    const verificationToken = getParameterByName('t');
    const email = getParameterByName('email');
    setIsSetupAccount(true);
    try {
      await firebase.auth().createUserWithEmailAndPassword(email, values.password);
      const firebaseUser = firebase.auth().currentUser;
      const uid = firebaseUser.uid;
      const userData = {
        token: verificationToken,
        externalUserId: uid,
      };
      await loginService.setupAccount(userData);
      try {
        setIsSetupAccount(false);
        await firebase.auth().signOut();
        toastNotification('info', t('newUser'));
      } catch (error) {
        setIsSetupAccount(false);
        toastNotification('error', t(`error:${Object.values(error.response.data.errors)[0]}`));
        setErrorMessage(t('accountSetupError'));
        try {
          toastNotification('info', t('userIsDeleted'));
        } catch (e) {
          toastNotification('error', t(`error:${Object.values(error.response.data.errors)[0]}`));
        }
      }
      navHistory.push('/');
    } catch (error) {
      const message = handleFirebaseErrors(error);
      setErrorMessage(t(message));
      setIsSetupAccount(false);
      toastNotification('warning', t(message));
    }
    setSubmitting(false);
  };

  const setupSchema = Yup.object().shape({
    password: Yup.string()
      .min(8, t('tooShort'))
      .max(50, t('tooLong'))
      .required(t('required')),
    confirmedPassword: Yup.string()
      .oneOf([ Yup.ref('password'), null ], t('doesNotMatch'))
      .min(8, t('tooShort'))
      .max(50, t('tooLong'))
      .required(t('required'))
  });

  return (
    <Box className={classes.container}>
      <Grid container className={classes.formContainer}>
        <Grid item md={10} xl={10} className={classes.inputFieldContainer}>
          <Grid item>
            <img className={classes.logo} src={logoWithText} alt={''} />
          </Grid >
          <Grid item>
            <img className={classes.logo} src={forgotPasswordIcon} alt={''} />
          </Grid >
          <Grid>
            <Formik
              initialValues={{ password: '', confirmedPassword: '' }}
              validationSchema={setupSchema}
              onSubmit={handleSubmit}>
              {({ isSubmitting, isValid, dirty }) => (
                <Form className={classes.form}>
                  <div className={classes.marginTop20}>
                    <PasswordInputField label='password' inputName='password' placeholder=''/>
                  </div>
                  <div className={classes.marginTop20}>
                    <PasswordInputField label='confirmPassword' inputName='confirmedPassword' placeholder=''/>
                  </div>
                  <br/>
                  <Button text='signUp' disabled={isSubmitting || !(isValid && dirty)} type='submit' variant='primary'/>
                  <br/>
                  {errorMessage && <p className={classes.error}>{errorMessage}</p>}
                </Form>
              )}
            </Formik>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};
