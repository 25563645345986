import React, { useState } from 'react';
import clsx from 'clsx';
import { Field, ErrorMessage, useFormikContext } from 'formik';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import Grid from '@material-ui/core/Grid';
import { NumericFormat } from 'react-number-format';

import { useStyles } from './InputField.css.js';

export const InputField = ({ inputName, type, placeholder, label, isRequired, readOnly, disabled, onChange, isNumber, isInteger, isMoney, defaultValue, className }) => {

  const classes = useStyles();
  const [t] = useTranslation('common');
  const [isFocus, setIsFocus] = useState(false);
  const { handleBlur } = useFormikContext();
  const labelT = t(label ?? '') === '' ? label : t(label);

  const getInputComponent = () => {
    if (onChange) {
      if (isNumber) {
        return (
          <NumericFormat
            defaultValue={defaultValue}
            className={clsx(classes.input, { [classes.readOnlyInput]: readOnly })}
            type={type}
            name={inputName}
            placeholder={t(placeholder)}
            readOnly={readOnly}
            disabled={disabled}
            onChange={onChange}
            customInput={Field}
            decimalScale={isInteger ? 0 : 2}
            thousandSeparator={true}
            prefix={isMoney ? '$' : undefined} />
        );
      } else {
        return (
          <Field
            className={clsx(classes.input, { [classes.readOnlyInput]: readOnly })}
            type={type}
            name={inputName}
            placeholder={t(placeholder)}
            readOnly={readOnly}
            disabled={disabled}
            onChange={onChange} />
        );
      }
    } else {
      return (
        <Field
          className={clsx(classes.input, { [classes.readOnly]: readOnly })}
          onFocus={() => !readOnly && setIsFocus(true)}
          onBlur={e => {
            handleBlur(e);
            setIsFocus(false);
          }}
          type={type}
          name={inputName}
          placeholder={t(placeholder)}
          readOnly={readOnly}
          disabled={disabled} />
      );
    }
  };

  return (
    <div className={className}>
      <Grid item className={classes.labelContainer}>
        <span className={classes.label}>{labelT}</span>
        {isRequired && <span className={classes.isRequired}>*</span>}
      </Grid>
      <div className={isFocus ? classes.focusContainer : classes.container}>
        <Grid container>
          <Grid item className={classes.inputContainer}>
            {getInputComponent()}
            <ErrorMessage className={classes.validation} name={inputName} component='div' />
          </Grid>
        </Grid>
      </div>
    </div>
  );
};

InputField.propTypes = {
  inputName: PropTypes.string,
  type: PropTypes.string,
  placeholder: PropTypes.string,
  label: PropTypes.string,
  isRequired: PropTypes.bool,
  readOnly: PropTypes.bool,
  disabled: PropTypes.bool,
  onChange: PropTypes.func,
  isNumber: PropTypes.bool,
  isInteger: PropTypes.bool,
  isMoney: PropTypes.bool,
  defaultValue: PropTypes.number,
  className: PropTypes.string,
};
