import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  cardContainer: {
    fontSize: '1rem',
    color: theme.secondaryTextColor,
    textAlign: 'left',
    padding: '1rem',
    width: '100%',
    boxShadow: 'none',
    '&:hover': {
      boxShadow: '0 2px 4px rgba(0,0,0,0.11), 0 2px 4px rgba(0,0,0,0.11)',
    }
  },
  browseBorder: {
    border: '1px solid' + theme.primaryTextColor
  },
  browseSelectedBorder: {
    border: '2px solid' + theme.primaryBackgroundColor,
    boxShadow: 'none'
  },
  titleContainer: {
    height: '2.5rem'
  },
  bottomText: {
    fontSize: '0.8rem'
  },
  noSpacing: {
    margin: '0',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    display: '-webkit-box',
    lineClamp: 2,
    boxOrient: 'vertical',
    wordWrap: 'break-word'
  },
  datePasus: {
    marginTop: '0.5rem',
    marginBottom: '0.5rem'
  },
  lightText: {
    color: theme.primaryTextColor,
    margin: '0'
  },
  spaceBetween: {
    width: '100%',
    height: '100%',
    direction: 'row',
    display: 'flex',
    columnGap: '2%',
    justifyContent: 'center',
    alignItems: 'center'
  },
  iconGrid: {
    width: '10%',
    marginRight: '1.5rem'
  },
  icon: {
    color: 'white',
    padding: '0',
  },
  wrapperIcon: {
    display: 'inline-block',
    backgroundColor: 'black',
    borderRadius: '50%',
    padding: '0.5rem',
    width: '1.5rem',
    height: '1.5rem',
    '&:hover': {
      backgroundColor: theme.primaryBackgroundColor
    }
  },
  '@media screen and (max-width: 1024px)': {
    cardContainer: {
      minHeight: '10rem',
    },
  },
}));
