import {
  completedIcon,
  usersIcon,
  emailIcon,
  messageAnsweredIcon,
  phoneIcon,
  yearIcon,
  leadsIcon,
  trimIcon,
  paymentTypeIcon,
  noteIcon,
  dollarIcon,
  draftIcon,
  conversationIcon,
  percentIcon,
  expiredIcon,
  callbackIcon
} from 'resources';

export const paidFeaturesType = {
  sendSms: 0,
  sendEmail: 1,
};

export const conversationMessageType = {
  sms: 1,
  mms: 2,
  email: 3,
};

export const conversationMessageSenderType = {
  user: 1,
  customer: 2
};

export const orderByEnum = {
  none: undefined,
  asc: 'asc',
  desc: 'desc'
};

export const roleType = {
  ADMIN: 1,
  SALE_REP: 2,
  DESIGNER: 3,
  SALE_MNG: 4,
};

export const optInSmsType = {
  Unknown: 0,
  Agreed: 1,
  Disagreed: 2,
  Sent: 3
};

export const rolesPerPage = [
  {
    value: 1,
    label: 5
  },
  {
    value: 2,
    label: 10
  },
  {
    value: 3,
    label: 25
  }
];

export const roles = [
  {
    value: 1,
    label: 'admin'
  },
  {
    value: 2,
    label: 'salesRepresentative'
  },
  {
    value: 3,
    label: 'designer'
  },
  {
    value: 4,
    label: 'salesManager'
  }
];

export const libraries = [
  {
    value: 2,
    label: 'private'
  },
  {
    value: 1,
    label: 'company'
  },
  {
    value: 0,
    label: 'general'
  },
];

export const companyStatuses = [
  {
    value: 1,
    label: 'Active'
  },
  {
    value: 0,
    label: 'Inactive'
  },
];

export const paymentTypes = [
  {
    value: 1,
    label: 'lease'
  },
  {
    value: 2,
    label: 'finance'
  },
  {
    value: 3,
    label: 'cash'
  }
];

export const communicationChannels = [
  {
    value: 0,
    label: 'Phone'
  },
  {
    value: 1,
    label: 'Email'
  }
];

export const leadStatus = [
  {
    value: 0,
    label: 'draft',
    icon: draftIcon,
    color: '#726F6F'
  },
  {
    value: 1,
    label: 'offerSent',
    icon: messageAnsweredIcon,
    color: '#A349A4'
  },
  {
    value: 2,
    label: 'offerOpened',
    icon: messageAnsweredIcon,
    color: '#1140E5'
  },
  {
    value: 8,
    label: 'callbackRequested',
    icon: callbackIcon,
    color: '#13A7FA'
  },
  {
    value: 4,
    label: 'offerAccepted',
    icon: messageAnsweredIcon,
    color: '#03BC03'
  },
  {
    value: 5,
    label: 'offerRejected',
    icon: messageAnsweredIcon,
    color: '#F90606'
  },
  {
    value: 6,
    label: 'offerCountered',
    icon: messageAnsweredIcon,
    color: '#FFCE07'
  },
  {
    value: 7,
    label: 'offerExpired',
    icon: expiredIcon,
    color: '#F90606'
  },
  {
    value: 3,
    label: 'completed',
    icon: completedIcon,
    color: '#726F6F'
  },
];

const generateArrayOfYears = () => {
  const max = new Date().getFullYear() + 1;
  const min = max - 121;
  const years = [];

  for (let i = max; i >= min; i--) {
    years.push({ value: i, label: i });
  }
  return years;
};

export const arrayOfYears = generateArrayOfYears();

export const arrayOfTerms = [
  {
    label: 24,
    value: 24
  },
  {
    label: 27,
    value: 27
  },
  {
    label: 36,
    value: 36
  },
  {
    label: 39,
    value: 39
  },
  {
    label: 42,
    value: 42
  },
  {
    label: 48,
    value: 48
  },
  {
    label: 54,
    value: 54
  },
  {
    label: 60,
    value: 60
  },
  {
    label: 72,
    value: 72
  },
  {
    label: 75,
    value: 75
  },
  {
    label: 84,
    value: 84
  },
  {
    label: 96,
    value: 96
  },
];

export const milesPerYearArray = [
  {
    label: '5,000',
    value: 5000
  },
  {
    label: '7,500',
    value: 7500
  },
  {
    label: '10,000',
    value: 10000
  },
  {
    label: '12,000',
    value: 12000
  },
  {
    label: '15,000',
    value: 15000
  },
  {
    label: '18,000',
    value: 18000
  },
  {
    label: '20,000',
    value: 20000
  },
];

export const viewLeadInfo = [
  {
    value: 1,
    type: 'string',
    label: 'name',
    icon: usersIcon
  },
  {
    value: 2,
    type: 'string',
    label: 'surname',
    icon: usersIcon
  },
  {
    value: 3,
    type: 'string',
    label: 'email',
    icon: emailIcon
  },
  {
    value: 4,
    type: 'string',
    label: 'phone',
    icon: phoneIcon
  },
  {
    value: 23,
    type: 'string',
    label: 'defaultChannel',
    icon: conversationIcon
  },
  {
    value: 24,
    type: 'string',
    label: 'optInSms',
    icon: conversationIcon
  },
  {
    value: 5,
    type: 'string',
    label: 'year',
    icon: yearIcon
  },
  {
    value: 6,
    type: 'string',
    label: 'make',
    icon: leadsIcon
  },
  {
    value: 7,
    type: 'string',
    label: 'model',
    icon: leadsIcon
  },
  {
    value: 8,
    type: 'string',
    label: 'trim',
    icon: trimIcon
  },
  {
    value: 9,
    type: 'string',
    label: 'paymentType',
    icon: paymentTypeIcon
  }
];

export const lease = [
  {
    value: 10,
    type: 'money',
    label: 'downPayment',
    icon: dollarIcon
  },
  {
    value: 11,
    type: 'money',
    label: 'monthlyPayment',
    icon: dollarIcon
  },
  {
    value: 12,
    type: 'number',
    label: 'term',
    icon: paymentTypeIcon
  },
  {
    value: 13,
    type: 'number',
    label: 'milesPerYear',
    icon: paymentTypeIcon
  }
];

export const finance = [
  {
    value: 10,
    type: 'money',
    label: 'downPayment',
    icon: dollarIcon
  },
  {
    value: 11,
    type: 'money',
    label: 'payment',
    icon: dollarIcon
  },
  {
    value: 12,
    type: 'number',
    label: 'term',
    icon: paymentTypeIcon
  },
  {
    value: 12,
    type: 'number',
    label: 'rate',
    icon: percentIcon
  },
  {
    value: 10,
    type: 'money',
    label: 'price',
    icon: dollarIcon
  }
];

export const cash = [
  {
    value: 10,
    type: 'money',
    label: 'price',
    icon: dollarIcon
  }
];

export const notes = [
  {
    value: 14,
    type: 'string',
    label: 'disclaimer',
    icon: noteIcon
  },
  {
    value: 15,
    type: 'string',
    label: 'internalNotes',
    icon: noteIcon
  }
];

export const tradeIn = [
  {
    value: 16,
    type: 'money',
    label: 'tradeInValue',
    icon: dollarIcon
  },
  {
    value: 17,
    type: 'string',
    label: 'tradeInMake',
    icon: leadsIcon
  },
  {
    value: 18,
    type: 'string',
    label: 'tradeInModel',
    icon: leadsIcon
  },
  {
    value: 19,
    type: 'string',
    label: 'tradeInYear',
    icon: yearIcon
  },
  {
    value: 20,
    type: 'number',
    label: 'tradeInMileage',
    icon: paymentTypeIcon
  },
  {
    value: 21,
    type: 'string',
    label: 'tradeInVin',
    icon: trimIcon
  },
  {
    value: 22,
    type: 'money',
    label: 'tradeInPayoff',
    icon: dollarIcon
  }
];

export const filterByDate = [
  {
    value: 0,
    label: 'dateCreated'
  },
  {
    value: 1,
    label: 'lastModified'
  }
];

export const allOrMine = [
  {
    value: 1,
    label: 'all'
  },
  {
    value: 2,
    label: 'mine'
  }
];

export const sendOfferVia = [
  {
    value: 1,
    label: 'Send SMS'
  },
  {
    value: 2,
    label: 'Send e-mail'
  }
];

export const offerExpipation = [
  {
    value: 0,
    label: 'Evergreen'
  },
  {
    value: 1,
    label: 'Expiring in (min)'
  }
];

export const arrayOfFonts = [
  {
    value: 'Arial',
    label: 'Arial',
    style: { fontFamily: 'Arial' }
  },
  {
    value: 'Book Antiqua',
    label: 'Book Antiqua',
    style: { fontFamily: 'Book Antiqua' }
  },
  {
    value: 'Broadway',
    label: 'Broadway',
    style: { fontFamily: 'Broadway' }
  },
  {
    value: 'Calibri',
    label: 'Calibri',
    style: { fontFamily: 'Calibri' }
  },
  {
    value: 'Comic Sans MS',
    label: 'Comic Sans MS',
    style: { fontFamily: 'Comic Sans MS' }
  },
  {
    value: 'Courier New',
    label: 'Courier New',
    style: { fontFamily: 'Courier New' }
  },
  {
    value: 'Helvetica',
    label: 'Helvetica',
    style: { fontFamily: 'Helvetica' }
  },
  {
    value: 'Impact',
    label: 'Impact',
    style: { fontFamily: 'Impact' }
  },
  {
    value: 'Mangueira',
    label: 'Mangueira',
    style: { fontFamily: 'Mangueira' }
  },
  {
    value: 'Segoe Print',
    label: 'Segoe Print',
    style: { fontFamily: 'Segoe Print' }
  },
  {
    value: 'Snap ITC',
    label: 'Snap ITC',
    style: { fontFamily: 'Snap ITC' }
  },
  {
    value: 'Stencil',
    label: 'Stencil',
    style: { fontFamily: 'Stencil' }
  },
  {
    value: 'Times New Roman',
    label: 'Times New Roman',
    style: { fontFamily: 'Times New Roman' }
  },
  {
    value: 'Verdana',
    label: 'Verdana',
    style: { fontFamily: 'Verdana' }
  },
];

export const arrayOfFontSizes = [
  {
    value: 8,
    label: 8
  },
  {
    value: 10,
    label: 10
  },
  {
    value: 12,
    label: 12
  },
  {
    value: 14,
    label: 14
  },
  {
    value: 16,
    label: 16
  },
  {
    value: 18,
    label: 18
  },
  {
    value: 20,
    label: 20
  },
  {
    value: 22,
    label: 22
  },
  {
    value: 24,
    label: 24
  },
  {
    value: 26,
    label: 26
  },
  {
    value: 28,
    label: 28
  },
  {
    value: 30,
    label: 30
  },
  {
    value: 32,
    label: 32
  },
  {
    value: 34,
    label: 34
  },
  {
    value: 36,
    label: 36
  },
  {
    value: 38,
    label: 38
  },
  {
    value: 40,
    label: 40
  }
];

export const arrayOfExpirationTimer = [
  {
    label: 10,
    value: 10
  },
  {
    label: 15,
    value: 15
  },
  {
    label: 20,
    value: 20
  },
  {
    label: 25,
    value: 25
  },
  {
    label: 30,
    value: 30
  },
  {
    label: 45,
    value: 45
  },
  {
    label: 60,
    value: 60
  },
  {
    label: 90,
    value: 90
  }
];