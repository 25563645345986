import React from 'react';
import Grid from '@material-ui/core/Grid';

import './Loader.css';

export const Loader = () => {

  return (
    <Grid item xs={12} sm={12} md={12} lg={12}>
      <div className='loader'>Loading...</div>
    </Grid>
  );
};