import React from 'react';
import { format } from 'date-fns';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { Tooltip } from '@material-ui/core';
import { dateFormatHours, conversationMessageType, conversationMessageSenderType } from 'helpers';

import { useStyles } from './ConversationMessage.css';

export const ConversationMessage = ({ message, customerName, userName, refNewFirstElement, onAcceptCounterOffer, onEditCounterOffer, showButton, disableAcceptButton, disableEditButton, tooltipAcceptTitle = '', tooltipEditTitle = '' }) => {
  const classes = useStyles();
  const createdAt = new Date(message.createdAt);

  let messageView;

  if (message.conversationMessageSender === conversationMessageSenderType.customer && message.conversationMessageType === conversationMessageType.sms) {
    messageView = (
      <div className={classes.container}>
        <p className={classes.buyerName}>{customerName}</p>
        <div className={classes.buyerContainer}>
          <p>{message.body}</p>
        </div>
        <p className={classes.date}>{format(createdAt, dateFormatHours)}</p>
      </div>
    );
  } else if (message.conversationMessageSender === conversationMessageSenderType.user && message.conversationMessageType === conversationMessageType.sms) {
    messageView = (
      <div className={classes.containerSalesRep}>
        <p className={classes.salesRepName}>{userName}</p>
        <div className={classes.salesRepContainer}>
          <p className={classes.salesRepBody}>{message.body}</p>
        </div>
        <p className={classes.dateSalesRep}>{format(createdAt, dateFormatHours)}</p>
      </div>
    );
  } else if (message.conversationMessageSender === conversationMessageSenderType.user && message.conversationMessageType === conversationMessageType.mms) {
    messageView = (
      <div className={classes.containerSalesRep}>
        <p className={classes.salesRepName}>{userName}</p>
        <div className={classes.gifContainerWrapper}>
          <img className={classes.gifContainer} src={message.mediaUrl} />
        </div>
        <p className={classes.dateSalesRep}>{format(createdAt, dateFormatHours)}</p>
      </div>
    );
  } else if (message.conversationMessageSender === conversationMessageSenderType.customer && message.conversationMessageType === conversationMessageType.mms) {
    messageView = (
      <div className={classes.container}>
        <p className={classes.buyerName}>{customerName}</p>
        <img className={classes.gifContainerBuyer} src={message.mediaUrl} />
        <p className={classes.date}>{format(createdAt, dateFormatHours)}</p>
      </div>
    );
  } else if (message.conversationMessageSender === conversationMessageSenderType.user && message.conversationMessageType === conversationMessageType.email) {
    messageView = (
      <div className={classes.containerSalesRep}>
        <p className={classes.salesRepName}>{userName}</p>
        {message.mediaUrl &&
          <div className={classes.gifContainerWrapper}>
            <img className={classes.gifContainer} src={message.mediaUrl} />
          </div>}
        {message.body &&
          <div className={classes.salesRepContainer}>
            <p className={classes.salesRepBody}>{message.body}</p>
          </div>}
        <p className={classes.dateSalesRep}>{format(createdAt, dateFormatHours)}</p>
      </div>
    );
  } else if (message.conversationMessageSender === conversationMessageSenderType.customer && message.conversationMessageType === conversationMessageType.email) {
    messageView = (
      <div className={classes.container}>
        <p className={classes.buyerName}>{customerName}</p>
        {message.mediaUrl &&
          <div className={classes.gifContainerWrapper}>
            <img className={classes.gifContainerBuyer} src={message.mediaUrl} />
            {showButton && onAcceptCounterOffer &&
              <Tooltip title={tooltipAcceptTitle} placement='bottom'>
                <span className={classes.buttonWrepper}>
                  <button type='button' className={clsx(classes.baseButton, classes.acceptButton, disableAcceptButton ? classes.disabledButton : '')}
                    disabled={disableAcceptButton}
                    onClick={() => onAcceptCounterOffer(message.refEntityId)}>
                    Accept Counter Offer
                  </button>
                </span>
              </Tooltip>
            }
            {onEditCounterOffer &&
              <Tooltip title={tooltipEditTitle} placement='bottom'>
                <span className={classes.buttonWrepper}>
                  <button type='button' className={clsx(classes.baseButton, classes.editButton, disableEditButton ? classes.disabledButton : '')}
                    disabled={disableEditButton}
                    onClick={() => onEditCounterOffer(message.refEntityId)}>
                    Edit Offer
                  </button>
                </span>
              </Tooltip>}
          </div>}
        {message.body &&
          <div className={classes.buyerContainer}>
            <p>{message.body}</p>
          </div>}
        <p className={classes.date}>{format(createdAt, dateFormatHours)}</p>
      </div>
    );
  }

  return (
    <div className={classes.wrapper} ref={refNewFirstElement}>
      {messageView}
    </div>
  );
};
ConversationMessage.propTypes = {
  message: PropTypes.object,
  customerName: PropTypes.string,
  userName: PropTypes.string,
  refNewFirstElement: PropTypes.object,
  onAcceptCounterOffer: PropTypes.func,
  onEditCounterOffer: PropTypes.func,
  showButton: PropTypes.bool,
  disableAcceptButton: PropTypes.bool,
  tooltipAcceptTitle: PropTypes.string,
  disableEditButton: PropTypes.bool,
  tooltipEditTitle: PropTypes.string
};