import { makeStyles } from '@material-ui/core/styles';
import { displayBorder } from 'helpers';

const drawerWidth = 346;

export const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    fontFamily: theme.fontLato,
    position: 'relative',
    height: '100%',
    [theme.breakpoints.down(displayBorder)]: {
      display: 'inline'
    },
  },
  menuButtonOpen: {
    color: theme.NavDrawerIconColor,
    backgroundColor: `${theme.navigationDrawerBackgroundColor} !important`,
    position: 'fixed',
    zIndex: 1000,
    bottom: 60,
    left: 336
  },
  menuButtonClose: {
    color: theme.NavDrawerIconColor,
    backgroundColor: `${theme.navigationDrawerBackgroundColor} !important`,
    position: 'fixed',
    zIndex: 1000,
    bottom: 60,
    left: 60
  },
  drawer: {
    width: drawerWidth,
    whiteSpace: 'nowrap',
    borderRightWidth: 0,
    position: 'relative',
  },
  drawerOpen: {
    backgroundColor: theme.navigationDrawerBackgroundColor,
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    borderRightWidth: 0,
  },
  drawerClose: {
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    width: 0
  },
  logoutContainer: {
    position: 'absolute',
    bottom: 50,
    left: 0,
    marginLeft: 'auto',
    marginRight: 'auto',
    right: 0,
    textAlign: 'center',
    [theme.breakpoints.down('sm')]: {
      bottom: 5,
    },
    [theme.breakpoints.between('sm', 'md')]: {
      bottom: 50,
    },
  },
  item: {
    opacity: '0.4',
    color: theme.tertiaryTextColor,
    fontSize: 18,
    letterSpacing: 0,
    lineHeight: 94.26,
    [theme.breakpoints.down('sm')]: {
      '&.MuiListItemIcon-root': {
        minWidth: '2rem'
      }
    },
    [theme.breakpoints.down('md')]: {
      color: theme.textColorOfUnselectedItemOnNavDrawerForMobile,
    },
  },
  itemActive: {
    opacity: '1',
    color: theme.tertiaryTextColor,
    fontWeight: 'bold',
    fontSize: 18,
    letterSpacing: 0,
    lineHeight: 94.26,
    [theme.breakpoints.down('sm')]: {
      '&.MuiListItemIcon-root': {
        minWidth: '2rem',
      }
    },
    [theme.breakpoints.down('md')]: {
      color: theme.textColorOfItemOnNavDrawerForMobile,
    },
  },
  borderRight: {
    [theme.breakpoints.up(displayBorder)]: {
      borderRight: `6px solid ${theme.primaryButtonColor}`,
    },
    [theme.breakpoints.down(displayBorder)]: {
      backgroundColor: theme.drawersBackgroundColor + '!important',
      border: '1px solid; border-radius: 15rem; height: 2rem',
      borderColor: theme.drawersBackgroundColor,
      fontWeight: 'bold',
    },
  },
  logOutText: {
    width: 51,
    opacity: '0.65',
    color: theme.tertiaryTextColor,
    fontSize: 16,
    letterSpacing: 0,
    lineHeight: 94.26,
    [theme.breakpoints.down('md')]: {
      color: theme.textColorOfUnselectedItemOnNavDrawerForMobile,
    },
  },
  listItem: {
    height: 36,
    marginBottom: 10,
    marginTop: 10,
    color: theme.tertiaryTextColor,
    [theme.breakpoints.down('md')]: {
      color: theme.textColorOfItemOnNavDrawerForMobile,
    },
  },
  listItemDrawerOpen: {
    marginLeft: 54,
    width: 292,
    [theme.breakpoints.down('sm')]: {
      width: '90%',
      height: '3rem',
      marginLeft: '0.5rem'
    },
  },
  logoutButton: {
    height: 36,
    width: 145,
    marginBottom: 10,
    marginTop: 10,
    [theme.breakpoints.down('sm')]: {
      width: '90%',
      marginLeft: '0.5rem',
      marginBottom: 0,
    },
  }
}));