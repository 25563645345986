import { toastNotification } from '../components';
import i18next from 'i18next';

export * from 'services/conversation.service';
export * from 'services/gifTemplates.service';
export * from 'services/lead.service';
export * from 'services/login.service';
export * from 'services/notification.service';
export * from 'services/offer.service';
export * from 'services/user.service';
export * from 'services/company.service';
export * from 'services/report.service';

export const baseApiErrorHandler = (err) => {
  toastNotification('error', i18next.t(`error:${Object.values(err.response.data.errors)[0]}`));
  if (err.response.status === 403) {
    if (window.location.pathname !== '/signin') {
      window.location.replace('/signin');
    }
  }
};