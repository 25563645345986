import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import { Grid, Table, TableContainer } from '@material-ui/core';
import withWidth, { isWidthUp } from '@material-ui/core/withWidth';

import { EnhancedTableBody } from './EnhancedTableBody';
import { EnhancedTableHead } from './EnhancedTableHead';
import { SadStates, Loader, Pagination } from 'components';
import { orderByEnum, displayBorder } from 'helpers';

import { useStyles } from './EnhancedTable.css';

const REFETCH_INTERVAL_MS = 60000;

const CustomTableComponent = ({ tableId, source, columnConfiguration, height, defaultRowsPerPage = 10, filter, width, setTotal }) => {

  const [rowsPerPage, setRowsPerPage] = useState(defaultRowsPerPage);
  const classes = useStyles();
  const [t] = useTranslation('common');
  const [order, setOrder] = useState(orderByEnum.asc);
  const [orderBy, setOrderBy] = useState('');
  const [page, setPage] = useState(1);
  const [prevFilter, setPrevFilter] = useState(filter);

  const paginationRef = useRef();

  const orderByParam = orderBy ? `${orderBy},${order}` : '';
  const { isLoading, data, refetch } = useQuery(['repoData' + tableId, { page, order, orderBy, filter, rowsPerPage }]
    , () => source((page - 1), rowsPerPage, orderByParam, filter)
    , { refetchInterval: REFETCH_INTERVAL_MS });

  useEffect(() => {
    refetch();
  }, [refetch]);

  useEffect(() => {
    for (const property in filter) {
      if (filter[property] !== prevFilter[property]) {
        setPrevFilter(filter);
        setPage(1);
        return;
      }
    }
  }, [filter, page]);

  const handleRequestSort = (e, property) => {
    const isAsc = orderBy === property && order === orderByEnum.asc;
    setOrder(isAsc ? orderByEnum.desc : orderByEnum.asc);
    setOrderBy(property);
  };

  const tableData = data ? data : { items: [], total: 0 };

  useEffect(() => {
    setTotal(tableData.total);
  }, [tableData]);

  return (
    <div className={classes.root}>
      {!isLoading && tableData?.total !== 0 &&
        <div>
          <TableContainer className={classes.container}>
            <SadStates
              states={[
                {
                  when: isLoading,
                  render: <Loader />
                }
              ]}>
              <Table classes={{ root: classes.rootTable }}>
                {isWidthUp(displayBorder, width) && <EnhancedTableHead
                  columnConfiguration={columnConfiguration}
                  onRequestSort={handleRequestSort}
                  order={order}
                  setRowsPerPage={(trHeight) => {
                    if (!height) {
                      return;
                    }
                    const pageinationHeight = paginationRef.current ? paginationRef.current.clientHeight : 0;
                    const rowsCount = Math.round((height - pageinationHeight) / trHeight);
                    setRowsPerPage(rowsCount > 0 ? rowsCount - 1 : 0);
                  }}
                  orderBy={orderBy} />
                }
                <EnhancedTableBody
                  columnConfiguration={columnConfiguration}
                  rows={tableData.items} />
              </Table>
            </SadStates>
          </TableContainer>
          <Pagination
            paginationRef={paginationRef}
            count={Math.ceil(tableData.total / rowsPerPage)}
            page={page}
            setPage={setPage}
            rowsPerPage={rowsPerPage}
            setRowsPerPage={setRowsPerPage} />
        </div>
      }
      {!isLoading && tableData?.total === 0 &&
        <Grid container>
          <Grid item className={classes.total}>
            <p className={classes.marginRight}>{t('noResultsFound')}</p>
          </Grid>
        </Grid>}
    </div>
  );
};

CustomTableComponent.propTypes = {
  columnConfiguration: PropTypes.array.isRequired,
  source: PropTypes.func.isRequired,
  height: PropTypes.number,
  tableId: PropTypes.string,
  defaultRowsPerPage: PropTypes.number,
  filter: PropTypes.object,
  width: PropTypes.any,
  setTotal: PropTypes.func
};

export const CustomTable = withWidth()(CustomTableComponent);
