import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(() => ({
  button: {
    cursor: 'pointer',
    padding: '0.5rem'
  },
  image: {
    width: '2.2rem',
    height: '2.2rem',
    color: 'orange'
  },
}));