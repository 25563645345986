import { makeStyles } from '@material-ui/core/styles';
import { displayBorder } from 'helpers';

export const useStyles = makeStyles((theme) => ({
  modal: {
    border: '1px solid rgba(100, 100, 100, 0.05)',
    minHeight: 'calc(100vh - 150px)',
    padding: '40px',
    backgroundClip: 'content-box',
    boxShadow: 'inset 0 0 0 40px' + theme.quinaryBackgroundColor,
    margin: '0 auto',
    maxWidth: '100%'
  },
  modalPhone: {
    padding: '1rem',
  },
  container: {
    width: '100%',
    height: '100%',
    paddingLeft: '0.5rem',
    paddingBottom: '0.5rem',
    position: 'relative',
    '&.MuiContainer-maxWidthLg': {
      maxWidth: '100%'
    }
  },
  backButtonContainer: {
    padding: '2rem'
  },
  containerContent: {
    paddingLeft: '10%'
  },
  form: {
    width: '100%',
    height: 'auto',
    paddingBottom: '3rem'
  },
  marginBottom: {
    marginBottom: '3rem'
  },
  spaceBetween: {
    direction: 'row',
    justifyContent: 'start'
  },
  spaceBetweenCol: {
    direction: 'col',
    justifyContent: 'start',
    width: '100%',
    height: 'auto',
    marginLeft: '0'
  },
  spaceBetweenRow: {
    direction: 'row',
    justifyContent: 'space-between',
    width: '100%',
    height: 'auto',
    margin: '0',
    marginLeft: '0.2rem'
  },
  rbUrl: {
    alignSelf: 'end'
  },
  titleContainer: {
    paddingLeft: '15%',
    width: '100%'
  },
  header: {
    width: '100%',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    marginTop: '0',
    fontSize: '1.5rem',
    letterSpacing: 0,
    [theme.breakpoints.down(displayBorder)]: {
      marginBottom: 0
    },
  },
  sectionTitle: {
    marginBottom: '2%'
  },
  dateContainer: {
    padding: '1rem',
    direction: 'row',
    justifyContent: 'center'
  },
  dateContainerPhone: {
    paddingLeft: '20%',
    direction: 'row',
    justifyContent: 'start'
  },
  containerContentForm: {
    paddingTop: '2rem',
    height: 'auto',
  },
  gifTemplateId: {
    margin: 0,
    color: theme.primaryTextColor
  },
  displayFlex: {
    display: 'flex'
  },
  displayNone: {
    display: 'none'
  },
  paddingTop: {
    paddingTop: '1.5rem !important'
  },
  paddingLeftTenPerc: {
    paddingLeft: '10%'
  },
  paddingLeft: {
    paddingLeft: '1rem'
  },
  marginNull: {
    margin: 0
  },
  autoWidth: {
    width: 'auto',
  },
  dateLabel: {
    paddingRight: '0.2rem',
    color: theme.primaryTextColor
  },
  gifPreview: {
    width: '100%',
    height: '100%',
    objectFit: 'contain',
    marginLeft: '2rem',
    backgroundColor: theme.denaryBackgroundColor,
    border: '1px solid #F5F5F5',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  gifPreviewCaption: {
    marginLeft: '6rem',
    marginTop: '1rem',
    color: theme.primaryTextColor,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  imageIcon: {
    color: '#E6E8F1',
    fontSize: '5rem',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  gifTemplateActions: {
    justifyContent: 'flex-end',
    marginTop: '3%',
    [theme.breakpoints.down(displayBorder)]: {
      justifyContent: 'space-between'
    },
  },
  uploadInstruction: {
    color: theme.primaryTextColor,
    marginTop: '0'
  },
  uploadGifContainer: {
    width: '100%',
    marginBottom: '0.8rem'
  },
  iconStyle: {
    height: '1.5rem',
    width: '1.5rem',
    color: theme.primaryBackgroundColor,
    paddingLeft: '0'
  },
  uncheckedIconStyle: {
    height: '1.5rem',
    width: '1.5rem',
    color: theme.primaryBorderColor,
    paddingLeft: 0
  },
  radioButtonContainer: {
    width: '100%'
  },
  marginTop: {
    marginTop: '0.5rem'
  },
  uploadContainer: {
    height: '8.5rem'
  },
  uploadContainerPhone: {
    height: '4.5rem'
  },
  noSpacing: {
    marginRight: '0 !important',
    padding: '0 !important'
  },
  urlField: {
    flexGrow: '100'
  },
  maxWidth: {
    width: '100%'
  },
  dataContainer: {
    width: '100%',
    marginTop: '0.5rem'
  },
  fileProperty: {
    margin: 0,
    fontWeight: 'bold',
    border: '0',
    width: 'auto',
    maxWidth: '100%',
    display: 'flex',
    justifyContent: 'left',
    backgroundColor: 'transparent'
  },
  xStyle: {
    width: 'auto',
    maxWidth: '100%',
    height: '1rem',
    stroke: theme.primaryTextColor,
    strokeWidth: '2',
  },
  gifmaker: {
    fontWeight: 500,
  },
  selectLibContainer: {
    alignItems: 'center',
    margin: '0',
    marginBottom: '0.5rem'
  },
  selectLibLabel: {
    color: theme.primaryTextColor
  },
  '@media screen and (max-width: 529px)': {
    paddingLeft: {
      paddingLeft: '10%'
    },
  },
  '@media screen and (max-width: 959px)': {
    titleContainer: {
      paddingLeft: '5%'
    },
    gifPreview: {
      width: '100%',
      aspectRatio: '1 / 1',
      height: '100%',
      marginLeft: '0',
      backgroundColor: theme.denaryBackgroundColor,
      border: '1px solid #F5F5F5',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center'
    },
    gifPreviewCaption: {
      marginLeft: '0',
      marginTop: '1rem',
      color: theme.primaryTextColor,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center'
    },
  },
  '@media screen and (min-width: 538px) and (max-width: 959px)': {
    spaceBetweenRow: {
      marginTop: '1rem'
    }
  },
  '@media screen and (min-width: 960px) and (max-width: 1024px)': {
    uploadContainer: {
      height: '4.5rem'
    },
  },
  '@media screen and (min-width: 1025px) and (max-width: 1544px)': {
    uploadContainer: {
      height: '7.5rem'
    },
  }
}));