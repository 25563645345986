import { makeStyles } from '@material-ui/core/styles';
import { displayBorder } from 'helpers';

export const useStyles = makeStyles((theme) => ({
  h: {
    color: theme.quaternaryTextColor,
    fontFamily: theme.fontLato,
    fontSize: '1.5rem',
    paddingBottom: '.25rem',
    [theme.breakpoints.up(displayBorder)]: {
      paddingBottom: '1.5rem',
    },
    display: 'inline-block',
    [theme.breakpoints.down(displayBorder)]: {
      marginRight: '0rem',
      marginLeft: '0.5rem'
    }
  },
  '@media screen and (max-width:320px)': {
    h: {
      fontSize: '1.4rem',
    }
  }
}));