import axios from 'axios';
import qs from 'qs';

import { getApiCoreUrl } from 'helpers';
import { baseApiErrorHandler } from 'services';

const baseUrl = `${getApiCoreUrl()}/api/v1/Lead`;

const getLeads = (page = 0, rowsPerPage = 10, orderBy = '', filter = {}) => {
  const leadsFilter = {};
  filter.array.map(f => {
    if (leadsFilter[f.id]) {
      leadsFilter[f.id].push(f.value);
    } else {
      leadsFilter[f.id] = [f.value];
    }
  });

  const url = `${baseUrl}?${qs.stringify({
    offset: page * rowsPerPage,
    limit: rowsPerPage,
    orderBy: orderBy,
    search: filter?.search || '',
    userId: filter?.userId || [],
    ...leadsFilter
  }, { arrayFormat: 'repeat' })}`;

  return axios.get(url).then(response => {
    return response.data;
  }).catch(baseApiErrorHandler);
};

const getLeadById = id => {
  const url = `${baseUrl}/${id}`;
  return axios.get(url).then(response => {
    return response.data;
  }).catch(baseApiErrorHandler);
};

const createLead = details => {
  return axios.post(baseUrl, details).catch(baseApiErrorHandler);
};

const updateLead = (user, id) => {
  const url = `${baseUrl}/${id}`;
  return axios.put(url, user).catch(baseApiErrorHandler);
};

const deleteLead = id => {
  const url = `${baseUrl}/${id}`;
  return axios.delete(url).catch(baseApiErrorHandler);
};

const getFilterParameters = (allOrMine) => {
  const url = `${baseUrl}/filterParameters?${qs.stringify({
    allOrMine: allOrMine
  })}`;
  return axios.get(url).then(response => {
    return response.data;
  }).catch(baseApiErrorHandler);
};

const getAutocompleteData = () => {
  const url = `${baseUrl}/autocomplete`;
  return axios.get(url).then(response => {
    return response.data;
  }).catch(baseApiErrorHandler);
};

const sendOptInSms = (leadId) => {
  return axios.post(`${baseUrl}/opt-in-sms/${leadId}`).catch(baseApiErrorHandler);
};

const sendOptInSelection = (tempCode, userSelection) => {
  return axios.put(`${baseUrl}/opt-in-sms?tempCode=${tempCode}&userSelection=${userSelection}`);
};

const getLeadInfo = (tempCode) => {
  return axios.get(`${baseUrl}/info/${tempCode}`);
};

export const leadService = {
  getLeads,
  getLeadById,
  createLead,
  updateLead,
  deleteLead,
  getFilterParameters,
  getAutocompleteData,
  sendOptInSms,
  sendOptInSelection,
  getLeadInfo
};