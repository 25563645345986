import axios from 'axios';
import { getApiCoreUrl } from 'helpers';
import qs from 'qs';
import { baseApiErrorHandler } from 'services';

const baseUrl = `${getApiCoreUrl()}/api/v1/Report`;

const getAll = (page = 0, rowsPerPage = 10, orderBy = '', filter = {}) => {
  const companyFilter = {};
  filter.array?.map(f => {
    if (companyFilter[f.id]) {
      companyFilter[f.id].push(f.value);
    } else {
      companyFilter[f.id] = [f.value];
    }
  });

  const url = `${baseUrl}?${qs.stringify({
    offset: page * rowsPerPage,
    limit: rowsPerPage,
    orderBy: orderBy,
    ...filter.dateFilter,
    ...companyFilter
  }, { arrayFormat: 'repeat' })}`;

  return axios.get(url).then(response => {
    return response.data;
  }).catch(baseApiErrorHandler);
};

export const reportService = {
  getAll,
};