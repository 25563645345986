import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(() => ({
  component: {
    width: 'auto',
    justifyContent: 'space-between',
    alignSelf: 'center',
    margin: '0',
  },
  item: {
    display: 'flex',
    flexDirection: 'row',
    alignSelf: 'center',
    alignItems: 'center',
    width: 'auto',
  },
  select: {
    width: '12rem',
  }
}));