import React, { useState, useEffect, useContext } from 'react';
import clsx from 'clsx';
import { Form, Formik, Field } from 'formik';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import { useHistory, useLocation } from 'react-router-dom';
import * as Yup from 'yup';
import Chip from '@material-ui/core/Chip';
import { Container, FormControl, FormControlLabel, Grid, Radio, RadioGroup, useMediaQuery, useTheme } from '@material-ui/core';
import { Image as ImageIcon, CloseOutlined } from '@material-ui/icons';
import RadioButtonCheckedIcon from '@material-ui/icons/RadioButtonChecked';
import RadioButtonUncheckedIcon from '@material-ui/icons/RadioButtonUnchecked';
import { UserContext } from 'providers';
import file_size_url from 'file_size_url';

import {
  Button, BackButton, DragAndDropInputField, FormSelectFilter,
  DeleteDialog, InputField, NoteSmallInputField, Loader, SadStates, toastNotification
} from 'components';
import { conversionBase, formatDateForTable, checkFileFormat, GIF, libraries, isAdmin, isDesigner } from 'helpers';
import { gifTemplateService, companyService } from 'services';

import { useStyles } from './GifTemplateForm.css.js';

export const GifTemplateForm = (props) => {
  const { search } = useLocation();
  const from = new URLSearchParams(search).get('from');
  const classes = useStyles();
  const { user } = useContext(UserContext);
  const { t } = useTranslation(['common', 'error']);
  const navHistory = useHistory();
  const [cancelUpdate, setCancelUpdate] = useState(false);
  const [gifTemplateId] = useState(from ? from : props.match.params.id);
  const [editMode] = useState(props.match.params.id !== undefined);
  const [isOpen, setDialogOpen] = useState(false);
  const [fileUrlSize, setFileUrlSize] = useState(0);
  const { isLoading, data = {} } = useQuery(['gifTemplateForm'], () => gifTemplateService.getGifTemplateById(gifTemplateId), { enabled: !!gifTemplateId, cacheTime: 0 });
  const { isLoading: isCompanyLoading, data: dataCompany = null } = useQuery(['companies'], () => companyService.getSelectItems(), { enabled: true, cacheTime: 10000 });
  const [previewUploadedFile, setPreviewUploadedFile] = useState(null);
  const [isSubmittingGlobal, setSubmittingGlobal] = useState(false);
  const [resultGlobal, setResultGlobal] = useState();
  const [library, setLibrary] = useState(2);

  const theme = useTheme();
  const isSmaller = useMediaQuery(theme.breakpoints.down('sm'));
  const isExtraSmaller = useMediaQuery(theme.breakpoints.down('xs'));
  const maxUrlSizeMB = 4;

  useEffect(() => {
    !!data?.url && setPreviewUploadedFile(data.url);
    if (data?.library !== undefined && (isAdmin(user.role) || isDesigner(user.role))) {
      setLibrary(data.library);
    }
  }, [data]);

  const handleSubmit = async (values, { setSubmitting }) => {
    setSubmittingGlobal(true);
    if (cancelUpdate) {
      return;
    }

    const formDetails = new FormData();

    const details = editMode ? { ...data, ...values } : { ...values };
    Object.keys(details).forEach(key => formDetails.append(key, details[key]));

    if (values.rbIsUploadImage) {
      delete values.fileUrl;
      if (values.uploadedFile !== null) {
        formDetails.append('gifFile', values.uploadedFile);
      }
    } else {
      delete values.uploadedFile;
    }

    try {
      if (editMode) {
        try {
          await gifTemplateService.updateGifTemplate(formDetails, gifTemplateId);
          toastNotification('info', t('gifTemplpateIsUpdated'));
          navHistory.goBack();
        } catch (error) {
          toastNotification('error', t(`error:${Object.values(error.response.data.errors)[0]}`));
        }
      } else {
        try {
          await gifTemplateService.createGifTemplate(formDetails);
          toastNotification('info', t('newGifTemplate'));
          navHistory.goBack();
        } catch (error) {
          toastNotification('error', t(`error:${Object.values(error.response.data.errors)[0]}`));
        }
      }
      setSubmitting(false);
    } catch (error) {
      toastNotification('error', t(`error:${Object.values(error.response.data.errors)[0]}`));
    }

    resetValuesField(values);
    setSubmittingGlobal(false);
  };

  const resetValuesField = (values) => {
    values.uploadedFile = {};
    values.url = '';
  };

  const imageWidthAndHeight = (file) => {
    return createImageBitmap(file)
      .then(bitmap => {
        return bitmap.width / bitmap.height;
      });
  };

  const getSizeMB = (size) => {
    let sizeMB = -1;
    const sizeArr = size.toString().split(' ');
    if (sizeArr.length === 2) {
      switch (sizeArr[1]) {
        case 'B': sizeMB = +(sizeArr[0]) / conversionBase / conversionBase;
          break;
        case 'KB': sizeMB = +(sizeArr[0]) / conversionBase;
          break;
        case 'MB': sizeMB = +(sizeArr[0]);
          break;
        case 'GB': sizeMB = +(sizeArr[0]) * conversionBase;
          break;
      }
    }
    return sizeMB;
  };

  const validationSchema = Yup.object().shape({
    rbIsUploadImage: Yup.boolean(),
    uploadedFile: Yup.mixed()
      .test('test_is_uploaded_file_required_or_nullable', null, (value, schema) => {
        if (schema.parent.rbIsUploadImage && !editMode && !value) {
          return new Yup.ValidationError(t('required'), null, 'uploadedFile');
        }
        return true;
      })
      .test('test_is_uploaded_file_gif_file', null, value => {
        if (value && ((value.name && !checkFileFormat(value.name, [GIF])) || (!value.name && !checkFileFormat(value, [GIF])))) {
          return new Yup.ValidationError(t('mustBeAGifFile'), null, 'uploadedFile');
        }
        return true;
      })
      .test('is_correct_file_size', null, value => {
        if (value && value?.size / conversionBase / conversionBase > maxUrlSizeMB) {
          return new Yup.ValidationError(t('fileTooLarge'), null, 'uploadedFile');
        }
        return true;
      })
      .test('test_is_uploaded_file_aspect_ratio', null, value => {
        if (value && value.name) {
          imageWidthAndHeight(value).then(result => {
            if (result) {
              setResultGlobal(result);
            }
          });
        }

        if (resultGlobal && (resultGlobal < 0.5 || resultGlobal > 1.5)) {
          return new Yup.ValidationError(t('unsupportedAspectRatio'), null, 'uploadedFile');
        }
        return true;
      }),
    fileUrl: Yup.string()
      .url(t('invalidUrl'))
      .test('test_is_url_file_required_or_nullable', null, (value, schema) => {
        if (!schema.parent.rbIsUploadImage && !editMode && !value) {
          return new Yup.ValidationError(t('required'), null, 'fileUrl');
        }
        return true;
      })
      .test('test_is_url_file_gif_file', null, (value, schema) => {
        if (!schema.parent.rbIsUploadImage && value && !checkFileFormat(value, [GIF])) {
          return new Yup.ValidationError(t('urlMustBeAGifFile'), null, 'fileUrl');
        }
        return true;
      })
      .test('is_correct_file_size', null, value => {
        if (value) {
          if (fileUrlSize < 0) {
            return new Yup.ValidationError(t('invalidUrl'), null, 'fileUrl');
          }
          if (fileUrlSize > maxUrlSizeMB) {
            return new Yup.ValidationError(t('fileTooLarge'), null, 'fileUrl');
          }
          return true;
        } else {
          return true;
        }
      }),
    title: Yup.string()
      .required(t('required'))
      .max(100, t('titleValidation')),
    description: Yup.string()
      .nullable()
      .max(1000, t('descriptonValidation'))
  });

  const handleCancel = () => {
    setCancelUpdate(true);
    toastNotification('info', t('canceled'));
    navHistory.goBack();
  };

  const handleDelete = async () => {
    setCancelUpdate(true);
    try {
      await gifTemplateService.deleteGifTemplate(gifTemplateId);
      toastNotification('info', t('gifTemplateIsDeleted'));
      navHistory.goBack();
    } catch (error) {
      toastNotification('error', t(`error:${Object.values(error.response.data.errors)[0]}`));
    }
  };

  const deleteGifTemplateDialog = () => {
    setDialogOpen(true);
  };

  const SelectLibrary = (prop) => {
    return (
      <FormControl component='fieldset'>
        <RadioGroup aria-label='position' row {...prop}>
          {libraries.map((x, i) => {
            return (
              <FormControlLabel
                key={i}
                value={x.value}
                disabled={x.value === 0 && !isAdmin(user.role) && !isDesigner(user.role)}
                control={
                  <Radio
                    checkedIcon={<RadioButtonCheckedIcon className={classes.iconStyle} />}
                    icon={<RadioButtonUncheckedIcon className={classes.uncheckedIconStyle} />} />
                }
                label={t(x.label)} />
            );
          })}
        </RadioGroup>
      </FormControl>
    );
  };

  return (
    <div className={isSmaller ? classes.modalPhone : classes.modal}>
      {(!isLoading && !isSubmittingGlobal) &&
        <Grid className={classes.backButtonContainer}>
          <BackButton callback={() => navHistory.push('/gifTemplates')} />
        </Grid>}
      <Container className={classes.container}>
        <DeleteDialog isOpen={isOpen} setDialogOpen={setDialogOpen} callback={handleDelete} />
        <SadStates
          states={[
            {
              when: isLoading || isSubmittingGlobal || isCompanyLoading,
              render: <Loader />
            }
          ]}>
          <Formik
            initialValues={editMode ? {
              rbIsUploadImage: true,
              uploadedFile: data.url,
              fileUrl: '',
              title: data.title,
              description: data.description,
              library: data.library,
              companyId: data.companyId ? data.companyId : dataCompany?.items[0].value
            } : {
              rbIsUploadImage: !data?.url,
              uploadedFile: '',
              fileUrl: data?.url ? data.url : '',
              title: data?.title ? data.title : '',
              description: data?.description ? data.description : '',
              library: library,
              companyId: data?.companyId ? data.companyId : dataCompany?.items[0].value
            }
            }
            validationSchema={validationSchema}
            onSubmit={handleSubmit}>
            {({ values, dirty, isSubmitting, setFieldValue, touched, setTouched, setFieldError }) => (
              <div>
                <Form className={classes.form}>
                  <Grid container className={classes.spaceBetween} spacing={5}>
                    <Grid item xs={12} sm={12} md={6} lg={6}>
                      <Grid item xs={12} sm={12} md={12} lg={12} container className={classes.titleContainer}>
                        <Grid item xs={12} sm={12} md={12} lg={12}>
                          <h1 className={classes.header}>{editMode ? `${data.title}` : t('createNewGifTemplate')}</h1>
                        </Grid>
                      </Grid>
                    </Grid>
                    {editMode &&
                      <Grid container item md={6} className={(isSmaller || isExtraSmaller) ? classes.dateContainerPhone : classes.dateContainer}>
                        <Grid item className={clsx(classes.displayFlex, (isSmaller || isExtraSmaller) ? classes.paddingLeftTenPerc : classes.paddingLeft)}>
                          <p className={clsx(classes.dateLabel, classes.marginNull)}>{t('dateCreated')}:</p>
                          <p className={classes.marginNull}>{formatDateForTable(data.createdAt)}</p>
                        </Grid>
                        <Grid item className={clsx(classes.displayFlex, classes.paddingLeft)}>
                          <p className={clsx(classes.dateLabel, classes.marginNull)}>{t('lastModified')}:</p>
                          <p className={classes.marginNull}>{formatDateForTable(data.modifiedAt)}</p>
                        </Grid>
                      </Grid>
                    }
                  </Grid>
                  <Grid item xs={12} sm={10} md={10} lg={10} className={classes.containerContent}>
                    <Grid container className={classes.spaceBetween} spacing={10}>
                      <Grid item xs={12} sm={12} md={6} lg={6} className={(isSmaller || isExtraSmaller) ? classes.displayNone : classes.displayFlex}>
                        <Grid item xs={12} sm={12} md={12} lg={12}>
                          {previewUploadedFile &&
                            <img src={previewUploadedFile} alt={''} className={classes.gifPreview} />
                          }
                          {!previewUploadedFile &&
                            <Grid container className={classes.gifPreview} alignItems='center' justifyContent='center'>
                              <ImageIcon className={classes.imageIcon}></ImageIcon>
                            </Grid>
                          }
                          <Grid className={classes.gifPreviewCaption}> {t('gifPreview')}</Grid>
                        </Grid>
                      </Grid>
                      <Grid item xs={12} sm={12} md={6} lg={6} className={classes.containerContentForm}>
                        <Grid>
                          <p className={classes.uploadInstruction}>{t('pleaseUploadGifFile')}</p>
                          <p className={classes.gifmaker}>Use <a href='https://giphy.com/create/gifmaker' target='_blank' rel='noreferrer'>GIPHY</a> to create a custom GIF with special effects.</p>
                        </Grid>
                        <Grid container className={classes.uploadGifContainer}>
                          <Grid container item className={classes.radioButtonContainer}>
                            <FormControl component='fieldset'>
                              <RadioGroup aria-label='position' value={values.rbIsUploadImage} onChange={(e) => {
                                setFieldValue('rbIsUploadImage', e.target.value === 'true');
                                if (editMode) {
                                  setPreviewUploadedFile(data.url);
                                } else {
                                  setPreviewUploadedFile(null);
                                }
                                setFieldValue('uploadedFile', '');
                                setFieldValue('fileUrl', '');
                                setTouched({ ...touched, 'uploadedFile': false, 'fileUrl': false });
                              }}>
                                <Grid container className={classes.spaceBetweenCol}>
                                  <FormControlLabel
                                    key={1}
                                    value={true}
                                    control={
                                      <Radio
                                        checkedIcon={<RadioButtonCheckedIcon className={classes.iconStyle} />}
                                        icon={<RadioButtonUncheckedIcon className={classes.uncheckedIconStyle} />} />
                                    }
                                    label={t('uploadGIF')} />
                                  <Grid container className={classes.radioButtonContainer}>
                                    <Grid className={clsx(classes.radioButtonContainer, (isSmaller || isExtraSmaller) ? classes.uploadContainerPhone : classes.uploadContainer)}>
                                      <DragAndDropInputField
                                        inputName='uploadedFile'
                                        type='file'
                                        accept='.gif'
                                        placeholder={t('dragAndDrop')}
                                        readOnly={!values.rbIsUploadImage}
                                        disabled={!values.rbIsUploadImage}
                                        toUploadOnPhone={false}
                                        onChange={(e) => {
                                          if (e.target.files[0]) {
                                            const fileCopy = new File([e.target.files[0]], e.target.files[0].name, { 'type': e.target.files[0].type });
                                            setPreviewUploadedFile(URL.createObjectURL(fileCopy));
                                            setFieldValue('uploadedFile', fileCopy);
                                            e.target.value = null;
                                          }
                                        }} />
                                      <Grid item xs={3} sm={3} md={3} lg={3}>
                                        <DragAndDropInputField
                                          inputName='uploadedFile'
                                          type='file'
                                          accept='.gif'
                                          placeholder={t('browse')}
                                          readOnly={!values.rbIsUploadImage}
                                          disabled={!values.rbIsUploadImage}
                                          toUploadOnPhone={true}
                                          onChange={(e) => {
                                            if (e.target.files[0]) {
                                              const fileCopy = new File([e.target.files[0]], e.target.files[0].name);
                                              setPreviewUploadedFile(URL.createObjectURL(fileCopy));
                                              setFieldValue('uploadedFile', fileCopy);
                                              e.target.value = null;
                                            }
                                          }} />
                                      </Grid>
                                      <Grid item xs={12} sm={12} md={12} lg={12} className={classes.dataContainer}>
                                        <Grid className={classes.autoWidth}>
                                          {previewUploadedFile && values.rbIsUploadImage && data.url !== previewUploadedFile &&
                                            <Chip className={classes.fileProperty}
                                              label={values.uploadedFile.name + '(' + (values.uploadedFile.size / conversionBase).toFixed(2) + ' ' + 'kb)'}
                                              deleteIcon={<CloseOutlined className={classes.xStyle} />}
                                              onDelete={() => {
                                                if (editMode) {
                                                  setPreviewUploadedFile(data.url);
                                                  values.uploadedFile = '';
                                                } else {
                                                  setPreviewUploadedFile(null);
                                                }
                                                setTouched({ ...touched, 'uploadedFile': false });
                                                setFieldError('uploadedFile', null);
                                              }}
                                              variant='outlined' />
                                          }
                                        </Grid>
                                      </Grid>
                                    </Grid>
                                  </Grid>
                                  <Grid container spacing={2} className={classes.spaceBetweenRow}>
                                    <Grid className={classes.marginTop}>
                                      <FormControlLabel
                                        key={0}
                                        value={false}
                                        control={
                                          <Radio
                                            checkedIcon={<RadioButtonCheckedIcon className={classes.iconStyle} />}
                                            icon={<RadioButtonUncheckedIcon className={classes.uncheckedIconStyle} />} />
                                        }
                                        label={t('orPasteGIFURL')} />
                                    </Grid>
                                    <Grid className={classes.urlField}>
                                      <InputField
                                        inputName='fileUrl'
                                        type='text'
                                        readOnly={values.rbIsUploadImage}
                                        disabled={values.rbIsUploadImage}
                                        onChange={(e) => {
                                          const valueUrl = e.target.value;
                                          valueUrl === '' && editMode ? setPreviewUploadedFile(data.url) : setPreviewUploadedFile(e.target.value);
                                          if (valueUrl && valueUrl !== '') {
                                            const valueUrlArr = valueUrl.split('.');
                                            if (valueUrlArr && valueUrlArr.length > 1 && valueUrlArr[valueUrlArr.length - 1] === 'gif') {
                                              file_size_url(valueUrl).then((size) => {
                                                setFileUrlSize(getSizeMB(size));
                                              }).catch(() => {
                                                setFileUrlSize(-1);
                                              });
                                            } else {
                                              setFileUrlSize(-1);
                                            }
                                          } else {
                                            setFileUrlSize(0);
                                          }
                                          setFieldValue('fileUrl', valueUrl);
                                        }} />
                                    </Grid>
                                  </Grid>
                                  <Grid item className={classes.dataContainer}>
                                    <Grid item className={classes.maxWidth}>
                                      <InputField label='templateName' type='text' inputName='title' />
                                    </Grid>
                                  </Grid>
                                  <Grid item className={classes.dataContainer}>
                                    <Grid className={classes.maxWidth}>
                                      <NoteSmallInputField label='description' inputName='description' type='text' />
                                    </Grid>
                                  </Grid>
                                  <Grid container className={classes.selectLibContainer}>
                                    <Grid item xs={12} sm={12} md={12} lg={3} className={classes.selectLibLabel}>
                                      {t('selectLibrary')}
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={12} lg={9}>
                                      <Field
                                        as={SelectLibrary}
                                        value={library}
                                        onChange={(e) => {
                                          const num = Number(e.target.value);
                                          setLibrary(num);
                                          setFieldValue('library', num);
                                        }}
                                        name='library' />
                                    </Grid>
                                  </Grid>
                                  {isAdmin(user.role) && library === 1 &&
                                    <Grid item className={classes.dataContainer}>
                                      <Grid item className={classes.maxWidth}>
                                        <FormSelectFilter
                                          inputName='companyId'
                                          label={t('companyId')}
                                          onChange={(e) => {
                                            setFieldValue('companyId', Number(e.target.value));
                                          }}
                                          value={values.companyId}
                                          menuItem={dataCompany?.items ?? []} />
                                      </Grid>
                                      <Grid item xs={12} sm={6} md={3} lg={3}></Grid>
                                    </Grid>}
                                </Grid>
                              </RadioGroup>
                            </FormControl>
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid item xs={11} sm={12} md={6} lg={5} className={clsx(classes.marginBottom, (isSmaller || isExtraSmaller) ? classes.displayFlex : classes.displayNone)}>
                        <Grid item xs={12} sm={12} md={12} lg={12}>
                          {previewUploadedFile &&
                            <img src={previewUploadedFile} alt={''} className={classes.gifPreview} />
                          }
                          {!previewUploadedFile &&
                            <Grid container className={classes.gifPreview} alignItems='center' justifyContent='center'>
                              <ImageIcon className={classes.imageIcon}></ImageIcon>
                            </Grid>
                          }
                          <Grid className={classes.gifPreviewCaption}> {t('gifPreview')}</Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid container spacing={2} className={classes.gifTemplateActions}>
                    {editMode &&
                      <Grid item xs={4} sm={2} md={2} lg={2}>
                        <Button type='reset' text={t('delete')} color='primary' callback={deleteGifTemplateDialog} smallButton={true} />
                      </Grid>}
                    {!isExtraSmaller && <Grid item sm={editMode ? 6 : 8} md={editMode ? 6 : 8} lg={editMode ? 6 : 8} />}
                    <Grid item xs={4} sm={2} md={2} lg={2}>
                      <Button type='reset' text='cancel' color='primary' callback={() => handleCancel()} smallButton={true} />
                    </Grid>
                    <Grid item xs={4} sm={2} md={2} lg={2}>
                      <Button text='save' color='defaultColor' disabled={isSubmitting || (!dirty && !gifTemplateId)} type='submit' smallButton={true} />
                    </Grid>
                  </Grid>
                </Form>
              </div>
            )}
          </Formik>
        </SadStates>
      </Container>
    </div>
  );
};

GifTemplateForm.propTypes = {
  match: PropTypes.object
};