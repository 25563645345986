import { makeStyles } from '@material-ui/core/styles';
import { displayBorder } from 'helpers';

export const useStyles = makeStyles((theme) => ({
  container: {
    position: 'absolute',
    width: '25%',
    height: '100%',
    backgroundColor: theme.drawersBackgroundColor,
    padding: 20,
    paddingTop: 15,
    alignItems: 'center',
    [theme.breakpoints.down(displayBorder)]: {
      width: '100%',
      minHeight: '100vh',
      padding: 0,
      position: 'relative',
      zIndex: 0
    },
  },
  logo: {
    marginRight: '1rem',
  },
  zeroNotificationsLogo: {
    alignSelf: 'center',
    whiteSpace: 'pre-wrap',
    textAlign: 'center',
    paddingBottom: '1.5rem'
  },
  zeroNotifications: {
    alignSelf: 'center',
    whiteSpace: 'pre-wrap',
    textAlign: 'center'
  },
  headerText: {
    display: 'flex',
    flexDirection: 'row',
  },
  header: {
    justifyContent: 'space-between',
    marginBottom: '1rem'
  },
  notificationsText: {
    margin: 0,
    fontWeight: 'bold',
    fontSize: '1.125rem'
  },
  notificationsNumb: {
    margin: 0,
    fontWeight: 'bold',
    fontSize: '1.125rem',
    color: theme.primaryBackgroundColor,
    marginLeft: '0.5rem'
  },
  closeIcon: {
    color: theme.primaryTextColor,
    cursor: 'pointer'
  },
  message: {
    margin: '0.5rem',
    fontSize: '1rem',
    color: theme.primaryTextColor
  },
  zeroNotificationsContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignContent: 'center',
    paddingTop: '50%'
  },
  allNotificationsText: {
    color: theme.primaryTextColor,
    fontSize: '0.875rem',
    paddingTop: '1rem',
    marginLeft: '2rem'
  },
  clearAllNotificationsText: {
    color: theme.primaryBackgroundColor,
    fontSize: '0.875rem',
    fontWeight: 'bold',
    paddingBottom: '1rem',
    cursor: 'pointer'
  },
  footer: {
    textAlign: '-webkit-center',
    position: 'relative',
    paddingTop: '1rem'
  },
  '@media screen and (max-width:1288px)': {
    container: {
      position: 'absolute',
      width: '20rem',
      padding: '1rem'
    }
  },
  '@media screen and (max-width:400px)': {
    container: {
      width: '100%',
    },
    zeroNotificationsContainer: {
      paddingRight: '0rem',
      marginLeft: '2rem'
    },
    headerText: {
      marginLeft: '2rem'
    },
    footer: {marginLeft: '2rem'
    },
    allNotificationsText: {
      marginLeft: '0'
    }
  }
}));