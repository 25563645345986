import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  cardContainer: {
    fontSize: '1rem',
    color: theme.secondaryTextColor,
    width: '100%',
    height: '100%',
  },
  cardGrid: {
    minWidth: '17%',
    maxWidth: '18%',
    minHeight: '20rem',
    maxHeight: '25rem'
  },
  spaceBetween: {
    position: 'relative',
    width: '100%',
    height: '100%',
    direction: 'row',
    columnGap: '1.6rem',
    rowGap: '2rem',
    justifyContent: 'start',
  },
  '@media screen and (min-width: 1500px)': {
    spaceBetween: {
      rowGap: '1'
    },
    cardGrid: {
      minWidth: '17%',
      maxWidth: '18%',
      maxHeight: '100%',
    },
  },
  '@media screen and (max-width: 425px)': {
    spaceBetween: {
      justifyContent: 'center'
    },
    cardGrid: {
      minWidth: '80%',
      maxWidth: '82%'
    },
  },
  '@media screen and (min-width: 375px) and (max-width: 426px)': {
    spaceBetween: {
      justifyContent: 'center'
    },
    cardGrid: {
      minWidth: '80%',
      maxWidth: '82%',
      maxHeight: '29rem'
    },
  },
  '@media only screen and (max-width: 770px) and (min-width: 426px)': {
    cardGrid: {
      minWidth: '38%',
      maxWidth: '40%',
    },
    spaceBetween: {
      columnGap: '1rem'
    },
  },
  '@media only screen and (max-width: 1025px) and (min-width: 771px)': {
    cardGrid: {
      minWidth: '30%',
      maxWidth: '32%',
    },
  },
}));
