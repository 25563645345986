import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  container: {
    borderBottom: '1px solid ' + theme.borderColor,
    borderColor: theme.borderColor,
    width: '100%',
    backgroundColor: theme.octonaryBackgroundColor,
    padding: '0.3rem',
    borderRadius: '15rem',
    height: '3rem',
  },
  icon: {
    paddingTop: '0.8rem',
    paddingLeft: '1rem'
  },
  input: {
    marginLeft: '0.2rem',
    paddingLeft: '0.5rem',
    border: '0',
    outline: 'none',
    height: '2rem',
    position: 'relative',
    backgroundColor: 'transparent',
    '&:-webkit-autofill': {
      WebkitBoxShadow: '0 0 0 1000px #F2F4F7 inset',
      fontSize: '1rem'
    },
    '&::-webkit-input-placeholder': {
      color: theme.primaryTextColor
    },
    fontSize: '1rem',
    width: '100%',
    marginTop: '0.5rem'
  },
  closeIcon: {
    fontSize: '1.5rem',
    color: theme.primaryTextColor
  },
  searchContainer: {
    width: 'calc(100% - 100px)'
  }
}));