import React from 'react';
import { infoIcon, errorIcon, warningIcon } from 'resources';
import { toast } from 'react-toastify';

export const toastNotification = (type, text) => {

  switch (type) {
    case 'info':
      toast.info(<div className='toastContainer'><img src={infoIcon}/><p>{text}</p></div>);
      break;
    case 'warning':
      toast.warning(<div className='toastContainer'><img src={warningIcon}/><p>{text}</p></div>);
      break;

    case 'error':
      toast.error(<div className='toastContainer'><img src={errorIcon}/><p>{text}</p></div>);
      break;
  }
};