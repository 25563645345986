import axios from 'axios';
import { getApiCoreUrl } from 'helpers';
import { baseApiErrorHandler } from 'services';

const baseUrl = `${getApiCoreUrl()}/api/v1/Auth`;

const login = () => {
  return axios.get(baseUrl).catch(baseApiErrorHandler);
};

const setupAccount = (userData) => {
  return axios.post(baseUrl, userData).then(res => {
    return res.data;
  }).catch(baseApiErrorHandler);
};

const resetPassword = (value) => {
  const url = `${baseUrl}/reset_password`;
  const obj = { email: value };
  return axios.post(url, obj).catch(baseApiErrorHandler);
};

export const loginService = {
  login,
  setupAccount,
  resetPassword
};