import { makeStyles } from '@material-ui/core/styles';
import { displayBorder } from 'helpers';

export const useStyles = makeStyles((theme) => ({
  container: {
    color: theme.quaternaryTextColor,
    height: '100%',
    position: 'relative',
    paddingLeft: '0.25rem',
    paddingRight: '0.25rem',
    paddingBottom: '3.5rem',
    overflowY: 'auto',
    [theme.breakpoints.up(displayBorder)]: {
      paddingLeft: '6rem',
      paddingRight: '6rem'
    }
  },
  tableCell: {
    fontSize: '0.8rem'
  },
  paddingRight: {
    display: 'flex',
    paddingLeft: '0.5rem'
  },
  spaceBetween: {
    direction: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: '1rem'
  },
  paddingTop: {
    paddingTop: '1.5rem !important',
    marginRight: '0.5rem'
  },
  customTooltip: {
    backgroundColor: theme.tertiaryBackgroundColor,
    padding: '0.5rem'
  },
  img: {
    height: '0.8rem',
    width: '0.8rem',
    margin: '6px 0 0 0.5px'
  },
  imgContainer: {
    height: '1.5rem',
    width: '1.5rem',
    borderRadius: '50%',
    display: 'flex',
    justifyContent: 'center',
    marginRight: '1rem'
  },
  switchButton: {
    alignSelf: 'center',
    justifyContent: 'space-between',
    paddingRight: '0.5rem',
    paddingTop: '0.5rem',
  },
  leadName: {
    margin: 0,
    cursor: 'pointer',
    fontWeight: 'bold',
    width: '45rem',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'normal',
    '-webkit-line-clamp': 2,
    '-webkit-box-orient': 'vertical',
    display: '-webkit-box'
  },
  total: {
    color: theme.primaryTextColor,
    display: 'flex',
    alignItems: 'baseline',
    flexWrap: 'wrap'
  },
  marginRight: {
    marginRight: '0.3rem',
    fontSize: 'small'
  },
  filterIcon: {
    width: '1.3rem',
    height: '1.3rem',
    marginRight: '0.5rem'
  },
  alignItems: {
    alignItems: 'center'
  },
  filterText: {
    margin: 0
  },
  selectFilter: {
    paddingLeft: '0.5rem',
    paddingRight: '0.5rem',
    width: '14rem'
  },
  root: {
    backgroundColor: theme.quinaryBackgroundColor,
    border: '2px solid' + theme.primaryBackgroundColor,
    alignItems: 'center',
    '&:hover': {
      backgroundColor: theme.quinaryBackgroundColor
    },
    marginBottom: '0.5rem',
    maxWidth: '20rem',
    width: 'auto',
  },
  deleteIcon: {
    color: theme.primaryTextColor,
    width: '1rem',
    height: '1rem'
  },
  chipContainer: {
    marginLeft: '1rem',
    marginTop: '1rem',
    alignItems: 'center',
    display: 'block',
    width: 'auto',
    whiteSpace: 'nowrap',
    overflow: 'hidden !important',
    textOverflow: 'ellipsis'
  },
  smallSearchContainer: {
    width: '80%',
    margin: '5% auto',
    marginRight: '11%'
  },
  '@media screen and (max-width: 380px)': {
    leadName: {
      margin: 0,
      cursor: 'pointer',
      fontWeight: 'bold',
      textOverflow: 'ellipsis',
      overflow: 'hidden',
      width: '8rem',
      whiteSpace: 'normal',
      '-webkit-line-clamp': 2,
      '-webkit-box-orient': 'vertical',
      display: '-webkit-box'
    },
  },
  '@media screen and (min-width: 381px) and (max-width: 400px)': {
    total: {
      flexWrap: 'wrap'
    },
    leadName: {
      margin: 0,
      cursor: 'pointer',
      fontWeight: 'bold',
      textOverflow: 'ellipsis',
      overflow: 'hidden',
      width: '15rem',
      whiteSpace: 'normal',
      '-webkit-line-clamp': 2,
      '-webkit-box-orient': 'vertical',
      display: '-webkit-box'
    },
  },
  '@media screen and (min-width: 401px) and (max-width: 550px)': {
    total: {
      flexWrap: 'wrap'
    },
    leadName: {
      margin: 0,
      cursor: 'pointer',
      fontWeight: 'bold',
      textOverflow: 'ellipsis',
      overflow: 'hidden',
      width: '20rem',
      whiteSpace: 'normal',
      '-webkit-line-clamp': 2,
      '-webkit-box-orient': 'vertical',
      display: '-webkit-box'
    },
  },
  '@media screen and (min-width: 551px) and (max-width: 800px)': {
    leadName: {
      margin: 0,
      cursor: 'pointer',
      fontWeight: 'bold',
      textOverflow: 'ellipsis',
      overflow: 'hidden',
      width: '30rem',
      whiteSpace: 'normal',
      '-webkit-line-clamp': 2,
      '-webkit-box-orient': 'vertical',
      display: '-webkit-box'
    },
  },
  '@media screen and (min-width: 801px)': {
    leadName: {
      margin: 0,
      cursor: 'pointer',
      fontWeight: 'bold',
      textOverflow: 'ellipsis',
      overflow: 'hidden',
      width: '28rem',
      whiteSpace: 'normal',
      '-webkit-line-clamp': 2,
      '-webkit-box-orient': 'vertical',
      display: '-webkit-box'
    },
  }
}));