import React, { useState, useContext } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router-dom';
import { Grid, Container, useMediaQuery, useTheme, Chip } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { useQuery } from 'react-query';

import { Button, CustomCardTable as Table, Header, SearchFilter, Wrapper, SwitchGifRelations, gifRelationsEnum, SelectMenu, FiltersDrawer, SortByComponent } from 'components';
import { filterByDate, orderByEnum, isAdmin } from 'helpers';
import { filterIcon } from 'resources';
import { gifTemplateService, companyService } from 'services';
import { UserContext } from 'providers';

import { useStyles } from './GifTemplatesPage.css.js';

export const GifTemplatesPage = ({ selectedCardItem, setselectedCardItem, onDoubleClick }) => {
  const classes = useStyles();
  const [t] = useTranslation('common');
  const navHistory = useHistory();
  const pathLocation = useLocation();
  const [search, setSearch] = useState('');
  const [total, setTotal] = useState();
  const [browseMode] = useState(pathLocation.pathname !== '/gifTemplates');
  const [filterDate, setFilterDate] = useState(0);
  const [order, setOrder] = useState(orderByEnum.desc);
  const { user } = useContext(UserContext);
  const [library, setLibrary] = useState(gifRelationsEnum.general);
  const { data: companies = null } = useQuery(['companies'], () => companyService.getSelectItems(), { enabled: true, cacheTime: 0 });
  const theme = useTheme();
  const isSmaller = useMediaQuery(theme.breakpoints.down('sm'));
  const [array, setArray] = useState([]);

  const orderBy = `${filterByDate[filterDate].label},${order}`;

  const callback = (query) => setSearch(query);

  const filters = { array, search, orderBy, library };

  const handleChangeFilterByOrder = () => {
    const isAsc = order === orderByEnum.asc;
    setOrder(isAsc ? orderByEnum.desc : orderByEnum.asc);
  };

  const handleChange = (value, filterName, filterLabel) => {
    if (array.filter(arr => arr.key === `${filterName}_${value}`).length === 0) {
      setArray([...array, { key: `${filterName}_${value}`, id: filterName, value: value, label: filterLabel }]);
    }
  };

  const handleDelete = el => {
    setArray(array.filter(a => el !== a));
  };

  const containerStyle = browseMode ? classes.whiteBackground : '';
  const nullPaddingBottom = browseMode ? classes.nullPaddingBottom : '';

  return (
    <Wrapper browseMode={browseMode}>
      <div className={clsx(classes.container, containerStyle, nullPaddingBottom)}>
        <Grid container className={classes.spaceBetween} spacing={2}>
          <Grid item className={classes.paddingTop}>
            <Header title={browseMode ? t('browseGifs') : t('gifTemplates')} />
          </Grid>
          {!browseMode &&
            <Grid item className={classes.paddingTop}>
              <Button text='createATemplate' callback={() => navHistory.push('/gifTemplates/create')} />
            </Grid>
          }
        </Grid>
        {isSmaller ?
          <div>
            {isAdmin(user?.role) && library === gifRelationsEnum.company && !browseMode &&
              <FiltersDrawer>
                <Container maxWidth={false} disableGutters={true}>
                  <Grid container direction='column' justifyContent='space-between' alignContent='center' spacing={3}>

                    <Grid className={classes.selectFilter} item >
                      <SelectMenu
                        label={t('byCompany')}
                        onChange={value => {
                          handleChange(value, 'companies', companies.items.find(item => item.value === value).label);
                        }}
                        menuItem={companies?.items ?? []} />
                    </Grid>

                  </Grid>
                </Container>
              </FiltersDrawer>}
            <FiltersDrawer header='sortBy'>
              <Container maxWidth={false} disableGutters={true}>
                <Grid container direction='column' justifyContent='space-between' alignContent='center' spacing={3}>

                  <SortByComponent orderByValue={order} sortByValue={filterDate} onOrderChange={handleChangeFilterByOrder} onSortByChange={e => setFilterDate(e.target.value)} />

                </Grid>
              </Container>
            </FiltersDrawer>
            <Grid className={classes.smallSearchContainer} item md={3}>
              <SearchFilter placeholder={t('typeHereToSearch')} callback={callback} />
            </Grid>
          </div> :
          <>
            <Grid container className={classes.alignItems}>
              <Grid className={classes.paddingRight} item md={1}>
                <img className={classes.filterIcon} src={filterIcon} />
                <p className={classes.filterText}>{t('filters')}</p>
              </Grid>

              {isAdmin(user?.role) && library === gifRelationsEnum.company && !browseMode &&
                <Grid className={`${classes.selectFilter} ${classes.paddingRight}`} item >
                  <SelectMenu
                    label={t('byCompany')}
                    onChange={value => {
                      handleChange(value, 'companies', companies.items.find(item => item.value === value).label);
                    }}
                    menuItem={companies?.items ?? []} />
                </Grid>
              }
              <Grid className={classes.paddingRight} item md={3}>
                <SearchFilter placeholder={t('typeHereToSearch')} callback={callback} />
              </Grid>

              <SortByComponent label={t('sortBy')} orderByValue={order} sortByValue={filterDate} onOrderChange={handleChangeFilterByOrder} onSortByChange={e => setFilterDate(e.target.value)} />
            </Grid>
          </>}

        <Grid container>
          <Grid item className={classes.total}>
            {!isSmaller && total !== 0 &&
              <Grid>
                <Grid item className={classes.total}>
                  <p className={classes.marginRight}>{t('total')}:</p>
                  <p>{total}</p>
                </Grid>
              </Grid>}
            {array.map((el, index) => {
              return (
                <Grid className={classes.chipContainer} item key={index}>
                  <Chip classes={{ root: classes.root, deleteIcon: classes.deleteIcon }} label={el.label} onDelete={() => handleDelete(el)} deleteIcon={<CloseIcon />} />
                </Grid>
              );
            })}
          </Grid>
        </Grid>

        <Grid container className={classes.switchButton} alignContent={isSmaller ? 'center' : 'flex-end'}>
          <SwitchGifRelations value={library} setChecked={(value) => {
            if (value !== gifRelationsEnum.general) {
              setArray([]);
            }
            setLibrary(value);
          }} />
        </Grid>

        <Table
          tableId='gifTemplatesPage'
          source={gifTemplateService.getGifTemplates}
          setTotal={value => setTotal(value)}
          filter={filters}
          browseMode={browseMode}
          selectedCardItem={selectedCardItem}
          setselectedCardItem={setselectedCardItem}
          onDoubleClick={onDoubleClick} />
      </div>
    </Wrapper>
  );
};

GifTemplatesPage.propTypes = {
  selectedCardItem: PropTypes.object,
  setselectedCardItem: PropTypes.func,
  onDoubleClick: PropTypes.func
};