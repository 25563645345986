import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(() => ({
  lockIcon: {
    display: 'inline-flex',
    padding: '5px 5px 5px 0',
    marginTop: '32px',
    width: '20px',
    height: '20px',
    cursor: 'pointer'
  }
}));