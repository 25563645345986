import { makeStyles, withStyles } from '@material-ui/core/styles';
import TableCell from '@material-ui/core/TableCell';

export const useStyles = makeStyles((theme) => ({
  tableCell: {
    fontSize: '1rem',
    color: theme.secondaryTextColor,
    maxWidth: 600,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
  tableFirstColumn: {
    color: theme.quaternaryTextColor,
  },
  functionCell: {
    display: 'flex',
  },
  iconFontSize: {
    fontSize: '1.5rem'
  },
  mediaSectionColumn: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    justifyContent: 'space-between'
  },
  emptyRow: {
    height: '0.5rem',
    width: '100%',
  },
  width100: {
    width: '100%',
  },
  tableRow: {
    width: '100%',
    backgroundColor: theme.quaternaryBackgroundColor,
    position: 'relative',
    zIndex: '2',
    borderRadius: '0.313rem',
    outline: 'none',
    '&:hover': {
      backgroundColor: theme.senaryBackgroundColor + ' !important',
      outline: '1px solid ' + theme.primaryBackgroundColor,
      outlineOffset: '-1px'
    }
  }
}));

export const BodyCell = withStyles({
  root: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    borderBottom: 'none',
    outline: 'none'
  }
})(TableCell);
