import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  container: {
    flexDirection: 'row',
    marginTop: '0.5rem',
    padding: '0.5rem',
    justifyContent: 'space-between',
    marginLeft: '0',
    cursor: 'pointer'
  },
  isRead: {
    backgroundColor: theme.quinaryBackgroundColor
  },
  notRead: {
    backgroundColor: theme.senaryBackgroundColor
  },
  date: {
    color: theme.primaryTextColor,
    fontSize: '0.75rem'
  },
  description: {
    fontSize: '0.875rem',
    wordWrap: 'break-word'
  },
  seeAll: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    display: '-webkit-box',
    lineClamp: 3,
    boxOrient: 'vertical',
    wordWrap: 'break-word'
  },
  imgContainer: {
    backgroundColor: theme.primaryBackgroundColor,
    height: '1.5rem',
    width: '1.5rem',
    borderRadius: '50%',
    display: 'flex',
    justifyContent: 'center',
    marginRight: '0.5rem',
    cursor: 'pointer',
  },
  img: {
    height: '0.625rem',
    width: '0.466rem',
    margin: 'auto',
  },
  opacity: {
    opacity: '30%'
  },
  textContainer: {
    justifyContent: 'space-between',
    flexDirection: 'column',
    wordWrap: 'break-word'
  },
  title: {
    margin: 0,
    marginTop: '0.5rem',
    fontSize: '1rem'
  },
  'MuiGrid-item': {
    width: '100%'
  },
  '@media screen and (max-width:400px)': {
    container: {
      width: '90%',
      marginLeft: '2rem'
    },
  }
}));