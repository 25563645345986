import React, { useState, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { Grid, useMediaQuery, useTheme, Chip } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { useQuery } from 'react-query';

import { CustomTable as Table, Header, Wrapper, SelectMenu, AppRangeDatePicker, AppExcelExport } from 'components';
import { formatDateForTable, roles, formatDate } from 'helpers';
import { filterIcon } from 'resources';
import { reportService, companyService } from 'services';

import { useStyles } from './style.css.js';

export const ReportListPage = () => {
  const classes = useStyles();
  const [t] = useTranslation('common');
  const navHistory = useHistory();
  const [total, setTotal] = useState();
  const [array, setArray] = useState([]);
  const [disabledExport, setDisabledExport] = useState(true);
  const dateNow = new Date(Date.now());
  const [dateFilter, setDateFilter] = useState({
    from: new Date(dateNow.getFullYear(), dateNow.getMonth(), dateNow.getDate(), 0, 0, 0, 0),
    to: new Date(dateNow.getFullYear(), dateNow.getMonth(), dateNow.getDate(), 1, 0, 0, 0),
  });
  const theme = useTheme();
  const isSmaller = useMediaQuery(theme.breakpoints.down('sm'));

  const { data: companies = null } = useQuery(['companies'], () => companyService.getSelectItems(), { enabled: true, cacheTime: 0 });

  const companyNameView = report => {
    return (
      <p className={classes.companyName} onClick={() => navHistory.push(`/companies/${report.companyId}`)}>
        {report.companyName}
      </p>
    );
  };

  const userNameView = report => {
    if (disabledExport) {
      setDisabledExport(false);
    }
    return (
      <p className={classes.companyName} onClick={() => navHistory.push(`/users/edit/${report.id}`)}>
        {report.name} {report.surname}
      </p>
    );
  };

  const userRoleView = user => {
    const userRole = roles.find(r => user.role === r.value);
    return <p className={classes.role}>{t(userRole.label)}</p>;
  };

  const columnConfiguration = useMemo(() => [
    { id: 'id', label: t('id'), disableSorting: true, format: report => report.id },
    { id: 'user_name', label: t('userName'), disableSorting: true, format: report => userNameView(report) },
    { id: 'user_role', label: t('userRole'), disableSorting: true, format: report => userRoleView(report) },
    { id: 'company_name', label: t('companyName'), disableSorting: true, format: report => companyNameView(report) },
    {
      id: 'dateCreated', label: t('dateCreated'), disableSorting: true, format: (user) => {
        return formatDateForTable(user.createdAt, classes.tableCell);
      }
    },
    {
      id: 'lastModified', label: t('lastModified'), disableSorting: true, format: (user) => {
        return formatDateForTable(user.modifiedAt, classes.tableCell);
      }
    }
  ], []);

  const filters = { array, dateFilter };

  const handleChange = (value, filterName, filterLabel) => {
    setDisabledExport(true);
    if (array.filter(arr => arr.key === `${filterName}_${value}`).length === 0) {
      setArray([...array, { key: `${filterName}_${value}`, id: filterName, value: value, label: filterLabel }]);
    }
  };

  const handleDelete = el => {
    setDisabledExport(true);
    setArray(array.filter(a => el !== a));
  };

  const getReport = () => {
    return reportService.getAll(0, 10000000, '', filters).then((data) => {
      return data.items.map((item) => {
        const userRole = roles.find(r => item.role === r.value);
        return {
          'ID#': item.id,
          'USER NAME': `${item.name} ${item.surname}`,
          'USER ROLE': t(userRole.label),
          'COMPANY NAME': item.companyName,
          'DATE CREATED': formatDate(item.createdAt),
          'LAST MODIFIED': formatDate(item.modifiedAt)
        };
      });
    });
  };

  const onDateChange = (date) => {
    setDisabledExport(true);
    setDateFilter(date);
  };

  const getFileName = () => {
    let company = 'All_companies';
    if (array.length > 0) {
      company = array.length === 1 ? array[0].label : 'Selected_companies';
    }
    return `Report_${company}_${formatDate(dateFilter.from)}_${formatDate(dateFilter.to)}`.replaceAll('/', '');
  };

  return (
    <Wrapper>
      <div className={classes.container}>
        <Grid container className={classes.spaceBetween} spacing={2}>
          <Grid item className={classes.paddingTop}>
            <Header title={t('reports')} />
          </Grid>
        </Grid>
        <Grid container className={classes.alignItems} spacing={3}>
          <Grid className={classes.marginRight} item xs={12} sm={1}>
            <img className={classes.filterIcon} src={filterIcon} />
            <p className={classes.filterText}>{t('filters')}</p>
          </Grid>
          <Grid item>
            <SelectMenu
              label={t('byCompany')}
              onChange={value => {
                handleChange(value, 'companies', companies.items.find(item => item.value === value).label);
              }}
              menuItem={companies?.items ?? []} />
          </Grid>
          <Grid item>
            <AppRangeDatePicker fromDate={dateFilter.from} toDate={dateFilter.to} onChange={onDateChange} />
          </Grid>
          <Grid item>
            <AppExcelExport fetchData={getReport} fileName={getFileName()} disabled={disabledExport} />
          </Grid>
        </Grid>

        <Grid container>
          <Grid item className={classes.total}>
            {!isSmaller && total !== 0 &&
              <Grid>
                <Grid item className={classes.total}>
                  <p className={classes.marginRight}>{t('total')}:</p>
                  <p>{total}</p>
                </Grid>
              </Grid>}
            {array.map((el, index) => {
              return (
                <Grid className={classes.chipContainer} item key={index}>
                  <Chip classes={{ root: classes.root, deleteIcon: classes.deleteIcon }} label={el.label} onDelete={() => handleDelete(el)} deleteIcon={<CloseIcon />} />
                </Grid>
              );
            })}
          </Grid>
        </Grid>
        <Table tableId='reportsPage' columnConfiguration={columnConfiguration} source={reportService.getAll} setTotal={value => setTotal(value)} filter={filters} />
      </div>
    </Wrapper>
  );
};