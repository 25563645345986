import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  container: {
    border: 'none',
    width: '100%',
    outline: 'none',
    fontSize: '1rem',
    cursor: 'pointer',
    '&:active': {
      transform: 'translateY(1px)'
    },
    borderRadius: '0.438rem',
    height: '3.875rem',
    minWidth: '5.438rem'
  },
  smallContainer: {
    minWidth: '4.438rem !important'
  },
  showMoreButtonContainer: {
    width: '10rem',
    height: '3rem',
    minWidth: '3.438rem !important'
  },
  defaultColor: {
    backgroundColor: theme.primaryBackgroundColor,
    color: theme.quaternaryBackgroundColor
  },
  primary: {
    backgroundColor: theme.tertiaryBackgroundColor,
    color: theme.quaternaryBackgroundColor
  },
  secondary: {
    backgroundColor: theme.senaryBackgroundColor,
    color: theme.primaryBackgroundColor
  },
  disabled: {
    backgroundColor: theme.quinaryBackgroundColor,
    color: theme.primaryTextColor
  },
  lightDefaultColor: {
    backgroundColor: theme.senaryBackgroundColor,
    color: theme.primaryBackgroundColor,
    fontWeight: 'bold'
  },
  backButtonContainer: {
    display: 'flex',
    cursor: 'pointer',
    paddingTop: '1rem',
    paddingLeft: '1rem',
    width: '4rem'
  },
  backButtonText: {
    margin: 0,
    fontSize: '1rem',
    paddingLeft: '0.5rem'
  },
  buttonWithIcon: {
    display: 'inline-block',
    border: 'none',
    width: '100%',
    outline: 'none',
    fontSize: '1rem',
    cursor: 'pointer',
    '&:active': {
      transform: 'translateY(1px)'
    },
    borderRadius: '15rem',
    height: '3.875rem',
    minWidth: '11.438rem',
    backgroundColor: theme.senaryBackgroundColor,
    color: theme.primaryBackgroundColor
  },
  buttonText: {
    display: 'inline-flex',
    alignItems: 'center',
    paddingTop: '0.2rem',
    fontWeight: 'bold',
    paddingRight: '5%'
  },
  '@media screen and (max-width: 359px)': {
    container: {
      border: 'none',
      width: '100%',
      outline: 'none',
      fontSize: '1rem',
      cursor: 'pointer',
      '&:active': {
        transform: 'translateY(1px)'
      },
      borderRadius: '0.438rem',
      height: '2.875rem',
      minWidth: '3.438rem'
    }
  },
  '@media screen and (min-width: 768px, max-width: 1025px)': {
    container: {
      border: 'none',
      width: '100%',
      outline: 'none',
      fontSize: '1rem',
      cursor: 'pointer',
      '&:active': {
        transform: 'translateY(1px)'
      },
      borderRadius: '0.438rem',
      height: '2.875rem',
      minWidth: '3.438rem'
    }
  },
}));