import React, { useEffect, useState, useContext } from 'react';
import { UserContext } from 'providers';
import { Form, Formik, Field } from 'formik';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';
import * as Yup from 'yup';
import { Container, Grid, useMediaQuery, useTheme, FormControl, FormControlLabel, Radio, RadioGroup } from '@material-ui/core';
import RadioButtonCheckedIcon from '@material-ui/icons/RadioButtonChecked';
import RadioButtonUncheckedIcon from '@material-ui/icons/RadioButtonUnchecked';
import { BackButton, Button, InputField, SadStates, Loader, toastNotification, EmptyDialog, CheckboxInputField } from 'components';
import { phoneRegExp, companyStatuses, isAdmin } from 'helpers';
import { companyService } from 'services';
import { deleteMessageIcon } from 'resources';
import { useStyles } from '../style.css.js';

export const UpdateCompanyPage = () => {
  const classes = useStyles();
  const { t } = useTranslation(['common', 'error']);
  const navHistory = useHistory();
  const { id } = useParams();
  const [isOpen, setIsOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [company, setCompany] = useState({});
  const { user } = useContext(UserContext);
  const isAdminRole = isAdmin(user && user.role);

  const theme = useTheme();
  const isExtraSmaller = useMediaQuery(theme.breakpoints.down('xs'));
  const isSmaller = useMediaQuery(theme.breakpoints.down('sm'));
  const confimDialogMessage = company && company.usersCount > 0 ?
    (<><span style={{ 'color': theme.primaryBackgroundColor }}>{company.name}</span> includes {company.usersCount} users.<br />Are you sure you want to delete the company and all its users permanently?</>) :
    (<>Are you sure you want to delete?</>);
  const confimDialogBody = company && company.usersCount > 0 ?
    (<></>) :
    (<>{company.name}</>);

  useEffect(() => {
    setIsLoading(true);
    companyService.get(id).then((res) => {
      setCompany(res.data);
      setIsLoading(false);
    }).catch((err) => {
      setIsLoading(false);
      toastNotification('error', t(`error:${Object.values(err.response.data.errors)[0]}`));
    });
  }, [id]);

  const handleSubmit = (values) => {
    setIsLoading(true);
    companyService.update(values, id).then(() => {
      setIsLoading(false);
      toastNotification('info', t('companyUpdated'));
      navHistory.goBack();
    }).catch((err) => {
      setIsLoading(false);
      toastNotification('error', t(`error:${Object.values(err.response.data.errors)[0]}`));
    });
  };

  const companySchema = Yup.object().shape({
    name: Yup.string()
      .max(50, t('stringMax50'))
      .required(t('required')),
    phone: Yup.string()
      .max(20, t('stringMax20'))
      .matches(phoneRegExp, t('invalidPhoneFormat'))
      .required(t('required')),
    status: Yup.number(),
    state: Yup.string()
      .max(20, t('stringMax20')),
    city: Yup.string()
      .max(20, t('stringMax20')),
    address: Yup.string()
      .max(100, t('stringMax100')),
    address2: Yup.string()
      .max(100, t('stringMax100')),
    zip: Yup.string()
      .max(10, t('stringMax10')),
    creditApplicationLink: Yup.string()
      .max(1000, t('stringMax1000'))
      .url(t('invalidUrl'))
  });

  const handleCancel = () => {
    navHistory.goBack();
  };

  const handleDelete = () => {
    setIsOpen(true);
  };
  const onDelete = () => {
    setIsLoading(true);
    companyService.remove(id).then(() => {
      setIsLoading(false);
      toastNotification('info', t('companyDeleted'));
      navHistory.goBack();
    }).catch((err) => {
      setIsLoading(false);
      toastNotification('error', t(`error:${Object.values(err.response.data.errors)[0]}`));
    });
  };

  const StatusRadioComponent = (prop) => {
    return (
      <FormControl component='fieldset' className={classes.radioGroup}>
        <RadioGroup aria-label='position' row {...prop}>
          {companyStatuses.map((type, index) => {
            return (
              <FormControlLabel
                className={classes.radio}
                key={index}
                value={type.value}
                control={
                  <Radio
                    checkedIcon={<RadioButtonCheckedIcon className={classes.iconStyle} />}
                    icon={<RadioButtonUncheckedIcon className={classes.uncheckedIconStyle} />} />
                }
                label={<span className={classes.radioLabel}>{t(type.label)}</span>} />
            );
          })}
        </RadioGroup>
      </FormControl>
    );
  };

  return (
    <div className={isSmaller ? classes.modalPhone : classes.modal}>
      <BackButton callback={() => navHistory.replace('/companies')} />
      <Container maxWidth={'md'} className={classes.container}>
        <EmptyDialog isOpen={isOpen} setDialogOpen={setIsOpen} callback={onDelete}
          headerText={confimDialogMessage} bodyText={confimDialogBody} imageSrc={deleteMessageIcon} />
        <SadStates
          states={[
            {
              when: isLoading,
              render: <Loader />
            }
          ]}>
          <Formik
            initialValues={{
              name: company.name,
              phone: company.phone,
              status: company.status ?? '',
              state: company.state ?? '',
              city: company.city ?? '',
              address: company.address ?? '',
              address2: company.address2 ?? '',
              zip: company.zip ?? '',
              creditApplicationLink: company.creditApplicationLink ?? '',
              paidSms: company.paidSms ?? false,
              paidEmail: company.paidEmail ?? false,
              optInDisabled: company.optInDisabled ?? false
            }}
            validationSchema={companySchema}
            onSubmit={handleSubmit}>
            {({ values, dirty, setFieldValue }) => (
              <Form className={classes.form}>
                <Grid container justifyContent='flex-start' spacing={5}>
                  <Grid item>
                    <h1 className={classes.header}>{company.name}</h1>
                  </Grid>
                </Grid>
                <Grid container justifyContent='center' spacing={2}>
                  <Grid item xs={12} sm={12} md={7} lg={7}>
                    <InputField label='companyName' inputName='name' isRequired disabled={!isAdminRole} />
                  </Grid>
                  <Grid item xs={12} sm={12} md={5} lg={5}>
                    <InputField label='companyPhone' inputName='phone' isRequired />
                  </Grid>
                </Grid>
                <Grid container justifyContent='center' spacing={2}>
                  <Grid item xs={12} sm={12} md={12} lg={12}>
                    <InputField label='address' inputName='address' />
                  </Grid>
                  <Grid item xs={12} sm={12} md={12} lg={12}>
                    <InputField label='address2' inputName='address2' readOnly={false} />
                  </Grid>
                </Grid>
                <Grid container justifyContent='center' spacing={2}>
                  <Grid item xs={12} sm={12} md={4} lg={4}>
                    <InputField label='city' inputName='city' readOnly={false} />
                  </Grid>
                  <Grid item xs={12} sm={12} md={4} lg={4}>
                    <InputField label='state' inputName='state' />
                  </Grid>
                  <Grid item xs={12} sm={12} md={4} lg={4}>
                    <InputField label='zip' inputName='zip' readOnly={false} />
                  </Grid>
                  <Grid item xs={12} sm={12} md={12} lg={12}>
                    <InputField label='creditApplicationLink' inputName='creditApplicationLink' />
                  </Grid>
                </Grid>

                {isAdminRole &&
                  <Grid container spacing={0}>
                    <Grid item xs={12} md={12} className={classes.radioBlock}>
                      <Field
                        as={StatusRadioComponent}
                        value={values.status}
                        onChange={(e) => {
                          setFieldValue('status', Number(e.target.value));
                        }}
                        name='status' />
                    </Grid>
                  </Grid>}

                <Grid item xs={12} md={12} className={classes.checkboxBlock}>
                  <span>Paid Features</span>
                  <div>
                    <CheckboxInputField disabled={!isAdminRole} label='SMS' checked={values.paidSms} inputName='paidSms'
                      onChange={(val) => {
                        setFieldValue('paidSms', val);
                      }} />
                    <CheckboxInputField disabled={!isAdminRole} label='Email' checked={values.paidEmail} inputName='paidEmail'
                      onChange={(val) => {
                        setFieldValue('paidEmail', val);
                      }} />
                  </div>
                </Grid>

                <Grid item xs={12} md={12} className={classes.checkboxBlock}>
                  <span>Opt-in</span>
                  <div>
                    <CheckboxInputField disabled={!isAdminRole} label={t('optInDisabledTitle')} checked={!values.optInDisabled} inputName='optInDisabled'
                      onChange={(val) => {
                        setFieldValue('optInDisabled', !val);
                      }} />
                  </div>
                </Grid>

                <Grid container spacing={2} className={classes.formActions}>
                  <Grid item xs={4} sm={3} md={2}>
                    {isAdminRole && <Button type='button' text='delete' color='primary' callback={() => handleDelete()} smallButton={true} />}
                  </Grid>
                  {!isExtraSmaller && <Grid item sm={3} md={6} lg={6} />}
                  <Grid item xs={4} sm={3} md={2}>
                    <Button type='reset' text='cancel' color='primary' callback={() => handleCancel()} smallButton={true} />
                  </Grid>
                  <Grid item xs={4} sm={3} md={2}>
                    <Button text='save' disabled={isLoading || !dirty} type='submit' smallButton={true} />
                  </Grid>
                </Grid>
              </Form>
            )}
          </Formik>
        </SadStates>
      </Container>
    </div>
  );
};