import { makeStyles } from '@material-ui/core/styles';
import { displayBorder } from 'helpers';

import './fonts.css';

export const useStyles = makeStyles((theme) => ({
  modal: {
    height: 'fit-content',
    minHeight: 'calc(100vh - 9.375rem)',
    padding: '2.5rem',
    backgroundClip: 'content-box',
    boxShadow: 'inset 0 0 0 2.5rem' + theme.quinaryBackgroundColor,
    margin: '0 auto',
    maxWidth: '100%'
  },
  container: {
    width: '100%',
    paddingLeft: '3%',
    paddingRight: '3%',
    paddingBottom: '3%',
    position: 'relative',
    '&.MuiContainer-maxWidthLg': {
      maxWidth: '100%'
    }
  },
  spaceBetween: {
    direction: 'row',
    justifyContent: 'space-between'
  },
  containerContent: {
    paddingLeft: '10%'
  },
  form: {
    width: '100%',
    height: '100%',
  },
  containerContentForm: {
    paddingTop: '2rem',
    height: '100%',
    marginBottom: '1rem'
  },
  containerContentGif: {
    paddingTop: '2rem',
    height: '100%',
    marginBottom: '1rem',
    display: 'flex',
    flexDirection: 'column',
    placeItems: 'center'
  },
  uploadGifContainer: {
    width: '90%',
    marginBottom: '0.8rem',
    maxHeight: '100%'
  },
  rowGap: {
    rowGap: '0rem',
  },
  radioButtonContainer: {
    width: '100%'
  },
  marginOneTop: {
    marginTop: '2rem'
  },
  spaceBetweenCol: {
    direction: 'col',
    justifyContent: 'space-between',
    width: '100%',
    height: 'auto',
    marginLeft: '0'
  },
  spaceBetweenRow: {
    direction: 'row',
    justifyContent: 'start',
    width: '100%',
    marginTop: '1rem',
    marginLeft: '0.2rem',
    fontWeight: 'bold'
  },
  marginBottom: {
    marginBottom: '1rem'
  },
  smallWidth: {
    '&.makeStyles-container-38': {
      minWidth: '7.438rem'
    }
  },
  titleContainer: {
    paddingTop: '5%',
    paddingBottom: '5%',
    paddingLeft: '1.5%'
  },
  iconStyle: {
    height: '1.5rem',
    width: '1.5rem',
    color: theme.primaryBackgroundColor,
    paddingLeft: '0'
  },
  uncheckedIconStyle: {
    height: '1.5rem',
    width: '1.5rem',
    color: theme.primaryBorderColor,
    paddingLeft: 0
  },
  fileProperty: {
    width: 'auto',
    maxWidth: '100%',
    fontWeight: 'bold',
    border: '2px solid' + theme.primaryBackgroundColor,
  },
  xStyle: {
    height: '1rem',
    stroke: theme.primaryTextColor,
    strokeWidth: '2',
  },
  validation: {
    color: theme.validationTextColor,
    position: 'relative',
    textAlign: 'center',
    fontSize: '1rem',
    fontWeight: 'normal',
    backgroundColor: theme.validationBackgroundColor,
    maxWidth: '60%',
    width: '50%',
    margin: '0.5rem'
  },
  centerContent: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  marginTop: {
    marginTop: '0.5rem'
  },
  urlField: {
    flexGrow: '100'
  },
  browseGifContainer: {
    width: '-webkit-fill-available',
    height: '5rem',
    marginBottom: '2rem'
  },
  dataContainer: {
    width: '100%',
    marginTop: '1rem'
  },
  textContainer: {
    width: '-webkit-fill-available',
    marginTop: '0.5rem'
  },
  marginMiddle: {
    height: '100%',
    marginLeft: '1rem',
    marginRight: '1rem'
  },
  autoWidth: {
    width: 'auto',
  },
  maxWidth: {
    width: '100%'
  },
  smallNoteField: {
    width: '100%',
    height: '9rem'
  },
  gifPreviewContainer: {
    width: '454px',
    height: '682px',
    rowGap: '1.5rem',
    border: '1px solid #F5F5F5',
    display: 'flex',
    backgroundColor: '#FAF9FF',
    overflow: 'hidden',
    fontFamily: 'Arial, sans-serif',
    textShadow: '1px 1px 0px #778899b0',
  },
  gifPreview: {
    width: '100%',
    height: '100%',
    objectFit: 'contain',
    flexDirection: 'column',
    border: '1px solid #F5F5F5',
    display: 'flex',
    backgroundSize: 'contain',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    backgroundImage: (props) => props.previewUploadedGifTemplate ? `url(${props.previewUploadedGifTemplate})` : 'none'
  },
  contentGifPreview: {
    width: (props) => props.previewUploadedGifTemplate ? `${props.previewUploadedGifTemplate.width}` : 'none',
    height: (props) => props.previewUploadedGifTemplate ? `${props.previewUploadedGifTemplate.height}` : 'none',
  },
  gifPreviewCaption: {
    marginTop: '1rem',
    color: theme.primaryTextColor,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  gifImage: {
    width: '100%',
    position: 'relative',
    objectFit: 'contain',
    zIndex: '1'
  },
  giffPreviewSmallLabel: {
    display: 'flex',
    textAlign: 'center',
    alignItems: 'center',
    wordBreak: 'break-word',
    margin: '0 auto',
    height: '25%',
    flexDirection: 'column',
    '& > div > p': {
      margin: '10px',
      zIndex: '1',
      overflowY: 'hidden',
      textShadow: `2px 1px ${theme.lightSlateGray}`
    }
  },
  giffPreviewSmallTop: {
    justifyContent: 'flex-start',
  },
  giffPreviewSmallBottom: {
    justifyContent: 'flex-end',
  },
  gifTextTop: {
    // padding: '5%',
    // margin: 'auto',
  },
  gifTextBottom: {
    // padding: '5%',
    // margin: 'auto',
  },
  giffPreviewBigLabel: {
    width: '100%',
    height: '50%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  imagePreview: {
    height: '100%',
    width: '100%',
    objectFit: 'contain'
  },
  imageIcon: {
    color: '#E6E8F1',
    fontSize: '5rem',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  gifTemplateActions: {
    justifyContent: 'space-between',
    marginTop: '3%',
    [theme.breakpoints.down(displayBorder)]: {
      justifyContent: 'space-between'
    },
  },
  radioLabel: {
    minWidth: '145px',
  },
  selectField: {
    border: '1px solid; border-radius: 7px;',
    borderColor: theme.primaryBorderColor,
    width: '100px',
    marginTop: '0.2rem',
    marginBottom: '2rem',
    zIndex: 100,
  },
  selectFieldInput: {
    color: theme.quaternaryTextColor,
    paddingLeft: '0.7rem',
    border: '0',
    outline: 'none',
    top: '0.1rem',
    position: 'relative',
    '& .MuiSelect-select:focus': {
      backgroundColor: 'initial',
    },
    whiteSpace: 'nowrap'
  },
  color_pallete: {
    margin: '5px 0 20px 0',
    border: 'none !important',
    boxShadow: 'none !important',
    borderRadius: 'none !important',
    '& >div': {
      padding: '0 !important'
    },
  },
  color_pallete_label: {
    color: '#98A0CE',
    outline: 'none',
    position: 'relative',
    fontSize: '1rem',
    whiteSpace: 'nowrap',
    '& >span': {
      display: 'inline-block',
      width: '1rem',
      height: '1rem',
      marginLeft: '5px',
      marginBottom: '-3px',
      borderRadius: '4px',
      border: '1px solid lightgrey'
    }
  },
  accordionFormatting: {
    marginTop: '-15px',
    marginBottom: '12px',
    boxShadow: 'none',
    '&:before': {
      display: 'none',
    },
    '&:not(:last-child)': {
      borderBottom: 0,
    },
  },
  accordionSummaryFormatting: {
    '& p': {
      color: '#98A0CE',
      fontSize: '0.8rem'
    }
  },
  selectInputField: {
    margin: theme.spacing(0),
    marginBottom: '0',
    '& > div > div > div': {
      maxHeight: '42.7px',
    },
    '& > div > div > div > div': {
      top: '6px'
    }
  },
  fontStyleButton: {
    fontFamily: 'Roboto',
    margin: '21px 0 0 8px',
    height: '42px',
    width: '42px',
    fontSize: '1.5rem',
    textAlign: 'center',
    cursor: 'pointer',
    outline: 'none',
    border: 'none',
    borderRadius: '0.5rem',
  },
  activeButton: {
    backgroundColor: theme.primaryBackgroundColor,
    color: theme.tertiaryBackgroundColor,
  },
  inactiveButton: {
    backgroundColor: theme.lightGreyColor,
    color: theme.darkGreyColor,
  },
  '@media screen and (min-width: 960px) and (max-width: 1432px)': {
    rowGap: {
      rowGap: '1.5rem !important'
    },
    textContainer: {
      width: '-webkit-fill-available'
    },
    validation: {
      color: theme.validationTextColor,
      top: '-0.5rem',
      position: 'relative',
      textAlign: 'center',
      fontWeight: 'normal',
      backgroundColor: theme.validationBackgroundColor,
      maxWidth: '100% !important',
      width: '100% !important',
      margin: '0.5rem'
    },
  },
  '@media screen and (min-width: 381px) and (max-width: 500px)': {
    gifPreviewContainer: {
      width: '354px',
      height: '531px'
    }
  },
  '@media screen and (max-width: 380px)': {
    gifPreviewContainer: {
      width: '230px',
      height: '345px'
    }
  },
  '@media screen and (min-width: 600px) and (max-width: 698px)': {
    modal: {
      padding: '1rem'
    }
  },
  '@media screen and (min-width: 350px) and (max-width: 650px)': {
    dataContainer: {
      marginTop: '0rem'
    },
  },
  '@media screen and (min-width: 332px) and (max-width: 599px)': {
    textContainer: {
      width: '-webkit-fill-available',
      marginTop: '0'
    },
    modal: {
      padding: '1rem'
    }
  },
  '@media screen and (max-width: 768px)': {
    textContainer: {
      width: '-webkit-fill-available',
      marginTop: '0'
    },
    modal: {
      boxShadow: 'inset 0 0 0 0',
      padding: '1rem'
    }
  },
  '@media screen and (max-width: 331px)': {
    textContainer: {
      width: '-webkit-fill-available',
      marginTop: '0'
    },
    modal: {
      padding: '1rem'
    }
  },
  '@media screen and (max-width: 497px)': {
    validation: {
      color: theme.validationTextColor,
      top: '-0.5rem',
      position: 'relative',
      textAlign: 'center',
      fontSize: '1rem',
      fontWeight: 'normal',
      backgroundColor: theme.validationBackgroundColor,
      maxWidth: '100% !important',
      width: '100% !important',
      margin: '0.5rem'
    },
    modal: {
      padding: '1rem'
    }
  }
}));