import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  root: {
    fontFamily: theme.fontLato
  },
  rootTable: {
    borderCollapse: 'separate',
    borderSpacing: '0 0.5rem',
    outline: 'none'
  },
  container: {
    borderBottom: '1px solid ' + theme.borderColor,
    borderColor: theme.borderColor,
    width: '100%',
    marginBottom: '1.5rem',
    paddingBottom: '1rem',
  },
  total: {
    color: theme.primaryTextColor,
    display: 'flex'
  },
  marginRight: {
    marginRight: '0.3rem',
    display: 'flex'
  },
}));