import React, { useContext } from 'react';
import { useQuery } from 'react-query';

import { UserContext } from 'providers';
import { notificationService } from 'services';

import { useStyles } from './NotificationIcon.css.js';

export const NotificationIcon = () => {
  const classes = useStyles();
  const { user } = useContext(UserContext);
  const { data: notifications } = useQuery(['notifications'], () => notificationService.getNotifications(user.id));

  let ninetyNinePlus = '';

  ninetyNinePlus = notifications === undefined ? '' : '99+';

  if (notifications?.totalUnread === 0) {
    return '';
  }
  return (
    <div className={classes.container}>
      <p className={notifications?.totalUnread < 100 ? classes.text : classes.ninetyNinePlus}>
        {notifications?.totalUnread < 100 ? notifications?.totalUnread : ninetyNinePlus}
      </p>
    </div>
  );
};
