import React, { useState, useContext } from 'react';
import clsx from 'clsx';
import firebase from 'firebase/app';
import { Form, Formik } from 'formik';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import { useHistory, useLocation } from 'react-router-dom';
import * as Yup from 'yup';
import { Container, Grid, useMediaQuery, useTheme } from '@material-ui/core';

import { BackButton, Button, InputField, PasswordInputField, SadStates, Loader, toastNotification } from 'components';
import { phoneRegExp, setAmericanPhoneCode, roles, handleFirebaseErrors } from 'helpers';
import { UserContext } from 'providers';
import { userService } from 'services';

import { useStyles } from './ProfileForm.css.js';

export const ProfileForm = () => {
  const classes = useStyles();
  const { t } = useTranslation([ 'common', 'error' ]);
  const navHistory = useHistory();
  const navLocation = useLocation();
  const { user, setUser } = useContext(UserContext);
  const [ cancelUpdate, setCancelUpdate ] = useState(false);
  const [ changePassword, setChangePassword ] = useState(false);

  const { isLoading, data= {} } = useQuery(['profileForm'], () => userService.getCurrentUser(), { cacheTime: 0 });

  const isProfileEditPage = navLocation.pathname === '/profile';

  const theme = useTheme();
  const isMedium = useMediaQuery(theme.breakpoints.down('md'));

  const handleResponse = async values => {
    try {
      await userService.updateLoginUser(values);
      navHistory.goBack();
    } catch (error) {
      toastNotification('error', t(`error:${Object.values(error.response.data.errors)[0]}`));
    }
  };

  const handleSubmit = async (values, { setSubmitting }) => {
    if (cancelUpdate) {
      return;
    }

    values.phone = setAmericanPhoneCode(values.phone);
    setUser({...user, ...values});

    try {
      const response = await userService.updateLoginUser(values);
      if (response && response.status === 200) {
        toastNotification('info', t('settingsSaved'));
      }
    } catch (error) {
      toastNotification('error', t(`error:${Object.values(error.response.data.errors)[0]}`));
    }

    const currentUser = firebase.auth().currentUser;
    const passwordChange = values.newPassword && values.newPassword.trim().length > 0;
    if (passwordChange) {
      const cred = firebase.auth.EmailAuthProvider.credential(currentUser.email, values.currentPassword);
      try {
        await currentUser.reauthenticateWithCredential(cred);
        try {
          await currentUser.updatePassword(values.newPassword.trim());
          toastNotification('info', t('passwordUpdateSuccess'));
          handleResponse(values);
        } catch (error) {
          toastNotification('error', t(`error:${Object.values(error.response.data.errors)[0]}`));
        }
      } catch (error) {
        const message = handleFirebaseErrors(error);
        toastNotification('warning', t(message));
      }
    } else {
      handleResponse(values);
    }
    setSubmitting(false);
  };

  const loginSchema = Yup.object().shape({
    name: Yup.string()
      .max(100, t('nameValidation'))
      .required(t('required')),
    surname: Yup.string()
      .max(100, t('surnameValidation'))
      .required(t('required')),
    phone: Yup.string()
      .max(50, t('phoneValidation'))
      .matches(phoneRegExp, t('invalidPhoneFormat'))
      .required(t('required')),
    currentPassword: Yup.string()
      .min(8, t('tooShort'))
      .max(50, t('tooLong')),
    newPassword: Yup.string()
      .min(8, t('tooShort'))
      .max(50, t('tooLong')),
    confirmNewPassword: Yup.string()
      .oneOf([ Yup.ref('newPassword'), null ], t('doesNotMatch'))
      .min(8, t('tooShort'))
      .max(50, t('tooLong')),
  });

  const handleCancel = () => {
    setCancelUpdate(true);
    navHistory.goBack();
  };

  const handleRoles = loggedInUser => {
    const userRole = roles.find(role => loggedInUser.role === role.value);
    return t(userRole.label);
  };

  return (
    <div className={classes.modal}>
      <BackButton callback={() => navHistory.goBack()}/>
      <Container maxWidth={false} className={classes.container}>
        <SadStates
          states={[
            {
              when: isLoading,
              render: <Loader/>
            }
          ]}>
          <Formik
            initialValues={{
              name: data.name,
              surname: data.surname,
              phone: data.phone,
              email: data.email
            }}
            validationSchema={loginSchema}
            onSubmit={handleSubmit}>
            {({ isSubmitting, dirty }) => (
              <Form className={classes.form}>
                <Grid className={clsx({[classes.sectionTitle]: !isProfileEditPage})} container>
                  <Grid item xs={12} sm={12} md={3} />
                  <Grid item xs={12} sm={12} md={3}>
                    <h1 className={classes.header}>{t('settings')}</h1>
                  </Grid>
                </Grid>
                <Grid container>
                  <Grid item xs={12} sm={12} md={3} />
                  <Grid item xs={12} sm={12} md={3}>
                    <h1 className={classes.subheading}>{t('profileDetails')}</h1>
                  </Grid>
                </Grid>
                <Grid container justifyContent='center' spacing={2}>
                  <Grid item xs={12} md={3}>
                    <InputField label='name' inputName='name' type='text' isRequired/>
                  </Grid>
                  <Grid item xs={12} md={3}>
                    <InputField label='surname' inputName='surname' type='text' isRequired/>
                  </Grid>
                </Grid>
                <Grid container justifyContent='center' spacing={2}>
                  <Grid item xs={12} md={3}>
                    <InputField label='phone' inputName='phone' type='text' isRequired/>
                  </Grid>
                  <Grid item xs={12} md={3}>
                    <InputField label='email' inputName='email' type='email' readOnly isRequired/>
                  </Grid>
                </Grid>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={12} md={3} />
                  <Grid item xs={12} sm={12} md={3}>
                    <p className={classes.role}>{t('role')}</p>
                    <p>{handleRoles(data)}</p>
                  </Grid>
                </Grid>
                <Grid className={classes.sectionTitle} justifyContent='flex-start' container>
                  <Grid item xs={12} sm={12} md={3} lg={3} />
                  <Grid item xs={12} sm={6} md={3} lg={3}>
                    {changePassword ?
                      <h1 className={classes.subheading}>{t('changePassword')}</h1> :
                      <Button text='changePassword' color='secondary' disabled={isSubmitting} callback={() => setChangePassword(true)} type='submit' />}
                  </Grid>
                </Grid>
                <Grid container className={classes.selectRoleContainer}>
                  <Grid className={classes.sectionTitle} justifyContent='flex-start' container>
                    {changePassword &&
                    <Grid container justifyContent='flex-start' spacing={2}>
                      <Grid item md={3}/>
                      <Grid item xs={12} sm={12} md={6} lg={2}>
                        <PasswordInputField label='currentPassword' inputName='currentPassword' isRequired profileForm={true}/>
                      </Grid>
                      {isMedium && <Grid item md={1}/>}
                      {isMedium && <Grid item md={3}/>}
                      <Grid item xs={12} sm={12} md={6} lg={2}>
                        <PasswordInputField label='newPassword' inputName='newPassword' isRequired profileForm={true}/>
                      </Grid>
                      {isMedium && <Grid item md={1}/>}
                      {isMedium && <Grid item md={3}/>}
                      <Grid item xs={12} sm={12} md={6} lg={2}>
                        <PasswordInputField label='confirmNewPassword' inputName='confirmNewPassword' isRequired profileForm={true}/>
                      </Grid>
                    </Grid>}
                  </Grid>
                  <Grid container spacing={2} className={classes.userActions}>
                    <Grid item xs={4} sm={3} md={2}>
                      <Button type='reset' text='cancel' color='primary' callback={() => handleCancel()} />
                    </Grid>
                    <Grid item xs={4} sm={3} md={2}>
                      <Button text='save' disabled={isSubmitting || !dirty} type='submit' />
                    </Grid>
                  </Grid>
                </Grid>
              </Form>
            )}
          </Formik>
        </SadStates>
      </Container>
    </div>
  );
};

ProfileForm.propTypes = {
  match: PropTypes.object
};

