import axios from 'axios';
import { getApiCoreUrl } from 'helpers';
import qs from 'qs';
import { baseApiErrorHandler } from 'services';

const baseUrl = `${getApiCoreUrl()}/api/v1/Company`;

const getAll = (page = 0, rowsPerPage = 10, orderBy = '', filter = {}) => {
  const url = `${baseUrl}?${qs.stringify({
    offset: page * rowsPerPage,
    limit: rowsPerPage,
    orderBy: !orderBy || orderBy === '' ? 'asc' : orderBy,
    search: filter.search ? filter.search : '',
    status: filter.status === -1 ? null : filter.status
  })}`;

  return axios.get(url).then(response => {
    return response.data;
  }).catch(baseApiErrorHandler);
};

const getSelectItems = () => {
  const url = `${baseUrl}/select-items`;

  return axios.get(url).then(response => {
    return response.data;
  }).catch(baseApiErrorHandler);
};

const get = (id) => {
  const url = `${baseUrl}/${id}`;
  return axios.get(url).catch(baseApiErrorHandler);
};

const create = (details) => axios.post(baseUrl, details).catch(baseApiErrorHandler);

const update = (company, id) => {
  const url = `${baseUrl}/${id}`;
  return axios.put(url, company).catch(baseApiErrorHandler);
};

const remove = (id) => {
  const url = `${baseUrl}/${id}`;
  return axios.delete(url).catch(baseApiErrorHandler);
};

export const companyService = {
  getAll,
  getSelectItems,
  get,
  create,
  update,
  remove
};