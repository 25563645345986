import React, { useEffect, useState } from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { IconButton } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import CloseIcon from '@material-ui/icons/Close';

import { searchIcon } from 'resources';

import { useStyles } from './SearchFilter.css.js';

export const SearchFilter = ({placeholder, callback}) => {

  const classes = useStyles();
  const [ criteria, setCriteria ] = useState('');

  const delayedQuery = _.debounce(q => callback(q), 500);

  const onChange = e => {
    setCriteria(e.target.value);
  };

  const clearCriteria = () => {
    setCriteria('');
  };

  useEffect(() => {
    if (criteria.trim().length > 2) {
      delayedQuery(criteria.substring(0, 100));
    } else if (criteria.trim().length < 1) {
      delayedQuery('');
    }
  }, [criteria]);

  return (
    <div className={classes.container}>
      <Grid container>
        <Grid item>
          <img src={searchIcon} className={classes.icon} />
        </Grid>
        <Grid item className={classes.searchContainer}>
          <input onChange={onChange} className={classes.input} type='text' placeholder={placeholder} value={criteria}/>
        </Grid>
        { criteria &&
            <Grid item>
              <IconButton onClick={clearCriteria}>
                <CloseIcon className={classes.closeIcon}/>
              </IconButton>
            </Grid>
        }
      </Grid>
    </div>
  );
};

SearchFilter.propTypes = {
  callback: PropTypes.func,
  placeholder: PropTypes.string
};
