import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router-dom';
import { useQuery } from 'react-query';
import * as Yup from 'yup';
import Chip from '@material-ui/core/Chip';
import { Form, Formik, ErrorMessage, Field } from 'formik';
import {
  Container, FormControl, FormControlLabel, Select, MenuItem,
  Grid, Radio, RadioGroup, Accordion, AccordionSummary, AccordionDetails, Typography, Tooltip
} from '@material-ui/core';
import { CloseOutlined } from '@material-ui/icons';
import RadioButtonCheckedIcon from '@material-ui/icons/RadioButtonChecked';
import RadioButtonUncheckedIcon from '@material-ui/icons/RadioButtonUnchecked';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import {
  BrowseGifTemplateDialog, Button, DragAndDropInputField, InputField, NoteSmallInputField,
  Loader, SadStates, OfferSentDialog, toastNotification, ButtonWithTooltip
} from 'components';
import { leadService, offerService } from 'services';
import {
  conversionBase, checkFileFormat, JPG, JPEG, PNG, sendOfferVia, offerExpipation, optInSmsType,
  arrayOfExpirationTimer, formatNumberWithSeparator, arrayOfFonts, getChangeComunicationChannelError, getPaidFeaturesError
} from 'helpers';
import { FontStyleComponent, initFontStyle } from './FontStyleComponent';

import { useStyles } from './SendOfferPage.css.js';

export const SendOfferPage = props => {
  const { t } = useTranslation(['common', 'error']);
  const navHistory = useHistory();
  const { search } = useLocation();
  const [cancelSendOffer, setCancelSendOffer] = useState(false);
  const [leadId] = useState(Number(props.match.params.id));
  const [counterOfferId] = useState(Number(new URLSearchParams(search).get('counteroffer')));
  const [isBrowseOpen, setBrowseDialogOpen] = useState(false);
  const [isOfferSentOpen, setOfferSentDialogOpen] = useState(false);

  const { isLoading, data = {} } = useQuery(['viewForm'], () => leadService.getLeadById(leadId), { cacheTime: 0 });
  const { isLoading: isLoadingCouterOffer, data: dataCouterOffer } =
    useQuery(
      ['getCounterOffer'],
      () => offerService.getCounterOfferById(counterOfferId),
      { cacheTime: 0, enabled: counterOfferId > 0, });
  const [isSubmittingGlobal, setIsSubmittingGlobal] = useState(false);
  const [isSuccessfullySent, setIsSuccessfullySent] = useState(false);
  const [previewUploadedImage, setPreviewUploadedImage] = useState(null);
  const [previewUploadedGifTemplate, setPreviewUploadedGifTemplate] = useState(null);
  const [uploadedGifTemplate, setUploadedGifTemplate] = useState(null);
  const [selectedCardItem, setselectedCardItem] = useState(null);
  const [size, setSize] = useState(window.innerWidth);
  const [gifContainerWidth, setGifContainerWidth] = useState();
  const [gifContainerHeight, setGifContainerHeight] = useState();
  const gifContainerRef = useRef();
  const [gifWidth, setGifWidth] = useState();
  const [gifHeight, setGifHeight] = useState();
  const [newGifWidth, setNewGifWidth] = useState();
  const [newGifHeight, setNewGifHeight] = useState();
  const textContainerRef = useRef();
  const [leadFont, setLeadFont] = useState({ ...initFontStyle });
  const [offerFont, setOfferFont] = useState({ ...initFontStyle, size: 20 });
  const [featureError, setFeatureError] = useState('');
  const [changeComunicationChannelError, setChangeComunicationChannelError] = useState('');
  const [isLoaded, setIsLoaded] = useState(false);
  const [comunicationChannel, setComunicationChannel] = useState(0);

  const updateSize = () => setSize(window.innerWidth);

  useEffect(() => {
    window.onresize = updateSize;
  }, []);

  useEffect(() => {
    if (!isLoading && !isLoadingCouterOffer) {
      if (dataCouterOffer) {
        if (dataCouterOffer.leadFontStyle) {
          setLeadFont({ ...dataCouterOffer.leadFontStyle });
        }
        if (dataCouterOffer.offerFontStyle) {
          setOfferFont({ ...dataCouterOffer.offerFontStyle });
        }
        if (dataCouterOffer.gifTemplate) {
          setUploadedGifTemplate(dataCouterOffer.gifTemplate);
          setPreviewUploadedGifTemplate(dataCouterOffer.gifTemplate.url);
        }
        if (dataCouterOffer.imageUrl) {
          setPreviewUploadedImage(dataCouterOffer.imageUrl);
        }
      }
      const channel = getComunicationChannel();
      setComunicationChannel(channel);
      setChangeComunicationChannelError(
        getChangeComunicationChannelError(data.user.company, -1, data?.optInSms === optInSmsType.Agreed)
      );
      const paidFeatureError = getPaidFeaturesError(data.user.company, -1);
      if (paidFeatureError === '') {
        if (channel === 1 && data?.optInSms !== optInSmsType.Agreed && !data.user.company.optInDisabled) {
          setFeatureError('The lead didn’t consent to receive SMS messages and offers.');
        }
      } else {
        setFeatureError(paidFeatureError);
      }
      setIsLoaded(true);
    }
  }, [isLoading, isLoadingCouterOffer]);

  useEffect(() => {
    getMeta();
    getGifContainerSize();
  }, [previewUploadedGifTemplate, size]);

  useEffect(() => {
    const gifRatio = gifWidth / gifHeight;
    const gifContainerRatio = gifContainerWidth / gifContainerHeight;

    if (gifContainerRatio >= gifRatio) {
      setNewGifHeight(gifContainerHeight);
      setNewGifWidth(gifContainerHeight * gifRatio);
    } else {
      setNewGifWidth(gifContainerWidth);
      setNewGifHeight(gifContainerWidth / gifRatio);
    }
    // getSizeTextTop();
    // getSizeTextBottom();
    // textContainerSize();
  }, [gifWidth, gifHeight, size]);

  const getMeta = () => {
    const image = new Image();
    image.src = previewUploadedGifTemplate;
    image.onload = function () {
      setGifHeight(this.height);
      setGifWidth(this.width);
    };
  };

  const getGifContainerSize = () => {
    setGifContainerWidth(gifContainerRef.current?.offsetWidth);
    setGifContainerHeight(gifContainerRef.current?.offsetHeight);
  };

  const styleProps = {
    previewUploadedGifTemplate: previewUploadedGifTemplate
  };
  const classes = useStyles(styleProps);

  const handleSubmit = async (values, { setSubmitting }) => {
    setIsSubmittingGlobal(true);
    if (cancelSendOffer) {
      return;
    }

    const formDetails = new FormData();
    const details = { ...values };
    Object.keys(details).forEach(key => formDetails.append(key, details[key]));

    formDetails.append('gifTemplateId', uploadedGifTemplate.id);
    formDetails.append('leadId', leadId);

    if (values.rbIsUploadImage) {
      delete values.imageUrl;
      if (values.uploadedImage !== null) {
        formDetails.append('imageFile', values.uploadedImage);
      }
    } else {
      delete values.uploadedImage;
    }

    if (values.sendOfferVia === 1) {
      formDetails.append('sendMms', true);
      formDetails.append('sendEmail', false);
    } else if (values.sendOfferVia === 2) {
      formDetails.append('sendMms', false);
      formDetails.append('sendEmail', true);
    }
    formDetails.delete('sendOfferVia', '');

    if (values.offerExpipation === 0) {
      formDetails.delete('expiresIn');
    }

    formDetails.append('leadTextColor', leadFont.color);
    formDetails.append('offerTextColor', offerFont.color);

    const ratio = gifWidth / gifContainerWidth;
    const leadFontModel = { ...leadFont, sizeRatio: leadFont.size * ratio };
    const offerFontModel = { ...offerFont, sizeRatio: offerFont.size * ratio };
    //console.log(gifContainerWidth, gifWidth, ratio);
    formDetails.append('leadFontStyleJson', JSON.stringify(leadFontModel));
    formDetails.append('offerFontStyleJson', JSON.stringify(offerFontModel));

    setOfferSentDialogOpen(true);

    try {
      await offerService.createOffer(formDetails);
      setIsSuccessfullySent(true);
    } catch (error) {
      toastNotification('error', t(`error:${Object.values(error.response.data.errors)[0]}`));
      setIsSuccessfullySent(false);
    }

    setSubmitting(false);
    setIsSubmittingGlobal(false);
  };

  const validationSchema = Yup.object().shape({
    uploadedGifTemplate: Yup.number()
      .required(t('required')),
    rbIsUploadImage: Yup.boolean(),
    uploadedImage: Yup.mixed()
      .nullable()
      .test('test_is_uploaded_file_image_file', null, value => {
        if (value && !checkFileFormat(value.name, [JPG, JPEG, PNG])) {
          return new Yup.ValidationError(t('mustBeAnImageFile'), null, 'uploadedImage');
        }
        return true;
      })
      .test('is-correct-file-size', null, value => {
        if (Yup.ref('rbIsUploadImage') && value && value?.size / conversionBase / conversionBase > 4) {
          return new Yup.ValidationError(t('fileTooLarge'), null, 'uploadedImage');
        }
        return true;
      }),
    imageUrl: Yup.string()
      .nullable()
      .test('test_is_url_file_image_file', null, value => {
        if (Yup.ref('rbIsUploadImage') && value && !checkFileFormat(value, [JPG, JPEG, PNG])) {
          return new Yup.ValidationError(t('urlMustBeAnImageFile'), null, 'imageUrl');
        }
        return true;
      }),
    leadInfo: Yup.string()
      .max(250, t('leadInfoOfferValidation')),
    leadOffer: Yup.string()
      .max(250, t('leadOfferValidation')),
    internalNotes: Yup.string()
      .nullable()
      .max(1000, t('internalNotesValidation')),
    disclaimer: Yup.string()
      .nullable()
      .max(500, t('disclaimerValidation')),
    sendEmail: Yup.boolean(),
    sendMms: Yup.boolean(),
  });

  const handleCancel = () => {
    setCancelSendOffer(true);
    toastNotification('info', t('canceled'));
    navHistory.goBack();
  };

  const browseGifDialog = () => {
    setBrowseDialogOpen(true);
  };

  const gifCaption = previewUploadedGifTemplate && previewUploadedImage ? t('gifPreview') : t('pleaseSelectTemplate');
  const rowGapStyle = previewUploadedGifTemplate ? classes.rowGap : '';

  const getLeadOfferText = (offerData) => {
    let leadOfferText = '';
    const isTradeIn = (typeof offerData.tradeInValue === 'number');// ? data.price - data.tradeInValue : data.price;
    switch (offerData.paymentType) {
      case 1:
        leadOfferText = t('leadOfferTextLease', {
          downPayment: formatNumberWithSeparator(offerData.downPayment, ','),
          monthlyPayment: formatNumberWithSeparator(offerData.monthlyPayment, ','),
          term: offerData.term,
          milesPerYear: formatNumberWithSeparator(offerData.milesPerYear, ',')
        });
        break;
      case 2:
        leadOfferText = t(isTradeIn ? 'leadOfferTextFinanceTradeIn' : 'leadOfferTextFinance', {
          downPayment: formatNumberWithSeparator(offerData.downPayment, ','),
          payment: formatNumberWithSeparator(offerData.payment, ','),
          term: offerData.term,
          actualPrice: formatNumberWithSeparator(isTradeIn ? offerData.price - offerData.tradeInValue : offerData.price, ','),
          tradeInValue: formatNumberWithSeparator(offerData.tradeInValue, ','),
          price: formatNumberWithSeparator(offerData.price, ','),
          rate: formatNumberWithSeparator(offerData.rate, ','),
        });
        break;
      case 3:
        leadOfferText = t(isTradeIn ? 'leadOfferTextCashTradeIn' : 'leadOfferTextCash', {
          actualPrice: formatNumberWithSeparator(isTradeIn ? offerData.price - offerData.tradeInValue : offerData.price, ','),
          tradeInValue: formatNumberWithSeparator(offerData.tradeInValue, ','),
          price: formatNumberWithSeparator(offerData.price, ',')
        });
        break;
    }

    return leadOfferText;
  };

  const getFontStyle = (value) => {
    const font = arrayOfFonts.find(x => x.value === value);
    if (font) {
      return font.style;
    }
    return {};
  };

  const getTextStyle = (font) => {
    return {
      ...getFontStyle(font.family),
      color: font.color,
      fontSize: font.size + 'px',
      fontWeight: font.isBold ? 'bold' : '400',
      fontStyle: font.isItalic ? 'italic' : 'normal',
      textDecoration: font.isUnderline ? 'underline' : 'none'
    };
  };

  const getStylePxString = (num) => {
    if (num > 0) {
      return `${num}px`;
    }
    return '';
  };

  const getComunicationChannel = () => {
    let channel = data.defaultChannel + 1;
    if (!data.user.company.paidSms && !data.user.company.paidEmail) {
      channel = 0;
    }
    if (data.defaultChannel === 0 && !data.user.company.paidSms) {
      channel = 2;
    }
    if (data.defaultChannel === 1 && !data.user.company.paidEmail) {
      channel = 1;
    }
    if (channel === 1 && data.optInSms !== optInSmsType.Agreed && !data.user.company.optInDisabled && data.user.company.paidEmail) {
      channel = 2;
    }
    return channel;
  };

  const HandleSelectSendOfferType = (prop) => {
    return (
      <FormControl component='fieldset'>
        <RadioGroup aria-label='position' row className={classes.radio} {...prop}>
          {sendOfferVia.map((type, index) => {
            return (
              <FormControlLabel
                className={classes.radioLabel}
                key={index}
                disabled={prop.disabled}
                value={type.value}
                control={
                  <Radio
                    checkedIcon={<RadioButtonCheckedIcon className={classes.iconStyle} />}
                    icon={<RadioButtonUncheckedIcon className={classes.uncheckedIconStyle} />} />
                }
                label={t(type.label)} />
            );
          })}
        </RadioGroup>
      </FormControl>
    );
  };

  const HandleSelectExpirationType = (prop) => {
    return (
      <FormControl component='fieldset'>
        <RadioGroup aria-label='position' row className={classes.radio} {...prop}>
          {offerExpipation.map((type, index) => {
            return (
              <FormControlLabel
                // eslint-disable-next-line no-negated-condition
                className={!index ? classes.radioLabel : ''}
                key={index}
                value={type.value}
                control={
                  <Radio
                    checkedIcon={<RadioButtonCheckedIcon className={classes.iconStyle} />}
                    icon={<RadioButtonUncheckedIcon className={classes.uncheckedIconStyle} />} />
                }
                label={t(type.label)} />
            );
          })}
        </RadioGroup>
      </FormControl>
    );
  };

  return (
    <div className={classes.modal}>
      <Container className={classes.container}>
        <SadStates
          states={[
            {
              when: isLoading,
              render: <Loader />
            }
          ]}>
          {isLoaded &&
            <Formik
              initialValues={{
                uploadedGifTemplate: uploadedGifTemplate ? uploadedGifTemplate.id : '',
                rbIsUploadImage: !previewUploadedImage,
                uploadedImage: '',
                imageUrl: previewUploadedImage ?? '',
                leadInfo: data.year + ' ' + data.make + ' ' + data.model + ' ' + data.trim,
                leadOffer: getLeadOfferText(dataCouterOffer ? dataCouterOffer : data),
                internalNotes: data.internalNotes,
                disclaimer: data.disclaimer,
                sendOfferVia: comunicationChannel,
                offerExpipation: 0,
                expiresIn: 15
              }}
              validationSchema={validationSchema}
              onSubmit={handleSubmit}>
              {({ values, isSubmitting, setFieldValue, touched, setTouched }) => (
                <div>
                  <OfferSentDialog isOpen={isOfferSentOpen} setDialogOpen={setOfferSentDialogOpen} leadId={leadId} isSuccessfullySent={isSuccessfullySent} setIsSuccessfullySent={setIsSuccessfullySent} isSubmittingGlobal={isSubmittingGlobal} />
                  <BrowseGifTemplateDialog
                    isOpen={isBrowseOpen}
                    setDialogOpen={setBrowseDialogOpen}
                    callback={(item) => {
                      if (!item) {
                        toastNotification('warning', t('requiredToSelectTemplate'));
                        return;
                      }

                      setUploadedGifTemplate(item);
                      setPreviewUploadedGifTemplate(item.url);
                      setFieldValue('uploadedGifTemplate', item.id);
                    }}
                    selectedCardItem={selectedCardItem}
                    setselectedCardItem={setselectedCardItem} />
                  <Form className={classes.form}>
                    <Grid item xs={12} sm={10} md={10} className={classes.containerContent}>
                      <Grid container className={classes.spaceBetween} spacing={5}>
                        <Grid container className={classes.titleContainer}>
                          <Grid item>
                            <h1 className={classes.header}> {t('sendOffer')} </h1>
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid item>
                        <Grid container className={classes.spaceBetween} spacing={10}>
                          <Grid item xs={12} sm={12} md={6} lg={6} className={classes.containerContentForm}>
                            <Grid container className={clsx(classes.uploadGifContainer, rowGapStyle)}>
                              <Grid item xs={12} sm={12} md={12} lg={12} className={classes.browseGifContainer}>
                                <Grid container className={classes.spaceBetweenRow}>
                                  <Grid item xs={6} sm={4} md={7} lg={4} className={classes.textContainer}>
                                    <Grid className={classes.textContainer}>
                                      {t('chooseAGifTemplate')}
                                    </Grid>
                                  </Grid>
                                  <Grid item xs={4} sm={3} md={3} lg={3} className={classes.marginMiddle}>
                                    <Button text='browse' color='lightDefaultColor' type='button' callback={browseGifDialog} />
                                  </Grid>
                                  <Grid item xs={12} sm={4} md={12} lg={4} className={classes.dataContainer}>
                                    <Grid className={classes.autoWidth}>
                                      {uploadedGifTemplate &&
                                        <Chip className={classes.fileProperty}
                                          label={uploadedGifTemplate.title}
                                          deleteIcon={<CloseOutlined className={classes.xStyle} />}
                                          onDelete={() => {
                                            setPreviewUploadedGifTemplate('');
                                            setUploadedGifTemplate(null);
                                            setselectedCardItem(null);
                                            setFieldValue('uploadedGifTemplate', '');
                                          }}
                                          variant='outlined' />
                                      }
                                    </Grid>
                                  </Grid>
                                  <Grid className={classes.centerContent}>
                                    <ErrorMessage className={classes.validation} name='uploadedGifTemplate' component='div' />
                                  </Grid>
                                </Grid>
                              </Grid>
                              <Grid container item xs={12} sm={12} md={12} lg={12} className={classes.radioButtonContainer}>
                                <FormControl component='fieldset'>
                                  <RadioGroup aria-label='position' value={values.rbIsUploadImage} onChange={(e) => {
                                    setFieldValue('rbIsUploadImage', e.target.value === 'true');
                                    setPreviewUploadedImage(null);
                                    setFieldValue('uploadedImage', '');
                                    setFieldValue('imageUrl', '');
                                    setTouched({ ...touched, 'uploadedImage': false, 'imageUrl': false });
                                  }}>
                                    <Grid container className={clsx(classes.spaceBetweenCol, classes.marginBottom)}>
                                      <FormControlLabel
                                        key={1}
                                        value={true}
                                        control={
                                          <Radio
                                            checkedIcon={<RadioButtonCheckedIcon className={classes.iconStyle} />}
                                            icon={<RadioButtonUncheckedIcon className={classes.uncheckedIconStyle} />} />
                                        }
                                        label={t('uploadImage')} />
                                      <Grid container className={classes.radioButtonContainer}>
                                        <Grid className={classes.radioButtonContainer}>
                                          <DragAndDropInputField
                                            inputName='uploadedImage'
                                            type='file'
                                            accept='.jpg, .jpeg, .png'
                                            placeholder={t('dragAndDrop')}
                                            readOnly={!values.rbIsUploadImage}
                                            disabled={!values.rbIsUploadImage}
                                            uploadImage={false}
                                            onChange={(e) => {
                                              if (e.target.files[0]) {
                                                setPreviewUploadedImage(URL.createObjectURL(e.target.files[0]));
                                                setFieldValue('uploadedImage', e.target.files[0]);
                                              }
                                            }} />
                                          <Grid item xs={3} sm={3} md={3} lg={3}>
                                            <DragAndDropInputField
                                              inputName='uploadedImage'
                                              type='file'
                                              accept='.jpg, .jpeg, .png'
                                              placeholder={t('browse')}
                                              readOnly={!values.rbIsUploadImage}
                                              disabled={!values.rbIsUploadImage}
                                              toUploadOnPhone={true}
                                              onChange={(e) => {
                                                if (e.target.files[0]) {
                                                  setPreviewUploadedImage(URL.createObjectURL(e.target.files[0]));
                                                  setFieldValue('uploadedImage', e.target.files[0]);
                                                }
                                              }} />
                                          </Grid>
                                        </Grid>
                                      </Grid>
                                      <Grid container spacing={2} className={classes.spaceBetweenRow}>
                                        <Grid className={classes.marginTop}>
                                          <FormControlLabel
                                            key={0}
                                            value={false}
                                            control={
                                              <Radio
                                                checkedIcon={<RadioButtonCheckedIcon className={classes.iconStyle} />}
                                                icon={<RadioButtonUncheckedIcon className={classes.uncheckedIconStyle} />} />
                                            }
                                            label={t('orPasteImageURL')} />
                                        </Grid>
                                        <Grid className={classes.urlField}>
                                          <InputField
                                            inputName='imageUrl'
                                            type='text'
                                            readOnly={values.rbIsUploadImage}
                                            disabled={values.rbIsUploadImage}
                                            onChange={(e) => {
                                              setPreviewUploadedImage(e.target.value);
                                              setFieldValue('imageUrl', e.target.value);
                                            }} />
                                        </Grid>
                                      </Grid>
                                      <Grid item className={classes.dataContainer}>

                                        <Grid item className={classes.maxWidth}>
                                          <InputField label='leadInfo' type='text' inputName='leadInfo' />
                                        </Grid>
                                        <Accordion className={classes.accordionFormatting}>
                                          <AccordionSummary
                                            className={classes.accordionSummaryFormatting}
                                            expandIcon={<ExpandMoreIcon />}
                                            aria-controls='leadInfo-content'
                                            id='leadInfo-header'>
                                            <Typography className={classes.heading}>Lead Info Formatting</Typography>
                                          </AccordionSummary>
                                          <AccordionDetails>
                                            {leadFont && <FontStyleComponent fontStyle={leadFont}
                                              onChange={(evt) => {
                                                setLeadFont({ ...leadFont, ...evt });
                                              }} />}
                                          </AccordionDetails>
                                        </Accordion>

                                        <Grid item className={classes.maxWidth}>
                                          <InputField label='offer' type='text' inputName='leadOffer' />
                                        </Grid>
                                        <Accordion className={classes.accordionFormatting}>
                                          <AccordionSummary
                                            className={classes.accordionSummaryFormatting}
                                            expandIcon={<ExpandMoreIcon />}
                                            aria-controls='offer-content'
                                            id='offer-header'>
                                            <Typography className={classes.heading}>Offer Formatting</Typography>
                                          </AccordionSummary>
                                          <AccordionDetails>
                                            {offerFont && <FontStyleComponent fontStyle={offerFont}
                                              onChange={(evt) => {
                                                setOfferFont({ ...offerFont, ...evt });
                                              }} />}
                                          </AccordionDetails>
                                        </Accordion>

                                        <Grid className={classes.maxWidth}>
                                          <Grid item className={classes.smallNoteField}>
                                            <NoteSmallInputField label='internalNotes' inputName='internalNotes' type='text' />
                                          </Grid>
                                        </Grid>
                                        <Grid className={classes.maxWidth}>
                                          <Grid item className={classes.smallNoteField}>
                                            <NoteSmallInputField label='disclaimer' inputName='disclaimer' type='text' />
                                          </Grid>
                                        </Grid>

                                        <Tooltip title={changeComunicationChannelError} placement='top-start'>
                                          <Grid className={classes.maxWidth}>
                                            <Field
                                              disabled={!(data?.user.company && data.user.company.paidSms && data.user.company.paidEmail && (data.optInSms === optInSmsType.Agreed || data.user.company.optInDisabled))}
                                              className={classes.radioButtonContainer}
                                              as={HandleSelectSendOfferType}
                                              value={values.sendOfferVia}
                                              onChange={(e) => {
                                                setFieldValue('sendOfferVia', Number(e.target.value));
                                              }}
                                              name='sendOfferVia' />
                                          </Grid>
                                        </Tooltip>

                                        <Grid container justifyContent='flex-start' spacing={0}>
                                          <Grid item xs='auto'>
                                            <Field
                                              className={classes.radioButtonContainer}
                                              as={HandleSelectExpirationType}
                                              value={values.offerExpipation}
                                              onChange={(e) => {
                                                setFieldValue('offerExpipation', Number(e.target.value));
                                              }}
                                              name='offerExpipation' />
                                          </Grid>
                                          <Grid item xs={12} sm={12} md={3}>
                                            <FormControl className={classes.selectField}>
                                              <Select
                                                IconComponent={ArrowDropDownIcon}
                                                disableUnderline
                                                disabled={!values.offerExpipation}
                                                className={classes.selectFieldInput}
                                                value={values.expiresIn}
                                                onChange={(e) => {
                                                  setFieldValue('expiresIn', Number(e.target.value));
                                                }}>
                                                {arrayOfExpirationTimer.map(c => (
                                                  <MenuItem
                                                    key={c.value} value={c.value}>{t(c.label)}
                                                  </MenuItem>
                                                ))}
                                              </Select>
                                            </FormControl>
                                          </Grid>
                                        </Grid>
                                      </Grid>
                                    </Grid>
                                  </RadioGroup>
                                </FormControl>
                              </Grid>
                            </Grid>
                          </Grid>
                          <Grid item xs={11} sm={11} md={6} lg={6} className={classes.containerContentGif}>
                            <Grid container className={classes.gifPreviewContainer} ref={gifContainerRef}>
                              <Grid className={classes.gifPreview}>
                                <Grid style={{ width: newGifWidth, maxWidth: '100%', marginTop: 'auto', marginBottom: 'auto', height: getStylePxString(newGifHeight), maxHeight: getStylePxString(newGifHeight) }}
                                  className={classes.contentGifPreview}>
                                  <Grid item className={`${classes.giffPreviewSmallTop} ${classes.giffPreviewSmallLabel}`} ref={textContainerRef}>
                                    {(previewUploadedGifTemplate || previewUploadedImage) &&
                                      <div style={{ display: 'flex', maxHeight: getStylePxString(newGifHeight / 2) }}>
                                        <p style={getTextStyle(leadFont)}
                                          className={classes.gifTextTop}>
                                          {values.leadInfo}
                                        </p>
                                      </div>
                                    }
                                  </Grid>
                                  <Grid item className={classes.giffPreviewBigLabel}>
                                    {previewUploadedImage &&
                                      <img src={previewUploadedImage} alt={''} className={classes.imagePreview} />
                                    }
                                  </Grid>
                                  <Grid item className={`${classes.giffPreviewSmallBottom} ${classes.giffPreviewSmallLabel}`}>
                                    {(previewUploadedGifTemplate || previewUploadedImage) &&
                                      <div style={{ display: 'flex', maxHeight: getStylePxString(newGifHeight / 2) }}>
                                        <p style={getTextStyle(offerFont)}
                                          className={classes.gifTextBottom}>
                                          {values.leadOffer}
                                        </p>
                                      </div>
                                    }
                                  </Grid>
                                </Grid>
                              </Grid>
                            </Grid>
                            <Grid className={classes.gifPreviewCaption}> {gifCaption} </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid container spacing={2} className={classes.gifTemplateActions}>
                      <Grid item xs={4} sm={3} md={2}>
                        <Button type='reset' text='cancel' color='primary' callback={() => handleCancel()} />
                      </Grid>
                      <Grid item xs={4} sm={3} md={2}>
                        <ButtonWithTooltip text='send' color='defaultColor' type='submit'
                          disabled={isSubmitting || featureError !== ''}
                          tooltipTitle={featureError} tooltipPosition='bottom' />
                      </Grid>
                    </Grid>
                  </Form>
                </div>
              )}
            </Formik>}
        </SadStates>
      </Container>
    </div>
  );
};

SendOfferPage.propTypes = {
  match: PropTypes.object
};