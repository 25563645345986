import PropTypes from 'prop-types';
import { Grid } from '@material-ui/core';
import { SelectFilter } from 'components';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import { filterByDate, orderByEnum } from 'helpers';
import clsx from 'clsx';
import { useStyles } from './style.css';

export const SortByComponent = ({ label, sortByValue, orderByValue, onOrderChange, onSortByChange }) => {
  const classes = useStyles();

  return (
    <Grid container spacing={2} className={classes.component}>
      {label && <Grid item className={clsx(classes.label, classes.item)}>{label}</Grid>}
      <Grid item className={clsx(classes.select, classes.item)}>
        <SelectFilter onChange={onSortByChange} value={sortByValue} menuItem={filterByDate} />
      </Grid>
      <Grid item className={clsx(classes.arrows, classes.item)} onClick={onOrderChange}>
        {orderByValue === orderByEnum.desc ? <ArrowDownwardIcon /> : <ArrowUpwardIcon />}
      </Grid>
    </Grid>
  );
};

SortByComponent.propTypes = {
  sortByValue: PropTypes.number,
  orderByValue: PropTypes.string,
  label: PropTypes.string,
  onOrderChange: PropTypes.func,
  onSortByChange: PropTypes.func
};