import React from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@material-ui/core';
import { SwitchItemButton } from './SwitchItemButton';
import { allOrMine } from 'helpers';
import { meIcon, allIcon } from 'resources';

import { useStyles } from './SwitchButton.css.js';

export const SwitchButton = ({ myLeads, setMyLeads, setAllOrMineValue }) => {
  const classes = useStyles();
  return (
    <Grid container spacing={2} className={classes.container}>
      <SwitchItemButton
        label='me'
        icon={meIcon}
        checked={myLeads}
        setChecked={() => {
          setMyLeads(!myLeads);
          setAllOrMineValue(allOrMine.find(item => 'mine' === item.label).value);
        }} />
      <SwitchItemButton
        label='all'
        icon={allIcon}
        checked={!myLeads}
        setChecked={() => {
          setMyLeads(!myLeads);
          setAllOrMineValue(allOrMine.find(item => 'all' === item.label).value);
        }} />
    </Grid>
  );
};

SwitchButton.propTypes = {
  myLeads: PropTypes.bool,
  setMyLeads: PropTypes.func,
  setAllOrMineValue: PropTypes.func
};