import React, { useState } from 'react';
import clsx from 'clsx';
import { ErrorMessage } from 'formik';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import Grid from '@material-ui/core/Grid';
import Autocomplete from '@material-ui/lab/Autocomplete';
import './InputACField.css';

import { useStyles } from './InputField.css.js';

export const InputACField = ({ inputName, options, value, placeholder, label, isRequired, readOnly, disabled, onChange, className }) => {

  const classes = useStyles();
  const [t] = useTranslation('common');
  const labelT = t(label ?? '') === '' ? label : t(label);
  const [inputValue, setInputValue] = useState(value ?? '');

  return (
    <div className={className}>
      <Grid item className={classes.labelContainer}>
        <span className={classes.label}>{labelT}</span>
        {isRequired && <span className={classes.isRequired}>*</span>}
      </Grid>
      <div className={classes.container}>
        <Grid container>
          <Grid item className={classes.inputContainer}>
            <Autocomplete freeSolo
              options={options.filter((x) => {
                const lowerCaseValue = inputValue.toLowerCase();
                if (inputValue.length > 0) {
                  return x.toLowerCase().startsWith(lowerCaseValue);
                }
                return null;
              })}
              value={inputValue}
              inputValue={inputValue}
              onInputChange={(e, newValue) => {
                onChange(newValue);
                setInputValue(newValue);
              }}
              renderInput={(params) => (
                <div ref={params.InputProps.ref}>
                  <input
                    {...params.inputProps}
                    className={clsx(classes.input, { [classes.readOnlyInput]: readOnly })}
                    type={'text'}
                    name={inputName}
                    placeholder={t(placeholder)}
                    readOnly={readOnly}
                    disabled={disabled} />
                </div>)
              } />
            <ErrorMessage className={classes.validation} name={inputName} component='div' />
          </Grid>
        </Grid>
      </div>
    </div>
  );
};

InputACField.propTypes = {
  inputName: PropTypes.string,
  options: PropTypes.array,
  value: PropTypes.string,
  placeholder: PropTypes.string,
  label: PropTypes.string,
  isRequired: PropTypes.bool,
  readOnly: PropTypes.bool,
  disabled: PropTypes.bool,
  onChange: PropTypes.func,
  className: PropTypes.string,
};
