import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { Grid } from '@material-ui/core';

import { Button, CustomTable as Table, Header, SearchFilter, SelectFilter, Wrapper } from 'components';
import { formatDateForTable, companyStatuses } from 'helpers';
import { filterIcon } from 'resources';
import { companyService } from 'services';

import { useStyles } from './style.css.js';

export const CompanyListPage = () => {
  const classes = useStyles();
  const [t] = useTranslation('common');
  const navHistory = useHistory();
  const [search, setSearch] = useState('');
  const [companyStatus, setCompanyStatus] = useState(-1);
  const [total, setTotal] = useState();

  const callback = (query) => setSearch(query);

  const statusView = company => {
    return <span className={classes.status}>{t(company.status === 0 ? 'Inactive' : 'Active')}</span>;
  };

  const companyNameView = company => {
    return (
      <p className={classes.companyName} onClick={() => navHistory.push(`/companies/${company.id}`)}>
        {company.name}
      </p>
    );
  };

  const columnConfiguration = useMemo(() => [
    { id: 'id', label: t('id'), disableSorting: false, format: company => company.id },
    { id: 'name', label: t('companyName'), disableSorting: false, format: company => companyNameView(company) },
    { id: 'status', label: t('status'), disableSorting: false, format: company => statusView(company) },
    { id: 'phone', label: t('companyPhone'), disableSorting: true, format: company => company.phone },
    {
      id: 'dateCreated', label: t('dateCreated'), format: (company) => {
        return formatDateForTable(company.createdAt, classes.tableCell);
      }
    },
    {
      id: 'lastModified', label: t('lastModified'), format: (company) => {
        return formatDateForTable(company.modifiedAt, classes.tableCell);
      }
    }
  ], []);

  const handleChangeStatus = (e) => {
    setCompanyStatus(e.target.value);
  };

  const filters = { status: companyStatus, search };

  return (
    <Wrapper>
      <div className={classes.container}>
        <Grid container className={classes.spaceBetween} spacing={2}>
          <Grid item className={classes.paddingTop}>
            <Header title={t('companies')} />
          </Grid>
          <Grid item className={classes.paddingTop}>
            <Button text='addNewCompany' callback={() => navHistory.push('/companies/create')} />
          </Grid>
        </Grid>
        <Grid container className={classes.alignItems}>
          <Grid className={classes.marginRight} item md={1}>
            <img className={classes.filterIcon} src={filterIcon} />
            <p className={classes.filterText}>{t('filters')}</p>
          </Grid>
          <Grid className={classes.paddingRight} item md={2} sm={3} xs={10}>
            <SelectFilter label={t('allStatus')} onChange={handleChangeStatus} value={companyStatus} menuItem={companyStatuses} />
          </Grid>
          <Grid className={classes.paddingRight} item md={3} sm={5} xs={10}>
            <SearchFilter placeholder={t('typeHereToSearch')} callback={callback} />
          </Grid>
        </Grid>
        {total !== 0 &&
          <Grid container>
            <Grid item className={classes.total}>
              <p className={classes.marginRight}>{t('total')}:</p>
              <p>{total}</p>
            </Grid>
          </Grid>}
        <Table tableId='companiesPage' columnConfiguration={columnConfiguration} source={companyService.getAll} setTotal={value => setTotal(value)} filter={filters} />
      </div>
    </Wrapper>
  );
};