import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { FormControl, MenuItem, Grid } from '@material-ui/core';
import Menu from '@material-ui/core/Menu';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';

import { useStyles } from './SelectMenu.css.js';

export const SelectMenu = ({ label, menuItem, onChange, modelMenuItem, saleRepMenuItem}) => {
  const classes = useStyles();
  const [t] = useTranslation('common');
  const [ anchorEl, setAnchorEl ] = useState(null);
  const isOpen = Boolean(anchorEl);

  const handleValue = value => {
    onChange(value);
    setAnchorEl(null);
  };

  return (
    <FormControl className={classes.container}>
      <Grid container className={classes.childContainer}>
        <Grid item className={classes.inputContainer}>
          <Grid container className={classes.input} onClick={e => setAnchorEl(e.currentTarget)} aria-describedby='simple-popover' variant='contained'>
            <Grid item className={classes.width80}>
              <p className={classes.margin}>{label}</p>
            </Grid>
            <Grid item className={classes.width10}>
              {isOpen ? <ArrowDropUpIcon classes={{root: classes.selectIcon}}/> : <ArrowDropDownIcon classes={{root: classes.selectIcon}}/>}
            </Grid>
          </Grid>
          <Menu anchorEl={anchorEl} classes={{paper: classes.paper}} keepMounted open={isOpen} onClose={() => setAnchorEl(null)} className={classes.menu}
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'left',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'left',
            }}>
            {!modelMenuItem && !saleRepMenuItem &&
            menuItem?.map(item => (
              <MenuItem
                classes={{root: classes.selectMenu}}
                onClick={() => handleValue(item.value)}
                key={item.value}
                value={item.value}>
                {t(item.label)}
              </MenuItem>
            ))}
            {modelMenuItem &&
            menuItem?.map(item => (
              <MenuItem
                classes={{root: classes.selectMenuWide}}
                onClick={() => handleValue(item)}
                key={item}
                value={item}>
                {item}
              </MenuItem>
            ))}
            {saleRepMenuItem &&
            menuItem?.map(item => (
              <MenuItem
                classes={{root: classes.selectMenuWide}}
                onClick={() => handleValue(item.id)}
                key={item.id}
                value={item.id}>
                {item.nameSurname}
              </MenuItem>
            ))}
          </Menu>
        </Grid>
      </Grid>
    </FormControl>
  );
};

SelectMenu.propTypes = {
  label: PropTypes.string,
  menuItem: PropTypes.array,
  onChange: PropTypes.func,
  modelMenuItem: PropTypes.bool,
  saleRepMenuItem: PropTypes.bool
};
