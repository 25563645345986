import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import {
  Dialog, DialogActions, Grid,
  DialogTitle, useMediaQuery, IconButton, useTheme
} from '@material-ui/core';
import Close from '@material-ui/icons/Close';

import { Button, toastNotification } from 'components';
import { GifTemplatesPage } from 'pages';
import { conversionBase } from 'helpers';

import { useStyles } from './BrowseGifTemplateDialog.css.js';

export const BrowseGifTemplateDialog = ({ isOpen, setDialogOpen, callback, selectedCardItem, setselectedCardItem }) => {

  const classes = useStyles();
  const theme = useTheme();
  const [t] = useTranslation('common');
  const [e] = useTranslation('error');

  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

  const maxUrlSizeB = 4 * conversionBase * conversionBase;
  const handleConfirm = () => {
    if (selectedCardItem) {
      if (selectedCardItem.size <= maxUrlSizeB) {
        callback(selectedCardItem);
        setDialogOpen(false);
      } else {
        toastNotification('error', e('GifTemplpateOutOfSize'));
      }
    } else {
      toastNotification('error', e('GifTemplpateIsEmpty'));
    }
  };

  return (
    <Dialog
      className={classes.container}
      classes={{ paper: classes.dialogPaper }}
      fullWidth={true}
      maxWidth='md'
      fullScreen={fullScreen}
      open={isOpen}
      aria-labelledby='responsive-dialog-title'>
      <DialogTitle id='responsive-dialog-title'>
        <IconButton
          disableRipple={true}
          className={classes.closeImg}
          onClick={() => setDialogOpen(false)}>
          <Close />
        </IconButton>
      </DialogTitle>
      <Grid className={classes.contentContainer}>
        <Grid container spacing={2}>
          <Grid item md={12}>
            <GifTemplatesPage
              selectedCardItem={selectedCardItem}
              setselectedCardItem={setselectedCardItem}
              onDoubleClick={handleConfirm} />
          </Grid>
        </Grid>
        <DialogActions>
          <Grid container className={classes.buttonContainer}>
            <Grid item md={2}>
              <Button text={t('applySelected')} callback={handleConfirm} autoFocus />
            </Grid>
          </Grid>
        </DialogActions>
      </Grid>
    </Dialog>
  );
};

BrowseGifTemplateDialog.propTypes = {
  isOpen: PropTypes.bool,
  setDialogOpen: PropTypes.func,
  callback: PropTypes.func,
  id: PropTypes.any,
  selectedCardItem: PropTypes.object,
  setselectedCardItem: PropTypes.func
};