import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';

import { useStyles } from './Wrapper.css.js';

export const Wrapper = ({ children, browseMode }) => {

  const classes = useStyles();
  const browseModeHeightStyle = browseMode ? classes.browseModeHeight : '';

  return (
    <div className={clsx(classes.wrapper, browseModeHeightStyle)}>
      {children}
    </div>
  );
};

Wrapper.propTypes = {
  children: PropTypes.any,
  browseMode: PropTypes.bool
};