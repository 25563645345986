import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { useStyles } from './Button.css.js';

export const Button = ({text, disabled, type, callback, color = 'defaultColor', smallButton = false, showMoreButton = false, onClick}) => {

  const classes = useStyles();
  const [t] = useTranslation('common');

  const smallButtonStyle = smallButton ? classes.smallContainer : '';
  const showMoreButtonStyle = showMoreButton ? classes.showMoreButtonContainer : '';

  return (
    <button
      type={type}
      disabled={disabled}
      className={`${classes.container} ${classes[color]} ${disabled && classes.disabled} ${smallButtonStyle} ${showMoreButtonStyle}`}
      onClick={() => {
        if (callback) {
          callback();
        }
        if (onClick) {
          onClick();
        }
      }}>
      {t(text)}
    </button>
  );
};

Button.propTypes = {
  text: PropTypes.string,
  type: PropTypes.string,
  disabled: PropTypes.bool,
  callback: PropTypes.func,
  color: PropTypes.string,
  smallButton: PropTypes.bool,
  showMoreButton: PropTypes.bool,
  onClick: PropTypes.func
};