import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(theme => ({
  container: {
    backgroundColor: theme.quinaryBackgroundColor,
    margin: '0.3rem',
    padding: '0.5rem',
    width: '100%'
  },
  label: {
    margin: 0,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    fontWeight: 'bold'
  },
  text: {
    margin: 0,
    overflow: 'hidden',
    textOverflow: 'ellipsis'
  },
  icon: {
    marginRight: '0.5rem'
  },
  optInBtn: {
    display: 'inline-block',
    float: 'right',
    padding: '3px 6px',
    fontWeight: '500',
    fontSize: '.8rem',
    textAlign: 'center',
    cursor: 'pointer',
    outline: 'none',
    borderRadius: '0.5rem',
    backgroundColor: theme.quaternaryBackgroundColor,
    color: theme.tertiaryBackgroundColor,
    border: `1px solid ${theme.primaryBackgroundColor}`
  },
  optInBtnDisabled: {
    cursor: 'default',
    border: `1px solid ${theme.quaternaryBackgroundColor}`
  }
}));