import React, { useState, useEffect } from 'react';
import { Form, Formik, Field } from 'formik';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import { useHistory } from 'react-router-dom';
import * as Yup from 'yup';
import {
  Container, FormControl, FormControlLabel, Grid, Radio,
  RadioGroup, useMediaQuery, useTheme
} from '@material-ui/core';
import RadioButtonCheckedIcon from '@material-ui/icons/RadioButtonChecked';
import RadioButtonUncheckedIcon from '@material-ui/icons/RadioButtonUnchecked';

import {
  Button, DeleteDialog, InputField, InputACField, CheckboxInputField,
  FormSelectFilter, NoteInputField, toastNotification, SadStates, Loader, LockIcon
} from 'components';
import { phoneRegExp, setAmericanPhoneCode, paymentTypes, arrayOfYears, arrayOfTerms, milesPerYearArray, formatNumber, communicationChannels } from 'helpers';
import { leadService } from 'services';

import { useStyles } from './LeadsForm.css.js';

const REFETCH_INTERVAL_MS = 10000;

export const LeadsForm = (props) => {
  const classes = useStyles();
  const { t } = useTranslation(['common', 'error']);
  const navHistory = useHistory();
  const [cancelUpdate, setCancelUpdate] = useState(false);
  const [paymentType, setPaymentType] = useState(1);
  const [isOpen, setDialogOpen] = useState(false);
  const [leadId] = useState(props.match.params.id);
  const [editMode] = useState(props.match.params.id !== undefined);
  const { isLoading, data } = useQuery(
    ['leadForm'], () => leadService.getLeadById(leadId)
    , { enabled: editMode, cacheTime: 0, refetchInterval: REFETCH_INTERVAL_MS });
  const { isLoading: isLoadingAC, data: dataAC = [] } = useQuery(
    ['leadFormAC'], () => leadService.getAutocompleteData()
    , { enabled: true, cacheTime: 0 });
  const [isSubmittingGlobal, setSubmittingGlobal] = useState(false);
  const [term, setTerm] = useState(24);
  const [milesPerYear, setMilesPerYear] = useState(5000);
  const [isTradeIn, setIsTradeIn] = useState(false);
  const [priceValue, setPriceValue] = useState();

  const isLease = paymentType === paymentTypes[0].value;
  const isFinance = paymentType === paymentTypes[1].value;
  const isCash = paymentType === paymentTypes[2].value;

  const theme = useTheme();
  const isExtraSmaller = useMediaQuery(theme.breakpoints.down('xs'));

  useEffect(() => {
    if (data) {
      setIsTradeIn(typeof data.tradeInValue === 'number');
      setPriceValue(data.price);
      setMilesPerYear(data.milesPerYear ?? milesPerYear);
      setTerm(data.term ?? term);
      setPaymentType(data.paymentType);
    }
  }, [data]);

  const handleSubmit = async (values, { setSubmitting }) => {
    setSubmittingGlobal(true);
    if (cancelUpdate) {
      return;
    }

    values.milesPerYear = milesPerYear;
    values.paymentType = paymentType;
    values.term = term;
    if (isLease) {
      delete values.price;
      delete values.payment;
      delete values.rate;
    } else if (isFinance) {
      delete values.monthlyPayment;
      delete values.milesPerYear;
    } else if (isCash) {
      delete values.downPayment;
      delete values.monthlyPayment;
      delete values.term;
      delete values.milesPerYear;
      delete values.payment;
      delete values.rate;
    }

    if (isTradeIn && !isLease) {
      if (values.tradeInYear === 0) {
        values.tradeInYear = null;
      }
      if (values.tradeInMileage === '') {
        values.tradeInMileage = null;
      }
      if (values.tradeInPayoff === '') {
        values.tradeInPayoff = null;
      }
    } else {
      values.tradeInValue = null;
      values.tradeInMake = null;
      values.tradeInModel = null;
      values.tradeInYear = null;
      values.tradeInMileage = null;
      values.tradeInVin = null;
      values.tradeInPayoff = null;
    }

    values.phone = setAmericanPhoneCode(values.phone);
    const details = editMode ? { ...data, ...values } : { ...values };

    try {
      if (editMode) {
        await leadService.updateLead(details, leadId);
        toastNotification('info', t('leadIsUpdated'));
        navHistory.goBack();
      } else {
        const createdLead = await leadService.createLead(details);
        toastNotification('info', t('newLead'));
        navHistory.push(`/leads/view/${createdLead.data.id}`);
      }
      setSubmitting(false);
      setSubmittingGlobal(false);
    } catch (error) {
      toastNotification('error', t(`error:${Object.values(error.response.data.errors)[0]}`));
    }
  };

  const validationSchema = Yup.object().shape({
    name: Yup.string()
      .max(100, t('nameValidation'))
      .required(t('required')),
    surname: Yup.string()
      .max(100, t('surnameValidation'))
      .required(t('required')),
    phone: Yup.string()
      .max(50, t('phoneValidation'))
      .matches(phoneRegExp, t('invalidPhoneFormat'))
      .required(t('required')),
    email: Yup.string()
      .max(300, t('emailValidation'))
      .email(t('invalidEmail'))
      .required(t('required')),
    year: Yup.string()
      .required(t('required')),
    make: Yup.string()
      .max(100, t('makeValidation'))
      .required(t('required')),
    model: Yup.string()
      .max(100, t('modelValidation'))
      .required(t('required')),
    trim: Yup.string()
      .max(100, t('trimValidation'))
      .required(t('required')),
    disclaimer: Yup.string()
      .nullable()
      .max(500, t('disclaimerValidation')),
    internalNotes: Yup.string()
      .nullable()
      .max(1000, t('internalNotesValidation')),
    downPayment: Yup.number()
      .nullable()
      .when('paymentType', () => {
        if (!isCash) {
          return (
            Yup.number()
              .required(t('required'))
              .min(0, t('downPaymentMinValidation'))
              .max(1000000000, t('downPaymentMaxValidation'))
              .typeError(t('pleaseEnterNumber'))
          );
        }
      }),
    monthlyPayment: Yup.number()
      .nullable()
      .when('paymentType', () => {
        if (isLease) {
          return (
            Yup.number()
              .required(t('required'))
              .min(0, t('monthlyPaymentMinValidation'))
              .max(1000000000, t('monthlyPaymentMaxValidation'))
              .typeError(t('pleaseEnterNumber'))
          );
        }
      }),
    // milesPerYear: Yup.number()
    //   .nullable()
    //   .when('paymentType', () => {
    //     if (isLease) {
    //       return (
    //         Yup.number()
    //           .required(t('required'))
    //       );
    //     }
    //   }),
    // term: Yup.number()
    //   .nullable()
    //   .when('paymentType', () => {
    //     if (!isCash) {
    //       return (
    //         Yup.number()
    //           .min(0, t('termMinValidation'))
    //           .max(1000000000, t('termMaxValidation'))
    //           .typeError(t('pleaseEnterNumber'))
    //           .required(t('required'))
    //       );
    //     }
    //   }),
    payment: Yup.number()
      .nullable()
      .when('paymentType', () => {
        if (isFinance) {
          return (
            Yup.number()
              .typeError(t('pleaseEnterNumber'))
              .min(0, t('paymentMinValidation'))
              .max(1000000000, t('paymentMaxValidation')))
            .required(t('required'));
        }
      }),
    rate: Yup.number()
      .nullable()
      .when('paymentType', () => {
        if (isFinance) {
          return (
            Yup.number()
              .typeError(t('pleaseEnterNumber'))
              .min(0, t('percentMinValidation'))
              .max(100, t('percentMaxValidation')))
            .required(t('required'));
        }
      }),
    price: Yup.number()
      .nullable()
      .when('paymentType', () => {
        if (!isLease) {
          return (
            Yup.number()
              .typeError(t('pleaseEnterNumber'))
              .min(0, t('priceMinValidation'))
              .max(1000000000, t('priceMaxValidation'))
              .required(t('required'))
          );
        }
      }),
    tradeInValue: Yup.number()
      .nullable()
      .when('tradeIn', () => {
        if (isTradeIn) {
          return (
            Yup.number()
              .typeError(t('pleaseEnterNumber'))
              .min(0, t('valueMinValidation'))
              .max(priceValue, t('tradeInValuePriceValidation'))
              .required(t('required'))
          );
        }
      }),
    tradeInMake: Yup.string()
      .nullable()
      .max(100, t('makeValidation')),
    tradeInModel: Yup.string()
      .nullable()
      .max(100, t('modelValidation')),
    tradeInMileage: Yup.number()
      .nullable()
      .typeError(t('pleaseEnterNumber'))
      .min(0, t('mileageMinValidation'))
      .max(1000000000, t('mileageMaxValidation')),
    tradeInVin: Yup.string()
      .nullable()
      .max(20, t('vinValidation')),
    tradeInPayoff: Yup.number()
      .nullable()
      .typeError(t('pleaseEnterNumber'))
      .min(0, t('payoffMaxValidation'))
      .max(1000000000, t('payoffMaxValidation')),

  });

  const handleCancel = () => {
    setCancelUpdate(true);
    navHistory.goBack();
  };

  const handleDelete = async () => {
    setCancelUpdate(true);
    try {
      await leadService.deleteLead(leadId);
      toastNotification('info', t('leadIsDeleted'));
      navHistory.push('/leads');
    } catch (error) {
      toastNotification('error', t(`error:${Object.values(error.response.data.errors)[0]}`));
    }
  };

  const deleteUserDialog = () => {
    setDialogOpen(true);
  };

  const getACOptions = (fieldName) => {
    return dataAC.filter((x) => x.fieldName === fieldName).map((x) => x.title);
  };

  const HandleSelectPaymentType = (prop) => {
    return (
      <FormControl component='fieldset'>
        <RadioGroup aria-label='position' row className={classes.radio} {...prop}>
          {paymentTypes.map((type, index) => {
            return (
              <FormControlLabel
                key={index}
                value={type.value}
                control={
                  <Radio
                    checkedIcon={<RadioButtonCheckedIcon className={classes.iconStyle} />}
                    icon={<RadioButtonUncheckedIcon className={classes.uncheckedIconStyle} />} />
                }
                label={t(type.label)} />
            );
          })}
        </RadioGroup>
      </FormControl>
    );
  };

  const HandleSelectDefaultChannel = (prop) => {
    return (
      <FormControl component='fieldset'>
        <RadioGroup aria-label='position' row className={classes.radio} {...prop}>
          {communicationChannels.map((type, index) => {
            return (
              <FormControlLabel
                key={index}
                value={type.value}
                control={
                  <Radio
                    checkedIcon={<RadioButtonCheckedIcon className={classes.iconStyle} />}
                    icon={<RadioButtonUncheckedIcon className={classes.uncheckedIconStyle} />} />
                }
                label={t(type.label)} />
            );
          })}
        </RadioGroup>
      </FormControl>
    );
  };

  const initialValues = (editMode && !isLoading) ? {
    name: data.name,
    surname: data.surname,
    phone: data.phone,
    email: data.email,
    year: data.year,
    make: data.make,
    model: data.model,
    trim: data.trim,
    paymentType: data.paymentType,
    defaultChannel: data.defaultChannel,
    disclaimer: data.disclaimer,
    internalNotes: data.internalNotes,
    downPayment: data.downPayment,
    downPaymentLock: data.downPaymentLock ?? false,
    monthlyPayment: data.monthlyPayment,
    monthlyPaymentLock: data.monthlyPaymentLock ?? false,
    term: data.term,
    termLock: data.termLock ?? false,
    milesPerYear: data.milesPerYear,
    milesPerYearLock: data.milesPerYearLock ?? false,
    price: data.price,
    priceLock: data.priceLock ?? false,
    payment: data.payment,
    paymentLock: data.paymentLock ?? false,
    rate: data.rate,
    rateLock: data.rateLock ?? false,
    tradeIn: typeof data.tradeInValue === 'number',
    tradeInValue: data.tradeInValue ?? '',
    tradeInValueLock: data.tradeInValueLock ?? false,
    tradeInMake: data.tradeInMake ?? '',
    tradeInModel: data.tradeInModel ?? '',
    tradeInYear: data.tradeInYear ?? 0,
    tradeInMileage: data.tradeInMileage ?? '',
    tradeInVin: data.tradeInVin ?? '',
    tradeInPayoff: data.tradeInPayoff ?? '',
  } : {
    name: '',
    surname: '',
    phone: '',
    email: '',
    year: new Date().getFullYear(),
    make: '',
    model: '',
    trim: '',
    paymentType: paymentType,
    defaultChannel: 0,
    disclaimer: '',
    internalNotes: '',
    downPayment: '',
    downPaymentLock: false,
    monthlyPayment: '',
    monthlyPaymentLock: false,
    term: term,
    termLock: false,
    milesPerYear: milesPerYear,
    milesPerYearLock: false,
    price: '',
    priceLock: false,
    payment: '',
    paymentLock: false,
    rate: '',
    rateLock: false,
    tradeIn: false,
    tradeInValue: '',
    tradeInValueLock: false,
    tradeInMake: '',
    tradeInModel: '',
    tradeInYear: 0,
    tradeInMileage: '',
    tradeInVin: '',
    tradeInPayoff: ''
  };

  return (
    <div className={classes.modal}>
      <Container maxWidth={false} className={classes.container}>
        <DeleteDialog isOpen={isOpen} setDialogOpen={setDialogOpen} callback={handleDelete} fullName={data && `${data.name} ${data.surname}`} />
        <SadStates
          states={[
            {
              when: isLoading || isLoadingAC || isSubmittingGlobal,
              render: <Loader />
            }
          ]}>
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}>
            {({ isSubmitting, setFieldValue, dirty, values }) => (
              <Form className={classes.form}>
                <Grid className={classes.sectionTitle} container>
                  <Grid item xs={12} sm={12} md={3} />
                  <Grid item xs={12} sm={12} md={6}>
                    <h1 className={classes.header}>{editMode ? `${data.name} ${data.surname}` : t('createNewLead')}</h1>
                  </Grid>
                </Grid>
                <Grid container justifyContent='center' spacing={2}>
                  <Grid item xs={12} md={3}>
                    <InputField label='name' inputName='name' type='text' isRequired />
                  </Grid>
                  <Grid item xs={12} md={3}>
                    <InputField label='surname' inputName='surname' type='text' isRequired />
                  </Grid>
                </Grid>
                <Grid container justifyContent='center' spacing={2}>
                  <Grid item xs={12} md={3}>
                    <InputField label='phone' inputName='phone' type='text' isRequired />
                  </Grid>
                  <Grid item xs={12} md={3}>
                    <InputField label='email' inputName='email' type='email' readOnly={editMode} isRequired />
                  </Grid>
                </Grid>
                <Grid container className={classes.paymentTypeContainer}>
                  <Grid item xs={12} sm={12} md={3} />
                  <Grid className={classes.selectPaymentType} item>
                    {t('defaultChannel')}
                  </Grid>
                  <Grid className={classes.isRequired} item>
                    <p>*</p>
                  </Grid>
                  <Grid className={classes.formFields} item>
                    <Field
                      as={HandleSelectDefaultChannel}
                      value={values.defaultChannel}
                      onChange={(e) => {
                        setFieldValue('defaultChannel', Number(e.target.value));
                      }}
                      name='defaultChannelCreate' />
                  </Grid>
                </Grid>
                <Grid container justifyContent='center' spacing={2}>
                  <Grid item xs={12} md={3}>
                    <FormSelectFilter
                      isRequired
                      label={t('year')}
                      onChange={(e) => {
                        setFieldValue('year', Number(e.target.value));
                      }}
                      value={values.year}
                      menuItem={arrayOfYears} />
                  </Grid>
                  <Grid item xs={12} md={3}>
                    <InputACField
                      label='make'
                      inputName='make'
                      options={getACOptions('make')}
                      onChange={(val) => {
                        setFieldValue('make', val);
                      }}
                      value={data?.make}
                      isRequired />
                  </Grid>
                </Grid>
                <Grid container justifyContent='center' spacing={2}>
                  <Grid item xs={12} md={3}>
                    <InputACField
                      label='model'
                      inputName='model'
                      options={getACOptions('model')}
                      onChange={(val) => {
                        setFieldValue('model', val);
                      }}
                      value={data?.model}
                      isRequired />
                  </Grid>
                  <Grid item xs={12} md={3}>
                    <InputACField
                      label='trim'
                      inputName='trim'
                      options={getACOptions('trim')}
                      onChange={(val) => {
                        setFieldValue('trim', val);
                      }}
                      value={data?.trim}
                      isRequired />
                  </Grid>
                </Grid>

                <Grid container className={classes.paymentTypeContainer}>
                  <Grid item xs={12} sm={12} md={3} />
                  <Grid className={classes.selectPaymentType} item>
                    {t('paymentType')}
                  </Grid>
                  <Grid className={classes.isRequired} item>
                    <p>*</p>
                  </Grid>
                  <Grid className={classes.formFields} item>
                    <Field
                      as={HandleSelectPaymentType}
                      value={paymentType}
                      onChange={(e) => {
                        setPaymentType(Number(e.target.value));
                        setFieldValue('paymentType', Number(e.target.value));
                      }}
                      name='paymentType' />
                  </Grid>
                </Grid>
                {!isLease &&
                  <Grid container justifyContent='center' className={classes.price}>
                    <Grid item xs={12} md={6}>
                      <LockIcon handleLock={(val) => setFieldValue('priceLock', val)} value={values.priceLock} />
                      <InputField className={classes.lockInputBig} label='price$' inputName='price' type='text' defaultValue={values.price}
                        isNumber isMoney isRequired
                        onChange={(e) => {
                          const num = formatNumber(e.target.value);
                          setPriceValue(num);
                          setFieldValue('price', num);
                        }} />
                    </Grid>
                  </Grid>}

                {isLease &&
                  <Grid container justifyContent='center' spacing={2}>
                    <Grid item xs={12} md={3}>
                      <FormSelectFilter
                        lockProps={{ handleLock: (val) => setFieldValue('termLock', val), value: values.termLock }}
                        isRequired
                        label={t('term')}
                        onChange={(e) => {
                          setTerm(e.target.value);
                          setFieldValue('term', Number(e.target.value));
                        }}
                        value={data?.term ?? term}
                        menuItem={arrayOfTerms} />
                    </Grid>
                    <Grid item xs={12} md={3}>
                      <FormSelectFilter
                        lockProps={{ handleLock: (val) => setFieldValue('milesPerYearLock', val), value: values.milesPerYearLock }}
                        isRequired
                        label={t('milesPerYear')}
                        onChange={(e) => {
                          setMilesPerYear(e.target.value);
                          setFieldValue('milesPerYear', Number(e.target.value));
                        }}
                        value={data?.milesPerYear ?? milesPerYear}
                        menuItem={milesPerYearArray} />
                    </Grid>
                  </Grid>}
                {isFinance &&
                  <Grid container justifyContent='center' spacing={2}>
                    <Grid item xs={12} md={3}>
                      <FormSelectFilter
                        lockProps={{ handleLock: (val) => setFieldValue('termLock', val), value: values.termLock }}
                        isRequired
                        label={t('termFinance')}
                        onChange={(e) => {
                          setTerm(e.target.value);
                          setFieldValue('term', Number(e.target.value));
                        }}
                        value={data?.term ?? term}
                        menuItem={arrayOfTerms} />
                    </Grid>
                    <Grid item xs={12} md={3}>
                      <LockIcon handleLock={(val) => setFieldValue('rateLock', val)} value={values.rateLock} />
                      <InputField className={classes.lockInput} label='rate%' inputName='rate' type='text' defaultValue={values.rate}
                        isNumber isRequired
                        onChange={(e) => {
                          const val = e.target.value;
                          if (val === '') {
                            setFieldValue('rate', null);
                          } else {
                            setFieldValue('rate', formatNumber(val));
                          }
                        }} />
                    </Grid>
                  </Grid>}
                <Grid container justifyContent='center' spacing={2}>
                  {(isFinance || isLease) &&
                    <Grid item xs={12} md={3}>
                      <LockIcon handleLock={(val) => setFieldValue('downPaymentLock', val)} value={values.downPaymentLock} />
                      <InputField className={classes.lockInput} label='downPayment$' inputName='downPayment' type='text' defaultValue={values.downPayment}
                        isNumber isMoney isRequired
                        onChange={(e) => {
                          setFieldValue('downPayment', formatNumber(e.target.value));
                        }} />
                    </Grid>}
                  {isFinance &&
                    <Grid item xs={12} md={3}>
                      <LockIcon handleLock={(val) => setFieldValue('paymentLock', val)} value={values.paymentLock} />
                      <InputField className={classes.lockInput} label='payment$' inputName='payment' type='text' defaultValue={values.payment}
                        isNumber isMoney isRequired
                        onChange={(e) => {
                          setFieldValue('payment', formatNumber(e.target.value));
                        }} />
                    </Grid>
                  }
                  {isLease &&
                    <Grid item xs={12} md={3}>
                      <LockIcon handleLock={(val) => setFieldValue('monthlyPaymentLock', val)} value={values.monthlyPaymentLock} />
                      <InputField className={classes.lockInput} label='monthlyPayment$' inputName='monthlyPayment' type='text' defaultValue={values.monthlyPayment}
                        isNumber isMoney isRequired
                        onChange={(e) => {
                          setFieldValue('monthlyPayment', formatNumber(e.target.value));
                        }} />
                    </Grid>
                  }
                </Grid>
                {!isLease &&
                  <div>
                    <Grid container justifyContent='center' spacing={2}>
                      <Grid item xs={12} md={3}>
                        <CheckboxInputField label='Trade-In' checked={isTradeIn} inputName='tradeIn' onChange={(val) => {
                          setIsTradeIn(val);
                          setFieldValue('tradeIn', val);
                          if (!val && !editMode) {
                            setFieldValue('tradeInValue', '');
                            setFieldValue('tradeInMake', '');
                            setFieldValue('tradeInModel', '');
                            setFieldValue('tradeInYear', 0);
                            setFieldValue('tradeInMileage', '');
                            setFieldValue('tradeInVin', '');
                            setFieldValue('tradeInPayoff', '');
                          }
                        }} />
                      </Grid>
                      <Grid item xs={12} md={3} style={{ visibility: isTradeIn ? 'visible' : 'hidden' }}>
                        <LockIcon handleLock={(val) => setFieldValue('tradeInValueLock', val)} value={values.tradeInValueLock} />
                        <InputField className={classes.lockInput} label='tradeInValue$' inputName='tradeInValue' type='text' disabled={!isTradeIn} defaultValue={values.tradeInValue}
                          isNumber isMoney isRequired={isTradeIn}
                          onChange={(e) => {
                            setFieldValue('tradeInValue', formatNumber(e.target.value));
                          }} />
                      </Grid>
                    </Grid>
                    {isTradeIn &&
                      <Grid container justifyContent='center' spacing={2}>
                        <Grid item xs={12} md={3}>
                          <InputACField
                            label='make'
                            inputName='tradeInMake'
                            options={getACOptions('make')}
                            onChange={(val) => {
                              setFieldValue('tradeInMake', val);
                            }}
                            value={data?.tradeInMake}
                            disabled={!isTradeIn}
                            isRequired />
                        </Grid>
                        <Grid item xs={12} md={3}>
                          <InputACField
                            label='model'
                            inputName='tradeInModel'
                            options={getACOptions('model')}
                            onChange={(val) => {
                              setFieldValue('tradeInModel', val);
                            }}
                            value={data?.tradeInModel}
                            disabled={!isTradeIn}
                            isRequired />
                        </Grid>
                      </Grid>}
                    {isTradeIn &&
                      <Grid container justifyContent='center' spacing={2}>
                        <Grid item xs={12} md={3}>
                          <FormSelectFilter
                            disabled={!isTradeIn}
                            label={t('year')}
                            onChange={(e) => {
                              setFieldValue('tradeInYear', Number(e.target.value));
                            }}
                            value={values.tradeInYear}
                            menuItem={[{ value: 0, label: '' }, ...arrayOfYears]} />
                        </Grid>
                        <Grid item xs={12} md={3}>
                          <InputField label='Mileage' inputName='tradeInMileage' type='text' disabled={!isTradeIn} defaultValue={values.tradeInMileage}
                            isNumber
                            onChange={(e) => {
                              setFieldValue('tradeInMileage', formatNumber(e.target.value));
                            }} />
                        </Grid>
                      </Grid>}
                    {isTradeIn &&
                      <Grid container justifyContent='center' spacing={2}>
                        <Grid item xs={12} md={3}>
                          <InputField label='VIN Number' inputName='tradeInVin' type='text' disabled={!isTradeIn} />
                        </Grid>
                        <Grid item xs={12} md={3}>
                          <InputField label='tradeInPayoff$' inputName='tradeInPayoff' type='text' disabled={!isTradeIn} defaultValue={values.tradeInPayoff}
                            isNumber isMoney
                            onChange={(e) => {
                              setFieldValue('tradeInPayoff', formatNumber(e.target.value));
                            }} />
                        </Grid>
                      </Grid>}
                  </div>}
                <Grid container justifyContent='center' className={classes.padding}>
                  <Grid item xs={12} md={6}>
                    <NoteInputField label='disclaimer' inputName='disclaimer' type='text' />
                  </Grid>
                </Grid>
                <Grid container justifyContent='center' className={classes.padding}>
                  <Grid item xs={12} md={6}>
                    <NoteInputField label='internalNotes' inputName='internalNotes' type='text' />
                  </Grid>
                </Grid>
                <Grid container spacing={2} className={classes.leadActions}>
                  {editMode &&
                    <Grid item xs={4} sm={3} md={2}>
                      <Button type='reset' text={t('delete')} color='primary' smallButton={true} callback={deleteUserDialog} />
                    </Grid>}
                  {!isExtraSmaller && <Grid item sm={editMode ? 6 : 8} md={editMode ? 6 : 8} lg={editMode ? 6 : 8} />}
                  <Grid item xs={4} sm={3} md={2}>
                    <Button type='reset' text='cancel' color='primary' smallButton={true} callback={() => handleCancel()} />
                  </Grid>
                  <Grid item xs={4} sm={3} md={2}>
                    <Button text='save' smallButton={true} disabled={isSubmitting || !dirty} type='submit' />
                  </Grid>
                </Grid>
              </Form>
            )}
          </Formik>
        </SadStates>
      </Container>
    </div>
  );
};

LeadsForm.propTypes = {
  match: PropTypes.object
};

