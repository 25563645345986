import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  container: {
    paddingBottom: '0.6rem',
    display: 'flex',
    borderRadius: '15rem',
    height: '2.5rem',
    width: '100%',
    minWidth: '10rem',
    justifyContent: 'space-between',
    backgroundColor: theme.quaternaryBackgroundColor,
    padding: '0.2rem',
    boxShadow: 'rgb(152, 160, 206, 0.2) 0px 3px 7px 0px',
  },
  childContainer: {
    flexWrap: 'nowrap',
    justifyContent: 'space-between',
    width: '100%',
    paddingTop: '0.7rem'
  },
  inputContainer: {
    marginLeft: '1rem',
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between'
  },
  selectMenu: {
    color: theme.quaternaryTextColor,
    width: '11rem',
    paddingRight: '0'
  },
  selectMenuWide: {
    color: theme.quaternaryTextColor,
    maxWidth: '20rem',
    minWidth: '11rem',
    width: 'auto',
    display: 'block',
    whiteSpace: 'nowrap',
    overflow: 'hidden !important',
    textOverflow: 'ellipsis'
  },
  menu: {
    paddingTop: '2rem'
  },
  paper: {
    marginTop: '3rem',
    maxHeight: '20rem',
    minHeight: '3rem',
    height: 'auto',
  },
  input: {
    width: '100%',
    cursor: 'pointer'
  },
  width80: {
    width: '80%'
  },
  margin: {
    margin: 0,
    marginLeft: '0.2rem',
    paddingLeft: '0'
  },
  width10: {
    width: '10%'
  },
  selectIcon: {
    color: theme.primaryTextColor
  },
}));