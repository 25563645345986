import React from 'react';
import 'i18n';
import i18next from 'i18next';
import ReactDOM from 'react-dom';
import { I18nextProvider } from 'react-i18next';
import { ThemeProvider, createTheme } from '@material-ui/core/styles';
import App from './App';
import { UserProvider } from 'providers';
import reportWebVitals from './reportWebVitals';
import themeDefault from 'themeDefault';
import './index.css';

ReactDOM.render(
  <React.StrictMode>
    <ThemeProvider theme={createTheme(themeDefault)}>
      <I18nextProvider i18n={i18next}>
        <UserProvider>
          <App />
        </UserProvider>
      </I18nextProvider>
    </ThemeProvider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
