import { makeStyles } from '@material-ui/core/styles';
import { displayBorder } from 'helpers';

export const useStyles = makeStyles((theme) => ({
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    borderTopLeftRadius: theme.pageBorderRadius,
    [theme.breakpoints.down(displayBorder)]: {
      borderTopRightRadius: theme.pageBorderRadius
    },
    [theme.breakpoints.up(displayBorder)]: {
      borderBottomLeftRadius: theme.pageBorderRadius,
    },
    overflow: 'hidden',
    minHeight: 'calc(100vh - 70px)',
    width: '100%',
    backgroundColor: theme.quinaryBackgroundColor
  },
  browseModeHeight: {
    minHeight: 'calc(100vh - 300px)',
    height: '100%',
    backgroundColor: theme.quaternaryBackgroundColor
  }
}));