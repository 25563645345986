import React, { useState, useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import { Drawer, Grid } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';

import { NotificationMessage } from './NotificationMessage';
import { SadStates, Loader, Button } from 'components';
import { UserContext } from 'providers';
import { notificationIconBlack, zeroNotifications } from 'resources';
import { notificationService } from 'services';
import { notificationsLifetimeInDays } from 'helpers';

import { useStyles } from './NotificationsPage.css.js';

const REFETCH_INTERVAL_MS = 10000;

export const NotificationsPage = ({ notificationsActive, setNotificationsActive }) => {
  const classes = useStyles();
  const [t] = useTranslation('common');
  const { user } = useContext(UserContext);
  const [refetchData, setRefetchData] = useState(false);
  const rows = 25;
  const [rowsPerPage, setRowsPerPage] = useState(rows);
  const [refetchInterval] = useState(REFETCH_INTERVAL_MS);

  const { data: notifications, refetch, isLoading } = useQuery(['notifications'], () => notificationService.getNotifications(user?.id, rowsPerPage)
    , { refetchData, refetchInterval: refetchInterval });

  useEffect(() => {
    refetch();
  }, [refetchData, rowsPerPage]);

  const handleDeleteAll = () => {
    notificationService.deleteAllNotifications().then(() => {
      refetch();
    });
  };

  return (
    <Drawer
      classes={{ paper: classes.container }}
      anchor='right'
      open={notificationsActive}
      onClose={() => setNotificationsActive(false)}
      onOpen={() => setNotificationsActive(true)}>
      <SadStates
        states={[
          {
            when: isLoading,
            render: <Loader />
          }
        ]}>
        <Grid container className={classes.header}>
          <Grid item className={classes.headerText}>
            <img className={classes.logo} src={notificationIconBlack} alt={''} />
            <p className={classes.notificationsText}>{t('notifications')}</p>
            {notifications?.totalUnread !== 0 && <p className={classes.notificationsNumb}>({notifications?.totalUnread})</p>}
          </Grid>
          <Grid item>
            <CloseIcon classes={{ root: classes.closeIcon }} onClick={() => setNotificationsActive(false)} />
          </Grid>
        </Grid>
        {notifications?.total === 0 ?
          <Grid container className={classes.zeroNotificationsContainer}>
            <Grid item className={classes.zeroNotificationsLogo}>
              <img src={zeroNotifications} alt={''} />
            </Grid>
            <Grid item className={classes.zeroNotifications}>
              <p className={classes.notificationsText}>{t('zeroNotification')}</p>
            </Grid>
            <Grid item className={classes.zeroNotifications}>
              <p className={classes.message}>{t('youHaveReadAllNotifications')}</p>
            </Grid>
          </Grid>
          :
          notifications?.items.map((notification, index) => {
            return <NotificationMessage key={index} {...notification} refetchData={refetchData} setRefetchData={setRefetchData} />;
          })}
        {notifications?.total !== 0 &&
          <Grid className={classes.footer}>
            {rowsPerPage < notifications?.total &&
              <Grid item xs={12} sm={12} md={12} lg={12}>
                <Button text='loadMore' callback={() => setRowsPerPage(rowsPerPage + rows)} />
              </Grid>}
            {rowsPerPage >= notifications?.total &&
              <p className={classes.allNotificationsText}>{t('thatsAllYourNotifications', { notificationsLifetimeInDays })}</p>}
            <p onClick={handleDeleteAll} className={classes.clearAllNotificationsText}>{t('clearAllNotifications')}</p>
          </Grid>}
      </SadStates>
    </Drawer>
  );
};

NotificationsPage.propTypes = {
  notificationsActive: PropTypes.bool,
  setNotificationsActive: PropTypes.func
};