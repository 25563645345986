import { makeStyles } from '@material-ui/core/styles';
import themeDefault from '../../themeDefault';

const yellowColor = themeDefault.primaryBackgroundColor;

export const useStyles = makeStyles((theme) => ({
  root: {
    width: 'auto',
    marginTop: '1rem',
    padding: '1rem',
  },
  btnContainer: {
    margin: '20px 0 0 0'
  },
  buttonStyle: {
    margin: theme.spacing(1),
    minWidth: '150px',
    cursor: 'pointer',
  },
  buttonAccept: {
    color: 'green',
    borderColor: 'green',
  },
  buttonDecline: {
    color: 'red',
    borderColor: 'red',
  },
  buttonLarge: {
    width: '318px'
  },
  text: {
    fontSize: '1.2rem',
    color: 'black',
    lineHeight: '2rem',
    fontWeight: '400',
  },
  textRegards: {
    marginTop: '1rem',
    lineHeight: '1.2rem',
    fontStyle: 'italic',
  },
  cbText: {
    margin: '0',
    padding: '0 15%',
    color: theme.primaryTextColor,
    fontSize: '1rem',
    lineHeight: '1.5rem',
    '& a': {
      paddingLeft: '38px',
      color: theme.primaryTextColor
    }
  },
  headerText: {
    display: 'inline-block',
    margin: '20px 0 4px 0',
    padding: '8px 24px',
    fontSize: '1.4rem',
    border: `solid 1px ${yellowColor}`,
    borderRadius: '5px',
    fontWeight: '600'
  },
  optInCheckbox: {
    marginTop: '15px',
    '& > label': {
      alignItems: 'flex-start',
      width: 'auto !important',
      '& > span:last-child': {
        padding: '8px 0 0 8px',
      }
    }
  }
}));