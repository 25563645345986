import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import {
  Dialog, Grid,
  DialogTitle, useMediaQuery, IconButton, useTheme
} from '@material-ui/core';
import Close from '@material-ui/icons/Close';

import { sendMessageIcon } from 'resources';
import { checkRunningBrowser, SAFARI } from 'helpers';
import { Loader } from 'components';

import { useStyles } from './OfferSentDialog.css.js';

export const OfferSentDialog = ({ isOpen, setDialogOpen, leadId, isSuccessfullySent, setIsSuccessfullySent, isSubmittingGlobal }) => {

  const classes = useStyles();
  const theme = useTheme();
  const navHistory = useHistory();
  const [t] = useTranslation('common');

  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

  const handleClose = () => {
    if (isSuccessfullySent) {
      navHistory.push(`/leads/view/${leadId}`);
    }
    setIsSuccessfullySent(false);
    setDialogOpen(false);
  };

  return (
    <Dialog
      className={classes.container}
      classes={{ paper: checkRunningBrowser() === SAFARI ? classes.dialogPaperSafari : classes.dialogPaper }}
      fullWidth={true}
      maxWidth='md'
      fullScreen={fullScreen}
      open={isOpen}
      onClick={handleClose}
      aria-labelledby='responsive-dialog-title'>
      <Grid className={classes.directionRow}>
        <Grid item xs={11} sm={11} md={11} lg={11} />
        <Grid item xs={1} sm={1} md={1} lg={1}>
          <DialogTitle id='responsive-dialog-title' classes={{ root: classes.positionEnd }}>
            <IconButton
              disableRipple={true}
              className={classes.closeImg}
              onClick={handleClose}>
              <Close />
            </IconButton>
          </DialogTitle>
        </Grid>
      </Grid>
      <DialogTitle className={clsx(classes.dialogTitle)} id='responsive-dialog-title'>
        {!isSubmittingGlobal && <Grid><img src={sendMessageIcon} alt={''} /></Grid>}
        {isSubmittingGlobal && <Loader />}
        {isSubmittingGlobal && <Grid className={classes.feedbackMessage}> {t('submittingOffer')} </Grid>}
        {!isSubmittingGlobal && isSuccessfullySent && <Grid className={classes.feedbackMessage}> {t('sentOfferMessageSuccessfully')} </Grid>}
        {!isSubmittingGlobal && !isSuccessfullySent && <Grid className={classes.feedbackMessage}> {t('sentOfferMessageFailed')} </Grid>}
      </DialogTitle>
    </Dialog>
  );
};

OfferSentDialog.propTypes = {
  isOpen: PropTypes.bool,
  setDialogOpen: PropTypes.func,
  leadId: PropTypes.number,
  isSuccessfullySent: PropTypes.bool,
  setIsSuccessfullySent: PropTypes.func,
  isSubmittingGlobal: PropTypes.bool
};