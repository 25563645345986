export const conversionBase = '1024';

export const displayBorder = 'md';
export const displayBorderSmall = 'sm';
export const displayBorderLarge = 'lg';
export const displayBorderExtraLarge = 'xl';

export const dateFormat = 'MM/dd/yyyy';

export const dateFormatHours = 'MM/dd/yyyy hh:mm aa';

export const minTextSize = 5;

export const rows = 25;

export const GIF = 'gif';
export const JPG = 'jpg';
export const JPEG = 'jpeg';
export const PNG = 'png';

export const notificationsLifetimeInDays = 30;

export const OPERA = 'Opera';
export const OPR = 'OPR';
export const CHROME = 'Chrome';
export const SAFARI = 'Safari';
export const FIREFOX = 'Firefox';
export const MSIE = 'MSIE';
export const UNKNOWN = 'Unknown';