import React, { useState } from 'react';
import { Form, Formik, Field } from 'formik';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import * as Yup from 'yup';
import { Container, Grid, useMediaQuery, useTheme, FormControl, FormControlLabel, Radio, RadioGroup } from '@material-ui/core';
import RadioButtonCheckedIcon from '@material-ui/icons/RadioButtonChecked';
import RadioButtonUncheckedIcon from '@material-ui/icons/RadioButtonUnchecked';
import { BackButton, Button, InputField, SadStates, Loader, toastNotification, CheckboxInputField } from 'components';
import { phoneRegExp, companyStatuses } from 'helpers';
import { companyService } from 'services';
import { useStyles } from '../style.css.js';

export const CreateCompanyPage = () => {
  const classes = useStyles();
  const { t } = useTranslation(['common', 'error']);
  const navHistory = useHistory();
  const [isLoading, setIsLoading] = useState(false);
  const [initialValues, setInitialValues] = useState({
    name: '',
    phone: '',
    status: 1,
    state: '',
    city: '',
    address: '',
    address2: '',
    zip: '',
    paidSms: false,
    paidEmail: false,
    optInDisabled: false,
    creditApplicationLink: ''
  });

  const theme = useTheme();
  const isExtraSmaller = useMediaQuery(theme.breakpoints.down('xs'));
  const isSmaller = useMediaQuery(theme.breakpoints.down('sm'));

  const handleSubmit = (values) => {
    setIsLoading(true);
    setInitialValues(values);
    companyService.create(values).then(() => {
      setIsLoading(false);
      toastNotification('info', t('newCompanyAdded'));
      navHistory.goBack();
    }).catch((err) => {
      setIsLoading(false);
      toastNotification('error', t(`error:${Object.values(err.response.data.errors)[0]}`));
    });
  };

  const companySchema = Yup.object().shape({
    name: Yup.string()
      .max(50, t('stringMax50'))
      .required(t('required')),
    phone: Yup.string()
      .max(20, t('stringMax20'))
      .matches(phoneRegExp, t('invalidPhoneFormat'))
      .required(t('required')),
    status: Yup.number(),
    state: Yup.string()
      .max(20, t('stringMax20')),
    city: Yup.string()
      .max(20, t('stringMax20')),
    address: Yup.string()
      .max(100, t('stringMax100')),
    address2: Yup.string()
      .max(100, t('stringMax100')),
    zip: Yup.string()
      .max(10, t('stringMax10'))
  });

  const handleCancel = () => {
    navHistory.goBack();
  };

  const StatusRadioComponent = (prop) => {
    return (
      <FormControl component='fieldset' className={classes.radioGroup}>
        <RadioGroup aria-label='position' row {...prop}>
          {companyStatuses.map((type, index) => {
            return (
              <FormControlLabel
                className={classes.radio}
                key={index}
                value={type.value}
                control={
                  <Radio
                    checkedIcon={<RadioButtonCheckedIcon className={classes.iconStyle} />}
                    icon={<RadioButtonUncheckedIcon className={classes.uncheckedIconStyle} />} />
                }
                label={<span className={classes.radioLabel}>{t(type.label)}</span>} />
            );
          })}
        </RadioGroup>
      </FormControl>
    );
  };

  return (
    <div className={isSmaller ? classes.modalPhone : classes.modal}>
      <BackButton callback={() => navHistory.replace('/companies')} />
      <Container maxWidth={'md'} className={classes.container}>
        <SadStates
          states={[
            {
              when: isLoading,
              render: <Loader />
            }
          ]}>
          <Formik
            initialValues={initialValues}
            validationSchema={companySchema}
            onSubmit={handleSubmit}>
            {({ values, dirty, setFieldValue }) => (
              <Form className={classes.form}>
                <Grid container justifyContent='flex-start' spacing={5}>
                  <Grid item>
                    <h1 className={classes.header}>{t('createCompany')}</h1>
                  </Grid>
                </Grid>
                <Grid container justifyContent='center' spacing={2}>
                  <Grid item xs={12} sm={12} md={7} lg={7}>
                    <InputField label='companyName' inputName='name' isRequired />
                  </Grid>
                  <Grid item xs={12} sm={12} md={5} lg={5}>
                    <InputField label='companyPhone' inputName='phone' isRequired />
                  </Grid>
                </Grid>
                <Grid container justifyContent='center' spacing={2}>
                  <Grid item xs={12} sm={12} md={12} lg={12}>
                    <InputField label='address' inputName='address' />
                  </Grid>
                  <Grid item xs={12} sm={12} md={12} lg={12}>
                    <InputField label='address2' inputName='address2' readOnly={false} />
                  </Grid>
                </Grid>
                <Grid container justifyContent='center' spacing={2}>
                  <Grid item xs={12} sm={12} md={4} lg={4}>
                    <InputField label='city' inputName='city' />
                  </Grid>
                  <Grid item xs={12} sm={12} md={4} lg={4}>
                    <InputField label='state' inputName='state' />
                  </Grid>
                  <Grid item xs={12} sm={12} md={4} lg={4}>
                    <InputField label='zip' inputName='zip' readOnly={false} />
                  </Grid>
                  <Grid item xs={12} sm={12} md={12} lg={12}>
                    <InputField label='creditApplicationLink' inputName='creditApplicationLink' />
                  </Grid>
                </Grid>

                <Grid container spacing={0}>
                  <Grid item xs={12} md={12} className={classes.radioBlock}>
                    <Field
                      as={StatusRadioComponent}
                      value={values.status}
                      onChange={(e) => {
                        setFieldValue('status', Number(e.target.value));
                      }}
                      name='status' />
                  </Grid>
                  <Grid item xs={12} md={12} className={classes.checkboxBlock}>
                    <span>Paid Features</span>
                    <div>
                      <CheckboxInputField label='SMS' checked={values.paidSms} inputName='paidSms'
                        onChange={(val) => {
                          setFieldValue('paidSms', val);
                        }} />
                      <CheckboxInputField label='Email' checked={values.paidEmail} inputName='paidEmail'
                        onChange={(val) => {
                          setFieldValue('paidEmail', val);
                        }} />
                    </div>
                  </Grid>
                  <Grid item xs={12} md={12} className={classes.checkboxBlock}>
                    <span>Opt-in</span>
                    <div>
                      <CheckboxInputField label={t('optInDisabledTitle')} checked={!values.optInDisabled} inputName='optInDisabled'
                        onChange={(val) => {
                          setFieldValue('optInDisabled', !val);
                        }} />
                    </div>
                  </Grid>
                </Grid>

                <Grid container spacing={2} className={classes.formActions}>
                  {!isExtraSmaller && <Grid item sm={8} md={8} lg={8} />}
                  <Grid item xs={4} sm={3} md={2}>
                    <Button type='reset' text='cancel' color='primary' callback={() => handleCancel()} smallButton={true} />
                  </Grid>
                  <Grid item xs={4} sm={3} md={2}>
                    <Button text='save' disabled={isLoading || !dirty} type='submit' smallButton={true} />
                  </Grid>
                </Grid>
              </Form>
            )}
          </Formik>
        </SadStates>
      </Container>
    </div>
  );
};