import React, { useContext } from 'react';
import { QueryClient, QueryClientProvider } from 'react-query';
import { Router, Route, Switch, Redirect } from 'react-router-dom';
import { ToastContainer, Slide } from 'react-toastify';

import { PageHeader } from 'components';
import { browserHistory, isAdmin, isSalesRep, isDesigner, isSalesMng } from './helpers';
import {
  AuthPage,
  ForgotPasswordPage,
  GifTemplateForm,
  GifTemplatesPage,
  LeadsForm,
  LeadsPage,
  ProfileForm,
  ResetPasswordPage,
  SendOfferPage,
  SignInPage,
  UserForm,
  UsersPage,
  ViewLeadPage,
  NotificationsPage,
  ConfirmOfferPage,
  CompanyListPage,
  CreateCompanyPage,
  UpdateCompanyPage,
  ReportListPage,
  OptInSmsPage
} from 'pages';
import { UserContext } from 'providers';

import 'react-toastify/dist/ReactToastify.min.css';
import './react-toastify-overrides.css';
import './App.css';

export const App = () => {

  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        refetchOnWindowFocus: false,
      },
    },
  });

  const { user, initialized } = useContext(UserContext);

  const admin = user && isAdmin(user.role);
  const salesRep = user && isSalesRep(user.role);
  const designer = user && isDesigner(user.role);
  const salesMng = user && isSalesMng(user.role);

  if (!initialized) {
    return null;
  }

  let routes = null;
  if (user) {
    routes = (
      <Switch>
        {(admin | salesMng) && <Route exact path='/users' component={UsersPage} />}
        {(admin | salesMng) && <Route exact path='/users/create' component={UserForm} />}
        {(admin | salesMng) && <Route exact path='/users/edit/:id' component={UserForm} />}
        {admin && <Route exact path='/companies' component={CompanyListPage} />}
        {admin && <Route exact path='/companies/create' component={CreateCompanyPage} />}
        {(admin || salesMng) && <Route exact path='/companies/:id' component={UpdateCompanyPage} />}
        {admin && <Route exact path='/reports' component={ReportListPage} />}
        <Route exact path='/accountSettings' component={ProfileForm} />
        {(salesRep || admin || salesMng) && <Route exact path='/leads' component={LeadsPage} />}
        {(salesRep || admin || salesMng) && <Route exact path='/leads/create' component={LeadsForm} />}
        {(salesRep || admin || salesMng) && <Route exact path='/leads/edit/:id' component={LeadsForm} />}
        {(salesRep || admin || salesMng) && <Route exact path='/leads/view/:id' component={ViewLeadPage} />}
        {(salesRep || admin || salesMng) && <Route exact path='/leads/view/:id/sendOffer' component={SendOfferPage} />}
        <Route exact path='/gifTemplates' component={GifTemplatesPage} />
        <Route exact path='/gifTemplates/create' component={GifTemplateForm} />
        <Route exact path='/gifTemplates/edit/:id' component={GifTemplateForm} />
        {(salesRep || admin || salesMng) && <Route exact path='/notifications' component={NotificationsPage} />}
        <Route exact path='/confirm' component={ConfirmOfferPage} />
        <Route exact path='/opt-in-sms' component={OptInSmsPage} />
        {(salesRep || admin || salesMng) && <Redirect to='/leads' />}
        {designer && <Redirect to='/gifTemplates' />}
      </Switch>
    );
  } else {
    routes = (
      <Switch>
        <Route exact path='/signin' component={SignInPage} />
        <Route exact path='/setup_account' component={AuthPage} />
        <Route exact path='/forgot_password' component={ForgotPasswordPage} />
        <Route exact path='/reset_password' component={ResetPasswordPage} />
        <Route exact path='/confirm' component={ConfirmOfferPage} />
        <Route exact path='/opt-in-sms' component={OptInSmsPage} />
        <Redirect to='/signin' />
      </Switch>
    );
  }

  return (
    <div>
      <QueryClientProvider client={queryClient}>
        <Router history={browserHistory}>
          <PageHeader hideControls={!user} />
          {routes}
        </Router>
      </QueryClientProvider>
      <ToastContainer
        autoClose={2500}
        position='top-center'
        hideProgressBar
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        pauseOnHover
        draggable={false}
        draggableDirection='y'
        limit='1'
        transition={Slide}
        closeButton={false} />
    </div>
  );
};

export default App;
