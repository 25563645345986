import React from 'react';
import { Link } from 'react-router-dom';
import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/database';
import { Formik, Form } from 'formik';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import { Box, Grid } from '@material-ui/core';

import { InputField, PasswordInputField, Button, toastNotification } from 'components';
import { handleFirebaseErrors } from 'helpers';
import { userIcon, logoWithText } from 'resources';

import { useStyles } from './SignInPage.css.js';

export const SignInPage = () => {

  const [t] = useTranslation('common');
  const classes = useStyles();

  const handleSubmit = async (values, { setSubmitting }) => {
    try {
      await firebase.auth().signInWithEmailAndPassword(values.email, values.password);
    } catch (error) {
      const message = handleFirebaseErrors(error);
      toastNotification('warning', t(message));
    }
    setSubmitting(false);
  };

  const loginSchema = Yup.object().shape({
    password: Yup.string()
      .min(8, t('tooShort'))
      .max(50, t('tooLong'))
      .required(t('required')),
    email: Yup.string()
      .email(t('invalidEmail'))
      .required(t('required'))
  });

  return (
    <Box className={classes.container}>
      <Grid container className={classes.formContainer}>
        <Grid item xs={9} sm={10} md={10} xl={10} className={classes.inputFieldContainer}>
          <Grid item>
            <img className={classes.logo} src={logoWithText} alt={''} />
          </Grid >
          <Grid item>
            <img className={classes.logoUser} src={userIcon} alt={''} />
          </Grid >
          <div>
            <Formik
              initialValues={{ email: '', password: '' }}
              validationSchema={loginSchema}
              onSubmit={handleSubmit}>
              {() => (
                <Form>
                  <div>
                    <InputField label='email' inputName='email' isRequired />
                  </div>
                  <div>
                    <PasswordInputField label='password' inputName='password' isRequired />
                  </div>
                  <br/>
                  <Button text='login' color='primary' type='submit'/>
                  <div>
                    <Link to='/forgot_password' className={classes.link}>{t('forgotPassword')}</Link>
                  </div>
                  <br/>
                </Form>
              )}
            </Formik>
          </div>
        </Grid>
      </Grid>
    </Box>
  );

};