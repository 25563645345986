import axios from 'axios';
import { getApiCoreUrl } from 'helpers';
import qs from 'qs';
import { baseApiErrorHandler } from 'services';

const baseUrl = `${getApiCoreUrl()}/api/v1/GifTemplate`;

const getGifTemplates = (page = 0, rowsPerPage = 10, filter = {}) => {
  const companyFilter = {};
  filter.array.map(f => {
    if (companyFilter[f.id]) {
      companyFilter[f.id].push(f.value);
    } else {
      companyFilter[f.id] = [f.value];
    }
  });

  const url = `${baseUrl}?${qs.stringify({
    offset: page * rowsPerPage,
    limit: rowsPerPage,
    search: filter.search || '',
    orderBy: filter.orderBy || '',
    library: filter.library,
    ...companyFilter
  }, { arrayFormat: 'repeat' })}`;

  return axios.get(url).then(response => {
    return response.data;
  }).catch(baseApiErrorHandler);
};

const getGifTemplateById = (id) => {
  const url = `${baseUrl}/${id}`;
  return axios.get(url).then(response => {
    return response.data;
  }).catch(baseApiErrorHandler);
};

const createGifTemplate = (details) => {
  return axios.post(baseUrl, details).catch(baseApiErrorHandler);
};

const updateGifTemplate = (gifTemplate, id) => {
  const url = `${baseUrl}/${id}`;
  return axios.put(url, gifTemplate).catch(baseApiErrorHandler);
};

const deleteGifTemplate = (id) => {
  const url = `${baseUrl}/${id}`;
  return axios.delete(url).catch(baseApiErrorHandler);
};

export const gifTemplateService = {
  getGifTemplates,
  getGifTemplateById,
  createGifTemplate,
  updateGifTemplate,
  deleteGifTemplate
};