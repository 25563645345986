import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { FormControl, Select, MenuItem, Grid } from '@material-ui/core';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';

import { useStyles } from './SelectFilter.css.js';

export const PaginationSelectFilter = ({value, menuItem, onChange}) => {
  const classes = useStyles();
  const [t] = useTranslation('common');

  return (
    <FormControl className={classes.paginationContainer}>
      <Grid container>
        <Grid item className={classes.formControl}>
          <Select
            IconComponent = {ArrowDropDownIcon}
            classes={{ icon: classes.paginationSelectIcon }}
            disableUnderline
            MenuProps={{
              anchorOrigin: {
                vertical: 'top',
                horizontal: 'right',
              },
              transformOrigin: {
                vertical: 'top',
                horizontal: 'left',
              },
              getContentAnchorEl: null
            }}
            className={classes.paginationInput}
            value={value ? value : 10}
            onChange={(e) => {
              onChange(e);
            }}>
            {menuItem.map(c => (
              <MenuItem
                classes={{root: classes.paginationSelectMenu}}
                key={c.value} value={c.label}>{t(c.label)}
              </MenuItem>
            ))}
          </Select>
        </Grid>
      </Grid>
    </FormControl>
  );
};

PaginationSelectFilter.propTypes = {
  label: PropTypes.string,
  value: PropTypes.number,
  menuItem: PropTypes.array,
  onChange: PropTypes.func,
  width: PropTypes.number
};
