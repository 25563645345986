import React from 'react';
import PropTypes from 'prop-types';
import 'date-fns';
import Grid from '@material-ui/core/Grid';
import DateFnsUtils from '@date-io/date-fns';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers';

import { useStyles } from './style.css.js';

export const AppRangeDatePicker = ({ fromDate, toDate, onChange }) => {
  const classes = useStyles();
  const invalidRangeMessage = 'Invalid Date Range';

  const handleFromChange = (date) => {
    if (date && date.toString() !== 'Invalid Date') {
      onChange({
        from: new Date(date.getFullYear(), date.getMonth(), date.getDate(), 0, 0, 0, 0),
        to: toDate,
      });
    }
  };

  const handleToChange = (date) => {
    if (date && date.toString() !== 'Invalid Date') {
      onChange({
        from: fromDate,
        to: new Date(date.getFullYear(), date.getMonth(), date.getDate(), 1, 0, 0, 0),
      });
    }
  };

  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils} >
      <Grid container className={classes.container} spacing={1} justifyContent='flex-start'>
        <Grid item>
          <KeyboardDatePicker autoOk disableToolbar
            className={classes.picker}
            InputProps={{ className: classes.input }}
            variant='inline'
            format='MM/dd/yyyy'
            id='date-picker-from'
            label='From'
            value={fromDate}
            maxDate={toDate}
            maxDateMessage={invalidRangeMessage}
            minDateMessage={invalidRangeMessage}
            onChange={handleFromChange} />
        </Grid>
        <Grid item>
          <KeyboardDatePicker autoOk disableToolbar
            className={classes.picker}
            InputProps={{ className: classes.input }}
            variant='inline'
            format='MM/dd/yyyy'
            id='date-picker-to'
            label='To'
            value={toDate}
            minDate={fromDate}
            maxDateMessage={invalidRangeMessage}
            minDateMessage={invalidRangeMessage}
            onChange={handleToChange} />
        </Grid>
      </Grid>
    </MuiPickersUtilsProvider>
  );
};

AppRangeDatePicker.propTypes = {
  fromDate: PropTypes.object,
  toDate: PropTypes.object,
  onChange: PropTypes.func,
};
