import { makeStyles } from '@material-ui/core/styles';
import { displayBorder } from 'helpers';

export const useStyles = makeStyles((theme) => ({
  container: {
    color: theme.quaternaryTextColor,
    height: '100%',
    position: 'relative',
    paddingLeft: '0.25rem',
    paddingRight: '0.25rem',
    backgroundColor: theme.drawersBackgroundColor,
    paddingBottom: '3.5rem',
    overflowY: 'auto',
    [theme.breakpoints.up(displayBorder)]: {
      paddingLeft: '6rem',
      paddingRight: '6rem'
    }
  },
  tableCell: {
    fontSize: '0.8rem'
  },
  paddingRight: {
    paddingRight: '1.25rem',
    alignSelf: 'center'
  },
  spaceBetween: {
    direction: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: '1rem'
  },
  userName: {
    margin: 0,
    cursor: 'pointer',
    fontWeight: 'bold',
    width: '50rem',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'normal',
    '-webkit-line-clamp': 2,
    '-webkit-box-orient': 'vertical',
    display: '-webkit-box'
  },
  companyName: {
    margin: 0,
    cursor: 'pointer',
    fontWeight: 'bold',
    minWidth: '15rem',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'normal',
    '-webkit-line-clamp': 2,
    '-webkit-box-orient': 'vertical',
    display: '-webkit-box'
  },
  role: {
    margin: 0,
    width: '10rem',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'normal',
    '-webkit-line-clamp': 2,
    '-webkit-box-orient': 'vertical',
    display: '-webkit-box'
  },
  total: {
    color: theme.primaryTextColor,
    display: 'flex'
  },
  marginRight: {
    marginRight: '0.3rem',
    display: 'flex'
  },
  paddingTop: {
    paddingTop: '1.5rem !important',
    marginRight: '0.5rem'
  },
  filterIcon: {
    width: '1.3rem',
    height: '1.3rem',
    marginRight: '0.5rem'
  },
  alignItems: {
    alignItems: 'center'
  },
  filterText: {
    margin: 0
  },
  '@media screen and (max-width: 380px)': {
    paddingRight: {
      margin: '0.5rem auto'
    },
    filterIcon: {
      marginLeft: '1rem'
    },
    userName: {
      margin: 0,
      cursor: 'pointer',
      fontWeight: 'bold',
      width: '8rem',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'normal',
      '-webkit-line-clamp': 2,
      '-webkit-box-orient': 'vertical',
      display: '-webkit-box'
    },
    role: {
      width: '8rem'
    }
  },
  '@media screen and (min-width: 381px) and (max-width: 615px)': {
    paddingRight: {
      margin: '0.5rem auto'
    },
    filterIcon: {
      marginLeft: '1rem'
    },
    userName: {
      margin: 0,
      cursor: 'pointer',
      fontWeight: 'bold',
      width: '10rem',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'normal',
      '-webkit-line-clamp': 2,
      '-webkit-box-orient': 'vertical',
      display: '-webkit-box'
    },
  },
  '@media screen and (min-width: 616px) and (max-width: 1300px)': {
    userName: {
      margin: 0,
      cursor: 'pointer',
      fontWeight: 'bold',
      width: '20rem',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'normal',
      '-webkit-line-clamp': 2,
      '-webkit-box-orient': 'vertical',
      display: '-webkit-box'
    },
  },
  '@media screen and (min-width: 1301px) and (max-width: 1800px)': {
    userName: {
      margin: 0,
      cursor: 'pointer',
      fontWeight: 'bold',
      width: '40rem',
      '-webkit-line-clamp': 2,
      '-webkit-box-orient': 'vertical',
      overflow: 'hidden',
      display: '-webkit-box'
    },
  }
}));