import { makeStyles } from '@material-ui/core/styles';
import { displayBorder } from 'helpers';

export const useStyles = makeStyles((theme) => ({
  container: {
    width: '100%',
    margin: 'auto',
    [theme.breakpoints.up(displayBorder)]: {
      width: '50%'
    }
  },
  closeImg: {
    cursor: 'pointer',
    float: 'right',
    marginTop: '5px',
    width: '40px',
    padding: '0px 6px 0px 0px',
    color: theme.primaryButtonColor,
    fontSize: '1.5rem',
    '&:hover': {
      backgroundColor: 'transparent'
    }
  },
  dialogTitle: {
    textAlign: 'center',
    padding: '0px 0px 10px 0px',
    fontSize: '1.3rem',
    '& .MuiTypography-h6': {
      fontFamily: theme.fontLato,
      fontWeight: 'bold'
    },
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'space-around',
    paddingRight: '1rem',
    paddingLeft: '1rem'
  },
  dialogPaper: {
    padding: '2rem 1rem',
    maxHeight: '60vh'
  },
  dialogPaperSafari: {
    padding: '2rem 1rem',
    maxHeight: '60vh'
  },
  dateName: {
    textAlign: 'center',
    color: theme.primaryBackgroundColor,
    fontSize: '1.25rem',
    fontWeight: 'bold'
  },
  deleteMessageIcon: {
    alignSelf: 'center'
  },
  '@media screen and (max-width:1024px)': {
    buttonContainer: {
      padding: '0',
      flexWrap: 'nowrap'
    },
  }
}));