import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  button: {
    display: 'flex',
    flexDirection: 'row',
    alignSelf: 'center',
    alignItems: 'center',
    backgroundColor: theme.primaryBackgroundColor,
    height: '1.7rem',
    color: theme.quaternaryBackgroundColor,
    borderRadius: '3.125rem',
    width: 'auto',
    boxSizing: 'border-box',
  },
  checked: {
    color: theme.tertiaryBackgroundColor,
  },
  notChecked: {
    backgroundColor: theme.tertiaryBackgroundColor,
  },
  container: {
    backgroundColor: theme.tertiaryBackgroundColor,
    width: '6.875rem',
    height: '1.875rem',
    borderRadius: '3.125rem',
    justifyContent: 'space-around',
    cursor: 'pointer',
    alignSelf: 'center',
    marginTop: '-2rem',
    float: 'right',
    marginRight: '0.2%',
    zIndex: '2',
    position: 'relative',
    boxSizing: 'border-box',
  },
  containerGif: {
    backgroundColor: theme.tertiaryBackgroundColor,
    width: '13rem',
    height: '1.7rem',
    boxSizing: 'border-box',
    borderRadius: '3.125rem',
    justifyContent: 'space-between',
    cursor: 'pointer',
    alignSelf: 'center',
    margin: '0 0.5rem 1rem 0',
    zIndex: '2',
  },
  label: {
    fontSize: '0.813rem',
    paddingBottom: '0.1rem',
    width: '100%',
    textAlign: 'center',
  },
  me: {
    width: '0.493rem',
    height: '0.563rem',
    paddingRight: '0.3rem',
  },
  all: {
    width: '0.781rem',
    height: '0.484rem',
    paddingRight: '0.3rem'
  },
  company: {
    width: '0.781rem',
    height: '0.484rem',
    paddingRight: '0.3rem'
  },
  private: {
    width: '0.781rem',
    height: '0.484rem',
    paddingRight: '0.3rem'
  },
  '@media screen and (max-width: 615px)': {
    container: {
      marginTop: '0rem',
      marginBottom: '1rem'
    }
  }
}));