import { makeStyles, withStyles } from '@material-ui/core/styles';
import Pagination from '@material-ui/lab/Pagination';

export const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center'
  },
  goToPageText: {
    margin: 0,
    marginRight: '0.5rem'
  },
  inputContainer: {
    height: '1.75rem',
    width: '5.625rem',
    padding: '0.4rem',
    border: '1px solid' + theme.primaryBorderColor,
    backgroundColor: theme.quaternaryBackgroundColor,
    display: 'flex',
    textAlign: 'center',
    alignItems: 'center'
  },
  input: {
    border: 0,
    outline: 'none',
    width: '3rem'
  },
  imgContainer: {
    backgroundColor: theme.primaryTextColor,
    width: '1.938rem',
    height: '1.938rem',
    borderRadius: '0.313rem',
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: theme.tertiaryBackgroundColor
    }
  },
  img: {
    width: '0.75rem',
    height: '0.75rem',
    marginTop: '0.6rem',
    cursor: 'pointer'
  },
  displayFlex: {
    display: 'flex'
  },
  showText: {
    margin: 0,
    marginLeft: '0.5rem',
    marginRight: '0.5rem',
    alignSelf: 'center'
  },
  paginationSelectFilter: {
    display: 'flex',
    border: 'none',
    width: '3rem',
    paddingRight: '2rem'
  },
  navigationContainer: {
    display: 'flex',
    flexDirection: 'row',
    paddingRight: '1.5rem',
    paddingLeft: '1.5rem',
    alignItems: 'center'
  },
  '@media screen and (max-width: 865px)': {
    container: {
      display: 'flex',
      textAlign: 'center',
      flexDirection: 'column',
    },
    navigationContainer: {
      display: 'none'
    },
    displayFlex: {
      display: 'none'
    }
  }
}));

export const StyledPagination = withStyles({
  root: {
    float: 'left',
    marginTop: '0.3rem',
    '& .Mui-selected': {
      backgroundColor: '#FFCE07',
    },
    '& .Mui-selected:hover': {
      backgroundColor: '#FFCE07',
    },
    '& .MuiButtonBase-root:hover': {
      backgroundColor: '#000000',
      color: '#FFFFFF'
    }
  }
})(Pagination);