import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  picker: {
    display: 'flex',
    borderRadius: '15rem',
    height: '2.5rem',
    width: '10rem',
    backgroundColor: theme.quaternaryBackgroundColor,
    padding: '0 0 0.8rem 1.5rem',
    boxShadow: 'rgb(152, 160, 206, 0.2) 0px 3px 7px 0px',
    '&>label': {
      color: 'grey !important',
      padding: '0.7rem 0 0 1.4rem',
    }
  },
  input: {
    color: 'black !important',
    fontWeight: 500,
    paddingTop: '0.3rem',
    '&::before': {
      borderBottom: 'none !important',
      transition: 'none !important',
    },
    '&::after': {
      borderBottom: 'none !important',
      transition: 'none !important',
    },
    '&>input': {

    },
    '&>div>button': {
      marginTop: '-1.1rem',
      padding: '1rem'
    }
  }
}));