import React, { useState, useContext, useEffect } from 'react';
import { Form, Formik, Field } from 'formik';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import { useHistory } from 'react-router-dom';
import * as Yup from 'yup';
import {
  Container, FormControl, FormControlLabel,
  Grid, Radio, RadioGroup, useMediaQuery, useTheme
} from '@material-ui/core';
import RadioButtonCheckedIcon from '@material-ui/icons/RadioButtonChecked';
import RadioButtonUncheckedIcon from '@material-ui/icons/RadioButtonUnchecked';

import {
  BackButton, Button, DeleteDialog, InputField, EmptyDialog,
  PasswordInputField, SadStates, Loader, toastNotification, FormSelectFilter
} from 'components';
import { phoneRegExp, setAmericanPhoneCode, roles, isAdmin, isSalesMng } from 'helpers';
import { UserContext } from 'providers';
import { userService, companyService } from 'services';
import { useStyles } from './UserForm.css.js';

export const UserForm = (props) => {
  const classes = useStyles();
  const { t } = useTranslation(['common', 'error']);
  const navHistory = useHistory();
  const { user } = useContext(UserContext);
  const [cancelUpdate, setCancelUpdate] = useState(false);
  const [userPassword, setUserPassword] = useState(false);
  const [role, setRole] = useState(2);
  const [isOpen, setDialogOpen] = useState(false);
  const [isConfirmOpen, setIsConfirmOpen] = useState(false);
  const [userId] = useState(props.match.params.id);
  const [editMode] = useState(props.match.params.id !== undefined);
  const { isLoading, data = {} } = useQuery(['userForm'], () => userService.getUserById(userId), { enabled: editMode, cacheTime: 0 });
  const { data: dataCompany = null } = useQuery(['companies'], () => companyService.getSelectItems(), { enabled: true, cacheTime: 10000 });
  const [isSubmittingGlobal, setSubmittingGlobal] = useState(false);
  const confimDialogMessage = (<>Are you sure you want to <br />{editMode ? 'move user to' : 'create user for'} inactive company?</>);

  const theme = useTheme();
  const isExtraSmaller = useMediaQuery(theme.breakpoints.down('xs'));
  const isSmaller = useMediaQuery(theme.breakpoints.down('sm'));

  useEffect(() => {
    editMode && data && setRole(data.role);
  }, [data]);

  const handleSubmit = async (values, { setSubmitting }) => {
    setSubmittingGlobal(true);
    if (cancelUpdate) {
      return;
    }

    values.role = role;
    values.phone = setAmericanPhoneCode(values.phone);
    const details = editMode ? { ...data, ...values } : { ...values };

    try {
      if (editMode) {
        await userService.updateUser(details, userId);
        toastNotification('info', t('userIsUpdated'));
        navHistory.goBack();
      } else {
        await userService.createUser(details);
        toastNotification('info', t('newUser'));
        navHistory.goBack();
      }
    } catch (error) {
      toastNotification('error', t(`error:${Object.values(error.response.data.errors)[0]}`));
    }
    setSubmitting(false);
    setSubmittingGlobal(false);
  };

  const onSubmit = async (values, { setSubmitting }) => {
    if (isAdmin(user?.role) && values.companyId === '') {
      values.companyId = null;
    }
    const company = dataCompany.items.find(c => c.value === values.companyId);
    if (company?.status === 0) {
      setIsConfirmOpen(true);
    } else {
      handleSubmit(values, { setSubmitting });
    }
  };

  const getUserSchema = (isCompanyRequired) => {
    return {
      name: Yup.string()
        .max(100, t('nameValidation'))
        .required(t('required')),
      surname: Yup.string()
        .max(100, t('surnameValidation'))
        .required(t('required')),
      phone: Yup.string()
        .max(50, t('phoneValidation'))
        .matches(phoneRegExp, t('invalidPhoneFormat'))
        .required(t('required')),
      email: Yup.string()
        .max(300, t('emailValidation'))
        .email(t('invalidEmail'))
        .required(t('required')),
      newPassword: Yup.string()
        .min(8, t('tooShort'))
        .max(50, t('tooLong')),
      confirmNewPassword: Yup.string()
        .oneOf([Yup.ref('newPassword'), null], t('doesNotMatch'))
        .min(8, t('tooShort'))
        .max(50, t('tooLong')),
      companyId: isCompanyRequired && Yup.string()
        .required(t('required')),
    };
  };

  const userSchema = Yup.object().shape(getUserSchema(role !== 1));

  const handleCancel = () => {
    setCancelUpdate(true);
    navHistory.goBack();
  };

  const handleDelete = () => {
    setCancelUpdate(true);
    const response = userService.deleteUser(userId);
    response.then(res => {
      if (res && res.status === 200) {
        toastNotification('info', t('userIsDeleted'));
        navHistory.goBack();
      }
    }).catch((error) => {
      toastNotification('error', t(`error:${Object.values(error.response.data.errors)[0]}`));
    });
  };

  const deleteUserDialog = () => {
    setDialogOpen(true);
  };

  const handleRoles = loggedInUser => {
    const userRole = roles.find(r => loggedInUser.role === r.value);
    return t(userRole.label);
  };

  const HandleSelectRole = (prop) => {

    return (
      <FormControl component='fieldset'>
        <RadioGroup aria-label='position' row {...prop}>
          {roles.slice(1).map((relation, index) => {
            return (
              <FormControlLabel
                key={index}
                value={relation.value}
                control={
                  <Radio
                    checkedIcon={<RadioButtonCheckedIcon className={classes.iconStyle} />}
                    icon={<RadioButtonUncheckedIcon className={classes.uncheckedIconStyle} />} />
                }
                label={t(relation.label)} />
            );
          })}
          {isAdmin(user?.role) && <FormControlLabel
            key={4}
            value={roles[0].value}
            control={
              <Radio
                checkedIcon={<RadioButtonCheckedIcon className={classes.iconStyle} />}
                icon={<RadioButtonUncheckedIcon className={classes.uncheckedIconStyle} />} />
            }
            label={t(roles[0].label)} />}
        </RadioGroup>
      </FormControl>
    );
  };
  return (
    <div className={isSmaller ? classes.modalPhone : classes.modal}>
      <BackButton callback={() => navHistory.replace('/users')} />
      <Container maxWidth={false} className={classes.container}>
        <DeleteDialog isOpen={isOpen} setDialogOpen={setDialogOpen} callback={handleDelete} fullName={data && `${data.name} ${data.surname}`} />
        <SadStates
          states={[
            {
              when: isLoading || isSubmittingGlobal,
              render: <Loader />
            }
          ]}>
          <Formik
            initialValues={editMode ? {
              name: data.name,
              surname: data.surname,
              phone: data.phone,
              email: data.email,
              role: data.role,
              companyId: data.company?.id
            } : {
              name: '',
              surname: '',
              phone: '',
              email: '',
              role: role,
              newPassword: '',
              confirmNewPassword: '',
              companyId: isSalesMng(user?.role) ? user?.company?.id ?? '' : ''
            }
            }
            validationSchema={userSchema}
            onSubmit={onSubmit}>
            {({ isSubmitting, dirty, setFieldValue, values, setSubmitting }) => (
              <Form className={classes.form}>
                <EmptyDialog isOpen={isConfirmOpen} setDialogOpen={setIsConfirmOpen} callback={() => handleSubmit(values, { setSubmitting })} headerText={confimDialogMessage} />
                <Grid className={classes.sectionTitle} container>
                  <Grid item xs={12} sm={12} md={3} lg={3} />
                  <Grid item xs={12} sm={12} md={6} lg={6}>
                    <h1 className={classes.header}>{editMode ? `${data.name} ${data.surname}` : t('createUser')}</h1>
                  </Grid>
                </Grid>
                <Grid container justifyContent='center' spacing={2}>
                  <Grid item xs={12} sm={6} md={3} lg={3}>
                    <InputField label='name' inputName='name' isRequired />
                  </Grid>
                  <Grid item xs={12} sm={6} md={3} lg={3}>
                    <InputField label='surname' inputName='surname' isRequired />
                  </Grid>
                </Grid>
                <Grid container justifyContent='center' spacing={2}>
                  <Grid item xs={12} sm={6} md={3} lg={3}>
                    <InputField label='phone' inputName='phone' isRequired />
                  </Grid>
                  <Grid item xs={12} sm={6} md={3} lg={3}>
                    <InputField label='email' inputName='email' readOnly={editMode} isRequired />
                  </Grid>
                </Grid>
                {role !== 1 &&
                  <Grid container justifyContent='center' spacing={2}>
                    <Grid item xs={12} sm={6} md={3} lg={3}>
                      <FormSelectFilter
                        inputName='companyId'
                        isRequired
                        label={t('companyId')}
                        onChange={(e) => {
                          setFieldValue('companyId', Number(e.target.value));
                        }}
                        hidden={isSalesMng(user && user.role)}
                        value={values.companyId}
                        menuItem={dataCompany?.items ?? []} />
                    </Grid>
                    <Grid item xs={12} sm={6} md={3} lg={3}></Grid>
                  </Grid>}
                {!isAdmin(data?.role) &&
                  <Grid container className={classes.selectRoleContainer}>
                    <Grid item xs={12} sm={12} md={3} lg={3} />
                    <Grid className={classes.selectRole} item>
                      {t('selectRole')}
                    </Grid>
                    <Grid className={classes.isRequired} item>
                      <p>*</p>
                    </Grid>
                    <Grid className={classes.formFields} item>
                      <Field
                        as={HandleSelectRole}
                        value={role}
                        onChange={(e) => {
                          setRole(Number(e.target.value));
                          setFieldValue('role', e.target.value);
                        }
                        }
                        name='relationType' />
                    </Grid>
                  </Grid>}
                {isAdmin(data?.role) &&
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={12} md={3} />
                    <Grid item xs={12} sm={12} md={3}>
                      <p className={classes.role}>{t('role')}</p>
                      <p>{handleRoles(data)}</p>
                    </Grid>
                  </Grid>}
                {editMode &&
                  <Grid className={classes.sectionTitle} justifyContent='flex-start' container spacing={2}>
                    <Grid item xs={12} sm={12} md={3} lg={3} />
                    <Grid item xs={12} sm={6} md={4} lg={4}>
                      <h1 className={classes.header}>{t('changeUserPassword')}</h1>
                    </Grid>
                    {!userPassword &&
                      <Grid item xs={4} sm={3} md={2} lg={2}>
                        <Button text='resetPassword' color='secondary' disabled={!editMode || isSubmitting} callback={() => setUserPassword(true)} type='submit' />
                      </Grid>
                    }
                    {userPassword &&
                      <Grid container justifyContent='flex-start' spacing={2}>
                        {!isSmaller && <Grid item md={3} />}
                        <Grid item xs={12} sm={6} md={3} lg={3}>
                          <PasswordInputField label='newPassword' inputName='newPassword' isRequired />
                        </Grid>
                        <Grid item xs={12} sm={6} md={3} lg={3}>
                          <PasswordInputField label='confirmNewPassword' inputName='confirmNewPassword' isRequired />
                        </Grid>
                      </Grid>
                    }
                  </Grid>
                }
                <Grid container spacing={2} className={classes.userActions}>
                  {editMode && !isSalesMng(user?.role) &&
                    <Grid item xs={4} sm={3} md={2}>
                      <Button type='reset' text={t('delete')} color='primary' disabled={editMode && userId === user.id.toString()} callback={deleteUserDialog} smallButton={true} />
                    </Grid>}
                  {!isExtraSmaller && <Grid item sm={editMode ? 6 : 8} md={editMode ? 6 : 8} lg={editMode ? 6 : 8} />}
                  <Grid item xs={4} sm={3} md={2}>
                    <Button type='reset' text='cancel' color='primary' callback={() => handleCancel()} smallButton={true} />
                  </Grid>
                  <Grid item xs={4} sm={3} md={2}>
                    <Button text='save' disabled={isSubmitting || !dirty} type='submit' smallButton={true} />
                  </Grid>
                </Grid>
              </Form>
            )}
          </Formik>
        </SadStates>
      </Container>
    </div>
  );
};

UserForm.propTypes = {
  match: PropTypes.object
};

