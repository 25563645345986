import axios from 'axios';
import { getApiCoreUrl } from 'helpers';
import qs from 'qs';

const baseUrl = `${getApiCoreUrl()}/api/v1/User`;

const getCurrentUser = () => {
  const url = `${baseUrl}/get_current`;
  return axios.get(url).then(response => {
    return response.data;
  });
};

const getUsers = (page = 0, rowsPerPage = 10, orderBy = '', filter = {}) => {
  const url = `${baseUrl}?${qs.stringify({
    offset: page * rowsPerPage,
    limit: rowsPerPage,
    orderBy: orderBy,
    search: filter.search ? filter.search : '',
    role: filter.role === -1 ? null : filter.role - 1
  })}`;

  return axios.get(url).then(response => {
    return response.data;
  });
};

const getUserById = (id) => {
  const url = `${baseUrl}/${id}`;
  return axios.get(url).then(response => {
    return response.data;
  });
};

const createUser = (details) => axios.post(baseUrl, details);

const updateUser = (user, id) => {
  const url = `${baseUrl}/${id}`;
  return axios.put(url, user);
};

const updateLoginUser = (details) => {
  return axios.put(baseUrl, details);
};

const deleteUser = (id) => {
  const url = `${baseUrl}/${id}`;
  return axios.delete(url);
};

const resendInvite = (value) => {
  const url = `${baseUrl}/resend_invitation`;
  const obj = { email: value };
  return axios.post(url, obj);
};

export const userService = {
  getCurrentUser,
  getUsers,
  getUserById,
  createUser,
  updateUser,
  updateLoginUser,
  deleteUser,
  resendInvite
};