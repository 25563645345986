import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { FormControl, Select, MenuItem, Grid, ListItemIcon, Typography } from '@material-ui/core';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';

import { useStyles } from './SelectFilter.css.js';

export const IconSelectFilter = ({ value, menuItem, onChange, width }) => {

  const classes = useStyles();
  const [t] = useTranslation('common');

  return (
    <FormControl className={classes.iconSelectContainer}>
      <Grid container className={classes.childContainer}>
        <Grid item md={width} className={classes.inputContainer}>
          <Select
            IconComponent={ArrowDropDownIcon}
            classes={{ root: classes.select }}
            disableUnderline
            MenuProps={{
              anchorOrigin: {
                vertical: 'top',
                horizontal: 'left',
              },
              transformOrigin: {
                vertical: 'top',
                horizontal: 'left',
              },
              getContentAnchorEl: null
            }}
            className={classes.input}
            value={value}
            onChange={(e) => {
              onChange(e);
            }}>
            {menuItem.map(c => (
              <MenuItem
                classes={{ root: classes[c.value] }}
                key={c.value} value={c.value}>
                <ListItemIcon classes={{ root: classes.root }} style={{ backgroundColor: c.color ? c.color : 'inherit' }} className={`${classes.imgContainer} ${classes[c.label]}`}>
                  <img className={classes.img} src={c.icon} alt={''} />
                </ListItemIcon>
                <Typography className={classes.wrap}>
                  {t(c.label)}
                </Typography>
              </MenuItem>
            ))}
          </Select>
        </Grid>
      </Grid>
    </FormControl>
  );
};

IconSelectFilter.propTypes = {
  value: PropTypes.number,
  menuItem: PropTypes.array,
  onChange: PropTypes.func,
  width: PropTypes.number
};
