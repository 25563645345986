import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(theme => ({
  container: {
    border: '1px solid' + theme.secondaryBorderColor,
    borderRadius: '0.313rem'
  },
  scrollBottom: {
    height: '100%',
    width: '100%'
  },
  header: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    borderBottom: '1px solid' + theme.secondaryBorderColor
  },
  conversationHistory: {
    display: 'flex',
    justifyContent: 'row',
    padding: '0.5rem',
    marginLeft: '1rem',
    cursor: 'pointer'
  },
  conversationHistoryText: {
    fontSize: '1.125rem',
    fontWeight: 'bold'
  },
  img: {
    marginRight: '0.5rem',
    marginTop: '0.4rem'
  },
  viewThread: {
    padding: '0.5rem',
    marginRight: '1rem',
    cursor: 'pointer'
  },
  sendIcon: {
    backgroundColor: theme.tertiaryBackgroundColor,
    padding: '1rem',
    borderRadius: '50%',
    height: '3rem',
    marginRight: '0.5rem'
  },
  viewThreadText: {
    color: theme.primaryBackgroundColor,
    fontSize: '1.125rem',
    fontWeight: 'bold'
  },
  scrollButton: {
    display: 'none'
  },
  conversation: {
    height: '40rem',
    display: 'flex',
    justifyContent: 'center',
    overflowY: 'auto',
    flexDirection: 'column-reverse',
    overscrollBehavior: 'contain'
  },
  gradient: {
    height: '100%',
    backgroundColor: 'purple',
    zIndex: '200'
  },
  footer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '0.5rem',
    borderTop: '1px solid' + theme.secondaryBorderColor
  },
  marginLeft: {
    marginLeft: '1rem'
  },
  writeSomething: {
    paddingRight: '2rem',
  },
  sendContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '10px 0 10px 0'
  },
  buttonSendSms: {
    cursor: 'pointer',
    border: 'none',
    background: 'none',
    borderRadius: '50%',
  },
  convMessage: {
    display: 'block',
  },
  widthInherit: {
    width: 'inherit'
  },
  loadMore: {
    width: '100%',
    cursor: 'pointer'
  },
  buttonDisabled: {
    backgroundColor: theme.primaryTextColor,
    color: theme.quinaryBackgroundColor,
    border: 'none'
  },
  '@media screen and (max-width: 960px)': {
    footer: {
      flexDirection: 'column',
      FlexWrap: 'nowrap'
    },
    sendContainer: {
      alignSelf: 'normal',
      columnGap: '20%',
      justifyContent: 'space-between'
    },
    viewThreadText: {
      display: 'none'
    }
  }
}));