import React from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@material-ui/core';
import { SwitchItemButton } from './SwitchItemButton';

import { useStyles } from './SwitchButton.css.js';

export const gifRelationsEnum = {
  general: 'general',
  company: 'company',
  private: 'private'
};

export const SwitchGifRelations = ({ value, setChecked }) => {
  const classes = useStyles();
  return (
    <Grid container spacing={2} className={classes.containerGif}>
      <SwitchItemButton
        md={4}
        label={gifRelationsEnum.general}
        checked={value === gifRelationsEnum.general}
        setChecked={() => {
          setChecked(gifRelationsEnum.general);
        }} />
      <SwitchItemButton
        md={4}
        label={gifRelationsEnum.company}
        checked={value === gifRelationsEnum.company}
        setChecked={() => {
          setChecked(gifRelationsEnum.company);
        }} />
      <SwitchItemButton
        md={4}
        label={gifRelationsEnum.private}
        checked={value === gifRelationsEnum.private}
        setChecked={() => {
          setChecked(gifRelationsEnum.private);
        }} />
    </Grid>
  );
};

SwitchGifRelations.propTypes = {
  value: PropTypes.string,
  setChecked: PropTypes.func
};