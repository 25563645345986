import React, { useState } from 'react';

import { Grid } from '@material-ui/core';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { InputField, NoteInputField, FormSelectFilter } from 'components';
import { formatNumber, arrayOfTerms } from 'helpers';
import FormButtons from './FormButtons';
import { useStyles } from '../style';

const FinanceTradeInForm = ({ offer, onCounterOffer, onCancel }) => {
  const classes = useStyles();
  const { t } = useTranslation(['common', 'error']);
  const [priceValue, setPriceValue] = useState(offer.price);

  const validationSchema = Yup.object().shape({
    price: Yup.number()
      .required(t('required'))
      .min(0, t('priceMinValidation'))
      .max(1000000000, t('priceMaxValidation'))
      .typeError(t('pleaseEnterNumber')),
    tradeInValue: Yup.number()
      .required(t('required'))
      .min(0, t('tradeInValueMinValidation'))
      .max(priceValue, t('tradeInValuePriceValidation'))
      .typeError(t('pleaseEnterNumber')),
    term: Yup.number()
      .required(t('required'))
      .min(0, t('termMinValidation'))
      .max(1000000000, t('termMaxValidation'))
      .typeError(t('pleaseEnterNumber')),
    rate: Yup.number()
      .required(t('required'))
      .min(0, t('rateMinValidation'))
      .max(1000000000, t('rateMaxValidation'))
      .typeError(t('pleaseEnterNumber')),
    downPayment: Yup.number()
      .required(t('required'))
      .min(0, t('downPaymentMinValidation'))
      .max(1000000000, t('downPaymentMaxValidation'))
      .typeError(t('pleaseEnterNumber')),
    payment: Yup.number()
      .required(t('required'))
      .min(0, t('paymentMinValidation'))
      .max(1000000000, t('paymentMaxValidation'))
      .typeError(t('pleaseEnterNumber')),
    internalNotes: Yup.string()
      .max(1000, t('notesValidation'))
  });

  return (
    <Formik
      initialValues={{
        price: offer.price,
        tradeInValue: offer.tradeInValue,
        term: offer.term,
        rate: offer.rate,
        downPayment: offer.downPayment,
        payment: offer.payment,
        internalNotes: undefined,
      }}
      validationSchema={validationSchema}
      onSubmit={onCounterOffer}>
      {({ isSubmitting, values, setFieldValue, dirty }) => (
        <Form className={classes.form}>
          <Grid container
            direction='row'
            justifyContent='center'
            alignItems='center'
            spacing={0}>
            <p className={classes.formHeader}>Provide your Counter offer details:</p>
          </Grid>
          <Grid container spacing={0}>
            <Grid item xs={12} md={6}>
              <InputField label='price$' inputName='price' type='text' defaultValue={values.price}
                disabled={!!offer.priceLock}
                isRequired={!offer.priceLock}
                isNumber isMoney
                className={clsx(classes.inputField, { [classes.inputFieldLock]: offer.priceLock })}
                onChange={(e) => {
                  const val = formatNumber(e.target.value);
                  setPriceValue(val);
                  setFieldValue('price', val);
                }} />
            </Grid>
            <Grid item xs={12} md={6}>
              <InputField label='tradeInV$' inputName='tradeInValue' type='text' defaultValue={values.tradeInValue}
                disabled={!!offer.tradeInValueLock}
                isRequired={!offer.tradeInValueLock}
                isNumber isMoney
                className={clsx(classes.inputField, { [classes.inputFieldLock]: offer.tradeInValueLock })}
                onChange={(e) => {
                  setFieldValue('tradeInValue', formatNumber(e.target.value));
                }} />
            </Grid>
          </Grid>
          <Grid container spacing={0}>
            <Grid item xs={12} md={6}>
              <FormSelectFilter
                disabled={!!offer.termLock}
                isRequired={!offer.termLock}
                label={t('termFinance')}
                className={clsx(classes.selectField, { [classes.selectFieldLock]: offer.termLock })}
                onChange={(e) => {
                  setFieldValue('term', Number(e.target.value));
                }}
                value={values.term}
                menuItem={arrayOfTerms} />
            </Grid>
            <Grid item xs={12} md={6}>
              <InputField label='rate%' inputName='rate' type='text' defaultValue={values.rate}
                disabled={!!offer.rateLock}
                isRequired={!offer.rateLock}
                isNumber
                className={clsx(classes.inputField, { [classes.inputFieldLock]: offer.rateLock })}
                onChange={(e) => {
                  setFieldValue('rate', formatNumber(e.target.value));
                }} />
            </Grid>
          </Grid>
          <Grid container spacing={0}>
            <Grid item xs={12} md={6}>
              <InputField label='downPayment$' inputName='downPayment' type='text' defaultValue={values.downPayment}
                disabled={!!offer.downPaymentLock}
                isRequired={!offer.downPaymentLock}
                isNumber isMoney
                className={clsx(classes.inputField, { [classes.inputFieldLock]: offer.downPaymentLock })}
                onChange={(e) => {
                  setFieldValue('downPayment', formatNumber(e.target.value));
                }} />
            </Grid>
            <Grid item xs={12} md={6}>
              <InputField label='payment$' inputName='payment' type='text' defaultValue={values.payment}
                disabled={!!offer.paymentLock}
                isRequired={!offer.paymentLock}
                isNumber isMoney
                className={clsx(classes.inputField, { [classes.inputFieldLock]: offer.paymentLock })}
                onChange={(e) => {
                  setFieldValue('payment', formatNumber(e.target.value));
                }} />
            </Grid>
          </Grid>
          <Grid container spacing={0}>
            <Grid item xs={12} md={12}>
              <NoteInputField label='notes' inputName='internalNotes' type='text' className={classes.textareaField} />
            </Grid>
          </Grid>
          <FormButtons disabled={isSubmitting || !dirty} onCancel={onCancel} />
        </Form>
      )}
    </Formik>
  );
};

FinanceTradeInForm.propTypes = {
  offer: PropTypes.object,
  onCounterOffer: PropTypes.func,
  onCancel: PropTypes.func,
};

export default FinanceTradeInForm;
