import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  container: {
    width: '1.25rem',
    lineHeight: '1.25rem',
    height: '1.25rem',
    backgroundColor: theme.primaryBackgroundColor,
    borderRadius: '50%',
    marginBottom: '0.2rem'
  },
  text: {
    margin: 0,
    textAlign: 'center',
    fontSize: '0.625rem',
    fontWeight: 'bold'
  },
  ninetyNinePlus: {
    margin: 0,
    textAlign: 'center',
    fontSize: '0.525em',
    fontWeight: 'bold',
    marginLeft: '1px'
  }
}));