import React, { useState, useEffect } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import { useHistory } from 'react-router-dom';
import { Grid, Container, useMediaQuery, useTheme } from '@material-ui/core';

import {
  Button, BackButton, IconSelectFilter, DeleteDialog, ViewLead, ConversationForm,
  SadStates, Loader, toastNotification, EmptyDialog
} from 'components';
import { leadStatus, formatDateForTable } from 'helpers';
import { editIcon, deleteIcon } from 'resources';
import { leadService } from 'services';

import { useStyles } from './ViewLeadPage.css.js';

const DRAFT = 0;
const RESOLUTION_WIDTH_TWO_COLUMNS = 767;
const SHOW_N_PROPERTIES = 4;

const REFETCH_INTERVAL_MS = 10000;

export const ViewLeadPage = props => {
  const classes = useStyles();
  const { t } = useTranslation(['common', 'error']);
  const [leadId] = useState(props.match.params.id);
  const navHistory = useHistory();
  const [leadStatusValue, setLeadStatusValue] = useState(DRAFT);
  const [isOpen, setDialogOpen] = useState(false);
  const [isOptInOpen, setOptInOpen] = useState(false);
  const [isOptInSent, setIsOptInSent] = useState(false);
  const [isOptInSending, setIsOptInSending] = useState(false);

  const { isLoading, data = {}, refetch: refetchLead } = useQuery(
    ['viewForm'], () => leadService.getLeadById(leadId)
    , { cacheTime: 0, refetchInterval: REFETCH_INTERVAL_MS });
  const [screenWidth] = useState(window.screen.width);
  const [showNElement, setShowNElement] = useState(screenWidth >= RESOLUTION_WIDTH_TWO_COLUMNS ? data.Length : SHOW_N_PROPERTIES);

  const theme = useTheme();
  const isExtraSmaller = useMediaQuery(theme.breakpoints.down('xs'));
  const isSmaller = useMediaQuery(theme.breakpoints.down('sm'));
  const userName = `${data?.name} ${data?.surname}`;

  useEffect(() => {
    if (data?.status) {
      setLeadStatusValue(data.status);
    }

    if (screenWidth >= RESOLUTION_WIDTH_TWO_COLUMNS) {
      setShowNElement(data?.Length);
    }
  }, [data]);

  const handleChangeStatus = async (e) => {
    setLeadStatusValue(e.target.value);
    const details = { ...data, status: e.target.value };
    try {
      await leadService.updateLead(details, leadId);
      toastNotification('info', t('leadIsUpdated'));
    } catch (error) {
      toastNotification('error', t(`error:${Object.values(error.response.data.errors)[0]}`));
    }
  };

  const handleDelete = async () => {
    try {
      await leadService.deleteLead(leadId);
      toastNotification('info', t('leadIsDeleted'));
      navHistory.goBack();
    } catch (error) {
      toastNotification('error', t(`error:${Object.values(error.response.data.errors)[0]}`));
    }
  };

  const handleShowHideTable = () => {
    if (screenWidth < RESOLUTION_WIDTH_TWO_COLUMNS) {
      if (showNElement === SHOW_N_PROPERTIES) {
        setShowNElement(data.Length);
      } else {
        setShowNElement(SHOW_N_PROPERTIES);
      }
    } else {
      setShowNElement(data.Length);
    }
  };

  const handleOptInSend = async () => {
    try {
      setIsOptInSending(true);
      await leadService.sendOptInSms(leadId);
      setIsOptInSent(true);
      toastNotification('info', t('optInSmsSended').replace('{name}', userName));
    } catch (error) {
      toastNotification('error', t(`error:${Object.values(error.response.data.errors)[0]}`));
    }
    setIsOptInSending(false);
  };

  return (
    <div className={isSmaller ? classes.modalPhone : classes.modal}>
      <Grid container className={classes.spaceBetween} spacing={3}>
        <Grid item md={3} className={classes.backContainer} >
          <BackButton callback={() => navHistory.push('/leads')} />
        </Grid>
        <Grid item md={3} className={classes.statusContainerPhone} container spacing={1}>
          <Grid item md={3} container className={classes.statusContainerContentPhone}>
            <Grid item md={5} className={classes.deleteIcon} onClick={() => setDialogOpen(true)}>
              <img className={classes.img} src={deleteIcon} alt={''} />
            </Grid>
            <Grid item md={5} className={classes.editIcon} onClick={() => navHistory.push(`/leads/edit/${leadId}`)}>
              <img className={classes.img} src={editIcon} alt={''} />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <DeleteDialog isOpen={isOpen} setDialogOpen={setDialogOpen} callback={handleDelete} fullName={data && `${data.name} ${data.surname}`} />
      <EmptyDialog isOpen={isOptInOpen} setDialogOpen={setOptInOpen} callback={handleOptInSend} headerText={t('optInSmsConfirmSend').replace('{name}', userName)} />
      <Container className={classes.container}>
        <SadStates
          states={[
            {
              when: isLoading || isOptInSending,
              render: <Loader />
            }
          ]}>
          <Grid container className={classes.spaceBetween} spacing={2}>
            <Grid item xs={12} sm={12} md={6} lg={6} >
              <Grid item xs={12} sm={12} md={12} lg={12} className={classes.paddingTop}>
                <h1 className={classes.header}>{`${data.name} ${data.surname}`}</h1>
              </Grid>
            </Grid>
            <Grid item xs={12} sm={12} md={6} container className={classes.statusContainer} spacing={1} justifyContent='flex-end'>
              <Grid item sm={4} md={4} container className={clsx(classes.statusContainerContent, classes.hide)}>
                <Grid item xs={1} sm={3} md={5} lg={4} className={classes.deleteIcon} onClick={() => setDialogOpen(true)}>
                  <img className={classes.img} src={deleteIcon} alt={''} />
                </Grid>
                <Grid item xs={1} sm={3} md={5} lg={4} className={classes.editIcon} onClick={() => navHistory.push(`/leads/edit/${leadId}`)}>
                  <img className={classes.img} src={editIcon} alt={''} />
                </Grid>
              </Grid>
              <Grid item xs={12} sm={8} md={8} container className={classes.statusContainerContent}>
                <Grid item md={2} className={clsx(classes.marginRight, classes.hide)}>
                  <p className={classes.statusLabel}>{t('status')}:</p>
                </Grid>
                <Grid item xs={12} sm={8} md={8} className={classes.selectStatus}>
                  <IconSelectFilter onChange={handleChangeStatus} value={leadStatusValue} menuItem={leadStatus} />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid container className={classes.dateContainer}>
            <Grid item className={classes.marginRightBorder}>
              <p className={classes.leadId}>#{data.id}</p>
            </Grid>
            <Grid item className={clsx(classes.displayFlex, classes.marginRightBorder, classes.paddingLeft)}>
              <p className={clsx(classes.dateLabel, classes.marginNull)}>{t('dateCreated')}:</p>
              <div className={classes.marginNull}>{formatDateForTable(data.createdAt)}</div>
            </Grid>
            <Grid item className={clsx(classes.displayFlex, classes.paddingLeft)}>
              <p className={clsx(classes.dateLabel, classes.marginNull)}>{t('lastModified')}:</p>
              <div className={classes.marginNull}>{formatDateForTable(data.modifiedAt)}</div>
            </Grid>
          </Grid>
          <Grid container spacing={5}>
            <Grid item lg={6} sm={6} md={6} className={classes.viewLeadContainer}>
              <ViewLead data={data} showNElement={showNElement} onOptInClick={() => setOptInOpen(true)} isOptInSent={isOptInSent} />
              {isExtraSmaller &&
                <Grid item className={classes.ShowMoreButtonContainer}>
                  <Button type='button' className={classes.ShowMoreButton} text={showNElement === SHOW_N_PROPERTIES ? t('showMore') : t('showLess')} color='primary' callback={handleShowHideTable} showMoreButton={true} />
                </Grid>}
            </Grid>
            <Grid item lg={6} xs={12} sm={6} md={6}>
              <ConversationForm leadData={data} leadId={leadId} refetchLead={refetchLead} />
            </Grid>
          </Grid>
        </SadStates>
      </Container>
    </div>
  );
};

ViewLeadPage.propTypes = {
  match: PropTypes.object
};