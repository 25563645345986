import React from 'react';
import PropTypes from 'prop-types';

import { useStyles } from './Header.css.js';

export const Header = ({title}) => {
  const classes = useStyles();
  return (
    <h1 className={classes.h}>{title}</h1>
  );
};

Header.propTypes = {
  title: PropTypes.string
};