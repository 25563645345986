import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  fullHeight: {
    height: '100%'
  },
  mediaSectionRoot: {
    backgroundColor: theme.quinaryBackgroundColor
  },
  mediaSectionColumn: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    justifyContent: 'space-between'
  },
  logoImage: {
    boxSizing: 'border-box',
    flexGrow: 1,
    padding: '2rem 3rem 3rem 3rem'
  },
  introImageSection: {
    flexGrow: 3,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  introImage: {
    width: '25%'
  },
  introTextSection: {
    boxSizing: 'border-box',
    flexGrow: 1,
    color: theme.tertiaryTextColor,
    fontSize: '3.5rem',
    padding: '2rem 3rem 3rem 3rem'
  },
  introText: {
    margin: 0,
    fontFamily: theme.primaryFontFamily
  },
  formSectionRoot: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center'
  },
  logo: {
    display: 'block',
    textAlign: 'center',
    margin: 'auto',
    paddingTop: '1.5rem'
  }
}));
