import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { useQuery } from 'react-query';
import * as Yup from 'yup';
import ScrollToBottom from 'react-scroll-to-bottom';
import { Grid, Tooltip } from '@material-ui/core';
import { Formik, Form } from 'formik';

import { Button, ButtonWithTooltip, ConversationInputField, toastNotification, ConversationMessage, SadStates, Loader, EmptyDialog } from 'components';
import { conversationMessageService, offerService } from 'services';
import { conversationMessageSenderType, rows, getPaidFeaturesError, optInSmsType } from 'helpers';
import { noteIcon, sendIcon, emptyChatIcon } from 'resources';

import { useStyles } from './ConversationForm.css';

const REFETCH_INTERVAL_MS = 10000;

export const ConversationForm = ({ leadData, leadId, refetchLead }) => {
  const classes = useStyles();
  const { t } = useTranslation(['common', 'error']);
  const [customerName] = useState(leadData.name + ' ' + leadData.surname);
  const [userName] = useState(leadData.user?.name + ' ' + leadData.user?.surname);
  const [searchIndex] = useState(leadData.user?.apiPhone + ':' + leadData.phone);
  const [rowsPerPage, setRowsPerPage] = useState(rows);
  const [refetchInterval] = useState(REFETCH_INTERVAL_MS);
  const [isLoadingMoreMessages, setIsLoadingMoreMessages] = useState(false);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [counterofferId, setCounterofferId] = useState(false);
  const { isLoading, data = {}, refetch } = useQuery(
    ['conversationForm'], () => conversationMessageService.getConversationMessages(leadId, searchIndex, rowsPerPage)
    , { isLoadingMoreMessages, refetchInterval: refetchInterval });
  const navHistory = useHistory();
  const [isSmsSent, setIsSmsSent] = useState(false);

  const refNewFirstElement = useRef();
  const paidFeatureError = getPaidFeaturesError(leadData?.user.company ?? {}, leadData?.defaultChannel);
  const disableCreateButton = leadData?.user.company ?
    !leadData.user.company.paidSms && !leadData.user.company.paidEmail : false;
  const optInError = leadData.optInSms !== optInSmsType.Agreed &&
    leadData?.defaultChannel === 0 &&
    !leadData?.user.company.optInDisabled ?
    t('optInDisagreedTooltip') : '';

  useEffect(() => {
    if (!isLoading && data.items.length <= rowsPerPage) {
      refNewFirstElement && refNewFirstElement.current && refNewFirstElement.current.scrollIntoView({ behavior: 'auto' });
    }
    setIsLoadingMoreMessages(false);
  }, [isLoading, data]);

  useEffect(() => {
    refetch();
  }, [isLoadingMoreMessages]);

  const onAcceptCounterOffer = (id) => {
    setIsDialogOpen(false);
    offerService.acceptCounterOffer(id).then(() => {
      refNewFirstElement.current.scrollIntoView({ behavior: 'auto' });
      toastNotification('info', t('counterOfferAccepted'));
    });
  };

  const onAcceptCounterOfferClick = (id) => {
    setCounterofferId(id);
    setIsDialogOpen(true);
  };

  const onEditCounterOfferClick = (id) => {
    navHistory.push(`/leads/view/${leadId}/sendOffer?counteroffer=${id}`);
  };

  const handleSubmit = async (values, { setSubmitting }) => {
    try {
      await conversationMessageService.createConversatonMessage(values);
      values.body = '';
      setSubmitting(false);
      refetch();
      refetchLead();
      setIsSmsSent(true);
    } catch (error) {
      toastNotification('error', t(`error:${Object.values(error.response.data.errors)[0]}`));
    }
  };

  const validationSchema = Yup.object().shape({
    body: Yup.string()
      .required(t('required'))
      .max(500, t('smsMessageValidation')),
  });

  return (
    <div className={classes.container}>
      <EmptyDialog isOpen={isDialogOpen} setDialogOpen={setIsDialogOpen} callback={onAcceptCounterOffer}
        id={counterofferId} headerText={t('acceptCounterOfferConfirmMessage')} />
      <Grid container className={classes.header}>
        <Grid item className={classes.conversationHistory}>
          <img className={classes.img} src={noteIcon} alt={''} />
          <p className={classes.conversationHistoryText}>{t('conversationHistory')}</p>
        </Grid>
        <Grid item className={classes.viewThread}>
          <p className={classes.viewThreadText}>{t('viewThread')}</p>
        </Grid>
      </Grid>
      <Grid container className={classes.conversation}>
        <SadStates
          states={[
            {
              when: isLoading,
              render: <Loader />
            }
          ]}>
          {data.total === 0 ?
            <img src={emptyChatIcon} alt={''} /> :
            <ScrollToBottom initialScrollBehavior='auto' followButtonClassName={classes.scrollButton} mode='bottom' className={classes.scrollBottom}>
              <Grid style={{ display: 'flex', flexDirection: 'column-reverse' }}>
                {data.items?.map((message, i) => {
                  return (
                    <ConversationMessage
                      onAcceptCounterOffer={onAcceptCounterOfferClick}
                      disableAcceptButton={paidFeatureError !== '' || optInError !== ''}
                      tooltipAcceptTitle={paidFeatureError === '' ? optInError : paidFeatureError}
                      onEditCounterOffer={onEditCounterOfferClick}
                      disableEditButton={disableCreateButton}
                      tooltipEditTitle={disableCreateButton ? paidFeatureError : ''}
                      message={message}
                      key={message.id}
                      customerName={customerName}
                      userName={userName}
                      className={classes.convMessage}
                      setIsSmsSent={setIsSmsSent}
                      showButton={message.entityId && !leadData.isExpired}
                      refNewFirstElement={rowsPerPage - rows === i || isSmsSent ? refNewFirstElement : null} />
                  );
                })}
                {data.total !== 0 &&
                  <Grid className={classes.loadMore}>
                    {rowsPerPage < data?.total &&
                      <Grid item md={12} sm={12} xs={12}>
                        <Button color='lightDefaultColor' text='loadMore'
                          callback={() => {
                            setRowsPerPage(rowsPerPage + rows);
                            setIsLoadingMoreMessages(true);
                            setIsSmsSent(false);
                          }} />
                      </Grid>}
                  </Grid>}
              </Grid>
            </ScrollToBottom>
          }
        </SadStates>
      </Grid>
      <Formik
        initialValues={{
          userPhone: leadData.user?.apiPhone,
          customerPhone: leadData.phone,
          conversationMessageSender: conversationMessageSenderType.user,
          body: '',
          conversationMessageType: 1,
          leadId: leadId
        }}
        validationSchema={validationSchema}
        validateOnChange={false}
        validateOnBlur={false}
        onSubmit={handleSubmit}>
        {() => (
          <Form className={classes.form}>
            <input type='hidden' value={leadId} name='leadId' />
            <Grid container className={classes.footer}>
              <Tooltip title={paidFeatureError === '' ? optInError : paidFeatureError} placement='bottom'>
                <Grid item xs={12} sm={12} md={8} className={classes.sendContainer}>
                  <ConversationInputField readOnly={paidFeatureError !== '' || optInError !== ''} inputName='body' placeholder='writeSomething' className={classes.writeSomething} />
                  <button type='submit' className={classes.buttonSendSms} disabled={paidFeatureError !== '' || optInError !== ''}>
                    <Grid item className={clsx(classes.sendIcon, paidFeatureError === '' && optInError === '' ? '' : classes.buttonDisabled)} >
                      <img src={sendIcon} alt={''} />
                    </Grid>
                  </button>
                </Grid>
              </Tooltip>
              <Grid item xs={12} sm={12} md={4} className={classes.sendContainer}>
                <ButtonWithTooltip tooltipTitle={disableCreateButton ? paidFeatureError : ''} tooltipPosition='bottom'
                  disabled={disableCreateButton}
                  text='sendOffer'
                  type='button'
                  variant='primary'
                  callback={() => navHistory.push(`/leads/view/${leadId}/sendOffer`)}
                  smallButton={true} />
              </Grid>
            </Grid>
          </Form>
        )}
      </Formik>
    </div>
  );
};
ConversationForm.propTypes = {
  leadData: PropTypes.object,
  leadId: PropTypes.string,
  refetchLead: PropTypes.func
};