import axios from 'axios';
import { getApiCoreUrl } from 'helpers';
import qs from 'qs';
import { baseApiErrorHandler } from 'services';

const baseUrl = `${getApiCoreUrl()}/api/v1/ConversationMessage`;

const getConversationMessages = (leadId, searchIndex = '', rowsPerPage = 50, page = 0, orderBy = 'desc') => {
  const url = `${baseUrl}?${qs.stringify({
    searchIndex: searchIndex,
    offset: page * rowsPerPage,
    limit: rowsPerPage,
    orderBy: orderBy,
    entityId: leadId
  })}`;
  return axios.get(url).then(response => {
    return response.data;
  }).catch(baseApiErrorHandler);
};

const getConversationMessageById = id => {
  const url = `${baseUrl}/${id}`;
  return axios.get(url).then(response => {
    return response.data;
  }).catch(baseApiErrorHandler);
};

const createConversatonMessage = (message) => {
  return axios.post(baseUrl, message).catch(baseApiErrorHandler);
};

export const conversationMessageService = {
  getConversationMessages,
  getConversationMessageById,
  createConversatonMessage
};