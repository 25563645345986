import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import {
  Dialog, DialogActions, Grid,
  DialogTitle, useMediaQuery, IconButton, useTheme
} from '@material-ui/core';
import Close from '@material-ui/icons/Close';

import { Button } from 'components';
import { deleteMessageIcon } from 'resources';
import { checkRunningBrowser, SAFARI } from 'helpers';

import { useStyles } from './DeleteDialog.css.js';

export const DeleteDialog = ({ isOpen, setDialogOpen, callback, id, fullName }) => {

  const classes = useStyles();
  const theme = useTheme();
  const [t] = useTranslation('common');

  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

  const handleConfirm = () => {
    callback(id);
    setDialogOpen(false);
  };

  return (
    <Dialog
      className={classes.container}
      classes={{ paper: checkRunningBrowser() === SAFARI ? classes.dialogPaperSafari : classes.dialogPaper }}
      fullWidth={true}
      maxWidth='md'
      fullScreen={fullScreen}
      open={isOpen}
      onClick={() => setDialogOpen(false)}
      aria-labelledby='responsive-dialog-title'>
      <DialogTitle id='responsive-dialog-title'>
        <IconButton
          disableRipple={true}
          className={classes.closeImg}
          onClick={() => setDialogOpen(false)}>
          <Close />
        </IconButton>
      </DialogTitle>
      <Grid item className={classes.deleteMessageIcon} >
        <img src={deleteMessageIcon} alt={''} />
      </Grid>
      <DialogTitle className={classes.dialogTitle} id='responsive-dialog-title'>
        {t('areYouSure')}
      </DialogTitle>
      <DialogTitle className={classes.dateName}>
        {fullName}
      </DialogTitle>
      <DialogActions>
        <Grid container className={classes.buttonContainer}>
          <Grid item xs={3} sm={3} md={3} lg={3}>
            <Button type='reset' text={t('no')} color='primary' onClick={() => setDialogOpen(false)} />
          </Grid>
          <Grid item xs={3} sm={3} md={3} lg={3}>
            <Button text={t('yes')} callback={handleConfirm} autoFocus />
          </Grid>
        </Grid>
      </DialogActions>
    </Dialog>
  );
};

DeleteDialog.propTypes = {
  isOpen: PropTypes.bool,
  setDialogOpen: PropTypes.func,
  callback: PropTypes.func,
  id: PropTypes.any,
  fullName: PropTypes.string
};