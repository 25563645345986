const themeDefault = {
  primaryTextColor: '#98A0CE',
  primaryBackgroundColor: '#FFCE07',
  secondaryBackgroundColor: '#FFF8DB',
  tertiaryBackgroundColor: '#000000',
  quaternaryBackgroundColor: '#FFFFFF',
  quinaryBackgroundColor: '#F4F5F9',
  senaryBackgroundColor: '#FFFAE5',
  septenaryBackgroundColor: '#4ECE79',
  octonaryBackgroundColor: '#EBEDF9',
  nonaryBackgroundColor: '#EDEBFF',
  denaryBackgroundColor: '#FAF9FF',
  primaryBorderColor: '#CFCDFE',
  secondaryBorderColor: '#ECEBFF',
  validationTextColor: '#FF4714',
  validationBackgroundColor: '#FFE8E2',
  lightGreyColor: '#E0E0E0',
  darkGreyColor: '#888888',
  lightSlateGray: '#778899'
};

export default themeDefault;