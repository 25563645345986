import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(theme => ({
  container: {
    width: '100%',
    paddingLeft: '1rem',
    paddingBottom: '3.5rem',
    position: 'relative'
  },
  spaceBetween: {
    direction: 'row',
    justifyContent: 'space-between',
    alignSelf: 'center',
  },
  backContainer: {
    justifyContent: 'start',
  },
  paddingTop: {
    paddingTop: '1.5rem'
  },
  img: {
    padding: '1.3rem'
  },
  modal: {
    height: 'fit-content',
    minHeight: 'calc(100vh - 9.375rem)',
    padding: '2.5rem',
    backgroundClip: 'content-box',
    boxShadow: 'inset 0 0 0 2.5rem' + theme.quinaryBackgroundColor,
    margin: '0 auto',
    maxWidth: '100%'
  },
  modalPhone: {
    padding: '1rem',
  },
  editIcon: {
    backgroundColor: theme.senaryBackgroundColor,
    width: '3.875rem',
    height: '3.875rem',
    borderRadius: '50%',
    textAlign: 'center',
    cursor: 'pointer'
  },
  deleteIcon: {
    backgroundColor: theme.senaryBackgroundColor,
    width: '3.875rem',
    height: '3.875rem',
    borderRadius: '50%',
    textAlign: 'center',
    marginRight: '1rem',
    cursor: 'pointer'
  },
  header: {
    margin: 0,
    width: '100%',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    lineClamp: 2,
  },
  selectStatus: {
    alignSelf: 'center',
    width: '100%'
  },
  marginRight: {
    marginRight: '1rem',
  },
  statusLabel: {
    margin: 0,
    fontWeight: 'bold'
  },
  marginRightBorder: {
    paddingRight: '1rem',
    borderRight: '1px solid' + theme.primaryTextColor,
    margin: 0,
  },
  displayFlex: {
    display: 'flex'
  },
  marginNull: {
    margin: 0
  },
  dateLabel: {
    paddingRight: '0.2rem',
    color: theme.primaryTextColor
  },
  leadId: {
    margin: 0,
    color: theme.primaryTextColor
  },
  paddingLeft: {
    paddingLeft: '1rem'
  },
  statusContainer: {
    justifyContent: 'flex-end',
    alignItems: 'center'
  },
  statusContainerContent: {
    justifyContent: 'flex-end',
    alignItems: 'center',
  },
  statusContainerPhone: {
    justifyContent: 'flex-end',
    alignItems: 'center',
    display: 'none'
  },
  statusContainerContentPhone: {
    justifyContent: 'flex-end',
    alignItems: 'center',
    display: 'none'
  },
  dateContainer: {
    paddingTop: '1rem',
    paddingBottom: '1rem'
  },
  ShowMoreButtonContainer: {
    justifyContent: 'center',
    display: 'none',
  },
  ShowMoreButton: {
    justifyContent: 'center',
    display: 'none',
  },
  viewLeadContainer: {
    width: '100%'
  },
  '@media screen and (max-width: 599px)': {
    statusContainer: {
      width: '100%'
    },
    statusContainerContent: {
      justifyContent: 'start',
      alignItems: 'center',
    },
    marginRight: {
      display: 'none'
    },
    hide: {
      display: 'none'
    },
    statusContainerPhone: {
      justifyContent: 'flex-end',
      alignItems: 'center',
      display: 'flex'
    },
    statusContainerContentPhone: {
      margin: '0.5rem',
      justifyContent: 'flex-end',
      alignItems: 'center',
      display: 'flex'
    },
    paddingTop: {
      paddingTop: '0'
    },
    ShowMoreButtonContainer: {
      justifyContent: 'center',
      display: 'flex',
      marginTop: '1rem'
    },
    ShowMoreButton: {
      margin: '0.5rem',
      height: '10px !important'
    }
  },
  '@media screen and (min-width: 600px, max-width: 959px)': {
    ShowMoreButtonContainer: {
      justifyContent: 'center',
      display: 'flex',
      marginTop: '1rem'
    },
    ShowMoreButton: {
      margin: '0.5rem',
      height: '10px !important'
    },
    selectStatus: {
      alignSelf: 'center',
      maxWidth: '250px'
    },
    statusContainerContent: {
      justifyContent: 'center',
      alignItems: 'center',
    }
  }
}));