import { makeStyles, withStyles } from '@material-ui/core/styles';
import TableCell from '@material-ui/core/TableCell';

export const useStyles = makeStyles((theme) => ({
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
  label: {
    textTransform: 'uppercase',
    color: theme.primaryTextColor,
    fontSize: '0.75rem'
  }
}));

export const HeaderCell = withStyles((theme) => ({
  root: {
    fontWeight: 'bold',
    border: '0px',
    alignContent: 'left',
    padding: '16px',
    color: theme.quaternaryTextColor
  }
}))(TableCell);