import React from 'react';
import { useTranslation } from 'react-i18next';
import { useStyles } from './Button.css.js';
import PropTypes from 'prop-types';

export const ButtonWithIcon = ({text, disabled, type, callback, icon}) => {

  const classes = useStyles();
  const [t] = useTranslation('common');

  return (
    <button type={type} disabled={disabled} className={classes.buttonWithIcon} onClick={() => {
      if (callback) {
        callback();
      }
    }} ><div className={classes.buttonText}>{icon} {t(text)}</div>
    </button>
  );
};

ButtonWithIcon.propTypes = {
  text: PropTypes.string,
  type: PropTypes.string,
  disabled: PropTypes.bool,
  callback: PropTypes.func,
  icon: PropTypes.element,
  isSmaller: PropTypes.bool
};