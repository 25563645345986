import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useStyles } from './FiltersDrawer.css.js';
import { Drawer, IconButton } from '@material-ui/core';
import ClearIcon from '@material-ui/icons/Clear';
import { ButtonWithIcon } from 'components';
import { useTranslation } from 'react-i18next';
import { filterButtonIcon } from 'resources';

export const FiltersDrawer = ({ children, header = 'filters' }) => {
  const classes = useStyles();
  const [t] = useTranslation('common');
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    setIsOpen(false);
  }, [children]);

  return (
    <div>
      <div className={classes.buttonContainer}>
        <ButtonWithIcon text={t(header)} type='submit' icon={<img className={classes.filterIcon} src={filterButtonIcon} />} callback={() => setIsOpen(true)} />
      </div>
      <Drawer classes={{ paper: classes.paper, root: classes.root }} anchor='left' open={isOpen} onClose={() => setIsOpen(false)}>
        <IconButton className={classes.flexEnd} onClick={() => setIsOpen(false)}>
          <ClearIcon className={classes.closeImg} />
        </IconButton>
        <h3 className={classes.h}>{t(header)}</h3>
        {children}
      </Drawer>
    </div>
  );
};

FiltersDrawer.propTypes = {
  children: PropTypes.object,
  header: PropTypes.string,
};