import React from 'react';

import { Grid, Button } from '@material-ui/core';
import PropTypes from 'prop-types';
import { ShareIcon } from '../../../resources';
import { useStyles } from '../style';

const FormButtons = ({ onCancel, disabled }) => {

  const classes = useStyles();

  return (
    <Grid container
      direction='row'
      justifyContent='flex-end'
      spacing={0}>
      <Button
        variant='outlined'
        size='large'
        color='default'
        onClick={onCancel}
        className={[classes.buttonCancelCounterOffer, classes.buttonStyle].join(' ')}>
        Cancel
      </Button>
      <Button
        type='submit'
        variant='outlined'
        size='large'
        disabled={disabled}
        className={[classes.buttonCounterOffer, classes.buttonStyle].join(' ')}
        startIcon={<ShareIcon />}>
        Send
      </Button>
    </Grid>
  );
};

FormButtons.propTypes = {
  disabled: PropTypes.bool,
  onCancel: PropTypes.func,
};

export default FormButtons;