import React, { useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';

import { orderByEnum } from 'helpers';

import { useStyles, HeaderCell } from './EnhancedTableHead.css';

export const EnhancedTableHead = ({ order, orderBy, onRequestSort, columnConfiguration, setRowsPerPage }) => {
  const classes = useStyles();
  const createSortHandler = (property) => (e) => {
    onRequestSort(e, property);
  };

  const rowRef = useRef();
  let timer;
  const updateSize = () => {
    clearTimeout(timer);
    timer = setTimeout(() => {
      if (rowRef.current) {
        setRowsPerPage(rowRef.current.clientHeight);
      }
    }, 250);
  };

  window.addEventListener('resize', updateSize);

  useEffect(() => {
    if (rowRef.current) {
      setRowsPerPage(rowRef.current.clientHeight);
    }
  }, [rowRef, setRowsPerPage]);

  return (
    <TableHead>
      <TableRow ref={rowRef}>
        {columnConfiguration.map((headCell, index) => (
          <HeaderCell
            key={index}
            align={'left'}
            padding={headCell.disablePadding ? 'none' : 'normal'}
            sortDirection={orderBy === headCell.id ? order : false}>
            {headCell.id && headCell.disableSorting &&
              <div className={classes.label}>{headCell.label}</div>
            }
            {headCell.id && !headCell.disableSorting &&
              <TableSortLabel
                active={orderBy === headCell.id}
                direction={orderBy === headCell.id ? order : orderByEnum.asc}
                onClick={createSortHandler(headCell.id)}
                className={classes.label}>
                {headCell.label}
                {orderBy === headCell.id ? (
                  <span className={classes.visuallyHidden}>
                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                  </span>
                ) : null}
              </TableSortLabel>
            }
          </HeaderCell>
        ))}
      </TableRow>
    </TableHead>
  );
};

EnhancedTableHead.propTypes = {
  columnConfiguration: PropTypes.array.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  setRowsPerPage: PropTypes.func.isRequired,
  order: PropTypes.string.isRequired,
  orderBy: PropTypes.string.isRequired
};