import { makeStyles, withStyles } from '@material-ui/core/styles';
import Pagination from '@material-ui/lab/Pagination';

export const useStyles = makeStyles((theme) => ({
  container: {
    borderBottom: '1px solid ' + theme.borderColor,
    borderColor: theme.borderColor,
    width: '100%',
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    marginBottom: '3.5rem',
  },
  total: {
    color: theme.primaryTextColor,
    display: 'flex',
  },
  marginRight: {
    marginRight: '0.3rem',
    display: 'flex',
  },
  '@media screen and (max-width:960px)': {
    container: {
      padding: '0 5%'
    }
  },
  '@media screen and (min-width:427px, max-width:770px)': {
    container: {
      padding: '0'
    }
  },
  '@media screen and (max-width:426px)': {
    container: {
      padding: '0'
    }
  },
}));

export const StyledPagination = withStyles({
  root: {
    float: 'right'
  }
})(Pagination);