import React, { useState } from 'react';
import clsx from 'clsx';
import { Field, ErrorMessage, useFormikContext } from 'formik';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import Grid from '@material-ui/core/Grid';

import { useStyles } from './InputField.css.js';

export const NoteInputField = ({ inputName, type, placeholder, label, readOnly, className }) => {

  const classes = useStyles();
  const [t] = useTranslation('common');
  const [isFocus, setIsFocus] = useState(false);
  const { handleBlur } = useFormikContext();

  return (
    <div className={className}>
      <Grid item className={classes.labelContainer}>
        <span className={classes.label}>{t(label)}</span>
      </Grid>
      <Grid item className={isFocus ? classes.focusNoteContainer : classes.noteContainer}>
        <Grid container>
          <Grid item className={classes.noteInputContainer}>
            <Field
              className={clsx(classes.noteInput, { [classes.readOnly]: readOnly })}
              onFocus={() => setIsFocus(true)}
              onBlur={e => {
                handleBlur(e);
                setIsFocus(false);
              }}
              type={type}
              name={inputName}
              placeholder={t(placeholder)}
              readOnly={readOnly}
              component='textarea' />
            <ErrorMessage className={classes.noteValidation} name={inputName} component='div' />
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};

NoteInputField.propTypes = {
  inputName: PropTypes.string,
  type: PropTypes.string,
  placeholder: PropTypes.string,
  label: PropTypes.string,
  readOnly: PropTypes.bool,
  className: PropTypes.string
};
