import axios from 'axios';

import { getApiCoreUrl } from 'helpers';
import { baseApiErrorHandler } from 'services';

const baseUrl = `${getApiCoreUrl()}/api/v1/Offer`;

const createOffer = details => {
  return axios.post(baseUrl, details).catch(baseApiErrorHandler);
};

const confirmOffer = (data) => {
  return axios.post(`${baseUrl}/confirmation`, data).catch(baseApiErrorHandler);
};

const acceptCounterOffer = (id) => {
  return axios.post(`${baseUrl}/accept-counteroffer/${id}`).catch(baseApiErrorHandler);
};

const getOfferInfo = (tempCode) => {
  return axios.get(`${baseUrl}/info?tempCode=${tempCode}`);
};

const getCounterOfferById = id => {
  if (id) {
    const url = `${baseUrl}/counteroffer/${id}`;
    return axios.get(url).then(response => {
      return response.data;
    }).catch(baseApiErrorHandler);
  } else {
    return null;
  }
};

export const offerService = {
  createOffer,
  confirmOffer,
  getOfferInfo,
  acceptCounterOffer,
  getCounterOfferById,
};