import React, { useState } from 'react';
import { Field, ErrorMessage, useFormikContext } from 'formik';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import Grid from '@material-ui/core/Grid';

import { showPasswordIcon, hidePasswordIcon } from 'resources';

import { useStyles } from './InputField.css.js';

export const PasswordInputField = ({inputName, label, isRequired, profileForm = false}) => {

  const classes = useStyles();
  const [t] = useTranslation('common');
  const [ showPassword, setShowPassword ] = useState(false);
  const [ isFocus, setIsFocus ] = useState(false);
  const { handleBlur } = useFormikContext();

  return (
    <div>
      <Grid item className={classes.labelContainer}>
        <span className={classes.label}>{t(label)}</span>
        {isRequired && <span className={classes.isRequired}>*</span>}
      </Grid>
      <div className={isFocus ? classes.focusContainer : classes.container}>
        <Grid container justifyContent='space-between' alignItems='center'>
          <Grid item className={classes.passwordInputContainer}>
            <Field
              className={classes.input}
              onFocus={() => setIsFocus(true)}
              onBlur={e => {
                handleBlur(e);
                setIsFocus(false);
              }}
              type={showPassword ? 'text' : 'password'}
              name={inputName}/>
            <ErrorMessage className={classes.passwordValidation} name={inputName} component='div' />
          </Grid>
          <Grid item className={profileForm ? classes.passwordIconProfileForm : classes.passwordIcon} onClick={() => setShowPassword(!showPassword)}>
            <img src={showPassword ? showPasswordIcon : hidePasswordIcon} alt={''} />
          </Grid>
        </Grid>
      </div>
    </div>
  );
};

PasswordInputField.propTypes = {
  inputName: PropTypes.string,
  label: PropTypes.string,
  isRequired: PropTypes.bool,
  profileForm: PropTypes.bool
};
