import React from 'react';
import { FormControlLabel, FormControl, Checkbox, withStyles } from '@material-ui/core';
import PropTypes from 'prop-types';
import themeDefault from '../../themeDefault';
import { useStyles } from './InputField.css.js';

const BrandCheckbox = withStyles({
  root: {
    color: themeDefault.primaryTextColor,
    '&$checked': {
      color: themeDefault.primaryBackgroundColor,
    },
  },
  checked: {},
})((props) => <Checkbox color='default' {...props} />);

export const CheckboxInputField = ({ inputName, checked, label, disabled, onChange, className }) => {

  const classes = useStyles();

  return (
    <FormControl className={`${classes.checkboxInputControl} ${className}`}>
      <FormControlLabel
        control={<BrandCheckbox
          checked={checked}
          name={inputName}
          disabled={disabled}
          onChange={(evt) => {
            onChange(evt.target.checked);
          }} />
        }
        label={(<span className={classes.checkboxInputLabel}>{label}</span>)} />
    </FormControl>
  );
};

CheckboxInputField.propTypes = {
  inputName: PropTypes.string,
  checked: PropTypes.bool,
  label: PropTypes.string,
  disabled: PropTypes.bool,
  onChange: PropTypes.func,
  className: PropTypes.string
};
