import React, { useLocation } from 'react-router-dom';
import { Grid } from '@material-ui/core';
import { SetupAccount } from './SetupAccount';

import { useStyles } from './AuthPage.css.js';

export const AuthPage = () => {
  const classes = useStyles();
  const navLocation = useLocation();
  let renderComponent = null;

  switch (navLocation.pathname) {
    case '/setup_account':
      renderComponent = <SetupAccount/>;
      break;
  }

  return (
    <Grid container className={classes.fullHeight}>
      <Grid item xs={12} sm={12} md={12} xl={12} className={classes.formSectionRoot}>
        {renderComponent}
      </Grid>
    </Grid>
  );
};